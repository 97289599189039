import React, { useState, useEffect, useRef } from 'react';
import { useTheme } from '@mui/system';
import { makeStyles } from '@material-ui/core/styles';
//import ReactEcharts from 'echarts-for-react';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Box from '@mui/material/Box';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid';
// import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import ChargerApi from '../ChargerApi';
import { Divider, FormControl, Grid, InputLabel, MenuItem, Select, TablePagination, TextField, Typography, IconButton } from '@mui/material';
import { FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage } from '@material-ui/icons';
import { StyledDataGrid } from 'app/utils/utils';
import { StyledTable } from 'app/utils/utils';
import Tooltip from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
// import Tooltip from '@mui/material/Tooltip';
import { Span } from 'app/components/Typography';
import InfoIcon from '@mui/icons-material/Info';
import Loading from 'app/components/MatxLoading';
import LinearWithValueLabel from 'app/views/vehicle/shared/LinearWithValueLabel';
const useStyles = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
    display: 'flex',
    alignItems: 'center',
  },
  select: {
    marginLeft: theme.spacing(1),
  },
}));

const CustomPagination = ({ count, page, rowsPerPage, onChangePage, onChangeRowsPerPage, darkTheme }) => {
  const classes = useStyles();

  const handleFirstPageButtonClick = () => {
    onChangePage(0);
  };

  const handleBackButtonClick = () => {
    onChangePage(page - 1);
  };

  const handleNextButtonClick = () => {
    onChangePage(page + 1);
  };

  const handleLastPageButtonClick = () => {
    onChangePage(Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  const backgroundColor = darkTheme ? '#333333' : '#f9f9f9';
  const textColor = darkTheme ? '#fff' : 'rgba(0, 0, 0, 0.87)';
  const dropdownBackgroundColor = darkTheme ? '#000' : '#fff';
  const dropdownTextColor = darkTheme ? '#fff' : 'rgba(0, 0, 0, 0.87)';

  return (
    <Box className={classes.root} style={{ backgroundColor, color: textColor, marginRight: '10px', height: '48px' }}>
      <Select
        className={classes.select}
        value={rowsPerPage}
        onChange={(e) => onChangeRowsPerPage(e)}
        style={{ color: textColor }}
        IconComponent={() => <ArrowDropDownIcon style={{ color: darkTheme ? '#fff' : '#000', position: 'absolute', right: '8px', top: '50%', transform: 'translateY(-50%)' }} />}
        MenuProps={{
          PaperProps: {
            style: {
              backgroundColor: dropdownBackgroundColor,
              color: dropdownTextColor,
            },
          },
        }}
      >
        {[10, 20,].map((pageSize) => ( // Added -1 for "All" option
          <MenuItem key={pageSize} value={pageSize}>
            {pageSize === -1 ? 'All' : pageSize} {/* Display "All" instead of -1 */}
          </MenuItem>
        ))}
      </Select>

      <Typography variant="body2" style={{ color: textColor }}>Rows per page</Typography>
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
        <FirstPage style={{ color: darkTheme ? '#fff' : 'rgba(0, 0, 0, 0.54)' }} />
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        <KeyboardArrowLeft style={{ color: darkTheme ? '#fff' : 'rgba(0, 0, 0, 0.54)' }} />
      </IconButton>
      <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
        <KeyboardArrowRight style={{ color: darkTheme ? '#fff' : 'rgba(0, 0, 0, 0.54)' }} />
      </IconButton>
      <IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="last page">
        <LastPage style={{ color: darkTheme ? '#fff' : 'rgba(0, 0, 0, 0.54)' }} />
      </IconButton>
      <Typography variant="body2">{`${page + 1} of ${Math.ceil(count / rowsPerPage)}`}</Typography>
    </Box>
  );
};

const ChargingStMonitor = ({ darkTheme }) => {
  // const classes = useStyles();
  const api = new ChargerApi(); // Update with the correct instantiation
  const [data, setReportData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [error, setError] = useState(false);
  const searchData = useRef({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(200);
  const [loading, setLoading] = useState(false);

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };



  useEffect(() => {
    setLoading(true);
    let isCanceled = false;
    getChargingMonitorReport();
    const intervalId = setInterval(() => {
      getChargingMonitorReport();
    }, 10000);

    return () => {
      isCanceled = true;
      clearInterval(intervalId);
    };
  }, [error]);

  const handleRefreshSearch = async () => {
    setFilteredData(
      data.filter((o) => {
        let res = true;
        if (searchData.current.hasOwnProperty('charge_box_id'))
          res = res && o['charge_box_id'].toLocaleString().includes(searchData.current['charge_box_id']);
        if (searchData.current.hasOwnProperty('charge_bay_name'))
          res = res && o['charge_bay_name'].toLocaleString().includes(searchData.current['charge_bay_name']);
        if (searchData.current.hasOwnProperty('vehicle_id') && o['vehicle_id'])
          res = res && o['vehicle_id'].toLocaleString().includes(searchData.current['vehicle_id']);
        if (searchData.current.hasOwnProperty('status'))
          res = res && o['status'].toLocaleString().includes(searchData.current['status']);
        return res;
      })
    );
  };

  const handleSearch = (e) => {
    searchData.current = { ...searchData.current, [e.target.name]: e.target.value };
    handleRefreshSearch();
  };

  const getChargingMonitorReport = async () => {
    let isCanceled = false;
    api
      .showChargerStMonitorReport()
      .then(({ data }) => {
        if (!isCanceled) {
          // Add unique IDs to each row
          const newData = data.map((row, index) => ({
            ...row,
            id: index, // You can adjust this to use a more appropriate unique identifier
          }));
          const map = {};
          data.forEach(obj => {
            const key = obj['charge_box_id'] + '-' + obj['connector_id'];
            map[key] = obj;
          });

          console.log(data)

          localStorage.setItem("chargerMonitor", JSON.stringify(map));

          setReportData(newData);
          setFilteredData(
            newData.filter((o) => {
              let res = true;
              if (searchData.current.hasOwnProperty('charge_box_id'))
                res = res && o['charge_box_id'].toLocaleString().includes(searchData.current['charge_box_id']);
              if (searchData.current.hasOwnProperty('charge_bay_name'))
                res = res && o['charge_bay_name'].toLocaleString().includes(searchData.current['charge_bay_name']);
              if (searchData.current.hasOwnProperty('vehicle_id') && o['vehicle_id'])
                res = res && o['vehicle_id'].toLocaleString().includes(searchData.current['vehicle_id']);
              if (searchData.current.hasOwnProperty('status'))
                res = res && o['status'].toLocaleString().includes(searchData.current['status']);
              return res;
            })
          );

          setLoading(false);
        }
      })
      .catch((e) => {
        setError(true);
      });
  };

  const statusColors = { Charging: '#008000', Available: '#4169e1', Preparing: '#FFAF38', Faulted: '#FF5733', Reserved: '#4BC0C0', Unavailable: '#878E98', Finishing: 'rgb(218, 165, 32)' };

  const columns = [
    { field: 'charge_bay_name', headerName: 'Transformer', width: 160, align: 'left' },
    {
      field: 'charge_box_id',
      headerName: 'Charger ID - Connector',
      width: 200,
      align: 'left',
      valueGetter: (params) => `${params.row.charge_box_id} - ${params.row.connector_id}`,
    },
    { field: 'transaction_pk', headerName: 'Transaction ID', width: 160, align: 'left' },
    { field: 'vehicle_license_plate', headerName: 'Vehicle License Plate', width: 200, align: 'left' },
    { field: 'mac_address', headerName: 'Mac Address.', width: 150,  },
    { field: 'Celsius', headerName: 'Cable/Outlet Temp. (°C)', width: 160, align: 'left', valueGetter: (params) => params.row['Celsius'] !== undefined ? parseFloat(params.row['Celsius']).toFixed(2) : '', },
    { field: 'power', headerName: 'Power (kW)', width: 140, align: 'left', valueGetter: (params) => params.row['power'] !== undefined ? parseFloat(params.row['power']).toFixed(2) : '', renderHeader: () => renderHeaderWithIcon('Power') },
    { field: 'V', headerName: 'Voltage (V)', width: 140, align: 'left', valueGetter: (params) => params.row['V'] !== undefined ? parseFloat(params.row['V']).toFixed(2) : '', renderHeader: () => renderHeaderWithIcon('Voltage') },
    { field: 'A', headerName: 'Current (A)', width: 140, align: 'left', valueGetter: (params) => params.row['A'] !== undefined ? parseFloat(params.row['A']).toFixed(2) : '', renderHeader: () => renderHeaderWithIcon('Current') },
    {
      field: 'status',
      headerName: 'Status',
      width: 180,
      align: 'center',
      renderCell: (params) => (
        <div style={{ width: '100%', padding: '1rem', margin: 0, background: statusColors[params.row['status']], color: 'white', textAlign: 'center', fontSize: '16px' }}>
          {params.row['status'] ? params.row['status'] : '-'}
        </div>

      ),
    },

    { field: 'Percent', headerName: 'Battery Info.', width: 200, renderCell: renderProgress },


  ];

  function renderProgress(params) {
    return (
      <div style={{ position: "relative", display: "flex", alignItems: "center", textAlign: "center", width: '200px', height: "3rem", marginLeft: "5px", padding: 0 }}>
        <div style={{
          height: "100%", zIndex: "1", width: "100%", position: "absolute", top: 0, right: 0
          , marginLeft: "2px", marginRight: "2px"
        }}><LinearWithValueLabel value={params.row['Percent']} />
        </div>
        <div style={{ color: "white", textAlign: "center", width: "100%", align: "center", padding: "0 3px 0 3px", zIndex: "1", position: "relative" }}>
          {params.row['Percent'] && params.row['Percent'] + "/100%"}
          {/* <a style={{ fontSize: "13px" }}>{dateTimeFormatter(row['start_time'])}</a> */}
          {/* &nbsp;{parseInt(row['kWh'])} kWh */}
        </div>
      </div>
    )
  }

  const renderHeaderWithIcon = (columnName) => {
    if (['Voltage', 'Current', 'Power'].includes(columnName)) {
      return (
        <Box display="flex" alignItems="center">
          <Typography style={{ fontSize: '16px', fontWeight: 'bold' }}>{columnName}</Typography>
          <Tooltip title={`Peak ${columnName}`}>
            <IconButton>

              <InfoOutlinedIcon style={{ color: darkTheme ? '#F2E34C' : 'black', width: '15px', height: '15px' }} />
            </IconButton>
          </Tooltip>
        </Box>
      );
    }
    return <Typography variant="subtitle1">{columnName}</Typography>;
  };
  // const modifiedData = data.map((row, index) => ({ ...row, id: index }));
  // Define background color and text color based on dark theme
  // Define background color and text color based on dark theme
  const backgroundColor = darkTheme ? '#333333' : '#f9f9f9';
  const backgroundColorOdd = darkTheme ? '#333333' : '#f9f9f9';
  const backgroundColorEven = darkTheme ? '#474747' : '#e0e0e0';
  const textColor = darkTheme ? '#F2E34C' : 'black';
  const cellColor = darkTheme ? '#fff' : 'black';

  const useStyles = makeStyles((theme) => ({
    exportButton: {
      display: 'flex',
      alignItems: 'center',
    },
  }));
  function CustomExportButton() {
    const classes = useStyles();

    return (
      <div className={classes.exportButton}>
        <GridToolbarExport style={{ color: darkTheme ? '#fff' : '#333' }} csvOptions={{ fileName: "Charger Monitor" }} />
        <Tooltip title="[Note: Select rows per page to export the data.] ">
          <InfoIcon style={{ color: darkTheme ? '#e0e0e0' : '#333', fontSize: '15px', marginLeft: '5px' }} />
        </Tooltip>
      </div>
    );
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton style={{ color: darkTheme ? '#fff' : '#333' }} />
        <GridToolbarFilterButton style={{ color: darkTheme ? '#fff' : '#333' }} />
        <GridToolbarDensitySelector style={{ color: darkTheme ? '#fff' : '#333' }} />
        <CustomExportButton />
      </GridToolbarContainer>

    );
  }
  return (
    <div style={{ backgroundColor }}>
      <Paper style={{ height: '60vh', width: '100%', backgroundColor }}>
        {/* <CustomPagination
          count={data.length}
          page={page}
          rowsPerPage={rowsPerPage}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        /> */}

        {
          loading ?
            <Loading /> :
            <DataGrid
              rows={filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
              columns={columns}
              pageSize={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPageOptions={[5, 10, 25, 50]}
              onRowsPerPageChange={handleChangeRowsPerPage}
              disableSelectionOnClick
              components={{
                Toolbar: CustomToolbar, // Custom toolbar component
                Pagination: (props) => (
                  <CustomPagination
                    count={data.length}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    darkTheme={darkTheme}
                  />
                ),
                NoRowsOverlay: () => (
                  <div style={{ textAlign: 'center', color: textColor }}>
                    {/* Custom no rows overlay */}
                  </div>
                ),
              }}
              sx={{
                '& .MuiDataGrid-cell': {
                  color: cellColor,
                  border: 'none',
                  fontSize: '16px'
                },
                '& .MuiDataGrid-row:nth-of-type(odd)': {
                  backgroundColor: backgroundColorOdd, // Background color for odd rows
                  border: 'none',
                },
                '& .MuiDataGrid-row:nth-of-type(even)': {
                  backgroundColor: backgroundColorEven, // Background color for even rows
                  border: 'none',
                },
                '& .MuiDataGrid-columnHeader': {
                  backgroundColor: backgroundColor,
                  color: textColor,
                  fontSize: '16px'
                },
                '& .MuiDataGrid-iconButtonContainer': {
                  visibility: 'visible',
                },
                '& .MuiDataGrid-sortIcon': {
                  opacity: 'inherit !important',
                  color: darkTheme ? '#fff' : '#000'
                },
                '& .MuiDataGrid-menuIconButton': {
                  opacity: 'inherit !important',
                  color: darkTheme ? '#fff' : '#000',
                }
              }}
            />
        }
      </Paper>
    </div>
  );
};

export default ChargingStMonitor;

// import React, { useState, useEffect, useRef } from 'react';
// import { useTheme } from '@mui/system';
// import { makeStyles } from '@material-ui/core/styles';
// //import ReactEcharts from 'echarts-for-react';
// import Paper from '@mui/material/Paper';
// import TableBody from '@mui/material/TableBody';
// import TableCell from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
// import SearchIcon from '@mui/icons-material/Search';
// import InputAdornment from '@mui/material/InputAdornment';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// // import TablePagination from '@mui/material/TablePagination';
// import TableRow from '@mui/material/TableRow';
// import ChargerApi from '../ChargerApi';
// import { FormControl, Grid, MenuItem, Select, TablePagination, TextField } from '@mui/material';
// import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
// import { StyledTable } from 'app/utils/utils';

// const useStyles = makeStyles({
//   table: {
//     '& .MuiTableCell-root': {
//       borderLeft: '1px solid rgba(224, 224, 224, 1)',
//     },

//   },
//   lensIcon: {
//     color: 'white',
//   },
//   // columnHeader: {
//   //   borderBottom: '2px solid white', // Change the color of the line below column headers
//   // },
// });

// const columns = [
//   // { id: 'depo_name', label: 'Depo ID', minWidth: 170, align: 'center' },
//   { id: 'charge_bay_name', label: 'Transformer', width: 140, align: 'left' },
//   { id: 'charge_box_id', label: 'Charger ID - Connector', width: 200, align: 'left' },
//   { id: 'transaction_pk', label: 'Transaction ID', width: 150, align: 'center' },

//   // {
//   //   id: 'connector_id',
//   //   label: 'Connector',
//   //   maxWidth: 170,
//   //   align: 'center',
//   //   format: (value) => value.toLocaleString('en-US'),
//   // },
//   {
//     id: 'vehicle_license_plate',
//     label: 'Vehicle License Plate',
//     width: 200,
//     align: 'left',
//     format: (value) => value.toLocaleString('en-US'),
//   },
//   // {
//   //   id: 'vehicle_license_plate',
//   //   label: 'Vehicle License Plate',
//   //   width: 280,
//   //   align: 'left',
//   //   format: (value) => value.toLocaleString('en-US'),
//   // },
//   {
//     id: 'Celsiu',
//     label: 'Cable Temp. (°C)',
//     width: 150,
//     align: 'right',
//     format: (value) => value.toFixed(2),
//   },
//   {
//     id: 'power',
//     label: 'Power (kW)',
//     width: 120,
//     align: 'right',
//     format: (value) => value.toFixed(2),
//   },
//   // {
//   //   id: 'consumption',
//   //   label: 'Power (kW)',
//   //   minWidth: 120,
//   //   align: 'center',
//   //   format: (value) => value.toFixed(2),
//   // },
//   {
//     id: 'V',
//     label: 'Voltage (V)',
//     width: 120,
//     align: 'right',
//     format: (value) => value.toFixed(2),
//   },
//   {
//     id: 'A',
//     label: 'Current (A)',
//     width: 120,
//     align: 'right',
//     format: (value) => value.toFixed(2),
//   },

//   {
//     id: 'status',
//     label: 'Status',
//     width: 170,
//     align: 'center',
//     format: (value) => value.toFixed(2),
//   },
// ];

// const ChargingStMonitor = ({ height, color = [], darkTheme }) => {
//   const theme = useTheme();
//   const classes = useStyles();
//   const [data, setReportData] = useState([]);
//   const [filteredData, setFilteredData] = useState([]);
//   const [error, setError] = useState(false);
//   const searchData = useRef({});
//   const api = new ChargerApi();
//   const [page, setPage] = React.useState(0);
//   const [rowsPerPage, setRowsPerPage] = React.useState(10);

//   const handleChangePage = (event, newPage) => {
//     setPage(newPage);
//   };

//   const handleChangeRowsPerPage = (event) => {
//     setRowsPerPage(parseInt(event.target.value, 10));
//     setPage(0);
//   };

//   useEffect(() => {
//     let isCanceled = false;
//     getChargingMonitorReport();
//     const intervalId = setInterval(() => {
//       getChargingMonitorReport();

//     }, 10000);



//     return () => {
//       isCanceled = true;
//       clearInterval(intervalId);
//     };
//   }, [error]);

//   const handleRefreshSearch = async () => {

//     setFilteredData(data.filter(o => {
//       let res = true;
//       if (searchData.current.hasOwnProperty('charge_box_id'))
//         res = res && o['charge_box_id'].toLocaleString().includes(searchData.current['charge_box_id']);
//       if (searchData.current.hasOwnProperty('charge_bay_name'))
//         res = res && o['charge_bay_name'].toLocaleString().includes(searchData.current['charge_bay_name']);
//       if (searchData.current.hasOwnProperty('vehicle_id') && o['vehicle_id'])
//         res = res && o['vehicle_id'].toLocaleString().includes(searchData.current['vehicle_id']);
//       if (searchData.current.hasOwnProperty('status'))
//         res = res && o['status'].toLocaleString().includes(searchData.current['status']);
//       return res;
//     })
//     )

//   }




//   const handleSearch = (e) => {
//     searchData.current = { ...searchData.current, [e.target.name]: e.target.value };
//     handleRefreshSearch();
//   }

//   const getChargingMonitorReport = async () => {
//     let isCanceled = false;
//     api
//       .showChargerStMonitorReport()
//       .then(({ data }) => {
//         if (!isCanceled) {
//           console.log(data)

//           const map = {};
//           data.forEach(obj => {
//             const key = obj['charge_box_id']+'-' + obj['connector_id'];
//             map[key] = obj;
//           });

//           localStorage.setItem("chargerMonitor", JSON.stringify(map));


//           setReportData(data);
//           //alert(JSON.stringify(data))

//           setFilteredData(data.filter(o => {
//             let res = true;
//             if (searchData.current.hasOwnProperty('charge_box_id'))
//               res = res && o['charge_box_id'].toLocaleString().includes(searchData.current['charge_box_id']);
//             if (searchData.current.hasOwnProperty('charge_bay_name'))
//               res = res && o['charge_bay_name'].toLocaleString().includes(searchData.current['charge_bay_name']);
//             if (searchData.current.hasOwnProperty('vehicle_id') && o['vehicle_id'])
//               res = res && o['vehicle_id'].toLocaleString().includes(searchData.current['vehicle_id']);
//             if (searchData.current.hasOwnProperty('status'))
//               res = res && o['status'].toLocaleString().includes(searchData.current['status']);
//             return res;
//           })
//           )
//         }
//       })
//       .catch((e) => {
//         setError(true);
//         // console.log('Cannot load report data', e);
//       })
//   };
//   const statusColors = { Charging: '#008000', Available: '#4169e1', Preparing: '#FFAF38', Faulted: '#FF5733', Reserved: '#4BC0C0', Unavailable: '#878E98', Finishing: 'rgb(218, 165, 32)' }

//   return (
//     <Grid height={"100%"} width={"100%"}>
//       <Paper sx={{ width: '100%', overflow: 'hidden', height: "100%", backgroundColor: darkTheme ? '#333333' : '#fff' }}>

//         <TableContainer style={{ height: "60vh", backgroundColor: darkTheme ? '#333333' : '#fff' }} >
//           <StyledTable stickyHeader aria-label="a dense table" >
//             <TableHead

//             >
//               <TableRow style={{ zIndex: 998888, backgroundColor: darkTheme ? '#333333' : '#eeeeee' }}>
//                 {columns.map((column) => (
//                   <TableCell
//                     key={column.id}
//                     align={column.align}
//                     minWidth={column.minWidth}
//                     width={column.width}
//                     style={{
//                       background: darkTheme ? '#333333': '#eeeeee',
//                       color: darkTheme ? '#F2E34C' : 'black',
//                       minWidth: column.minWidth,
//                       width: column.width,
//                       padding: 10
//                     }}
//                   >
//                     <div style={{ height: "2rem" }}>{column.label}</div>


//                     {
//                       column.id === 'kW' | column.id === 'A' || column.id === 'V' || column.id === 'power'
//                         || column.id === 'status' || column.label === 'Cable Temp. (°C)'
//                         ? (
//                           column.id === 'status' ? (
//                             <div key={column.id} style={{ width: "100%", padding: 0, marging: 0, height: "2rem" }}>
//                               <FormControl variant="standard" sx={{ width: "100%" }}>
//                                 {/* <InputLabel id="select-label">All</InputLabel> */}
//                                 <Select
//                                   labelId="demo-simple-select-standard-label"
//                                   id="demo-simple-select-standard"
//                                   name='status'
//                                   // value={selectedValue}
//                                   label="Select an Option"
//                                   onChange={handleSearch}
//                                   fullWidth
//                                   SelectDisplayProps={{
//                                     style: { color: darkTheme ? '#fff' : '#000' }, // Change the text color of the dropdown menu
//                                   }}
//                                   IconComponent={(props) => (
//                                     <ArrowDropDownIcon style={{ color: darkTheme ? '#fff' : '#000' }} /> // Change the color of the dropdown icon
//                                   )}
//                                 >
//                                   {/* <Divider /> */}
//                                   <MenuItem value=""
//                                     style={{ display: 'block', padding: "10px", textAlign: "center", backgroundColor: darkTheme ? '#000' : '#fff', color: darkTheme ? '#fff' : '#000' }}>All</MenuItem>
//                                   {/* <Divider /> */}
//                                   <MenuItem value="Available"
//                                     style={{ display: 'block', padding: "10px", textAlign: "center", backgroundColor: darkTheme ? '#000' : '#fff', color: darkTheme ? '#fff' : '#000' }}>Available</MenuItem>
//                                   {/* <Divider /> */}
//                                   <MenuItem value="Charging"
//                                     style={{ display: 'block', padding: "10px", textAlign: "center", backgroundColor: darkTheme ? '#000' : '#fff', color: darkTheme ? '#fff' : '#000' }}>Charging</MenuItem>
//                                   {/* <Divider /> */}
//                                   <MenuItem value="Reserved"
//                                     style={{ display: 'block', padding: "10px", textAlign: "center", backgroundColor: darkTheme ? '#000' : '#fff', color: darkTheme ? '#fff' : '#000' }}>Reserved</MenuItem>
//                                   {/* <Divider /> */}
//                                   <MenuItem value="Fault"
//                                     style={{ display: 'block', padding: "10px", textAlign: "center", backgroundColor: darkTheme ? '#000' : '#fff', color: darkTheme ? '#fff' : '#000' }}>Fault</MenuItem>
//                                   {/* <Divider /> */}
//                                   <MenuItem value="Unavailable"
//                                     style={{ display: 'block', padding: "10px", textAlign: "center", backgroundColor: darkTheme ? '#000' : '#fff', color: darkTheme ? '#fff' : '#000' }}>Unavailable</MenuItem>
//                                   {/* <Divider /> */}
//                                 </Select>
//                               </FormControl>
//                             </div>
//                           )
//                             : <div style={{ height: "2rem" }}></div>
//                         ) :
//                         (<div key={column.id} style={{ padding: 0, marging: 0, height: "2rem" }}>
//                           <TextField
//                             name={column.id}
//                             style={{ padding: 0, width: '100%' }}
//                             minWidth={column.minWidth}
//                             onChange={handleSearch}
//                             InputProps={{
//                               startAdornment: (
//                                 <InputAdornment position="start">
//                                   <SearchIcon style={{ color: darkTheme ? '#fff' : '#000' }} />
//                                 </InputAdornment>
//                               ),
//                               style: { color: darkTheme ? '#fff' : '#000' },
//                             }}
//                             variant="standard"
//                             fullWidth
//                           />
//                         </div>)
//                     }

//                   </TableCell>
//                 ))}
//               </TableRow>

//             </TableHead>
//             <TableBody>
//               {filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
//                 <TableRow hover role="checkbox" tabIndex={-1} style={{ backgroundColor: darkTheme ? `${index % 2 !== 0 ? ('#333333') : ('#454545')}` : `${index % 2 !== 0 ? ('#eeeeee') : ('#f9f9f9')}` }} key={row.code}>
//                   {columns.map((column) => {
//                     if (column.id === "status") {
//                       return <TableCell style={{ padding: 0, paddingLeft: 10 }} key={column.id} align={column.align} minWidth={column.minWidth}
//                         width={column.width}
//                       >
//                         <div style={{ width: "100%", padding: "1rem", margin: 0, background: statusColors[row[column.id]], color: "white" }}>
//                           {row[column.id] ? row[column.id] : "-"}

//                         </div>

//                       </TableCell>
//                     }
//                     else if (column.id === 'charge_box_id') {
//                       return <TableCell style={{ padding: 0, paddingLeft: 10, color : darkTheme ? '#fff' : '#000' }}
//                         key={column.id} align={column.align} minWidth={column.minWidth}>
//                         {row[column.id]}
//                         {row['connector_id'] !== null ? " - " + row['connector_id'] : ""}
//                       </TableCell>
//                     }
//                     else {
//                       return <TableCell style={{ padding: 0, paddingLeft: 10, color : darkTheme ? '#fff' : '#000' }}
//                         key={column.id} align={column.align} minWidth={column.minWidth}>
//                         {column.format && typeof row[column.id] === 'number'
//                           ? column.format(row[column.id]) : row[column.id] ? row[column.id] : "-"}
//                       </TableCell>
//                     }


//                   })}
//                 </TableRow>
//               ))}
//             </TableBody>
//           </StyledTable>
//         </TableContainer>
//         <TablePagination
//           rowsPerPageOptions={[5, 10, 25, 50]} // Customize the available rows per page options
//           component="div"
//           count={filteredData.length}
//           rowsPerPage={rowsPerPage}
//           page={page}
//           onPageChange={handleChangePage}
//           onRowsPerPageChange={handleChangeRowsPerPage}
//           SelectProps={{
//             style: { color: darkTheme ? '#fff' : '#000' }, // Change the text color of the page size select
//             IconComponent: (props) => (
//               <ArrowDropDownIcon style={{ fill: darkTheme ? '#fff' : '#000' }} />
//             )
//           }}
//           labelRowsPerPage={<span style={{ color: darkTheme ? '#fff' : '#000' }}>Rows per page:</span>} // Change the text color of the pagination caption
//           rowsPerPageOptionsLabel={<span style={{ color: darkTheme ? '#fff' : '#000' }}>Rows per page</span>}
//           labelDisplayedRows={({ from, to, count }) => (
//             <span style={{ color: darkTheme ? '#fff' : '#000' }}>{`${from}-${to} of ${count}`}</span> // Change the color of the text "1-10 of 10"
//           )}
//           nextIconButtonProps={{ 'aria-label': 'Next Page', style: { color: darkTheme ? '#fff' : '#000' } }}
//           backIconButtonProps={{ 'aria-label': 'Previous Page', style: { color: darkTheme ? '#fff' : '#000' } }}
//         />
//       </Paper>
//     </Grid>

//   );
// };

// export default ChargingStMonitor;