import React, { useEffect, useRef } from 'react';
import * as echarts from 'echarts';
import { chartOptions } from 'app/utils/utils';
import { DateTime } from 'luxon';

const SocChart = ({ data = [], darkTheme }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    const chartDom = chartRef.current;
    const myChart = echarts.init(chartDom);
    const textColor = darkTheme ? '#F2E34C' : '#000'; // Conditionally set text color
    const titleColor = darkTheme ? '#F2E34C' : '#000';
    const tooltipColor = darkTheme ? '#333333' : '#ffffff';
    const labelColor = darkTheme ? '#000' : '#6a7985';

    const orgTimezone = localStorage.getItem("orgTimezone");


    const option = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          label: {
            backgroundColor: labelColor,
            color: textColor
          }
        },
        backgroundColor: tooltipColor,
        textStyle: {
          color: darkTheme ? '#F2E34C' : '#000' // Set text color based on theme
        },
        //   formatter: function(params) {
        //     return params.map(function (item) {
        //         return item.axisValueLabel + '<br/>' + item.seriesName + ': ' + item.value + ' kW';
        //     }).join('<br>');
        // }
      },
      legend: {
        data: ['SoC'], // Set legend data
        textStyle: {
          color: darkTheme ? '#F2E34C' : '#000'
        }
      },
      toolbox: {
        show: true,
        top: '10px',
        right: 130,
        feature: {
          saveAsImage: {
            show: true,
            title: 'Download',
            name: 'SoC',
            type: 'png'
          }
        },
        iconStyle: {
          borderColor: darkTheme ? '#F2E34C' : '#000'
        }
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: data.map(obj => 
          // new Date(obj.value_timestamp)
          // .toLocaleTimeString(undefined, { hour12: true, hour: '2-digit', minute: '2-digit' })),
          DateTime.fromISO(obj.value_timestamp)  // Convert timestamp
            .setZone(orgTimezone)  // Convert to `orgTimezone` from state
            .toFormat("hh:mm a") // Format to HH:mm AM/PM
          ),
        name: 'Time',
        axisLabel: chartOptions().axisLabel,
        axisLabel: {
          color: textColor // Set text color based on theme
        },
        nameLocation: 'middle',
        nameGap: 35,
        nameTextStyle: chartOptions().nameTextStyle,
        nameTextStyle: {
          color: textColor // Set text color based on theme
        }
      },
      yAxis: {
        type: 'value',
        name: 'SoC (%)',
        nameLocation: 'middle',
        nameGap: 40,
        nameTextStyle: chartOptions().nameTextStyle,
        nameTextStyle: {
          color: textColor // Set text color based on theme
        },
        axisLabel: chartOptions().axisLabel,
        axisLabel: {
          color: textColor // Set text color based on theme
        },
      },
      series: [
        {
          name: 'SOC',
          color:'#c54c82',
          type: 'line',
          data: data.map(obj => obj.value),
          smooth: true,
          lineStyle: {
            width: 4, // Set line width if needed
            color: '#c54c82' // Set line color to red
          },
          showSymbol: false,
          areaStyle: {
            opacity: 0.8,
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: '#f4aeba'
              },
              // {
              //   offset: 1,
              //   color: 'rgb(1, 191, 236)'
              // }
            ])
          },
          emphasis: {
            focus: 'series'
          },

        }
      ]
    };

    option && myChart.setOption(option);

    return () => {
      myChart.dispose(); // Clean up when the component unmounts
    };
  }, [data.length]); // Empty dependency array to run this effect only once

  return <div ref={chartRef} style={{ width: '100%', height: '25rem',paddingBottom:"15px" }}></div>;
};

export default SocChart;
