import React, { useState, useEffect } from 'react';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
    Grid,
    Typography,
    Divider,
    styled,
    Stack
} from '@mui/material';
import ReportApi from 'app/views/reports/ReportApi';
import { Papar2, Title2 } from './UtilizationTable';

export const CarbonEmission = ({ timeFilterDto, darkTheme, selectedFilter }) => {

    const reportApi = new ReportApi();
    const [data, setData] = useState({});

    console.log("hh", timeFilterDto);

    const [loading, setLoading] = useState(false);

    useEffect(() => {

        fetchCarbonEmission();
    }, [timeFilterDto, selectedFilter]);


    const fetchCarbonEmission = () => {

        const today = new Date();
        today.setHours(0, 0, 0, 0); // Set to midnight
        if (selectedFilter === "today") {
            timeFilterDto = {};
        }
        else if (selectedFilter === "yesterday") {
            const yesterday = new Date();
            yesterday.setDate(yesterday.getDate() - 1);
            yesterday.setHours(0, 0, 0, 0); // Set to midnight
            timeFilterDto = { from: yesterday.valueOf(), to: today.valueOf() };

        } else if (selectedFilter === "last15days") {
            const last15Days = new Date();
            last15Days.setDate(last15Days.getDate() - 15);
            last15Days.setHours(0, 0, 0, 0); // Set to midnight
            timeFilterDto = {
                from: last15Days.valueOf()
                , to: today.setHours(0, 0, 0, 0).valueOf()
            }

        } else if (selectedFilter === "lastMonth") {
            const lastMonth = new Date();
            lastMonth.setDate(lastMonth.getDate() - 30);
            lastMonth.setHours(0, 0, 0, 0); // Set to midnight
            timeFilterDto = {
                from: lastMonth.valueOf()
                , to: today.setHours(0, 0, 0, 0).valueOf()
            }

        }
        setLoading(true);
        reportApi
            .getCarbonEmission(timeFilterDto)
            .then(({ data }) => {
                console.log(data)
                setData(data);
            })
            .catch((e) => {
                console.error('Cannot load report data', e);
            })
            .finally(() => setLoading(false));
    };



    return (


        <Grid height='34vh'>
            <Papar2 elevation={3} style={{
                background: '#9fcba1',
                color: 'black', height: '100%'
            }} >


                <Grid container alignItems="center" height='100%' justifyContent="center">

                    {/* Title */}
                    <Grid item width={"100%"} textAlign="center">
                        <Typography variant='h6'>Carbon Emission</Typography>
                    </Grid>

                    {/* Image Container (Centered) */}
                    <Grid item display="flex" justifyContent="center" alignItems="center">
                        <img src="/co.png" alt="CO2 icon" style={{ height: '7rem', width: '190px' }} />
                    </Grid>

                    {/* Emission Data */}
                    <Grid item width={"100%"} textAlign="center">
                        <Typography variant="h5" gutterBottom>
                            <b>{parseFloat(data.emission ?? 0).toFixed(2)}kg</b>
                        </Typography>
                    </Grid>

                </Grid>
            </Papar2 >




        </Grid>

    );

}