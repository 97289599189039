export const departureBoard = [
	"Block ID",
	"Schedule Departure Time",
	"Vehicle ID",
	"Vehicle Status",
	"Actual Departure Time",
];
export const arrivalBoard = [
	"Block ID",
	"Schedule Arrival Time",
	"Vehicle ID",
	"Vehicle Status",
	"Actual Arrival Time",
];
export const chargingStatusBoard = [
	"Vehicle ID",
	"Assigned Charger",
	"Arrival SoC",
	"Assigned Next Block",
	"Required SoC",
	"Current SoC",
];

export const chargerAssignment = [
	"Block ID",
	"Vehicle ID",
	"Schedule Arrival Time",
	"Charger ID",
	"Assigned Next Block",
	"Schedule Departure Time",
	"Required SoC",
];

export const blockAssignmentPlan = [
	"Vehicle ID",
	"Expected Arrival Time",
	"Assigned Next Block",
	"Schedule Departure Time",
	"Required SoC",
];
export const blockReAssignmentPlan = [
	"Vehicle ID",
	"Last Arrival Time (Scheduled)",
	"Assigned Next Block (Scheduled)",
	"Last Arrival Time (Actual)",
	"Arrival SoC (Actual)",
	"Re-Assigned Next Block",
	"Required SoC",
];
export const blockAssignmentFinal = [
	"Vehicle ID",
	"Current SoC",
	"Assigned Next Block (Scheduled)",
	"Schedule Departure Time",
];

export const FrontendUrl="http://csms.ev-opt.com/";
// export const FrontendUrl="https://landisandgyr-csms.ev-opt.com/";

