import * as React from 'react';
import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Grid } from '@mui/material';

function LinearProgressWithLabel(props) {

  return (
    <Grid sx={{ display: 'flex',height:"100%", alignItems: 'center',margin:0,padding:0 }}>
      <Grid sx={{ width: '100%',height:"100%", mr: 0,margin:0,padding:0 }}>
        <LinearProgress style={{height:"100%", transition: 'width 0.5s ease-in-out'}} variant="determinate" {...props} />
      </Grid>
      
      
    </Grid>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

export default function LinearWithValueLabel(props) {

  // console.log("props:" + props.value);
  const [progress, setProgress] = React.useState(10);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 100 ? 10 : prevProgress + 10));
    }, 800);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <Grid style={{ width: '100%',height:"100%",margin:0,padding:0 }}>

      {
             props.value!==undefined?<LinearProgressWithLabel value={props.value} />:""
      }    </Grid>
  );
}