import React, { useState, useEffect } from 'react';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Paper, Slide, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';

import FormControl from '@mui/material/FormControl';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import moment from 'moment';
import { makeStyles } from '@material-ui/core';
import { useThemeContext } from 'app/ThemeProvider';
import Loading from 'app/components/MatxLoading';
import ChargerApi from 'app/views/charger/ChargerApi';
import dayjs from 'dayjs';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles({
  root: {
    padding: '0',
  },
  subtitleRight: {
    margin: '20px 0 10px 0',
    backgroundColor: 'rgb(7, 128, 235)',
    padding: '10px 10px 10px 15px',
    borderRadius: '0 30px 30px 0',
    color: 'white',
    position: 'relative',
    boxShadow:
      '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
    marginLeft: 'inherit',
    width: 'fit-content',
  },
  field: {
    // height: '50vh',
    width: 'fit-content',
    // borderRadius: ({isMobile}) => isMobile ? "inherit" : '20px',
    margin: '10px 20px',
    '& .MuiOutlinedInput-input': {
      padding: '18.5px 27px',
    },
  },
  fieldDate: {
    // height: '50vh',
    width: 'fit-content',
    // borderRadius: ({isMobile}) => isMobile ? "inherit" : '20px',
    margin: '10px 20px',
    '& .MuiOutlinedInput-input': {
      padding: '18.5px 5px',
    },
  },
});

function OccpSignedUpdateFirmware({
  open,
  handleOpenDialog,
  handleCloseDialog,
  row,
  occpCommnad,
  chargeBoxId,
}) {
  const classes = useStyles();
  const api = new ChargerApi();
  const [signedUpdateFirmwareParams, setSignedUpdateFirmwareParams] = useState({});

  const [disabled, setDisabled] = useState(false);
  const [selectedStartDate, handleStartDateChange] = useState(new Date());
  const { darkTheme } = useThemeContext();

  const [firmware, setFirmware] = useState({});

  const handleClickOpen = () => {
    handleOpenDialog();
  };

  const handleClose = () => {
    // setValues([]);
    handleCloseDialog();
  };

  const handleSignedUpdateFirmwareParamsChange = (event) => {
    setSignedUpdateFirmwareParams({ ...signedUpdateFirmwareParams, [event.target.name]: event.target.value });
  };

  const handleFirmwareChange = (event) => {
    setFirmware({ ...firmware, [event.target.name]: event.target.value });
  };

  const saveUpdatedDetails = () => {
    setDisabled(true);

    signedUpdateFirmwareParams.firmware = firmware;

    api
      .signedUpdateFirmware(signedUpdateFirmwareParams, chargeBoxId)
      .then(({ data }) => {
        return toast.success('Request ' + data);
      })
      .catch((e) => {
        // console.log('Cannot load event data', e);
        return toast.error('Request failed');

      }).finally(() => setDisabled(false));
  };

  const { retryInterval, retries, requestId, } = signedUpdateFirmwareParams;
  const { location, retrieveDateTime, installDateTime, signingCertificate, signature } = firmware;

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      //keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      fullWidth
    >
      <DialogTitle id="alert-dialog-slide-title" style={{ padding: '0', backgroundColor: darkTheme ? '#333333' : '#f9f9f9' }}>


        <Grid container >
          <Grid container className={classes.subtitleRight} item xs={11}>
            <Grid item xs={8}>
              <Typography variant={'h5'} >
                Signed Update Firmware
              </Typography>
            </Grid>

            <Grid item xs={4} style={{ fontSize: '16px', paddingTop: '3px' }} >

              Charger ID:{chargeBoxId}

            </Grid>

          </Grid>

          <Grid item container xs={0.5}>
            <IconButton aria-label="close" color="inherit" onClick={handleClose}>
              <CloseIcon style={{ color: darkTheme ? '#fff' : '#000' }} />
            </IconButton>
          </Grid>
        </Grid>

      </DialogTitle>

      <DialogContent dividers style={{ backgroundColor: darkTheme ? '#333333' : '#f9f9f9', color: darkTheme ? '#fff' : '#000' }}>
        <ValidatorForm onError={() => null}>
          {' '}
          <Grid container justify={'left'} alignItems={'left'} direction={'column'}>
          <FormControl fullWidth style={{ marginTop: '10px' }}>
          <TextField
                type="text"
                variant="standard"
                name="location"
                label="Location (directory URI):"
                onChange={handleFirmwareChange}
                value={location || ''}
                InputLabelProps={{
                  style: { color: darkTheme ? '#fff' : '#000' },
                }}
                sx={{
                  "& .MuiInput-underline:before": {
                    borderBottomColor: darkTheme ? '#fff' : '#000',
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: darkTheme ? '#fff' : '#000',
                  },
                  "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                    borderBottomColor: darkTheme ? '#fff' : '#000',
                  },
                  "& .MuiInputBase-input": {
                    color: darkTheme ? '#fff' : '#000',
                  },
                }}
              />
            </FormControl>


            <FormControl fullWidth style={{ marginTop: '10px' }}>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MobileDateTimePicker
                  // value={retrieveDateTime}
                  slotProps={{ textField: { variant: 'standard', fullWidth: true } }}
                  label={<Typography style={{ color: darkTheme ? '#fff' : '#000' }}>Retrieve Date/Time</Typography>}

                  // minDate={dayjs().subtract(3, 'month').startOf('day')}

                  onChange={(newToDate) => {
                    const date = new Date(newToDate);
                    const utcMilliseconds = Date.UTC(
                      date.getFullYear(),
                      date.getMonth(),
                      date.getDate(),
                      date.getHours(),
                      date.getMinutes(),
                      date.getSeconds(),
                      date.getMilliseconds()
                    );
                    newToDate = dayjs(newToDate).valueOf();
                    setFirmware({ ...firmware, retrieveDateTime: utcMilliseconds });

                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth // Make the TextField full-width
                    />
                  )}
                  sx={{ width: '100%' }} // Set the width of the picker to 100%
                />
              </LocalizationProvider>
            </FormControl>

            <FormControl fullWidth style={{ marginTop: '10px' }}>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MobileDateTimePicker
                  // value={retrieveDateTime}
                  slotProps={{ textField: { variant: 'standard', fullWidth: true } }}
                  label={<Typography style={{ color: darkTheme ? '#fff' : '#000' }}>Install Date/Time</Typography>}

                  // minDate={dayjs().subtract(3, 'month').startOf('day')}

                  onChange={(newToDate) => {
                    const date = new Date(newToDate);
                    const utcMilliseconds = Date.UTC(
                      date.getFullYear(),
                      date.getMonth(),
                      date.getDate(),
                      date.getHours(),
                      date.getMinutes(),
                      date.getSeconds(),
                      date.getMilliseconds()
                    );
                    newToDate = dayjs(newToDate).valueOf();
                    setFirmware({ ...firmware, installDateTime: utcMilliseconds });

                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth // Make the TextField full-width
                    />
                  )}
                  sx={{ width: '100%' }} // Set the width of the picker to 100%
                />
              </LocalizationProvider>
            </FormControl>

            <FormControl fullWidth style={{ marginTop: '10px' }}>
              <TextField
                type="text"
                variant="standard"
                name="signingCertificate"
                label="Signing Certificate:"
                onChange={handleFirmwareChange}
                value={signingCertificate || ''}
                InputLabelProps={{
                  style: { color: darkTheme ? '#fff' : '#000' },
                }}
                sx={{
                  "& .MuiInput-underline:before": {
                    borderBottomColor: darkTheme ? '#fff' : '#000',
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: darkTheme ? '#fff' : '#000',
                  },
                  "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                    borderBottomColor: darkTheme ? '#fff' : '#000',
                  },
                  "& .MuiInputBase-input": {
                    color: darkTheme ? '#fff' : '#000',
                  },
                }}
              />
            </FormControl>

            <FormControl fullWidth style={{ marginTop: '10px' }}>
              <TextField
                type="text"
                variant="standard"
                name="signature"
                label="Signature:"
                onChange={handleFirmwareChange}
                value={signature || ''}
                InputLabelProps={{
                  style: { color: darkTheme ? '#fff' : '#000' },
                }}
                sx={{
                  "& .MuiInput-underline:before": {
                    borderBottomColor: darkTheme ? '#fff' : '#000',
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: darkTheme ? '#fff' : '#000',
                  },
                  "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                    borderBottomColor: darkTheme ? '#fff' : '#000',
                  },
                  "& .MuiInputBase-input": {
                    color: darkTheme ? '#fff' : '#000',
                  },
                }}
              />
            </FormControl>

            <FormControl fullWidth style={{ marginTop: '10px' }}>
              <TextField
                type="number"
                variant="standard"
                name="retries"
                label="Retries (integer):"
                onChange={handleSignedUpdateFirmwareParamsChange}
                value={retries || ''}
                InputLabelProps={{
                  style: { color: darkTheme ? '#fff' : '#000' },
                }}
                sx={{
                  "& .MuiInput-underline:before": {
                    borderBottomColor: darkTheme ? '#fff' : '#000',
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: darkTheme ? '#fff' : '#000',
                  },
                  "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                    borderBottomColor: darkTheme ? '#fff' : '#000',
                  },
                  "& .MuiInputBase-input": {
                    color: darkTheme ? '#fff' : '#000',
                  },
                }}
              />
            </FormControl>

            <FormControl fullWidth style={{ marginTop: '10px' }}>
              <TextField
                type="number"
                variant="standard"
                name="retryInterval"
                label="Retry Interval (integer):"
                onChange={handleSignedUpdateFirmwareParamsChange}
                value={retryInterval || ''}
                InputLabelProps={{
                  style: { color: darkTheme ? '#fff' : '#000' },
                }}
                sx={{
                  "& .MuiInput-underline:before": {
                    borderBottomColor: darkTheme ? '#fff' : '#000',
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: darkTheme ? '#fff' : '#000',
                  },
                  "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                    borderBottomColor: darkTheme ? '#fff' : '#000',
                  },
                  "& .MuiInputBase-input": {
                    color: darkTheme ? '#fff' : '#000',
                  },
                }}
              />
            </FormControl>


            <FormControl fullWidth style={{ marginTop: '10px' }}>
              <TextField
                type="number"
                variant="standard"
                name="requestId"
                label="Request Id (integer):"
                onChange={handleSignedUpdateFirmwareParamsChange}
                value={requestId || ''}
                InputLabelProps={{
                  style: { color: darkTheme ? '#fff' : '#000' },
                }}
                sx={{
                  "& .MuiInput-underline:before": {
                    borderBottomColor: darkTheme ? '#fff' : '#000',
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: darkTheme ? '#fff' : '#000',
                  },
                  "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                    borderBottomColor: darkTheme ? '#fff' : '#000',
                  },
                  "& .MuiInputBase-input": {
                    color: darkTheme ? '#fff' : '#000',
                  },
                }}
              />
            </FormControl>





          </Grid>
        </ValidatorForm>
      </DialogContent>
      <DialogActions style={{ backgroundColor: darkTheme ? '#333333' : '#f9f9f9' }}>
        <Button
          type={'submit'}
          size={'large'}
          variant={'contained'}
          style={{ backgroundColor: disabled ? 'white' : 'green', color: 'white' }}
          onClick={saveUpdatedDetails}
          disabled={disabled}
        >
          {disabled ? <Loading /> : "Submit"}
        </Button>

        <Button
          type={'submit'}
          size={'large'}
          variant={'contained'}
          // color="secondry"
          onClick={handleClose}
          style={{ backgroundColor: '#334155', color: 'white' }}
        >
          Close
        </Button>
      </DialogActions>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </Dialog>
    // </div>
  );
}

export default OccpSignedUpdateFirmware;
