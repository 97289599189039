import React from 'react';
import * as ExcelJS from 'exceljs';
import { Button, Tooltip } from '@mui/material';
import { SaveAlt } from '@mui/icons-material';


export const ExportToExcel2 = ({ data, columns, fileName, sheetName = 'Sheet 1' }) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet(sheetName);

  // Add header row
  const headerRow = worksheet.addRow(columns.map(column => column.headerName));

  // Add data rows
  data.forEach(row => {
    const values = columns.map(column => column.valueGetter?column.valueGetter({row:row}): row[column.field]);
    // console.log(values);
    worksheet.addRow(values);
  });

  // Generate Excel file
  workbook.xlsx.writeBuffer().then(buffer => {
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = URL.createObjectURL(blob);

    // Create a link and trigger a click to download the file
    const a = document.createElement('a');
    a.href = url;
    a.download = `${fileName}.xlsx`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  });
};



const ExportToExcel = ({ data, columns, fileName, sheetName = 'Sheet 1' }) => {
  const exportToExcel = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(sheetName);

    // Add header row
    const headerRow = worksheet.addRow(columns.map(column => column.label));

    // Add data rows
    data.forEach(row => {
      const values = columns.map(column => column.format?column.format(row[column.id]):row[column.id]);
      // console.log(values);
      worksheet.addRow(values);
    });

    // Generate Excel file
    workbook.xlsx.writeBuffer().then(buffer => {
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = URL.createObjectURL(blob);

      // Create a link and trigger a click to download the file
      const a = document.createElement('a');
      a.href = url;
      a.download = `${fileName}.xlsx`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    });
  };

  return (
    <Tooltip title={"Export"}>
      <Button onClick={exportToExcel}
        style={{
          border: '2px solid green', backgroundColor: 'white',
          color: 'green', fontSize: '15px', fontWeight: 'bold', borderRadius: '25px',
          paddingLeft: '15px', paddingRight: '15px'
        }}

      ><img width={"50%"} src='/assets/images/icons8-microsoft-excel-50.png' /></Button>
    </Tooltip>
  );
};

export default ExportToExcel;