import React, { useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import { Grid } from '@mui/material';
import { chartOptions } from 'app/utils/utils';
import ChargerApi from '../../ChargerApi';
import ReportApi from 'app/views/reports/ReportApi';
import { color } from 'echarts';

const WeeklyConsumptionChart = ({ row, setTotalMeterConsumtion, setTotalChargerConsumtion, darkTheme }) => {
  const api = new ReportApi();
  const [weeklyData, setWeeklyData] = useState(null);
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    fetchWeeklyCost();

    // const intervalId = setInterval(() => {
    //   fetchWeeklyCost();
    // }, 300000);

    // return () => {
    //   clearInterval(intervalId);
    // };
  }, []);

  const fetchWeeklyCost = () => {
    api.getChargerAndMeterWeeklyConsumptionChartData({ chargeBoxId: row.chargeBoxId })
      .then(({ data }) => {
        // console.log(data);
        setWeeklyData(data);
        setTotalMeterConsumtion(
          data.map(obj => obj.meter_energy)
            .filter(value => !isNaN(value) && value !== null)
            .reduce((acc, value) => acc + value, 0)
        );

        setTotalChargerConsumtion(
          data.map(obj => obj.charger_energy)
            .filter(value => !isNaN(value) && value !== null)
            .reduce((acc, value) => acc + value, 0)
        );

        setLoading(false);
      })
      .catch((e) => {
        console.log('Request history data', e);
      });
  };
  const textColor = darkTheme ? '#F2E34C' : '#000'; // Conditionally set text color
  const titleColor = darkTheme ? '#F2E34C' : '#000';
  const tooltipColor = darkTheme ? '#333333' : '#ffffff';
  const labelColor = darkTheme ? '#000' : '#6a7985';

  const options = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
        label: {
          show: true
        }
      }
    },
    toolbox: {
      show: true,
      feature: {
        // mark: { show: true },
        // dataView: { show: true, readOnly: false },
        // magicType: { show: true, type: ['line', 'bar'] },
        // restore: { show: true },
        saveAsImage: { show: true }
      }
    },
    calculable: true,
    legend: {
      data: [ 'Input Energy','Output Energy',],
      itemGap: 5
    },
    grid: {
      top: '12%',
      left: '3%',
      right: '3%',
      containLabel: true
    },
    yAxis: {
      type: 'value',
      name: 'Consumption (kWh)',
      nameLocation: 'middle',
      nameGap: 35,
      nameTextStyle: {
        ...chartOptions().nameTextStyle,
        color: textColor // Change text color based on dark theme
      },
      axisLabel: chartOptions().axisLabel,
      axisLabel: {
        color: textColor // Set text color based on theme
      }
    },
    xAxis: [
      {
        type: 'category',
        data: weeklyData ? weeklyData.map(obj => obj.weekDay) : [],
      }
    ],

    series: [
      {
        name: 'Input Energy',
        type: 'bar',
        data: weeklyData ? weeklyData.map(obj => obj['meter_energy']) : [],
        color:'limegreen'
        // Adjust the bar width here (default is 30%)
        // barWidth: '30%', // Change this value as needed
      },
      {
        name: 'Output Energy',
        type: 'bar',
        data: weeklyData ? weeklyData.map(obj => obj['charger_energy']) : [],
        color:'royalblue'
        // Adjust the bar width here (default is 30%)
        // barWidth: '30%', // Change this value as needed
      },

    ],
  };









  return (
    <Grid container style={{ height: '100%' }}>
      <Grid item xs={12} style={{ height: '100%' }}>
        {loading ? (
          <div style={{ textAlign: 'center', fontWeight: 'bold', color: darkTheme ? '#fff' : 'blue', fontSize: '20px', paddingTop: '20%' }}>
            Loading...
          </div>
        ) :
          <ReactECharts
            option={options}
            // onEvents={{ click: onChartClick }}

            style={{ height: '25rem', width: '100%' }}
            opts={{ renderer: 'canvas' }} // Use 'canvas' or 'svg' renderer based on your preference
          />
        }


      </Grid>
    </Grid>
  );
};

export default WeeklyConsumptionChart;
