import axios from 'axios.js';
export default class VehicleGraphApi {
  constructor() {
    this.api_token = null;
    this.client = null;
    this.user = JSON.parse(localStorage.getItem('user'));
  }
  init = () => {
    this.client = axios.create({
      timeout: 120000,
    });

    return this.client;
  };

  getVehicleChargingStatus = (params) => {
    return this.init().get('/api/vehicle/show_vehicle_charge_status', { params: params });
  };

  getChargerPortView = (params, chargerId, connectorId, vehicleId) => {
    return this.init().get('/api/charger/charger_port/' + chargerId, { params: params });
  };

  getChargerVehicleSOCView = (params, chargerId, connectorId, vehicleId) => {
    return this.init().get('/api/charger/charger_vehicle_soc/' + chargerId, { params: params });
  };


  getVehicleList = (params, depotId) => {
    return this.init().get('/api/vehicle/show/' + depotId, { params: params });
  };
}
