import axios from 'axios';

// let headers = {
//   Accept: 'application/json',
// };
const axiosInstance = axios.create({
  // baseURL: 'http://192.168.1.231:8091/EV',

  // baseURL: 'http://122.160.48.7:8095/ED',
  baseURL: 'https://cms-app.ev-opt.com/ED',

  // baseURL: 'http://122.160.48.7:8091/ED',
  // baseURL: 'http://localhost:8093/ED',
  //  baseURL: 'http://3.6.162.54:8091/EV',
  // baseURL: 'http://192.168.1.123:8093/ED',
  // baseURL: 'https://ocppmgl.ev-opt.com/EV',


  headers: {
    depoId: localStorage.getItem("selected_depot"),
  },

  timeout: 120000
});

// Request interceptor to add Authorization header dynamically
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('accessToken'); // Get token from localStorage
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`; // Set Bearer token
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response ) || 'Something went wrong!')
);
export default axiosInstance;




// {
//   "organization_name": "Org1",
//     "depot_name": "depo1",
//       "transformer_name": "trafo1",
//         "data": [
//           {
//             "name": "Charging",
//             "value": 0
//           },
//           {
//             "name": "Available",
//             "value": 10
//           },
//           {
//             "name": "Fault",
//             "value": 0
//           },
//           {
//             "name": "Reserved",
//             "value": 0
//           },
//           {
//             "name": "Unavailable",
//             "value": 0
//           },
//           {
//             "name": "Preparing",
//             "value": 0
//           },
//           {
//             "name": "Finishing",
//             "value": 0
//           }
//         ]
// }


// {
//   "organization_name": "Org1",
//     "depot_name": "depo1",
//       "transformer_name": "trafo1",
//         "data": [
//           {
//             "transaction_pk": 368017,
//             "current": 157.97,
//             "soc": 40.0,
//             "vehicle_license_plate": "AP39UJ0586",
//             "charge_box_id": "GEXHYDSCK00044",
//             "charge_box_pk": 4643,
//             "charge_bay_name": "Trafo 1",
//             "severity_level": "info",
//             "id_tag": "91269CBE",
//             "depo_name": "Hyderabad Depot",
//             "voltage": 545.3,
//             "mac_address": "141FBA109DE2",
//             "connector_id": 1,
//             "power": 86.14101,
//             "meter_id": 5,
//             "vehicle_id": "AP39UJ0586",
//             "status": "Charging"
//           },
//         ]
// }





// {
//   "organization_name": "Org1",
//     "depot_name": "depo1",
//       "transformer_name": "trafo1",
//         "data": [
//           {
//             "chargeBoxId": "GEXHYDSCK00044",
//             "connectorId": 2,
//             "type": "status",
//             "status": "Charging",
//             "note": null,
//             "errorCode": "NoError",
//             "vendorId": null,
//             "vendorErrorCode": null,
//             "datetime": "2025-01-15T13:59:16.000Z",
//             "alarmCause": null,
//             "alarmResolution": null
//           },
//         ]
// }

