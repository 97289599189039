import React, { useState, useEffect, useRef } from 'react';
import { useTheme } from '@mui/system';
import { makeStyles } from '@material-ui/core/styles';
//import ReactEcharts from 'echarts-for-react';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
// import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import ReportApi from '../ReportApi';
import dayjs, { Dayjs } from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Box from '@mui/material/Box';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid';
import TablePagination from '@mui/material/TablePagination';
// import TablePagination from '@mui/material/TablePagination';
import { Grid, InputAdornment, TextField, IconButton, Typography, Select, MenuItem } from '@mui/material';
import ExportToExcel from './ExportToExcel';
import { MobileDateTimePicker } from '@mui/x-date-pickers';
import { StyledTable } from 'app/utils/utils';
import { GridSearchIcon } from '@mui/x-data-grid';
import { useThemeContext } from 'app/ThemeProvider';
import { FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage } from '@material-ui/icons';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import Loading from 'app/components/MatxLoading';

const useStyles = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
    display: 'flex',
    alignItems: 'center',
  },
  select: {
    marginLeft: theme.spacing(1),
  },
}));

const CustomPagination = ({ count, page, rowsPerPage, onChangePage, onChangeRowsPerPage, darkTheme }) => {
  const classes = useStyles();

  const handleFirstPageButtonClick = () => {
    onChangePage(0);
  };

  const handleBackButtonClick = () => {
    onChangePage(page - 1);
  };

  const handleNextButtonClick = () => {
    onChangePage(page + 1);
  };

  const handleLastPageButtonClick = () => {
    onChangePage(Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  const backgroundColor = darkTheme ? '#333333' : '#f9f9f9';
  const textColor = darkTheme ? '#fff' : 'rgba(0, 0, 0, 0.87)';
  const dropdownBackgroundColor = darkTheme ? '#000' : '#fff';
  const dropdownTextColor = darkTheme ? '#fff' : 'rgba(0, 0, 0, 0.87)';

  return (
    <Box className={classes.root} style={{ backgroundColor, color: textColor, marginRight: '10px', height: '48px' }}>
      <Select
        className={classes.select}
        value={rowsPerPage}
        onChange={(e) => onChangeRowsPerPage(e)}
        style={{ color: textColor }}
        IconComponent={() => <ArrowDropDownIcon style={{ color: darkTheme ? '#fff' : '#000', position: 'absolute', right: '8px', top: '50%', transform: 'translateY(-50%)' }} />}
        MenuProps={{
          PaperProps: {
            style: {
              backgroundColor: dropdownBackgroundColor,
              color: dropdownTextColor,
            },
          },
        }}
      >
        {[20, 50, 100].map((pageSize) => ( // Added -1 for "All" option
          <MenuItem key={pageSize} value={pageSize}>
            {pageSize === -1 ? 'All' : pageSize} {/* Display "All" instead of -1 */}
          </MenuItem>
        ))}
      </Select>

      <Typography variant="body2" style={{ color: textColor }}>Rows per page</Typography>
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
        <FirstPage style={{ color: darkTheme ? '#fff' : 'rgba(0, 0, 0, 0.54)' }} />
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        <KeyboardArrowLeft style={{ color: darkTheme ? '#fff' : 'rgba(0, 0, 0, 0.54)' }} />
      </IconButton>
      <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
        <KeyboardArrowRight style={{ color: darkTheme ? '#fff' : 'rgba(0, 0, 0, 0.54)' }} />
      </IconButton>
      <IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="last page">
        <LastPage style={{ color: darkTheme ? '#fff' : 'rgba(0, 0, 0, 0.54)' }} />
      </IconButton>
      <Typography variant="body2">{`${page + 1} of ${Math.ceil(count / rowsPerPage)}`}</Typography>
    </Box>
  );
};

// const columns = [
//   { id: 'depot_name', label: 'Charging Bay', minWidth: 170, align: 'center' },
//   { id: 'depot_id', label: 'Charger Id', minWidth: 100, align: 'center' },
//   {
//     id: 'connector_id',
//     label: 'Connector',
//     maxWidth: 170,
//     align: 'center',
//     format: (value) => value.toLocaleString('en-US'),
//   },
//   {
//     id: 'vehicleIdStr',
//     label: 'Vehicle Name',
//     minWidth: 170,
//     align: 'center',
//     format: (value) => value.toLocaleString('en-US'),
//   },
//   {
//     id: 'cable_temp',
//     label: 'Cable Temp. (F)',
//     minWidth: 120,
//     align: 'center',
//     format: (value) => value.toFixed(2),
//   },
//   {
//     id: 'power',
//     label: 'Power (kW)',
//     minWidth: 120,
//     align: 'center',
//     format: (value) => value.toFixed(2),
//   },
//   {
//     id: 'voltage',
//     label: 'Voltage (V)',
//     minWidth: 120,
//     align: 'center',
//     format: (value) => value.toFixed(2),
//   },
//   {
//     id: 'current',
//     label: 'Current (A)',
//     minWidth: 120,
//     align: 'center',
//     format: (value) => value.toFixed(2),
//   },
//   {
//     id: 'status',
//     label: 'Status',
//     minWidth: 120,
//     align: 'center',
//     format: (value) => value.toFixed(2),
//   },
// ];



export const columns = [
  // { id: 'index', label: 'SR No', align: 'center' },

  { field: 'charge_box_id', headerName: ' Charger ID', width: 200, align: 'left' },
  { field: 'charge_point_serial_number', headerName: ' Serial No.', width: 200, align: 'left' },

  { field: 'depot_transaction_pk', headerName: 'Depot Transaction ID', width: 150, align: 'left' },

  { field: 'transaction_pk', headerName: 'Transaction ID', width: 150, align: 'left' },
  { field: 'depot_zone', headerName: 'Zone', minWidth: 200, align: 'left' },

  { field: 'city', headerName: 'Location', minWidth: 200, align: 'left' },

  { field: 'depot_name', headerName: 'Depot', minWidth: 200, align: 'left' },
  { field: 'charging_bay', headerName: 'Transformer', width: 120, align: 'left' },

  {
    field: 'connector_id',
    headerName: 'Connector',
    width: 150,
    align: 'left',
    format: (value) => value.toLocaleString('en-US'),
  },

  {
    field: 'connector_last_transaction_end_meter_value',
    headerName: 'Last Transaction End Meter Value',
    width: 220,
    align: 'left',
  },


  {
    field: 'start_meter_value',
    headerName: 'Meter Start',
    width: 220,
    align: 'left',
  },
  {
    field: 'end_meter_value',
    headerName: 'Meter End',
    width: 220,
    align: 'left',
  },

  {
    field: 'meter_difference',
    headerName: 'Meter Difference (Wh)',
    width: 220,
    align: 'left',
    valueGetter: (params) => parseInt(params.row['start_meter_value'])
      - parseInt(params.row['connector_last_transaction_end_meter_value'])
  },




  {
    field: 'id_tag',
    headerName: 'RFID Tag',
    minWidth: 120,
    align: 'center',
    format: (value) => value.toFixed(2),
  },


  {
    field: 'vehicle_license_plate',
    headerName: 'Vehicle License Plate',
    width: 200,
    align: 'left',
  },
  {
    field: 'vehicle_size',
    headerName: 'Vehicle Size (m)',
    width: 200,
    align: 'left',
  },
  {
    field: 'mac_address',
    headerName: 'Mac ID',
    width: 200,
    align: 'left',
    format: (value) => value.toFixed(2),
  },


  {
    field: 'max_current',
    headerName: 'Peak Current (A)',
    width: 150,
    align: 'left',
    valueGetter: (params) => isNaN(parseFloat(params.row['max_current'])) ? '' :
      parseFloat(params.row['max_current']).toFixed(2),
  },


  {
    field: 'max_voltage',
    headerName: 'Peak Voltage (V)',
    width: 150,
    align: 'left',
    valueGetter: (params) => isNaN(parseFloat(params.row['max_voltage'])) ? '' :
      parseFloat(params.row['max_voltage']).toFixed(2),
  },

  {
    field: 'start_soc',
    headerName: 'Start SOC (%)',
    width: 150,
    align: 'left',
    format: (value) => value.toFixed(2),
  },
  {
    field: 'end_soc',
    headerName: 'End SOC (%)',
    width: 150,
    align: 'left',
    format: (value) => value.toFixed(2),
  },
  // {
  //   field: 'Current (A)',
  //   headerName: 'Peak Power',
  //   width: 120,
  //   align: 'left',
  // },
  // {
  //   field: 'start_meter_value',
  //   headerName: 'Start Meter Value',
  //   width: 120,
  //   align: 'left',
  // },
  // {
  //   field: 'end_meter_value',
  //   headerName: 'End Meter Value',
  //   width: 120,
  //   align: 'left',
  // },
  {
    field: 'energy',
    headerName: 'Energy Consumed (kWh)',
    width: 220,
    align: 'left',
    valueGetter: (params) => params.row['energy'] !== undefined ? parseFloat(params.row['energy']).toFixed(2) : '',
  },
  {
    field: 'energy_cost',
    headerName: 'Energy Cost ',
    width: 220,
    align: 'left',
    valueGetter: (params) => {

      const defaultEnergyCost = params.row['default_energy_cost'] || 0;
      const normalEnergyCost = params.row['normal_energy_cost'] || 0
      const peakEnergyCost = params.row['peak_energy_cost'] || 0
      const offPeakEnergyCost = params.row['offPeak_energy_cost'] || 0

      const cost = parseFloat(defaultEnergyCost + normalEnergyCost + peakEnergyCost + offPeakEnergyCost).toFixed(2);

      return cost>0?cost:""

    },
  },
  {
    field: 'event_date',
    headerName: 'Event Date',
    width: 120,
    align: 'left',
  },
  {
    field: 'event_time',
    headerName: 'Event Time',
    width: 120,
    align: 'left',
  },
  {
    field: 'start_date',
    headerName: 'Start Date',
    width: 120,
    align: 'left',
  },
  {
    field: 'start_time',
    headerName: 'Start Time',
    width: 120,
    align: 'left',
  },
  {
    field: 'end_date',
    headerName: 'End Date',
    width: 120,
    align: 'left',
  },
  {
    field: 'end_time',
    headerName: 'End Time',
    width: 120,
    align: 'left',
  },
  {
    field: 'duration',
    headerName: 'Charging Duration (Mins)',
    width: 200,
    align: 'left',
    // valueGetter: (value) => value.toFixed(2),
  },
  {
    field: 'min_temperature_inlet',
    headerName: 'Min Inlet Temp. (C)',
    minWidth: 170,
    width: 150,
    align: 'center',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    field: 'max_temperature_inlet',
    headerName: 'Max Inlet Temp. (C)',
    minWidth: 170,
    width: 150,
    align: 'center',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    field: 'min_temperature_outlet',
    headerName: 'Min Outlet Temp. (C)',
    minWidth: 170,
    width: 150,
    align: 'center',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    field: 'max_temperature_outlet',
    headerName: 'Max Outlet Temp. (C)',
    minWidth: 170,
    width: 150,
    align: 'center',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    field: 'min_temperature_body',
    headerName: 'Min Body Temp. (C)',
    minWidth: 170,
    width: 150,
    align: 'center',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    field: 'max_temperature_body',
    headerName: 'Max Body Temp. (C)',
    minWidth: 170,
    width: 150,
    align: 'center',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    field: 'stop_reason',
    headerName: 'Stop Reason',
    width: 220,
    align: 'left',
  },
];

let params = {};

// const useStyles = makeStyles((theme) => ({
//   root: {
//     flexShrink: 0,
//     marginLeft: theme.spacing(2.5),
//     display: 'flex',
//     alignItems: 'center',
//   },
//   select: {
//     marginLeft: theme.spacing(2),
//   },
// }));

// const CustomPagination = ({ count, page, rowsPerPage, onChangePage, onChangeRowsPerPage, darkTheme }) => {
//   const classes = useStyles();

//   const handleFirstPageButtonClick = () => {
//     onChangePage(0);
//   };

//   const handleBackButtonClick = () => {
//     onChangePage(page - 1);
//   };

//   const handleNextButtonClick = () => {
//     onChangePage(page + 1);
//   };

//   const handleLastPageButtonClick = () => {
//     onChangePage(Math.max(0, Math.ceil(count / rowsPerPage) - 1));
//   };

//   const backgroundColor = darkTheme ? '#333333' : '#f9f9f9';
//   const textColor = darkTheme ? '#fff' : 'rgba(0, 0, 0, 0.87)';
//   const dropdownBackgroundColor = darkTheme ? '#000' : '#fff'; 
// const dropdownTextColor = darkTheme ? '#fff' : 'rgba(0, 0, 0, 0.87)';

//   return (
//     <Box className={classes.root} style={{ backgroundColor, color: textColor }}>
//        <Select
//           className={classes.select}
//           value={rowsPerPage}
//           onChange={(e) => onChangeRowsPerPage(e)} // Ensure onChangeRowsPerPage is invoked with the event
//           style={{ color: textColor }}
//           IconComponent={() => <ArrowDropDownIcon style={{ color: darkTheme ? '#fff' : '#000',position: 'absolute', right: '8px', top: '50%', transform: 'translateY(-50%)' }} />}
//           MenuProps={{
//             PaperProps: {
//               style: {
//                 backgroundColor: dropdownBackgroundColor,
//                 color: dropdownTextColor,
//               },
//             },
//           }}
//         >

//         {[5, 10, 15, 20].map((pageSize) => (
//           <MenuItem key={pageSize} value={pageSize}>{pageSize}</MenuItem>
//         ))}
//       </Select>
//       <Typography variant="body2" style={{ color: textColor }}>Rows per page</Typography>
//       <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
//         <FirstPage style={{ color: darkTheme ? '#fff' : 'rgba(0, 0, 0, 0.54)' }} />
//       </IconButton>
//       <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
//         <KeyboardArrowLeft style={{ color: darkTheme ? '#fff' : 'rgba(0, 0, 0, 0.54)' }} />
//       </IconButton>
//       <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
//         <KeyboardArrowRight style={{ color: darkTheme ? '#fff' : 'rgba(0, 0, 0, 0.54)' }} />
//       </IconButton>
//       <IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="last page">
//         <LastPage style={{ color: darkTheme ? '#fff' : 'rgba(0, 0, 0, 0.54)' }} />
//       </IconButton>
//       <Typography variant="body2">{`${page + 1} of ${Math.ceil(count / rowsPerPage)}`}</Typography>

//     </Box>
//   );
// };

const ChargingStMonitorReport = ({ height, color = [] }) => {
  // const classes = useStyles();
  const [data, setReportData] = useState([]);
  const [error, setError] = useState(false);
  const [filteredData, setFilteredData] = useState([]);

  const searchData = useRef({});

  const api = new ReportApi();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const { darkTheme } = useThemeContext();
  useEffect(() => {
    getChargingMonitorReport();
  }, [error]);

  const getChargingMonitorReport = () => {
    let isCanceled = false;

    setLoading(true)

    api
      .showChargerStMonitorReport(params)
      .then(({ data }) => {
        if (!isCanceled) {
          const dataWithIndex = data.map((item, index) => ({
            ...item,
            id: index + 1, // Change 'index' to 'id'
          }));
          setReportData(dataWithIndex);
          setFilteredData(dataWithIndex);
          setLoading(false);

          console.log(dataWithIndex)

          // setFilteredData(data.filter(o => {
          //   let res = true;
          //   if (searchData.current.hasOwnProperty('transaction_pk'))
          //     res = res && o['transaction_pk'].toLocaleString().includes(searchData.current['transaction_pk']);
          //   if (searchData.current.hasOwnProperty('charge_box_id'))
          //     res = res && o['charge_box_id'].toLocaleString().includes(searchData.current['charge_box_id']);
          //   if (searchData.current.hasOwnProperty('charge_bay_name'))
          //     res = res && o['charge_bay_name'].toLocaleString().includes(searchData.current['charge_bay_name']);
          //   if (searchData.current.hasOwnProperty('vehicle_license_plate') && o['vehicle_license_plate'])
          //     res = res && o['vehicle_license_plate'].toLocaleString().includes(searchData.current['vehicle_license_plate']);
          //   if (searchData.current.hasOwnProperty('status'))
          //     res = res && o['status'].toLocaleString().includes(searchData.current['status']);
          //   return res;
          // })
          // )


        }
      })
      .catch((e) => {
        setError(true);
      });
  };



  const handleRefreshSearch = async () => {


    setFilteredData(data.filter(o => {
      let res = true;
      if (searchData.current.hasOwnProperty('transaction_pk'))
        res = res && o['transaction_pk'].toLocaleString().includes(searchData.current['transaction_pk']);
      if (searchData.current.hasOwnProperty('charge_box_id'))
        res = res && o['charge_box_id'].toLocaleString().includes(searchData.current['charge_box_id']);
      if (searchData.current.hasOwnProperty('charge_bay_name'))
        res = res && o['charge_bay_name'].toLocaleString().includes(searchData.current['charge_bay_name']);
      if (searchData.current.hasOwnProperty('vehicle_license_plate') && o['vehicle_license_plate'])
        res = res && o['vehicle_license_plate'].toLocaleString().includes(searchData.current['vehicle_license_plate']);
      if (searchData.current.hasOwnProperty('status'))
        res = res && o['status'].toLocaleString().includes(searchData.current['status']);
      return res;
    })
    )

  }

  const handleSearch = (e) => {
    searchData.current = { ...searchData.current, [e.target.name]: e.target.value };
    handleRefreshSearch();
  }



  const handleSubmit = (e) => {
    e.preventDefault();
    params = {
      from: fromDate,
      to: toDate,
    };
    getChargingMonitorReport(params);
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const backgroundColor = darkTheme ? '#333333' : '#f9f9f9';
  const textColor = darkTheme ? '#F2E34C' : 'black';
  const cellColor = darkTheme ? '#fff' : 'black';
  const backgroundColorOdd = darkTheme ? '#333333' : '#f9f9f9';
  const backgroundColorEven = darkTheme ? '#474747' : '#e0e0e0';

  const useStyles = makeStyles((theme) => ({
    exportButton: {
      display: 'flex',
      alignItems: 'center',
    },
  }));
  function CustomExportButton() {
    const classes = useStyles();

    return (
      <div className={classes.exportButton}>
        <GridToolbarExport style={{ color: darkTheme ? '#fff' : '#333' }} csvOptions={{ fileName: "Charger_Monitor_Report" }} />
        <Tooltip title="[Note: Select rows per page to export the data.] ">
          <InfoIcon style={{ color: darkTheme ? '#e0e0e0' : '#333', fontSize: '15px', marginLeft: '5px' }} />
        </Tooltip>
      </div>
    );
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton style={{ color: darkTheme ? '#fff' : '#333' }} />
        <GridToolbarFilterButton style={{ color: darkTheme ? '#fff' : '#333' }} />
        <GridToolbarDensitySelector style={{ color: darkTheme ? '#fff' : '#333' }} />
        <CustomExportButton />
      </GridToolbarContainer>

    );
  }

  return (
    <>

      <Paper sx={{ width: '100%', overflow: 'hidden', height: '70vh' }} style={{ backgroundColor }}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} style={{ backgroundColor, alignItems: "right", padding: "5px" }} justifyContent="flex-end">
            {/* <Grid item xs={1} sm={3} md={6.8} style={{ display: "flex", alignItems: "center" }}>
              <ExportToExcel data={data} columns={columns} fileName={"Charger_Monitor_Report_" + new Date().toString()} />
            </Grid> */}
            <Grid item xs={2} sm={5} md={2}>
              {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MobileDateTimePicker
                  label="From Date"
                  value={fromDate}
                  onChange={(newFromDate) => {
                    newFromDate = dayjs(newFromDate).valueOf();
                    setFromDate(newFromDate);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider> */}
            </Grid>
            <Grid item xs={2} sm={5} md={2}>
              {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MobileDateTimePicker
                  label="To Date"
                  value={toDate}
                  onChange={(newToDate) => {
                    newToDate = dayjs(newToDate).valueOf();
                    setToDate(newToDate);
                  }}
                  renderInput={(params) => <TextField {...params}
                   />}
                />
              </LocalizationProvider> */}
            </Grid>
            {/* </Grid> */}
            <Grid item xs={1} sm={3} md={1} style={{ display: "flex", alignItems: "center" }}>
              {/* <Button type="submit" variant="contained" color="primary"
                style={{
                  border: '2px solid #007bff', backgroundColor: 'white',
                  color: '#007bff', fontSize: '15px', fontWeight: 'bold', borderRadius: '25px',
                  paddingLeft: '15px', paddingRight: '15px'
                }}

              >
                Filter
              </Button> */}


            </Grid>

          </Grid>

        </form>


        {loading ?
          <Loading /> :

          <DataGrid
            rows={data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
            columns={columns}
            pageSize={rowsPerPage}
            page={page}
            getRowId={(row) => row.id}
            onPageChange={handleChangePage}
            rowsPerPageOptions={[5, 10, 25, 50]}
            onRowsPerPageChange={handleChangeRowsPerPage}
            disableSelectionOnClick
            components={{
              Toolbar: CustomToolbar, // Custom toolbar component
              Pagination: (props) => (
                <CustomPagination
                  count={data.length}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  darkTheme={darkTheme}
                />
              ),
              NoRowsOverlay: () => (
                <div style={{ textAlign: 'center', color: textColor }}>
                  No Data Available to show
                </div>
              ),
            }}
            sx={{
              '& .MuiDataGrid-cell': {
                color: cellColor,
                border: 'none',
                fontSize: '16px'
              },
              '& .MuiDataGrid-row:nth-of-type(odd)': {
                backgroundColor: backgroundColorOdd, // Background color for odd rows
                border: 'none',
              },
              '& .MuiDataGrid-row:nth-of-type(even)': {
                backgroundColor: backgroundColorEven, // Background color for even rows
                border: 'none',
              },
              '& .MuiDataGrid-columnHeader': {
                backgroundColor: backgroundColor,
                color: textColor,
                fontSize: '16px'
              },
              '& .MuiDataGrid-iconButtonContainer': {
                visibility: 'visible',
              },
              '& .MuiDataGrid-sortIcon': {
                opacity: 'inherit !important',
                color: darkTheme ? '#fff' : '#000'
              },
              '& .MuiDataGrid-menuIconButton': {
                opacity: 'inherit !important',
                color: darkTheme ? '#fff' : '#000',
              }
            }}
          />
        }


      </Paper>
    </>
  );
};

export default ChargingStMonitorReport;
