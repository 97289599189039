import * as React from 'react';
import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import SimpleForm from './SimpleForm';
import { useThemeContext } from 'app/ThemeProvider';
import { makeStyles, createMuiTheme } from '@material-ui/core/styles';
import { Span } from 'app/components/Typography';

export default function AlertDialog(props) {
  const [open, setOpen] = useState(false);
  const [row, setRow] = useState({});
  const { darkTheme } = useThemeContext();

  const handleClickOpen = () => {
    setRow(props.row)
    setOpen(true);
  };

  const handleClose = () => {
    setRow({})
    setOpen(false);
  };

  const style = makeStyles({
    titleItemRight: {
      float: 'right',
      position: 'relative',
      transform: 'translateY(-50%)',
    },
  });

  const classes = style();

  return (
    <div>
      <Button
        variant="contained"
        style={{ border: '2px solid', borderColor: darkTheme ? '#F2E34C' : '#000', backgroundColor: darkTheme ? '#F2E34C' : '#f9f9f9', color: 'black', fontSize: '15px', fontWeight: 'bold', borderRadius: '25px', padding: "10px" }}
        onClick={handleClickOpen}
      >
        +1 NEW CHARGING PROFILE
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            backgroundColor: darkTheme ? '#333333' : '#fff',
          },
        }}
      >
        <DialogTitle id="alert-dialog-title" style={{ color: darkTheme ? '#F2E34C' : '#000' }}>
          Add Charging Profile
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <SimpleForm  handleClose={handleClose}/>
          </DialogContentText>
        </DialogContent>
        {/* <DialogActions>
                    <Button onClick={handleClose}>Disagree</Button>
                    <Button onClick={handleClose} autoFocus>
                        Agree
                    </Button>
                </DialogActions> */}
        <DialogActions>
          {/* <Button
          color="info"
          alignItems="right"
          className={classes.titleItemRight}
          variant="contained"
          type="button"
          onClick={handleClose}
          style={{
            background: '#e0e0e0',
            color: 'black',
            borderRadius: '3px',
            padding: '5px 7px',
            margin: '15px',
          }}
        >
          <Span sx={{ textTransform: 'capitalize' }}>Close</Span>
        </Button>
        <Button
          color="success"
          style={{
            background: '#2e7d32',
            color: 'white',
            borderRadius: '3px',
            padding: '5px 7px',
            margin: '5px',
          }}
          alignItems="right"
          className={classes.titleItemRight}
          variant="contained"
          type="submit"
        >
          <Span sx={{ textTransform: 'capitalize' }}>Submit</Span>
        </Button> */}
        </DialogActions>
      </Dialog>
    </div>
  );
}