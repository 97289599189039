import { Button, FormControlLabel, FormGroup, Grid, Switch, styled } from '@mui/material';
import { Span } from 'app/components/Typography';
import { useEffect, useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { ToastContainer, toast } from 'react-toastify';
import { makeStyles } from '@material-ui/core/styles';
import 'react-toastify/dist/ReactToastify.css';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import DepartmentApi from './DepartmentApi';
import UpdateIcon from '@mui/icons-material/Update';
import { useThemeContext } from 'app/ThemeProvider';

const TextField = styled(TextValidator)(() => ({
    width: '100%',
    marginBottom: '16px',
}));
const style = makeStyles({
    titleItemRight: {
        float: 'right',
        position: 'relative',
        transform: 'translateY(-50%)',
        marginTop: 10,
    },
});

const UpdateDepartment = ({ setRefresh,row }) => {
    const [state, setState] = useState(row);
    const api = new DepartmentApi();
    const classes = style();
    const { darkTheme } = useThemeContext();

    // useEffect(() => {
    //     ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
    //         if (value !== state.password) return false;

    //         return true;
    //     });

    //     ValidatorForm.addValidationRule('isPasswordSixDigit', (value) => {
    //         if (value.toString().length < 6) return false;

    //         return true;
    //     });

    //     return () => ValidatorForm.removeValidationRule('isPasswordMatch');
    // }, [state.password]);

    const handleSubmit = (event) => {
        setRefresh(true);
        debugger;

        event.preventDefault();
        api
            .update(state)
            .then((res) => {
                toast.success('Successfully updated.');
                setRefresh(false);
                setOpen(false)
            })
            .catch((e) => {
                return toast.error(e.message);
                console.log('Cannot add  data', e);
            }).finally(() => setRefresh(false));

    };



    const refreshPage = (event) => {
        setOpen(false);
    };

    const handleChange = (event) => {
        event.persist();
        setState({ ...state, [event.target.name]: event.target.value });
    };

    const handleSelectChange = (event) => {
        setState({ ...state, [event.target.name]: [event.target.value] });
    };

    const handleSelectCountry = (event) => {
        setState({ ...state, [event.target.name]: event.target.value });
    };

    const { name, orgId } = state;


    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div style={{display:"inline"}}>
            <UpdateIcon
            onClick={handleClickOpen}
                    variant="contained"
            color='primary' size='small'
                                             />
            <Dialog
                open={open}
                onClose={(_, reason) => {
                    if (reason !== 'backdropClick') {
                        handleClose();
                    }
                }}
                disableEscapeKeyDown="true"
                fullWidth="false"
                maxWidth="sm"
                PaperProps={{
                    style: {
                      backgroundColor: darkTheme ? '#333333' : '#fff', 
                    },
                  }}
            >
                <DialogTitle style={{ color: darkTheme ? '#F2E34C' : '#000' }}> Update Department</DialogTitle>
                <DialogContent>
                    <ValidatorForm onSubmit={handleSubmit} onError={() => null}>
                        <Grid container spacing={6}>
                            <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
                                <TextField
                                    type="text"
                                    name="name"
                                    id="standard-basic"
                                    value={name || ''}
                                    onChange={(event) => {
                                        const regex = /^[A-Za-z0-9-& ]{0,70}$/; // Allow letters, numbers, "-", "&", and spaces (max 70 chars)
                                        if (regex.test(event.target.value)) {
                                            handleChange(event); // Only update state if input is valid
                                        }
                                    }}
                                    errorMessages={['this field is required']}
                                    label=" Department Name"
                                    validators={['required']}
                                    variant="standard"
                                    style={{ borderColor : darkTheme ? '#fff' : '#000' }}
                                    inputProps={{
                                        maxLength: 70, // Limits input length to 70 characters
                                    }}
                                    InputProps={{
                                      style: { color: darkTheme ? '#fff' : '#000' }, 
                                    }}
                                    InputLabelProps={{
                                      style: { color: darkTheme ? '#fff' : '#000' }, 
                                    }}
                                    sx={{
                                      "& .MuiInput-underline:before": {
                                        borderBottomColor: darkTheme ? '#fff' : '#000',
                                      },
                                      "& .MuiInput-underline:after": {
                                        borderBottomColor: darkTheme ? '#fff' : '#000',
                                      },
                                      "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                                        borderBottomColor: darkTheme ? '#fff' : '#000',
                                      },
                                      "& .MuiInputBase-input": {
                                        color: darkTheme ? '#fff' : '#000',
                                      },
                                    }}
                                />
                            </Grid>
                        </Grid>

                        <Button
                            color="info"
                            alignItems="right"
                            className={classes.titleItemRight}
                            variant="contained"
                            type="button"
                            onClick={refreshPage}
                            style={{ margin: '5px', marginBottom: '-20px', background: '#e0e0e0', color: 'black' }}
                        >
                            <Span sx={{ textTransform: 'capitalize' }}>Cancel</Span>
                        </Button>

                        <Button
                            color="success"
                            alignItems="right"
                            className={classes.titleItemRight}
                            variant="contained"
                            type="submit"
                            style={{ margin: '5px', marginBottom: '-20px' }}
                        >
                            <Span sx={{ textTransform: 'capitalize' }}>Submit</Span>
                        </Button>

                    </ValidatorForm>
                    <ToastContainer
                        position="top-right"
                        autoClose={2000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="colored"
                    />
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default UpdateDepartment;