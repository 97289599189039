import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, Grid, MenuItem, Select, TablePagination, TextField, Typography, IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import ChargerApi from '../ChargerApi';
import { StyledDataGrid } from 'app/utils/utils';
import { FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage } from '@material-ui/icons';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import EnergyMeterApi from 'app/views/cmsmgmt/energyMeter/EnergyMeterApi';
import Loading from 'app/components/MatxLoading';

const useStyles = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
    display: 'flex',
    alignItems: 'center',
  },
  select: {
    marginLeft: theme.spacing(1),
  },
}));

const CustomPagination = ({ count, page, rowsPerPage, onChangePage, onChangeRowsPerPage, darkTheme }) => {
  const classes = useStyles();

  const handleFirstPageButtonClick = () => {
    onChangePage(0);
  };

  const handleBackButtonClick = () => {
    onChangePage(page - 1);
  };

  const handleNextButtonClick = () => {
    onChangePage(page + 1);
  };

  const handleLastPageButtonClick = () => {
    onChangePage(Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  const backgroundColor = darkTheme ? '#333333' : '#f9f9f9';
  const textColor = darkTheme ? '#fff' : 'rgba(0, 0, 0, 0.87)';
  const dropdownBackgroundColor = darkTheme ? '#000' : '#fff'; 
  const dropdownTextColor = darkTheme ? '#fff' : 'rgba(0, 0, 0, 0.87)';

  return (
    <Box className={classes.root} style={{ backgroundColor, color: textColor, marginRight: '10px', height: '48px' }}> 
       <Select
          className={classes.select}
          value={rowsPerPage}
          onChange={(e) => onChangeRowsPerPage(e)}
          style={{ color: textColor }}
          IconComponent={() => <ArrowDropDownIcon style={{ color: darkTheme ? '#fff' : '#000', position: 'absolute', right: '8px', top: '50%', transform: 'translateY(-50%)' }} />}
          MenuProps={{
            PaperProps: {
              style: {
                backgroundColor: dropdownBackgroundColor,
                color: dropdownTextColor,
              },
            },
          }}
        >
          {[10, 20].map((pageSize) => ( // Added -1 for "All" option
            <MenuItem key={pageSize} value={pageSize}>
              {pageSize === -1 ? 'All' : pageSize} {/* Display "All" instead of -1 */}
            </MenuItem>
          ))}
        </Select>

      <Typography variant="body2" style={{ color: textColor }}>Rows per page</Typography>
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
        <FirstPage style={{ color: darkTheme ? '#fff' : 'rgba(0, 0, 0, 0.54)' }} />
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        <KeyboardArrowLeft style={{ color: darkTheme ? '#fff' : 'rgba(0, 0, 0, 0.54)' }} />
      </IconButton>
      <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
        <KeyboardArrowRight style={{ color: darkTheme ? '#fff' : 'rgba(0, 0, 0, 0.54)' }} />
      </IconButton>
      <IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="last page">
        <LastPage style={{ color: darkTheme ? '#fff' : 'rgba(0, 0, 0, 0.54)' }} />
      </IconButton>
      <Typography variant="body2">{`${page + 1} of ${Math.ceil(count / rowsPerPage)}`}</Typography>
    </Box>
  );
};

export default function EnergyTable({ darkTheme }) {
  const [rows, setRow] = React.useState([]);
  const [page, setPage] = useState(0);
  const [loading,setLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(20);
 
  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const api = new EnergyMeterApi();

  React.useEffect(() => {

    setLoading(true)

    getChargingMonitorReport();
    const interval= setInterval(() => {
      getChargingMonitorReport();
      
    }, 10000);

    return ()=>clearInterval(interval);

  }, []);

  const getChargingMonitorReport = async () => {
    let isCanceled = false;
    api
      .getAllEnergyMeterValue()
      .then(({ data }) => {
        if (!isCanceled) {
          console.log(data)
          setLoading(false)

          if(Array.isArray(data))
            setRow(data.map((data,index)=>({...data,id:index})));
        }
      })
      .catch((e) => {
        // Handle error
        console.log("error",e)
      });
  };




  const columns = [
    // { field: 'sources', headerName: 'Sources', width: 200 },
    { field: 'meter_id', headerName: 'Meter ID', width: 100,flex:1 },
    { field: 'device_id', headerName: 'Device', width: 200,flex:1 },

    { field: 'register_value_timestamp', headerName: 'Datetime', width: 200,flex:1 },


    // { field: 'serial_number', headerName: 'Serial No.', width: 200 },

    { field: 'voltage_avg', headerName: 'Voltage L-L Avg (V)', width: 200, flex:1,
      valueGetter: (params) => params.row['voltage_avg'] !== undefined ? parseFloat(params.row['voltage_avg']).toFixed(2) : '' },
      
    { field: 'average_current', headerName: 'Current Avg (A)', width: 200, flex:1,
      valueGetter: (params) => params.row['average_current'] !== undefined ? parseFloat(params.row['average_current']).toFixed(2) : '' },
    
      { field: '3_phase_active_power', headerName: 'Real Power (kW)', width: 200, flex:1,
        valueGetter: (params) => params.row['3_phase_active_power'] !== undefined ? (parseFloat(params.row['3_phase_active_power'])/1000).toFixed(2) : '' },
    
      { field: '3_phase_reactive_power', headerName: 'Reactive Power (kVAr)', width: 200, flex:1,
          valueGetter: (params) => params.row['3_phase_reactive_power'] !== undefined ? (parseFloat(params.row['3_phase_reactive_power'])/1000).toFixed(2) : '' },
    
      { field: '3_phase_apparent_power', headerName: 'Apparent Power (kVA)', width: 200, flex:1,
            valueGetter: (params) => params.row['3_phase_apparent_power'] !== undefined ? (parseFloat(params.row['3_phase_apparent_power'])/1000).toFixed(2) : '' },
    
      { field: 'average_power_factor', headerName: 'Avg Power Factor', width: 200,flex:1,
        valueGetter: (params) => params.row['average_power_factor'] !== undefined ? parseFloat(params.row['average_power_factor']).toFixed(2) : '' },
  ];


  const pageCount = rows ? Math.ceil(rows.length / rowsPerPage) : 0;
  const backgroundColor = darkTheme ? '#333333' : '#f9f9f9';
const backgroundColorOdd = darkTheme ? '#333333' : '#f9f9f9';
const backgroundColorEven = darkTheme ? '#474747' : '#e0e0e0';
const textColor = darkTheme ? '#F2E34C' : 'black';
const cellColor = darkTheme ? '#fff' : 'black';

const useStyles = makeStyles((theme) => ({
  exportButton: {
    display: 'flex',
    alignItems: 'center',
  },
}));
function CustomExportButton() {
  const classes = useStyles();

  return (
    <div className={classes.exportButton}>
      <GridToolbarExport style={{ color: darkTheme ? '#fff' : '#333' }}  csvOptions={{fileName: "Energy Monitor"}} />
      <Tooltip title="[Note: Select rows per page to export the data.] ">
      <InfoIcon style={{ color: darkTheme ? '#e0e0e0' : '#333', fontSize: '15px', marginLeft: '5px' }} />
      </Tooltip>
    </div>
  );
}

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton style={{ color: darkTheme ? '#fff' : '#333' }} />
      <GridToolbarFilterButton style={{ color: darkTheme ? '#fff' : '#333' }} />
      <GridToolbarDensitySelector style={{ color: darkTheme ? '#fff' : '#333' }}/>
      <CustomExportButton />
    </GridToolbarContainer>
    
  );
}

  return (
    <Grid height={'100%'} width={'100%'} style={{ backgroundColor }}>
      <Paper sx={{ width: '100%', overflow: 'hidden',height:'60vh' }} style={{ backgroundColor }}>

  {
  loading?
  <Loading/>:

        <DataGrid
          rows={rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
          columns={columns}
          pageSize={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onPageSizeChange={handleChangeRowsPerPage}
        
          sx={{
            '& .MuiDataGrid-cell': {
              color: cellColor,
              border: 'none',
              fontSize: '16px'
            },
            '& .MuiDataGrid-row:nth-of-type(odd)': {
              backgroundColor: backgroundColorOdd, // Background color for odd rows
              border: 'none',
            },
            '& .MuiDataGrid-row:nth-of-type(even)': {
              backgroundColor: backgroundColorEven, // Background color for even rows
              border: 'none',
            },
            '& .MuiDataGrid-columnHeader': {
              backgroundColor: backgroundColor,
              color: textColor,
              fontSize: '16px'
            },
            '& .MuiDataGrid-iconButtonContainer' : {
              visibility: 'visible',
            },
            '& .MuiDataGrid-sortIcon' : {
              opacity: 'inherit !important',
              color: darkTheme ? '#fff' : '#000'
            },
            '& .MuiDataGrid-menuIconButton' : {
              opacity: 'inherit !important',
              color: darkTheme ? '#fff' : '#000',
            }
          }}
        />
      }

      </Paper>
    </Grid>
  );
}

// import * as React from 'react';
// import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
// import TableCell from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
// import TableRow from '@mui/material/TableRow';
// import Paper from '@mui/material/Paper';
// import { StyledTable } from 'app/utils/utils';
// import { Grid, TablePagination } from '@mui/material';
// import ChargerApi from '../ChargerApi';
// import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
// import { makeStyles } from '@material-ui/core/styles';

// const useStyles = makeStyles({
//   table: {
//     '& .MuiTableCell-root': {
//       borderLeft: '1px solid rgba(224, 224, 224, 1)',
//     },

//   },
//   lensIcon: {
//     color: 'white', 
//   },
//   // columnHeader: {
//   //   borderBottom: '2px solid white', // Change the color of the line below column headers
//   // },
// });

// export default function EnergyTable({ darkTheme }) {

//     const [rows, setRow] = React.useState([]);
//     const [page, setPage] = React.useState(0);
//     const [rowsPerPage, setRowsPerPage] = React.useState(10);

//     const api = new ChargerApi();

//     const handleChangePage = (event, newPage) => {
//         setPage(newPage);
//       };
    
//       const handleChangeRowsPerPage = (event) => {
//         setRowsPerPage(parseInt(event.target.value, 10));
//         setPage(0);
//       };





//     React.useEffect(() => {

//         getChargingMonitorReport();

//     }, [])

//     const getChargingMonitorReport = async () => {
//         let isCanceled = false;
//         api
//             .showChargerStMonitorReport()
//             .then(({ data }) => {
//                 if (!isCanceled) {
//                     // console.log(data)
//                     setRow(data);

//                 }
//             })
//             .catch((e) => {
//                 // console.log('Cannot load report data', e);
//             })
//     };




//     return (
//         <Grid height={"100%"} width={"100%"}>
//             <Paper sx={{ width: '100%', overflow: 'hidden', backgroundColor: darkTheme ? '#333333' : '#fff' }}>
//                 <TableContainer style={{ height: "60vh", backgroundColor: darkTheme ? '#333333' : '#fff' }}>
//                     <StyledTable stickyHeader aria-label="a dense table" sx={{ minWidth: 850 }}>
//                         <TableHead>
//                             <TableRow style={{ backgroundColor: darkTheme ? '#333333' : '#eeeeee' }}>
//                                 <TableCell style={{ paddingLeft: "20px", background: darkTheme ? '#333333': '#eeeeee', color: darkTheme ? '#F2E34C' : 'black', }} width={150} >Sources</TableCell>
//                                 <TableCell style={{ background: darkTheme ? '#333333': '#eeeeee', color: darkTheme ? '#F2E34C' : 'black', }} align="center" width={200}>Voltage L-L Avg (V)</TableCell>
//                                 <TableCell style={{ background: darkTheme ? '#333333': '#eeeeee', color: darkTheme ? '#F2E34C' : 'black', }} align="center" width={150}>Current Avg (A)</TableCell>
//                                 <TableCell style={{ background: darkTheme ? '#333333': '#eeeeee', color: darkTheme ? '#F2E34C' : 'black', }} align="center" width={200}>Real Power (kW)</TableCell>
//                                 <TableCell style={{ background: darkTheme ? '#333333': '#eeeeee', color: darkTheme ? '#F2E34C' : 'black', }} align="center" width={200}>Reactive Power (kVAr)</TableCell>
//                                 <TableCell style={{ background: darkTheme ? '#333333': '#eeeeee', color: darkTheme ? '#F2E34C' : 'black', }} align="center" width={200}>Apparent Power (kVA)</TableCell>
//                                 <TableCell style={{ background: darkTheme ? '#333333': '#eeeeee', color: darkTheme ? '#F2E34C' : 'black', }} align="center" width={150}>Power Factor</TableCell>
//                             </TableRow>
//                         </TableHead>
//                         <TableBody>

//                             {rows.map((row,index) => (
//                                 <TableRow
//                                     key={row.source}
//                                     sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
//                                     style={{ backgroundColor: darkTheme ? `${index % 2 !== 0 ? ('#333333') : ('#454545')}` : `${index % 2 !== 0 ? ('#eeeeee') : ('#f9f9f9')}` }}
//                                 >

//                                     <TableCell style={{ paddingLeft: "20px", color : darkTheme ? '#fff' : '#000' }} component="th" scope="row">
//                                         {row['charge_box_id']}
//                                     </TableCell>
//                                     <TableCell style={{ color : darkTheme ? '#fff' : '#000' }} align="center" width={200}>{200}</TableCell>
//                                     <TableCell style={{ color : darkTheme ? '#fff' : '#000' }} align="center" width={200}>{2}</TableCell>
//                                     <TableCell style={{ color : darkTheme ? '#fff' : '#000' }} align="center" width={200}>{400}</TableCell>
//                                     <TableCell style={{ color : darkTheme ? '#fff' : '#000' }} align="center" width={200}>{400}</TableCell>
//                                     <TableCell style={{ color : darkTheme ? '#fff' : '#000' }} align="center" width={200}>{400}</TableCell>
//                                     <TableCell style={{ color : darkTheme ? '#fff' : '#000' }} align="center" width={200}>1</TableCell>
//                                 </TableRow>
//                             ))}
//                         </TableBody>

//                     </StyledTable>
//                 </TableContainer>

//                 <TablePagination
//                     rowsPerPageOptions={[5, 10, 25, 50]} // Customize the available rows per page options
//                     component="div"
//                     count={rows.length}
//                     rowsPerPage={rowsPerPage}
//                     page={page}
//                     onPageChange={handleChangePage}
//                     onRowsPerPageChange={handleChangeRowsPerPage}
//                     SelectProps={{
//                       style: { color: darkTheme ? '#fff' : '#000' }, // Change the text color of the page size select
//                       IconComponent: (props) => (
//                         <ArrowDropDownIcon style={{ fill: darkTheme ? '#fff' : '#000' }} /> 
//                       )
//                     }}
//                     labelRowsPerPage={<span style={{ color: darkTheme ? '#fff' : '#000' }}>Rows per page:</span>} // Change the text color of the pagination caption
//                     rowsPerPageOptionsLabel={<span style={{ color: darkTheme ? '#fff' : '#000' }}>Rows per page</span>} 
//                     labelDisplayedRows={({ from, to, count }) => (
//                       <span style={{ color: darkTheme ? '#fff' : '#000' }}>{`${from}-${to} of ${count}`}</span> // Change the color of the text "1-10 of 10"
//                     )}
//                     nextIconButtonProps={{ 'aria-label': 'Next Page', style: { color: darkTheme ? '#fff' : '#000' } }}
//                     backIconButtonProps={{ 'aria-label': 'Previous Page', style: { color: darkTheme ? '#fff' : '#000' } }}
//                 />
//             </Paper>
//         </Grid>
//     );
// }