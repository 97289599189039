import React, { useState, useEffect, useRef } from 'react';
import { useTheme } from '@mui/system';
import { makeStyles } from '@material-ui/core/styles';
//import ReactEcharts from 'echarts-for-react';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
// import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import dayjs, { Dayjs } from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Box from '@mui/material/Box';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid';
import TablePagination from '@mui/material/TablePagination';
// import TablePagination from '@mui/material/TablePagination';
import { Grid, InputAdornment, TextField, IconButton, Typography, Select, MenuItem } from '@mui/material';
import { MobileDateTimePicker } from '@mui/x-date-pickers';
import { StyledTable } from 'app/utils/utils';
import { GridSearchIcon } from '@mui/x-data-grid';
import { useThemeContext } from 'app/ThemeProvider';
import { FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage } from '@material-ui/icons';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import Loading from 'app/components/MatxLoading';
import { columns } from 'app/views/reports/shared/ChargingStMonitorReport';

const useStyles = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
    display: 'flex',
    alignItems: 'center',
  },
  select: {
    marginLeft: theme.spacing(1),
  },
}));

const CustomPagination = ({ count, page, rowsPerPage, onChangePage, onChangeRowsPerPage, darkTheme }) => {
  const classes = useStyles();

  const handleFirstPageButtonClick = () => {
    onChangePage(0);
  };

  const handleBackButtonClick = () => {
    onChangePage(page - 1);
  };

  const handleNextButtonClick = () => {
    onChangePage(page + 1);
  };

  const handleLastPageButtonClick = () => {
    onChangePage(Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  const backgroundColor = darkTheme ? '#333333' : '#f9f9f9';
  const textColor = darkTheme ? '#fff' : 'rgba(0, 0, 0, 0.87)';
  const dropdownBackgroundColor = darkTheme ? '#000' : '#fff';
  const dropdownTextColor = darkTheme ? '#fff' : 'rgba(0, 0, 0, 0.87)';

  return (
    <Box className={classes.root} style={{ backgroundColor, color: textColor, marginRight: '10px', height: '48px' }}>
      <Select
        className={classes.select}
        value={rowsPerPage}
        onChange={(e) => onChangeRowsPerPage(e)}
        style={{ color: textColor }}
        IconComponent={() => <ArrowDropDownIcon style={{ color: darkTheme ? '#fff' : '#000', position: 'absolute', right: '8px', top: '50%', transform: 'translateY(-50%)' }} />}
        MenuProps={{
          PaperProps: {
            style: {
              backgroundColor: dropdownBackgroundColor,
              color: dropdownTextColor,
            },
          },
        }}
      >
        {[20, 50, 100].map((pageSize) => ( // Added -1 for "All" option
          <MenuItem key={pageSize} value={pageSize}>
            {pageSize === -1 ? 'All' : pageSize} {/* Display "All" instead of -1 */}
          </MenuItem>
        ))}
      </Select>

      <Typography variant="body2" style={{ color: textColor }}>Rows per page</Typography>
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
        <FirstPage style={{ color: darkTheme ? '#fff' : 'rgba(0, 0, 0, 0.54)' }} />
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        <KeyboardArrowLeft style={{ color: darkTheme ? '#fff' : 'rgba(0, 0, 0, 0.54)' }} />
      </IconButton>
      <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
        <KeyboardArrowRight style={{ color: darkTheme ? '#fff' : 'rgba(0, 0, 0, 0.54)' }} />
      </IconButton>
      <IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="last page">
        <LastPage style={{ color: darkTheme ? '#fff' : 'rgba(0, 0, 0, 0.54)' }} />
      </IconButton>
      <Typography variant="body2">{`${page + 1} of ${Math.ceil(count / rowsPerPage)}`}</Typography>
    </Box>
  );
};

const Sessions = ({loading=false,data=[],setTransactionPk  }) => {
  // const classes = useStyles();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);

  const { darkTheme } = useThemeContext();

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const backgroundColor = darkTheme ? '#333333' : '#f9f9f9';
  const textColor = darkTheme ? '#F2E34C' : 'black';
  const cellColor = darkTheme ? '#fff' : 'black';
  const backgroundColorOdd = darkTheme ? '#333333' : '#f9f9f9';
  const backgroundColorEven = darkTheme ? '#474747' : '#e0e0e0';

  const useStyles = makeStyles((theme) => ({
    exportButton: {
      display: 'flex',
      alignItems: 'center',
    },
  }));
  function CustomExportButton() {
    const classes = useStyles();

    return (
      <div className={classes.exportButton}>
        <GridToolbarExport style={{ color: darkTheme ? '#fff' : '#333' }} csvOptions={{ fileName: "Charger_Monitor_Report" }} />
        <Tooltip title="[Note: Select rows per page to export the data.] ">
          <InfoIcon style={{ color: darkTheme ? '#e0e0e0' : '#333', fontSize: '15px', marginLeft: '5px' }} />
        </Tooltip>
      </div>
    );
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton style={{ color: darkTheme ? '#fff' : '#333' }} />
        <GridToolbarFilterButton style={{ color: darkTheme ? '#fff' : '#333' }} />
        <GridToolbarDensitySelector style={{ color: darkTheme ? '#fff' : '#333' }} />
        <CustomExportButton />
      </GridToolbarContainer>

    );
  }

  return (
    <>

      <Paper sx={{ width: '100%', overflow: 'hidden', height: '70vh' }} style={{ backgroundColor }}>

        {loading ?
          <Loading /> :

          <DataGrid
            rows={data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
            columns={columns.map(column =>
                column.field === "transaction_pk"
                    ? ({
                        ...column,
                        renderCell: (params) => (
                            <Button onClick={()=>setTransactionPk(params.row['transaction_pk'])}
                            color='success'
                            style={{width:"100%",fontSize:'16px' }}
                            variant='outlined'
                            >
                              {params.row['transaction_pk']}</Button>
                        ),
                    })
                    : column // Return the column unchanged if the field doesn't match
            )}
            pageSize={rowsPerPage}
            page={page}
            getRowId={(row) => row.id}
            onPageChange={handleChangePage}
            rowsPerPageOptions={[5, 10, 25, 50]}
            onRowsPerPageChange={handleChangeRowsPerPage}
            disableSelectionOnClick
            components={{
              Toolbar: CustomToolbar, // Custom toolbar component
              Pagination: (props) => (
                <CustomPagination
                  count={data.length}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  darkTheme={darkTheme}
                />
              ),
              NoRowsOverlay: () => (
                <div style={{ textAlign: 'center', color: textColor }}>
                  No Data Available to show
                </div>
              ),
            }}
            sx={{
              '& .MuiDataGrid-cell': {
                color: cellColor,
                border: 'none',
                fontSize: '16px'
              },
              '& .MuiDataGrid-row:nth-of-type(odd)': {
                backgroundColor: backgroundColorOdd, // Background color for odd rows
                border: 'none',
              },
              '& .MuiDataGrid-row:nth-of-type(even)': {
                backgroundColor: backgroundColorEven, // Background color for even rows
                border: 'none',
              },
              '& .MuiDataGrid-columnHeader': {
                backgroundColor: backgroundColor,
                color: textColor,
                fontSize: '16px'
              },
              '& .MuiDataGrid-iconButtonContainer': {
                visibility: 'visible',
              },
              '& .MuiDataGrid-sortIcon': {
                opacity: 'inherit !important',
                color: darkTheme ? '#fff' : '#000'
              },
              '& .MuiDataGrid-menuIconButton': {
                opacity: 'inherit !important',
                color: darkTheme ? '#fff' : '#000',
              }
            }}
          />
        }


      </Paper>
    </>
  );
};

export default Sessions;
