import React, { useState, useEffect } from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { makeStyles } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import { CircularProgress, Grid, IconButton, Typography } from '@mui/material';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import Tooltip from '@mui/material/Tooltip';

import RefreshIcon from '@mui/icons-material/Refresh';
import { toast } from 'react-toastify';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { NotificationGroupTable } from './NotificationGroupTable';
import NotificationApi from '../NotificationApi';

import EditNotificationsIcon from '@mui/icons-material/EditNotifications';







function NotificationMngmnt({ orgId, depot }) {

    const darkTheme = false;

    const [open, setOpen] = useState(false);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    const api = new NotificationApi();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {


        if (open)
            fetchLastUpdatedConfiguration();

    }, [open]);




    const fetchLastUpdatedConfiguration = () => {

    }


    useEffect(() => {

        if (open)
            api
                .getAllNotificationCategory()
                .then(({ data }) => {
                    setData(data.data);
                    console.log(data);
                })
                .catch((e) => {
                    console.log('Request history data', e);
                });


    }, [open]);


    return (
        <>
            {/* <Tooltip title="Config"> */}
            <Button disabled={depot.id === null}
                variant='contained'
                size="small"
                style={{
                    backgroundColor: depot.id === null ? 'gray' : '#2196f3', // Change background color when disabled
                    color: depot.id === null ? 'white' : 'white', // Change text color when disabled
                }}
                onClick={handleClickOpen}
            >
                Notifications

            </Button>

            {/* </Tooltip> */}

            <Dialog open={open} onClose={handleClose} fullScreen>
                <Paper style={{ backgroundColor: darkTheme ? '#000' : '#f9f9f9', height: '100%' }}>


                    <Grid container >
                        <Grid container item xs={11.5}>
                            <Grid item xs={12} padding={"10px"} textAlign={"center"}>
                                <Typography variant={'h5'} >
                                    <b>
                                        Manage Alert And Notifications

                                    </b>
                                </Typography>
                            </Grid>
                        </Grid>



                        <Grid item container xs={0.5}>
                            <IconButton aria-label="close" color="inherit" onClick={handleClose}>
                                <CloseIcon style={{ color: darkTheme ? '#fff' : '#000' }} />
                            </IconButton>

                        </Grid>
                    </Grid>



                    <DialogContent>
                        <DialogContentText>


                            <Grid container item >
                                <Grid item xs={12} padding={"10px"} >
                                    <Typography variant={'h5'} >
                                        <b>
                                            Categories
                                        </b>
                                    </Typography>
                                </Grid>

                            </Grid>


                            {
                                data.map(row => (


                                    <div>
                                        <Accordion>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon style={{ color: darkTheme ? '#fff' : '#000' }} />}
                                                aria-controls="panel1-content"
                                                id="panel1-header"
                                                style={{ backgroundColor: darkTheme ? '#333333' : '#eeeeee' }}
                                            >
                                                <Typography variant='h6' style={{ color: darkTheme ? '#fff' : '#000' }}>
                                                    {row.name}
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails style={{ backgroundColor: darkTheme ? '#333333' : '#eeeeee' }}>
                                                <NotificationGroupTable userDepotPermissionId={depot.id} categoryId={row.id} />
                                            </AccordionDetails>
                                        </Accordion>

                                    </div>
                                ))
                            }
                        </DialogContentText>
                    </DialogContent>
                </Paper>
            </Dialog>
        </>
    );
}

export default NotificationMngmnt;
