import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import ChargerApi from '../ChargerApi';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Slide, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useThemeContext } from 'app/ThemeProvider';
import Loading from 'app/components/MatxLoading';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles({
  root: {
    padding: '0',
  },
  subtitleRight: {
    margin: '20px 0 10px 0',
    backgroundColor: 'rgb(7, 128, 235)',
    padding: '10px 15px 10px 15px',
    borderRadius: '0 30px 30px 0',
    color: 'white',
    position: 'relative',
    boxShadow:
      '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
    marginLeft: 'inherit',
    width: 'fit-content',
  },
  field: {
    // height: '50vh',
    width: 'fit-content',
    // borderRadius: ({isMobile}) => isMobile ? "inherit" : '20px',
    margin: '10px 20px',
    '& .MuiOutlinedInput-input': {
      padding: '18.5px 27px',
    },
  },
  fieldDate: {
    // height: '50vh',
    width: 'fit-content',
    // borderRadius: ({isMobile}) => isMobile ? "inherit" : '20px',
    margin: '10px 20px',
    '& .MuiOutlinedInput-input': {
      padding: '18.5px 5px',
    },
  },
});

function OccpCommandViewDialog({
  open,
  handleOpenDialog,
  handleCloseDialog,
  row,
  occpCommnad,
  chargeBoxId,
}) {
  const classes = useStyles();
  const api = new ChargerApi();
  const [values, setValues] = useState([]);
  const [tags, setTags] = useState([]);
  const [chargingProfile, setChargingProfile] = useState([]);
  const [state, setState] = useState({});
  const [disabled, setDisabled] = useState(false);
  const { darkTheme } = useThemeContext();



  const orgId = localStorage.getItem("orgId");

  useEffect(() => {
    let isCanceled = false;
    
    api
      .getChargergingProfile(null, row)
      .then(({ data }) => {
        if (!isCanceled) {
          setChargingProfile(data);
        }
      })
      .catch((e) => {
        // console.log('Cannot load event data', e);
      });
    return () => {
      isCanceled = true;
    };
  }, [row]);

  useEffect(() => {

    let isCanceled = false;
    api
      .getChargerConnectorList(null, row)
      .then(({ data }) => {
        if (!isCanceled) {
          setValues(data);
        }
      })
      .catch((e) => {
        // console.log('Cannot load event data', e);
      });

    getAllOcppTag();

    return () => {
      isCanceled = true;
    };
  }, [row]);


  const getAllOcppTag = () => {

    api
      .getAllOcppTag({ orgId: orgId })
      .then((response) => {

        console.log(response.data)
        setTags(response.data);
        console.log(response.data);
      })
      .catch((e) => {
        console.log('Cannot load event data', e);
      });
  };

  const handleClickOpen = () => {
    handleOpenDialog();
  };

  const handleClose = () => {
    setValues([]);
    handleCloseDialog();
  };

  const handleUpdateDetails = (name) => (event) => {
    // console.log(event.target.value);
    // console.log(event.target.name);
    setValues({ ...values, [name]: event.target.value });
  };

  const handleChanage = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const saveUpdatedDetails = () => {
    setDisabled(true);
    if (occpCommnad === 2) {
      // console.log(state);
      api
        .remoteStart(row, state.occpIdTag, state, state.connectorId)
        .then(({ data }) => {
          handleClose()
          return toast.success('Request ' + data);
        })
        .catch((e) => {
          // console.log('Cannot load event data', e);

          return toast.error('Request Failed');
        }).finally(() => setDisabled(false));
    }
    if (occpCommnad === 3) {
      // console.log(state);
      setDisabled(true);

      api
        .remoteStop(row, state.connectorId)
        .then(({ data }) => {
          handleClose()
          return toast.success('Request ' + data);
        })
        .catch((e) => {
          // console.log('Cannot load event data', e);

          return toast.error('Request Failed');
        }).finally(() => setDisabled(false));
    }
    if (occpCommnad > 3) {
      return toast.info('Charge Box not found !!!');
    }

    //handleClose();
  };

  const { connectorId, availableType } = state;

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      // //keepMounted

      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      fullWidth
      maxWidth="sm"                 // Sets the maximum width to 'md' (medium)

    >
      <DialogTitle id="alert-dialog-slide-title" style={{ padding: '0', backgroundColor: darkTheme ? '#333333' : '#f9f9f9' }}>
        <Grid container >
          <Grid container className={classes.subtitleRight} item xs={11}>
            <Grid item xs={4}>
                <Typography variant={'h5'} >
                  Remote {occpCommnad === 2 ? "Start" : "Stop"}
                  &nbsp;


                </Typography>
            </Grid>

            <Grid item xs={8} style={{fontSize:'16px',paddingTop:'3px'}} >

                Charger ID:{chargeBoxId}

            </Grid>

          </Grid>

          <Grid item container xs={0.5}>
            <IconButton aria-label="close" color="inherit" onClick={handleClose}>
              <CloseIcon style={{ color: darkTheme ? '#fff' : '#000' }} />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>

      <DialogContent dividers style={{ backgroundColor: darkTheme ? '#333333' : '#f9f9f9', color: darkTheme ? '#fff' : '#000' }}>
        {/* maybe to do smart form that detect what he get from the props (column & row)  */}
        <form noValidate autoComplete="off">
          {' '}
          {/*onSubmit={handleSubmit} className={classes.form} */}
          <Grid container alignItems={'left'} direction={'column'}>
            <FormControl fullWidth>
              <InputLabel variant="standard" htmlFor="uncontrolled-native" style={{ color: darkTheme ? '#fff' : '#000', fontSize: '18px' }}>
                Connectors
              </InputLabel>
              <NativeSelect
                onChange={handleChanage}
                inputProps={{
                  name: 'connectorId',
                  id: 'uncontrolled-native',
                }}
                style={{ color: darkTheme ? '#fff' : '#000' }}
                IconComponent={() => <ArrowDropDownIcon style={{ color: darkTheme ? '#fff' : '#000' }} />}
                sx={{
                  "& .MuiSelect-icon": {
                    color: darkTheme ? '#fff' : '#000',
                  },
                  "&:before, &:after": {
                    borderBottom: '1px solid',
                    borderColor: darkTheme ? '#fff' : '#000'
                  },
                  "& option": {
                    backgroundColor: darkTheme ? '#000' : '#fff',
                    color: darkTheme ? '#fff' : '#000',
                  },
                }}
              >
                <option style={{ backgroundColor: darkTheme ? '#000' : '#fff', color: darkTheme ? '#fff' : '#000' }} value={0}></option>
                {values &&
                  values.map((s, index) => <option style={{ backgroundColor: darkTheme ? '#000' : '#fff', color: darkTheme ? '#fff' : '#000' }} value={s.connectorId}>{s.name}</option>)}
              </NativeSelect>
            </FormControl>
          </Grid>
          <br />
          <Grid container alignItems={'left'} direction={'column'}>
            <FormControl fullWidth>
              <InputLabel variant="standard" htmlFor="uncontrolled-native" style={{ color: darkTheme ? '#fff' : '#000', fontSize: '18px' }}>
                RFID Tag
              </InputLabel>
              <NativeSelect
                onChange={handleChanage}
                autoWidth
                inputProps={{
                  name: 'occpIdTag',
                  id: 'uncontrolled-native',
                }}
                style={{ fontSize: '10', color: darkTheme ? '#fff' : '#000' }}
                IconComponent={() => <ArrowDropDownIcon style={{ color: darkTheme ? '#fff' : '#000' }} />}
                sx={{
                  "& .MuiSelect-icon": {
                    color: darkTheme ? '#fff' : '#000',
                  },
                  "&:before, &:after": {
                    borderBottom: '1px solid',
                    borderColor: darkTheme ? '#fff' : '#000'
                  },
                  "& option": {
                    backgroundColor: darkTheme ? '#000' : '#fff',
                    color: darkTheme ? '#fff' : '#000',
                  },
                }}
              >
                <option style={{ backgroundColor: darkTheme ? '#000' : '#fff', color: darkTheme ? '#fff' : '#000' }} value={0}></option>
                {tags && tags.map((s, index) => <option style={{ backgroundColor: darkTheme ? '#000' : '#fff', color: darkTheme ? '#fff' : '#000' }} value={s.idTag}>{s.idTag}</option>)}
              </NativeSelect>
            </FormControl>
          </Grid>
          <br />
          <Grid container alignItems={'left'} direction={'column'}>
            <FormControl fullWidth style={{ marginBottom: '20px' }}>
              <InputLabel variant="standard" htmlFor="uncontrolled-native" style={{ color: darkTheme ? '#fff' : '#000', fontSize: '18px' }}>
                Charging Profile ID:
              </InputLabel>
              <NativeSelect
                onChange={handleChanage}
                inputProps={{
                  name: 'chargingProfilePk',
                  id: 'uncontrolled-native',
                }}
                style={{ color: darkTheme ? '#fff' : '#000'}}
                IconComponent={() => <ArrowDropDownIcon style={{ color: darkTheme ? '#fff' : '#000'}} />}
                sx={{
                  "& .MuiSelect-icon": {
                    color: darkTheme ? '#fff' : '#000',
                  },
                  "&:before, &:after": {
                    borderBottom: '1px solid',
                    borderColor: darkTheme ? '#fff': '#000'
                  },
                  "& option": {
                    backgroundColor: darkTheme ? '#000' : '#fff',
                    color: darkTheme ? '#fff' : '#000',
                  },
                }}
              >
                <option style={{ backgroundColor: darkTheme ? '#000' : '#fff', color: darkTheme ? '#fff' : '#000' }} value={0}></option>
                {chargingProfile &&
                  chargingProfile.map((s, index) => (
                    <option style={{ backgroundColor: darkTheme ? '#000' : '#fff', color: darkTheme ? '#fff' : '#000' }} value={s.chargingProfilePk}>{s.description}</option>
                  ))}
              </NativeSelect>
            </FormControl>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions style={{ backgroundColor: darkTheme ? '#333333' : '#f9f9f9' }}>
      <Button
          type={'submit'}
          size={'large'}
          variant={'contained'}
          style={{ backgroundColor: disabled ? 'white' : 'green', color: 'white' }}
          onClick={saveUpdatedDetails}
          disabled={disabled}
        >
          {disabled ? <Loading /> : "Submit"}
        </Button>

        <Button
          type={'submit'}
          size={'large'}
          variant={'contained'}
          // color="secondry"
          onClick={handleClose}
          style={{ backgroundColor: '#334155', color: 'white' }}
        >
          Close
        </Button>
      </DialogActions>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </Dialog>
    // </div>
  );
}

export default OccpCommandViewDialog;
