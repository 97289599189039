import React, { useEffect, useRef } from 'react';
import * as echarts from 'echarts';
import { chartOptions } from 'app/utils/utils';


const ChargingPortChart = ( {data=[], darkTheme}) => {

  const chartRef = useRef(null);

  console.log(data);

    useEffect(() => {
        const chartDom = chartRef.current;
        const myChart = echarts.init(chartDom);

    const textColor = darkTheme ? '#F2E34C' : '#000'; // Conditionally set text color
    const titleColor = darkTheme ? '#F2E34C' : '#000';
    const tooltipColor = darkTheme ? '#333333' : '#ffffff';
    const labelColor = darkTheme ? '#000' : '#6a7985';

    const option = data ? {
      // title: {
      //   text: 'Load Profile',
      //   padding: [0,0, 0, 20]
      // },

      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          label: {
            backgroundColor: labelColor,
            color: textColor
        }
        },
        backgroundColor: tooltipColor,
        textStyle: {
            color: darkTheme ? '#F2E34C' : '#000' // Set text color based on theme
        },
        formatter: function (params) {
          let tooltip = params[0].name + '<br/>';
          params.forEach(param => {
            if (param.seriesName !== 'Inlet') { 
              tooltip += param.marker + ' ' + param.seriesName + ' : ' + parseFloat(param.value).toFixed(2).toLocaleString() + ' kW<br/>';
            }
          });
          return tooltip;
      }
      },
      legend: {
        data: ['3 Phase Active Power', '3 Phase Reactive Power','3 Phase Apparent Power'], // Set legend data
        color:['blue','red','green'],
        textStyle: {
          color: darkTheme ? '#F2E34C' : '#000'
        }
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '14%',
      containLabel: true
      },
      // dataZoom: [
      //     {
      //         type: 'inside'
      //     },
      //     {
      //         type: 'slider'
      //     }
      // ],
      toolbox: {
        show: true,
        top: '10px',
        right: 45,
        feature: {
          saveAsImage: {
            show: true,
            title: 'Download',
            name: 'Charging Power', 
            type: 'png' 
          }
        },
        iconStyle: {
            borderColor: darkTheme ? '#F2E34C' : '#000'
        }
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: data.filter(obj => obj.measurand==="3_phase_active_power"
        ).map(obj => new Date(obj.created_at)
            .toLocaleTimeString(undefined, { hour12: true, hour: '2-digit', minute: '2-digit' })),
          name: 'Time',
          axisLabel: chartOptions().axisLabel,
          nameLocation: 'middle',
          nameGap: 35,
          nameTextStyle: {
            ...chartOptions().nameTextStyle,
            color: textColor // Change text color based on dark theme
        },
        axisLabel: {
          color: textColor // Set text color based on theme
      }
      },
      yAxis: {
        type: 'value',
        name: 'Power (kW)',
        nameLocation: 'middle',
        nameGap: 40,
        nameTextStyle: {
          ...chartOptions().nameTextStyle,
          color: textColor // Change text color based on dark theme
      },
        axisLabel: chartOptions().axisLabel,
        axisLabel: {
          color: textColor // Set text color based on theme
      }
      },
      series: [
        {
            name: '3 Phase Active Power', // Set series name
            type: 'line',
            data: data.filter(obj => obj.measurand==="3_phase_active_power"
            ).map(obj => obj.register_value/1000),
            // areaStyle: {
            //     opacity: 0.5
            // },


            smooth: true,
            color:'blue',
            lineStyle: {
              width: 4, // Set line width if needed
              color: 'blue' // Set line color to red
            },
            showSymbol: false,
            // areaStyle: {
            //   opacity: 0.8,
            //   color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            //     {
            //       offset: 0,
            //       color: '#8cca6e'
            //     },
            //     {
            //       offset: 1,
            //       color: 'rgb(1, 191, 236)'
            //     }
            //   ])
            // },
            emphasis: {
              focus: 'series'
            },
        },
        {
          name: '3 Phase Reactive Power', // Set series name
          type: 'line',
          data: data.filter(obj => obj.measurand==="3_phase_reactive_power"
          ).map(obj => obj.register_value/1000),
          // areaStyle: {
          //     opacity: 0.5
          // },


          smooth: true,
          color:'green',
          lineStyle: {
            width: 4, // Set line width if needed
            color: 'green' // Set line color to red
          },
          showSymbol: false,
          // areaStyle: {
          //   opacity: 0.5,
          //   color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          //     {
          //       offset: 0,
          //       color: 'blue'
          //     },
          //     {
          //       offset: 1,
          //       color: 'rgb(1, 191, 236)'
          //     }
          //   ])
          // },
          emphasis: {
            focus: 'series'
          },
      },
        {
            name: '3 Phase Apparent Power', // Set series name
            color:'yellow',
            type: 'line',
            data: data.filter(obj => obj.measurand==="3_phase_apparent_power")
            .map(obj=>obj.register_value/1000),

            // areaStyle: {
            //     opacity: 0.5,
            // },

            smooth: true,
            lineStyle: {
              width: 4, // Set line width if needed
              color: 'yellow' // Set line color to red
            },
            showSymbol: false,
            emphasis: {
              focus: 'series'
            },
        },
        // {
        //     name: 'Inlet', // Set series name

        //     type: 'line',
        //     data: data.filter(obj => obj.location === "Inlet").map(obj => obj.value/1000),
        //     areaStyle: {
        //         opacity: 0.5,
        //     },
        // },
    ]
} : {};

option && myChart.setOption(option);

return () => {
    myChart.dispose(); // Clean up when the component unmounts
};
}, [data.length]); // Empty dependency array to run this effect only once

return <div ref={chartRef} style={{ width: '100%', height: '50vh',paddingBottom:"15px" }}></div>;
};

export default ChargingPortChart;

// import React from 'react';
// import ReactEcharts from 'echarts-for-react'; // Import ReactEcharts
// import * as echarts from 'echarts';


// const MyChartComponent = () => {
//   // Define the data
//   const seriesData = [
//     {
//       name: 'Line 1',
//       type: 'line',
//       stack: 'Total',
      // smooth: true,
      // lineStyle: {
      //   width: 0
      // },
      // showSymbol: false,
      // areaStyle: {
      //   opacity: 0.8,
      //   color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
      //     {
      //       offset: 0,
      //       color: 'rgb(128, 255, 165)'
      //     },
      //     {
      //       offset: 1,
      //       color: 'rgb(1, 191, 236)'
      //     }
      //   ])
      // },
      // emphasis: {
      //   focus: 'series'
      // },
//       data: [140, 232, 101, 264, 90, 340, 250]
//     },
//     {
//       name: 'Line 2',
//       type: 'line',
//       stack: 'Total',
      // smooth: true,
      // lineStyle: {
      //   width: 2, // Set line width if needed
      //   color: 'red' // Set line color to red
      // },
      // showSymbol: false,
      // emphasis: {
      //   focus: 'series'
      // },
//       data: [120, 282, 111, 234, 220, 340, 310]
//     },
//   ];
  

//   // Define options object
//   const option = {
//     color: ['#80FFA5', 'red', '#37A2FF', '#FF0087', '#FFBF00'],
//     title: {
//       text: 'Gradient Stacked Area Chart'
//     },
//     tooltip: {
//       trigger: 'axis',
//       axisPointer: {
//         type: 'cross',
//         label: {
//           backgroundColor: '#6a7985'
//         }
//       }
//     },
//     legend: {
//       data: ['Line 1', 'Line 2', 'Line 3', 'Line 4', 'Line 5']
//     },
//     toolbox: {
//       feature: {
//         saveAsImage: {}
//       }
//     },
//     grid: {
//       left: '3%',
//       right: '4%',
//       bottom: '3%',
//       containLabel: true
//     },
//     xAxis: [
//       {
//         type: 'category',
//         boundaryGap: false,
//         data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
//       }
//     ],
//     yAxis: [
//       {
//         type: 'value'
//       }
//     ],
//     series: seriesData // Assign seriesData to the series property
//   };

//   return <ReactEcharts option={option} />; // Render the ReactEcharts component with options
// };

// export default MyChartComponent;
