import React, { useState, useEffect } from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Paper, Slide, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ChargerApi from '../ChargerApi';
import { makeStyles } from '@material-ui/core';
import { useThemeContext } from 'app/ThemeProvider';
import { DateTime } from 'luxon';

const useStyles = makeStyles({
  root: {
    padding: '0',
  },
    subtitleRight: {
        margin: '20px 0 10px 0',
        backgroundColor: 'rgb(7, 128, 235)',
        padding: '10px 10px 10px 15px',
    borderRadius: '0 30px 30px 0',
    color: 'white',
    position: 'relative',
    boxShadow:
      '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
    // marginLeft: 'inherit',
    width: 'fit-content',
  },
  field: {
    // height: '50vh',
    width: 'fit-content',
    // borderRadius: ({isMobile}) => isMobile ? "inherit" : '20px',
    margin: '10px 20px',
    '& .MuiOutlinedInput-input': {
      padding: '18.5px 27px',
    },
  },
  fieldDate: {
    // height: '50vh',
    width: 'fit-content',
    // borderRadius: ({isMobile}) => isMobile ? "inherit" : '20px',
    margin: '10px 20px',
    '& .MuiOutlinedInput-input': {
      padding: '18.5px 5px',
    },
  },
});


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});



function ChargerViewDialog({ open, handleOpenDialog, handleCloseDialog, row }) {

  const [data, setData] = useState([]);
  const { darkTheme } = useThemeContext();
  const api = new ChargerApi();
  const classes = useStyles();
  const [autoRefresh, setAutoRefresh] = useState(false);


  console.log(row)

  const timezone = localStorage.getItem("orgTimezone");


  const handleClickOpen = () => {
    handleOpenDialog();
    // handleOpenDialog(true)
    // setOpen(true);
  };

  useEffect(() => {
    if (open) {
      fetchChargerLogs();

      if (autoRefresh) {
        const interval = setInterval(() => {
          fetchChargerLogs();
        }, 5000);

        // Cleanup function to clear the interval
        return () => clearInterval(interval);
      }
    }

    // If `open` is false or `autoRefresh` is false, return a cleanup function
    return () => { };
  }, [open, autoRefresh]); // Add autoRefresh to the dependency array



  const fetchChargerLogs = () => {
    debugger;
    api
      .getChargerLogs(row.chargeBoxId)
      .then(({ data }) => {
        console.log(data)
        setData(data);
      })
      .catch((e) => {
        // console.log('Cannot load report data', e);
      })
  }

  const handleClose = () => {
    // if (true) {
    //   setValues({ id: '', age: '', displayName: '' });
    // } else {
    //   setValues({ id: '', price: '', displayName: '' });
    // }
    handleCloseDialog();
  };

  const handleUpdateDetails = (name) => (event) => {
    // console.log(event.target.value);
    // console.log(event.target.name);
  };

  const saveUpdatedDetails = () => {
    handleClose();
  };

  const sortedData = [...data].sort((a, b) => new Date(b.date) - new Date(a.date));

  return (
    <div>

      <Dialog
        open={open}
        // TransitionComponent={Transition}
        // //keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        height="100vh"
        maxWidth="xl"
      >
        <DialogTitle id="alert-dialog-slide-title" style={{ padding: 0, margin: 0, backgroundColor: darkTheme ? '#333333' : '#f9f9f9' }}>
          <Grid container >
            <Grid item xs={11} >
              <div >
                <div className={classes.subtitleRight}>
                  <Typography variant={'h5'} align={'left'}>
                    Charger Details
                  </Typography>
                </div>


                <Grid container textAlign="center" justifyContent="center" alignItems="center" spacing={2}>
                  <Grid item md={3}>
                    <span style={{ color: darkTheme ? '#f2e34c' : '#000' }}>Name: {row.chargeBoxId}</span>
                  </Grid>
                  <Grid item md={3}>
                    <span style={{ color: darkTheme ? '#f2e34c' : '#000' }}>Serial No.: {row.chargePointSerialNumber}</span>
                  </Grid>
                  <Grid item md={6}>
                    <span style={{ color: darkTheme ? '#f2e34c' : '#000' }}>Current Firmware: {row.fwVersion}</span>
                  </Grid>
                  {/* <hr style={{ width: '100%' }}/> */}
                </Grid>

              </div>
            </Grid>
            <Grid item xs={1} display="flex" justifyContent="flex-end" alignItems="flex-start">
              <IconButton aria-label="close" color="inherit" onClick={handleClose}>
                <CloseIcon style={{ color: darkTheme ? '#fff' : '#000' }} />
              </IconButton>
            </Grid>


          </Grid>

        </DialogTitle>

        <DialogContent dividers style={{ backgroundColor: darkTheme ? '#333333' : '#f9f9f9', color: 'white' }}>
          {/* maybe to do smart form that detect what he get from the props (column & row)  */}
          <Grid container>
            <Grid item md={4} lg={4} >
              {/* <form noValidate autoComplete="off">
                <Grid container alignItems={'left'} direction={'column'}>
                  <p style={{ color: 'white' }}>Name: {row.name}</p>
                  <p style={{ color: 'white' }}>Version: {row.chargePointSerialNumber}</p>
                  <p style={{ color: 'white' }}>Firmware: {row.fwVersion}</p>
                </Grid>
              </form> */}
            </Grid>
            <Grid item md={12} lg={12} minHeight={"60vh"} container>

              {/* <Grid item md={12} lg={12} style={{ textAlign: "center" }}> */}

              {/* <h3 >
                  Firmware History
                </h3> */}

              {/* <TableContainer component={Paper}  >
                  <Table stickyHeader style={{ textAlign: "center" }}>
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ textAlign: 'center', backgroundColor: '#333333', color: 'white' }}>Firmware</TableCell>
                        <TableCell style={{ textAlign: 'center', backgroundColor: '#333333', color: 'white' }}>Version</TableCell>
                        <TableCell style={{ textAlign: 'center', backgroundColor: '#333333', color: 'white' }}>Updated AT</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map(row => (
                        <TableRow key={row.id}>
                          <TableCell>{row.id}</TableCell>
                          <TableCell>{row.name}</TableCell>
                          <TableCell>{row.age}</TableCell>
                          <TableCell>{row.email}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer> */}

              <TableContainer component={Paper} style={{ backgroundColor: darkTheme ? '#333333' : '#f9f9f9', border: '1px solid #ccc', borderRadius: '5px' }}  >
                <Grid container>
                  <Grid item md={4.5}>
                    <Typography variant='h6' style={{ padding: "10px", color: darkTheme ? '#ffffff' : '#000' }}>
                    </Typography>
                  </Grid>

                  <Grid item md={6}>
                    <Typography variant='h6' style={{ padding: "10px", color: darkTheme ? '#ffffff' : '#000' }}>
                      Last Seen : 
                      {/* {new Date(row.lastHeartbeatTimestamp).toLocaleString()} */}
                      {DateTime.fromISO(row.lastHeartbeatTimestamp).setZone(timezone).toFormat('dd-MM-yyyy hh:mm:ss a')}
                    </Typography>
                  </Grid>

                  <Grid item md={1.5}>
                    <Typography
                      style={{ padding: "10px", color: darkTheme ? '#ffffff' : '#000' }}
                    >Auto refresh  <Switch value={autoRefresh} onChange={(e) => setAutoRefresh(e.target.checked)} /></Typography>

                  </Grid>
                </Grid>
                <Table stickyHeader aria-label="a dense table" >
                  <TableHead stickyHeader style={{ position: "sticky" }}>
                    <TableRow style={{ position: "sticky" }}>
                      <TableCell style={{ textAlign: 'left', width: "200px", paddingLeft: "10px", backgroundColor: darkTheme ? '#333333' : '#f9f9f9', color: darkTheme ? '#fff' : '#000' }}>Time</TableCell>
                      <TableCell style={{ textAlign: 'left', width: "100px", backgroundColor: darkTheme ? '#333333' : '#f9f9f9', color: darkTheme ? '#fff' : '#000' }}>Type</TableCell>
                      <TableCell style={{ textAlign: 'left', backgroundColor: darkTheme ? '#333333' : '#f9f9f9', color: darkTheme ? '#fff' : '#000' }}>Message</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>

                    {sortedData && sortedData.map(row => (
                      <TableRow key={row.id}>
                        <TableCell style={{ textAlign: 'left', width: "200px", paddingLeft: "10px", color: darkTheme ? '#fff' : '#000' }}>{row.date}</TableCell>
                        <TableCell style={{ textAlign: 'left', width: "100px", color: darkTheme ? '#fff' : '#000' }}>{row.type}</TableCell>
                        <TableCell style={{ color: darkTheme ? '#fff' : '#000' }}>{row.data}</TableCell>
                      </TableRow>
                    ))
                    }

                  </TableBody>
                </Table>


              </TableContainer>

              {/* </Grid> */}




            </Grid>
          </Grid>

        </DialogContent>
        {/* <DialogActions>
          <IconButton aria-label="close" color="inherit" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogActions> */}
      </Dialog>
    </div>
  );
}
export default ChargerViewDialog;
