import axios from 'axios.js';

export default class SearchApi {
    constructor() {
        this.api_token = null;
        this.client = null;
        this.user = JSON.parse(localStorage.getItem('user'));
    }

    init = () => {
        this.client = axios.create({
            timeout: 360000,
        });

        return this.client;
    };

    getBySearchRequestDto = (params) => {

        return this.init().get('/api/search/', { params: params });
    };
}