import axios from 'axios.js';
export default class DepoApi {
  constructor() {
    this.api_token = null;
    this.client = null;
    this.user = JSON.parse(localStorage.getItem('user'));
  }

  init = () => {
    this.client = axios.create({
      timeout: 120000,
    });

    return this.client;
  };

  addDepo = (data) => {
    return this.init().post('/api/depot/add_depot', data);
  };

  getDepoList = (params) => {
    return this.init().get('/api/depot/show/', { params: params });
  };

  getPermittedDepotList=(params)=>{
    
    return this.init().get('/api/user-depot-permission/permitted', { params: params });

  };

  addUserDepo = (params) => {
    return this.init().get('/api/depo/show_list', { params: params });
  };

  deleteDepot = (id) => {
    return this.init().post('/api/depot/delete_depot/' + id);
  };

  updateDepo = (data) => {
    return this.init().put('/api/depot/update_depot', data);
  };
}
