// import { Icon, IconButton } from '@mui/material';
// import { styled, useTheme } from '@mui/system';
// import { topBarHeight } from 'app/utils/constant';
// import React, { useState } from 'react';

// const SearchContainer = styled('div')(({ theme }) => ({
//   position: 'absolute',
//   top: 0,
//   left: 0,
//   zIndex: 9,
//   width: '100%',
//   display: 'flex',
//   alignItems: 'center',
//   height: topBarHeight,
//   background: theme.palette.primary.main,
//   color: theme.palette.text.primary,
//   '&::placeholder': {
//     color: theme.palette.text.primary,
//   },
// }));

// const SearchInput = styled('input')(({ theme }) => ({
//   width: '100%',
//   border: 'none',
//   outline: 'none',
//   fontSize: '1rem',
//   paddingLeft: '20px',
//   height: 'calc(100% - 5px)',
//   background: theme.palette.primary.main,
//   color: theme.palette.text.primary,
//   '&::placeholder': { color: theme.palette.text.primary },
// }));

// const MatxSearchBox = () => {
//   const [open, setOpen] = useState(false);
//   const toggle = () => {
//     setOpen(!open);
//   };

//   const { palette } = useTheme();
//   const textColor = palette.text.primary;

//   return (
//     <React.Fragment>
//       {!open && (
//         <IconButton onClick={toggle}>
//           <Icon sx={{ color: textColor }}>search</Icon>
//         </IconButton>
//       )}

//       {open && (
//         <SearchContainer>
//           <SearchInput type="text" placeholder="Search here..." autoFocus />
//           <IconButton onClick={toggle} sx={{ mx: 2, verticalAlign: 'middle' }}>
//             <Icon sx={{ color: textColor }}>close</Icon>
//           </IconButton>
//         </SearchContainer>
//       )}
//     </React.Fragment>
//   );
// };

// export default MatxSearchBox;



import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { AppBar, Grid, IconButton, InputBase, Toolbar, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { styled, alpha } from '@mui/material/styles';
import SearchAnalytics from 'app/views/search/SearchAnalytics';









export default function MatxSearchBox() {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (open) =>  {


    setState({ ...state, top: open });
  };



  return (
    <Grid sx={{ display: { xs: 'none', sm: 'none', lg: 'flex', md: 'none' } }}>


      <IconButton onClick={()=>toggleDrawer(true)}>
        <SearchIcon />
      </IconButton>
      <Drawer
        anchor={"top"}
        open={state["top"]}
        onClose={()=>toggleDrawer(false)}

        sx={{
          "& .MuiPaper-root": {
            backgroundColor: "transparent", // Transparent background
            // boxShadow: "none", // Optional: Remove shadow
          },
        }}

      >
        <SearchAnalytics toggleDrawer={toggleDrawer} />
      </Drawer>

    </Grid>
  );
}
