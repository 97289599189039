import React, { useState, useEffect } from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChargerApi from '../ChargerApi';
import { makeStyles } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import { CircularProgress, Grid, IconButton, Typography } from '@mui/material';
import { useThemeContext } from 'app/ThemeProvider';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import Tooltip from '@mui/material/Tooltip';

import RefreshIcon from '@mui/icons-material/Refresh';
import { toast } from 'react-toastify';

const useStyles = makeStyles({
    root: {
        padding: '0',
    },
    subtitleRight: {
        margin: '20px 0 10px 0',
        backgroundColor: 'rgb(7, 128, 235)',
        padding: '10px 10px 10px 15px',
        borderRadius: '0 30px 30px 0',
        color: 'white',
        position: 'relative',
        boxShadow:
            '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
        // marginLeft: 'inherit',
        width: 'fit-content',
    },
    field: {
        // height: '50vh',
        width: 'fit-content',
        // borderRadius: ({isMobile}) => isMobile ? "inherit" : '20px',
        margin: '10px 20px',
        '& .MuiOutlinedInput-input': {
            padding: '18.5px 27px',
        },
    },
    fieldDate: {
        // height: '50vh',
        width: 'fit-content',
        // borderRadius: ({isMobile}) => isMobile ? "inherit" : '20px',
        margin: '10px 20px',
        '& .MuiOutlinedInput-input': {
            padding: '18.5px 5px',
        },
    },
});

const RefreshConfiguration = ({row,setRows,fetchLastUpdatedConfiguration}) => {
    

    const api = new ChargerApi();
    const [loading,setLoading]=useState(false);


    const fetchConfiguration2 = () => {
        debugger;
        const obj = {
            confKey: row.key,
            chargeBoxId: row.chargeBoxPk,
            customKeyList: ""

        }
        setLoading(true);
        try {

            setLoading(true);

            api
                .getConfiguration(obj)
                .then(({ data }) => {
                    if (data && data.length > 0) {

                        // console.log(data)
                        // if (Array.isArray(data))
                        //     setRows(data);
                        fetchLastUpdatedConfiguration();
                        toast.success("Configuration Refreshed")
                    }

                    setLoading(false);

                })
                .catch((e) => {
                    console.log('Cannot load report data', e);
                    toast.error("Please try again after some time.")
                    return e;
                }).finally(() => setLoading(false))
        } catch (e) {
            console.log('Cannot load report data', e);
        }
    }


    return (
        <IconButton variant='contained' color='primary' onClick={() => fetchConfiguration2()} disabled={loading} >
            {
                loading?<CircularProgress/>:<RefreshIcon />
            }
        </IconButton>
    )
}


function ConfigDialog({ row, CommandOptionList }) {

    const api = new ChargerApi();
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [rows, setRows] = useState([]);
    const { darkTheme } = useThemeContext();
    const [loading, setLoading] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {


        if (open)
            fetchLastUpdatedConfiguration();

    }, [open]);




    const fetchLastUpdatedConfiguration = () => {

        try {

            api
                .getLastUpdatedConfiguration({ chargeBoxPk: row.chargeBoxPk })
                .then(({ data }) => {
                    if (data && data.length > 0) {

                        console.log(data)
                        if (Array.isArray(data))
                            setRows(data);
                    }

                })
                .catch((e) => {
                    console.log('Cannot load report data', e);
                    return e;
                })
        } catch (e) {
            console.log('Cannot load report data', e);
        }
    }

    console.log(rows)




    const fetchConfiguration = () => {
        debugger;
        const obj = {
            confKeyList: "AllowOfflineTxForUnknownId,AuthorizationCacheEnabled,AuthorizeRemoteTxRequests,ChargeProfileMaxStackLevel",
            chargeBoxId: row.chargeBoxPk,
            customKeyList: ""

        }
        try {

            setLoading(true);

            api
                .getConfiguration(obj)
                .then(({ data }) => {
                    if (data && data.length > 0) {

                        console.log(data)
                        if (Array.isArray(data))
                            setRows(data);
                        toast.success("Configuration Refreshed")
                    }

                })
                .catch((e) => {
                    console.log('Cannot load report data', e);
                    toast.error("Please try again after some time.")
                    return e;
                }).finally(() => setLoading(false))
        } catch (e) {
            console.log('Cannot load report data', e);
        }
    }



    // console.log(rows);

    return (
        <div>
            <Tooltip title="Config">
                <IconButton style={{ margin: 0, padding: 0 }} disabled={!CommandOptionList.includes("Config")}>

                    <SettingsSuggestIcon
                        onClick={handleClickOpen}
                        style={{
                            color: CommandOptionList.includes("Config") ? (darkTheme ? '#F2E34C' : '#000') : 'gray',
                            cursor: 'pointer'
                        }} />
                </IconButton>

            </Tooltip>

            <Dialog open={open} onClose={handleClose} maxWidth='xl'>
                <Paper style={{ backgroundColor: darkTheme ? '#000' : '#f9f9f9' }}>


                    <Grid container >
                        <Grid container className={classes.subtitleRight} item xs={11.5}>
                            <Grid item xs={4}>
                                <Typography variant={'h5'} >
                                    Charger Configuration
                                </Typography>
                            </Grid>

                            <Grid item xs={3} style={{ fontSize: '16px', paddingTop: '3px' }} >

                                Charger ID:{row.chargeBoxId}

                            </Grid>

                            <Grid item xs={4} style={{ fontSize: '16px', paddingTop: '3px' }} >

                                {/* Last Updated : {

                                    rows && rows.length > 0 ? new Date(rows[0]['timestamp']).toLocaleString() : ""
                                } */}

                            </Grid>

                            <Grid item md={0.5}>

                                {
                                    loading ? <CircularProgress size={25} style={{ color: 'white' }} /> :
                                        <RefreshIcon style={{ margin: 0, padding: 0 }} onClick={() => fetchConfiguration()} />

                                }
                            </Grid>



                        </Grid>



                        <Grid item container xs={0.5}>
                            <IconButton aria-label="close" color="inherit" onClick={handleClose}>
                                <CloseIcon style={{ color: darkTheme ? '#fff' : '#000' }} />
                            </IconButton>

                        </Grid>
                    </Grid>


                    <DialogContent>
                        <DialogContentText>


                            <div>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon style={{ color: darkTheme ? '#fff' : '#000' }} />}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                        style={{ backgroundColor: darkTheme ? '#333333' : '#eeeeee' }}
                                    >
                                        <Typography variant='h6' style={{ color: darkTheme ? '#fff' : '#000' }}>
                                            Core Profile
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails style={{ backgroundColor: darkTheme ? '#333333' : '#eeeeee' }}>
                                        <TableContainer component={Paper} style={{ height: "50vh", backgroundColor: darkTheme ? '#848482' : '#eeeeee' }}>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell style={{ padding: "10px", color: darkTheme ? '#fff' : '#000' }}>Key</TableCell>
                                                        <TableCell style={{ padding: "10px", color: darkTheme ? '#fff' : '#000' }}>Value</TableCell>
                                                        <TableCell style={{ padding: "10px", color: darkTheme ? '#fff' : '#000' }}>Read Only</TableCell>
                                                        <TableCell style={{ padding: "10px", color: darkTheme ? '#fff' : '#000' }}>Last Updated</TableCell>
                                                        <TableCell style={{ padding: "10px", }}>Refresh</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {rows && rows.filter(row => row.ocppKey === 'Core Profile').map((row) => (
                                                        <TableRow key={row.id}>
                                                            <TableCell style={{ padding: "10px", color: darkTheme ? '#fff' : '#000' }}>{row.key}</TableCell>
                                                            <TableCell style={{ padding: "10px", color: darkTheme ? '#fff' : '#000' }}>{row.value}</TableCell>
                                                            <TableCell style={{ padding: "10px", color: darkTheme ? '#fff' : '#000' }}>{row.readOnly.toString()}</TableCell>
                                                            <TableCell style={{ padding: "10px", color: darkTheme ? '#fff' : '#000' }}>{new Date(row.timestamp).toLocaleString()}</TableCell>

                                                            <TableCell style={{ padding: "10px", }}>
                                                                <RefreshConfiguration row={row} setRows={setRows} fetchLastUpdatedConfiguration={fetchLastUpdatedConfiguration} />
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon style={{ color: darkTheme ? '#fff' : '#000' }} />}
                                        aria-controls="panel2-content"
                                        id="panel2-header"
                                        style={{ backgroundColor: darkTheme ? '#333333' : '#eeeeee' }}
                                    >
                                        <Typography variant='h6' style={{ color: darkTheme ? '#fff' : '#000' }}>
                                            Local Authorization
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails style={{ backgroundColor: darkTheme ? '#333333' : '#eeeeee' }}>
                                        <TableContainer component={Paper} style={{ height: "50vh", backgroundColor: darkTheme ? '#848482' : '#eeeeee' }}>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell style={{ padding: "10px", color: darkTheme ? '#fff' : '#000' }}>Key</TableCell>
                                                        <TableCell style={{ padding: "10px", color: darkTheme ? '#fff' : '#000' }}>Value</TableCell>
                                                        <TableCell style={{ padding: "10px", color: darkTheme ? '#fff' : '#000' }}>Read Only</TableCell>
                                                        <TableCell style={{ padding: "10px", color: darkTheme ? '#fff' : '#000' }}>Last Updated</TableCell>

                                                        <TableCell style={{ padding: "10px", }}>Refresh</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {rows && rows.filter(row => row.ocppKey === 'Local Auth List').map((row) => (
                                                        <TableRow key={row.id}>
                                                            <TableCell style={{ padding: "10px", color: darkTheme ? '#fff' : '#000' }}>{row.key}</TableCell>
                                                            <TableCell style={{ padding: "10px", color: darkTheme ? '#fff' : '#000' }}>{row.value}</TableCell>
                                                            <TableCell style={{ padding: "10px", color: darkTheme ? '#fff' : '#000' }}>{row.readOnly.toString()}</TableCell>
                                                            <TableCell style={{ padding: "10px", color: darkTheme ? '#fff' : '#000' }}>{new Date(row.timestamp).toLocaleString()}</TableCell>

                                                            <TableCell style={{ padding: "10px",  }}>
                                                            <RefreshConfiguration row={row} setRows={setRows} fetchLastUpdatedConfiguration={fetchLastUpdatedConfiguration} />
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon style={{ color: darkTheme ? '#fff' : '#000' }} />}
                                        aria-controls="panel3-content"
                                        id="panel3-header"
                                        style={{ backgroundColor: darkTheme ? '#333333' : '#eeeeee' }}
                                    >
                                        <Typography variant='h6' style={{ color: darkTheme ? '#fff' : '#000' }}>
                                            Reservation Profile
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails style={{ backgroundColor: darkTheme ? '#333333' : '#eeeeee' }}>
                                        <TableContainer component={Paper} style={{ height: "50vh", backgroundColor: darkTheme ? '#848482' : '#eeeeee' }}>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell style={{ padding: "10px", color: darkTheme ? '#fff' : '#000' }}>Key</TableCell>
                                                        <TableCell style={{ padding: "10px", color: darkTheme ? '#fff' : '#000' }}>Value</TableCell>
                                                        <TableCell style={{ padding: "10px", color: darkTheme ? '#fff' : '#000' }}>Read Only</TableCell>
                                                        <TableCell style={{ padding: "10px", color: darkTheme ? '#fff' : '#000' }}>Last Updated</TableCell>

                                                        <TableCell style={{ padding: "10px", }}>Refresh</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {rows && rows.filter(row => row.ocppKey === 'Reservation Profile').map((row) => (
                                                        <TableRow key={row.id}>
                                                            <TableCell style={{ padding: "10px", color: darkTheme ? '#fff' : '#000' }}>{row.key}</TableCell>
                                                            <TableCell style={{ padding: "10px", color: darkTheme ? '#fff' : '#000' }}>{row.value}</TableCell>
                                                            <TableCell style={{ padding: "10px", color: darkTheme ? '#fff' : '#000' }}>{row.readOnly.toString()}</TableCell>
                                                            <TableCell style={{ padding: "10px", color: darkTheme ? '#fff' : '#000' }}>{new Date(row.timestamp).toLocaleString()}</TableCell>

                                                            <TableCell style={{ padding: "10px",  }}>
                                                            <RefreshConfiguration row={row} setRows={setRows} fetchLastUpdatedConfiguration={fetchLastUpdatedConfiguration} />
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </AccordionDetails>

                                </Accordion>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon style={{ color: darkTheme ? '#fff' : '#000' }} />}
                                        aria-controls="panel2-content"
                                        id="panel2-header"
                                        style={{ backgroundColor: darkTheme ? '#333333' : '#eeeeee' }}
                                    >
                                        <Typography variant='h6' style={{ color: darkTheme ? '#fff' : '#000' }}>
                                            SmartCharging
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails style={{ backgroundColor: darkTheme ? '#333333' : '#eeeeee' }}>
                                        <TableContainer component={Paper} style={{ height: "50vh", backgroundColor: darkTheme ? '#848482' : '#eeeeee' }}>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell style={{ padding: "10px", color: darkTheme ? '#fff' : '#000' }}>Key</TableCell>
                                                        <TableCell style={{ padding: "10px", color: darkTheme ? '#fff' : '#000' }}>Value</TableCell>
                                                        <TableCell style={{ padding: "10px", color: darkTheme ? '#fff' : '#000' }}>Read Only</TableCell>
                                                        <TableCell style={{ padding: "10px", color: darkTheme ? '#fff' : '#000' }}>Last Updated</TableCell>

                                                        <TableCell style={{ padding: "10px", }}>Refresh</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {rows && rows.filter(row => row.ocppKey === 'Smart Charging Profile').map((row) => (
                                                        <TableRow key={row.id}>
                                                            <TableCell style={{ padding: "10px", color: darkTheme ? '#fff' : '#000' }}>{row.key}</TableCell>
                                                            <TableCell style={{ padding: "10px", color: darkTheme ? '#fff' : '#000' }}>{row.value}</TableCell>
                                                            <TableCell style={{ padding: "10px", color: darkTheme ? '#fff' : '#000' }}>{row.readOnly.toString()}</TableCell>
                                                            <TableCell style={{ padding: "10px", color: darkTheme ? '#fff' : '#000' }}>{new Date(row.timestamp).toLocaleString()}</TableCell>

                                                            <TableCell style={{ padding: "10px",  }}>
                                                            <RefreshConfiguration row={row} setRows={setRows} fetchLastUpdatedConfiguration={fetchLastUpdatedConfiguration} />
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon style={{ color: darkTheme ? '#fff' : '#000' }} />}
                                        aria-controls="panel2-content"
                                        id="panel2-header"
                                        style={{ backgroundColor: darkTheme ? '#333333' : '#eeeeee' }}
                                    >

                                        <Typography variant='h6' style={{ color: darkTheme ? '#fff' : '#000' }}>
                                            Custom
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails style={{ backgroundColor: darkTheme ? '#333333' : '#eeeeee' }}>
                                        <TableContainer component={Paper} style={{ height: "50vh", backgroundColor: darkTheme ? '#848482' : '#eeeeee' }}>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell style={{ padding: "10px", color: darkTheme ? '#fff' : '#000' }}>Key</TableCell>
                                                        <TableCell style={{ padding: "10px", color: darkTheme ? '#fff' : '#000' }}>Value</TableCell>
                                                        <TableCell style={{ padding: "10px", color: darkTheme ? '#fff' : '#000' }}>Read Only</TableCell>
                                                        <TableCell style={{ padding: "10px", color: darkTheme ? '#fff' : '#000' }}>Last Updated</TableCell>

                                                        <TableCell style={{ padding: "10px", }}>Refresh</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {rows && rows.filter(row => row.ocppKey === 'Custom').map((row) => (
                                                        <TableRow key={row.id}>
                                                            <TableCell style={{ padding: "10px", color: darkTheme ? '#fff' : '#000' }}>{row.key}</TableCell>
                                                            <TableCell style={{ padding: "10px", color: darkTheme ? '#fff' : '#000' }}>{row.value}</TableCell>
                                                            <TableCell style={{ padding: "10px", color: darkTheme ? '#fff' : '#000' }}>{row.readOnly.toString()}</TableCell>
                                                            <TableCell style={{ padding: "10px", color: darkTheme ? '#fff' : '#000' }}>{new Date(row.timestamp).toLocaleString()}</TableCell>

                                                            <TableCell style={{ padding: "10px",  }}>
                                                            <RefreshConfiguration row={row} setRows={setRows} fetchLastUpdatedConfiguration={fetchLastUpdatedConfiguration} />
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        </DialogContentText>
                    </DialogContent>




                    {/* <DialogActions>
                    <CloseIcon onClick={handleClose} color="primary"/>
                    
                </DialogActions> */}
                </Paper>
            </Dialog>
        </div>
    );
}

export default ConfigDialog;
