// import {
//   Box,
//   Icon,
//   IconButton,
//   styled,
//   Table,
//   TableBody,
//   TableCell,
//   TableHead,
//   TablePagination,
//   TableRow,
// } from '@mui/material';
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import { useState, useEffect } from 'react';
// import DeleteIcon from '@mui/icons-material/Delete';
// import { pink } from '@mui/material/colors';
// import OrgApi from './OrgApi';

// const StyledTable = styled(Table)(() => ({
//   whiteSpace: 'pre',
//   overflowX: 'auto',
//   '& thead': {
//     '& tr': { '& th': { paddingLeft: 0, paddingRight: 0 } },
//   },
//   '& tbody': {
//     '& tr': { '& td': { paddingLeft: 0, textTransform: 'capitalize' } },
//   },
// }));

// const OrgListTable = () => {
//   const [page, setPage] = useState(0);
//   const [rowsPerPage, setRowsPerPage] = useState(5);
//   const [APIData, setAPIData] = useState([]);
//   const api = new OrgApi();

//   const handleChangePage = (_, newPage) => {
//     setPage(newPage);
//   };

//   const handleChangeRowsPerPage = (event) => {
//     setRowsPerPage(+event.target.value);
//     setPage(0);
//   };

//   const handleDeleteOrg = (id) => {
//     api
//       .deleteOrg(id)
//       .then((response) => {
//         toast.success('Successfully  deleted.');
//         fetchOrgList();
//       })
//       .catch((e) => {
//         // console.log('Cannot load event data', e);
//       });
//   };

//   const fetchOrgList = () => {
//     api
//       .getOrgList()
//       .then((response) => {
//         setAPIData(response.data);
//       })
//       .catch((e) => {
//         // console.log('Cannot load event data', e);
//       });
//   };

//   const openDepoPage = (id) => {
//     window.location.href = '/cmsmgmt/depomgmt/?q=' + id;
//   };

//   useEffect(() => {
//     api
//       .getOrgList()
//       .then((response) => {
//         setAPIData(response.data);
//       })
//       .catch((e) => {
//         // console.log('Cannot load event data', e);
//       });
//   }, []);

//   return (
//     <Box width="100%" overflow="auto">
//       <StyledTable otions style={{ background: 'rgb(240, 240, 240)' }}>
//         <TableHead>
//           <TableRow>
//             <TableCell style={{ background: 'rgb(210, 210, 210)' }} align="center">
//               Name
//             </TableCell>
//             <TableCell style={{ background: 'rgb(210, 210, 210)' }} align="center">
//               Country
//             </TableCell>
//             <TableCell style={{ background: 'rgb(210, 210, 210)' }} align="center">
//               Currency
//             </TableCell>
//             <TableCell style={{ background: 'rgb(210, 210, 210)' }} align="center">
//               State
//             </TableCell>
//             <TableCell style={{ background: 'rgb(210, 210, 210)' }} align="center">
//               City
//             </TableCell>
//             <TableCell style={{ background: 'rgb(210, 210, 210)' }} align="center">
//               ZipCode
//             </TableCell>
//             <TableCell style={{ background: 'rgb(210, 210, 210)' }} align="center">
//               Address
//             </TableCell>
//             <TableCell style={{ background: 'rgb(210, 210, 210)' }} align="center">
//               Action
//             </TableCell>
//           </TableRow>
//         </TableHead>
//         <TableBody>
//           {APIData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(
//             (subscriber, index) => (
//               <TableRow key={index}>
//                 <TableCell align="center" onClick={() => openDepoPage(subscriber.id)}>
//                   <a href="#" style={{ color: 'blue' }}>
//                     <u>{subscriber.name}</u>
//                   </a>
//                 </TableCell>
//                 <TableCell align="center">{subscriber.country}</TableCell>
//                 <TableCell align="center">{subscriber.currency}</TableCell>
//                 <TableCell align="center">{subscriber.orgState}</TableCell>
//                 <TableCell align="center">{subscriber.city}</TableCell>
//                 <TableCell align="center">{subscriber.pinCode}</TableCell>
//                 <TableCell align="center">{subscriber.address}</TableCell>
//                 <TableCell align="center">
//                   <IconButton>
//                     <DeleteIcon
//                       onClick={() => handleDeleteOrg(subscriber.id)}
//                       fontSize="small"
//                       sx={{ color: pink[500] }}
//                     />
//                   </IconButton>
//                 </TableCell>
//               </TableRow>
//             )
//           )}
//         </TableBody>
//       </StyledTable>
//       <ToastContainer
//         position="top-right"
//         autoClose={2000}
//         hideProgressBar={false}
//         newestOnTop={false}
//         closeOnClick
//         rtl={false}
//         pauseOnFocusLoss
//         draggable
//         pauseOnHover
//         theme="colored"
//       />
//     </Box>
//   );
// };

// export default OrgListTable;

// import IconButton from '@material-ui/core/IconButton';
// import Paper from '@material-ui/core/Paper';
// import Table from '@material-ui/core/Table';
// import TableBody from '@material-ui/core/TableBody';
// import TableCell from '@material-ui/core/TableCell';
// import TableFooter from '@material-ui/core/TableFooter';
// import TableHead from '@material-ui/core/TableHead';
// import TableRow from '@material-ui/core/TableRow';

// class OrgListTable extends React.Component {
//   constructor(props) {
//     super(props);

//     this.state = {
//       expand: false,
//     };

//     this.handleExpand = this.handleExpand.bind(this);
//   }

//   handleExpand = () => {
//     this.setState({
//       expand: !this.state.expand,
//     });
//   };

//   render() {
//     return (
//       <div className="App">
//         <Table>
//           <TableHead>
//             <TableRow>
//               <TableCell>Expand</TableCell>
//               <TableCell>Charging Profile ID</TableCell>
//               <TableCell>Stack Level</TableCell>
//               <TableCell>Description</TableCell>
//               <TableCell>Profile Purpose</TableCell>
//               <TableCell>Profile Kind</TableCell>
//               <TableCell>Recurrency Kind</TableCell>
//               <TableCell>Valid From</TableCell>
//               <TableCell>Valid To</TableCell>
//               <TableCell>Actions</TableCell>
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             <TableRow>
//               <TableCell>
//                 <IconButton onClick={this.handleExpand}>
//                   <SvgMore />
//                 </IconButton>
//               </TableCell>
//               <TableCell>1</TableCell>
//               <TableCell>2</TableCell>
//               <TableCell>3</TableCell>
//               <TableCell>3</TableCell>
//               <TableCell>3</TableCell>
//               <TableCell>3</TableCell>
//               <TableCell>3</TableCell>
//               <TableCell>3</TableCell>
//               <TableCell>3</TableCell>
//             </TableRow>

//             {this.state.expand ? (
//               <TableRow>
//                 <TableCell colSpan="3">
//                   <Table>
//                     <TableHead>
//                       <TableRow>
//                         <TableCell>One</TableCell>
//                         <TableCell>Two</TableCell>
//                         <TableCell>Three</TableCell>
//                       </TableRow>
//                     </TableHead>
//                     <TableBody>
//                       <TableRow>
//                         <TableCell>1</TableCell>
//                         <TableCell>2</TableCell>
//                         <TableCell>3</TableCell>
//                       </TableRow>
//                     </TableBody>
//                   </Table>
//                 </TableCell>
//               </TableRow>
//             ) : null}
//             <TableRow>
//               <TableCell>
//                 <IconButton onClick={this.handleExpand}>
//                   <SvgMore />
//                 </IconButton>
//               </TableCell>
//               <TableCell>1</TableCell>
//               <TableCell>2</TableCell>
//               <TableCell>3</TableCell>
//               <TableCell>3</TableCell>
//               <TableCell>3</TableCell>
//               <TableCell>3</TableCell>
//               <TableCell>3</TableCell>
//               <TableCell>3</TableCell>
//               <TableCell>3</TableCell>
//             </TableRow>

//           </TableBody>
//         </Table>
//       </div>
//     );
//   }
// }

import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import DeleteIcon from '@mui/icons-material/Delete';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { pink, blue } from '@mui/material/colors';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import OrgApi from './OrgApi';
import Paper from '@material-ui/core/Paper';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Span } from 'app/components/Typography';
import { useThemeContext } from 'app/ThemeProvider';
import TextField from '@material-ui/core/TextField';
import { StyledTable } from 'app/utils/utils';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const classes = {};

const useStyles = makeStyles({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
    textAlign:"left"
  },
});

const ExpandableTableRow = ({ children, darkTheme,  expandComponent,index, ...otherProps}) => {
  const [isExpanded, setIsExpanded] = React.useState(false);

  return (
    <>
      <TableRow 
      // style={{ backgroundColor: `${index % 2 !== 0 ? ('#eeeeee') : ('#f9f9f9')}` }}
       {...otherProps}>
        <TableCell padding="checkbox" style={{ whiteSpace: 'pre-wrap', color: darkTheme ? '#fff' : '#000', fontSize: "16px" }}>
          <IconButton style ={{ color: darkTheme ? '#fff' : '#000' }} onClick={() => setIsExpanded(!isExpanded)}>
            {isExpanded ? <KeyboardArrowUpIcon style ={{ color: darkTheme ? '#fff' : '#000' }} /> : <KeyboardArrowDownIcon style ={{ color: darkTheme ? '#fff' : '#000' }} />}
          </IconButton>
        </TableCell>
        {children}
      </TableRow>
      {isExpanded && (
        <TableRow 
        // style={{ backgroundColor: `${index % 2 !== 0 ? ('#eeeeee') : ('#f9f9f9')}` }}
        >
          <TableCell style={{ whiteSpace: 'pre-wrap', color: darkTheme ? '#fff' : '#000', fontSize: "16px" }} padding="checkbox" />
          {expandComponent}
        </TableRow>
      )}
    </>
  );
};

export default function OrgListTable() {
  const classes = useStyles();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [state, setState] = useState({});
  const [profileId, setProfileId] = useState(0);
  const [APIData, setAPIData] = useState([]);
  const api = new OrgApi();
  const { darkTheme } = useThemeContext();
  const { startPeriodInSeconds, powerLimit, numberPhases } = state;

  useEffect(() => {
    fetchProfileList();
  }, []);

  const fetchProfileList = () => {
    api
      .getChargeProfileList()
      .then((response) => {
        setAPIData(response.data);
      })
      .catch((e) => {
        // console.log('Cannot load event data', e);
      });
  };

  const handleDeleteProfile = (id) => {
    api
      .deleteChargeProfile(id)
      .then((response) => {
        toast.success('Successfully  deleted.');
        fetchProfileList();
      })
      .catch((e) => {
        // console.log('Cannot load event data', e);
      });
  };

  const handleChange = (event) => {
    event.persist();
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const handleDeleteTimeSchedule = (id) => {
   
    api
      .deleteTimePeriod(id)
      .then((response) => {
        toast.success('Successfully deleted.');
        fetchProfileList();
      })
      .catch((e) => {
        // console.log('Cannot load event data', e);
      });
  };

  const handleClickTimePeriod = (id) => {
    setIsModalVisible(true);
    setProfileId(id);
  };

  const handleHandleClose = () => {
    setIsModalVisible(false);
    // window.location.reload(false);
  };

  const handleSubmitTime = () => {
   
    state.chargingProfilePk = profileId;
    api
      .addTimeSchedule(state)
      .then((response) => {
        toast.success('Successfully  Added');
        fetchProfileList();
      })
      .catch((e) => {
        // console.log('Cannot load event data', e);
      });
  };

  return (
    <div>
      <Paper className={classes.root} style={{ backgroundColor: darkTheme ? '#333333' : '#f9f9f9' }}>
        <StyledTable className={classes.table} aria-label="simple table" >
          <TableHead >
            <TableRow>
              <TableCell style={{ backgroundColor: darkTheme ? '#333333' : '#f9f9f9', color: darkTheme ? '#F2E34C' : '#000', fontSize: "16px" }} padding="checkbox" />
              <TableCell style={{ backgroundColor: darkTheme ? '#333333' : '#f9f9f9', color: darkTheme ? '#F2E34C' : '#000', fontSize: "16px" }}>Charging Profile ID</TableCell>
              <TableCell align="center" style={{ backgroundColor: darkTheme ? '#333333' : '#f9f9f9', color: darkTheme ? '#F2E34C' : '#000', fontSize: "16px" }}>
                Stack Level
              </TableCell>
              <TableCell align="center" style={{ backgroundColor: darkTheme ? '#333333' : '#f9f9f9', color: darkTheme ? '#F2E34C' : '#000', fontSize: "16px" }}>
                Description
              </TableCell>
              <TableCell align="center" style={{ backgroundColor: darkTheme ? '#333333' : '#f9f9f9', color: darkTheme ? '#F2E34C' : '#000', fontSize: "16px" }}>
                Profile Purpose
              </TableCell>
              <TableCell align="center" style={{ backgroundColor: darkTheme ? '#333333' : '#f9f9f9', color: darkTheme ? '#F2E34C' : '#000', fontSize: "16px" }}>
                Profile Kind
              </TableCell>
              <TableCell align="center" style={{ backgroundColor: darkTheme ? '#333333' : '#f9f9f9', color: darkTheme ? '#F2E34C' : '#000', fontSize: "16px" }}>
                Recurrency Kind
              </TableCell>
              <TableCell align="center" style={{ backgroundColor: darkTheme ? '#333333' : '#f9f9f9', color: darkTheme ? '#F2E34C' : '#000', fontSize: "16px" }}>
                Valid From
              </TableCell>
              <TableCell align="center" style={{ backgroundColor: darkTheme ? '#333333' : '#f9f9f9', color: darkTheme ? '#F2E34C' : '#000', fontSize: "16px" }}>
                Valid To
              </TableCell>
              <TableCell align="center" style={{ backgroundColor: darkTheme ? '#333333' : '#f9f9f9', color: darkTheme ? '#F2E34C' : '#000', fontSize: "16px" }}>
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {APIData.map((row,index) => (
              <ExpandableTableRow
                key={row.name}
                index={index}
                expandComponent={
                  <TableCell colSpan="9" style={{color: darkTheme ? '#F2E34C' : '#000' }}>
                    <Table style={{background:"white"}}>
                      <TableHead style={{background:"white"}}>
                        <TableRow style={{background:"white"}}
                        >
                          <TableCell style={{paddingLeft:"10px", backgroundColor: darkTheme ? '#333333' : '#f9f9f9', color: darkTheme ? '#F2E34C' : '#000', fontSize: "16px"}}>Start Period (sec)</TableCell>
                          <TableCell style={{backgroundColor: darkTheme ? '#333333' : '#f9f9f9', color: darkTheme ? '#F2E34C' : '#000', fontSize: "16px"}}>Power Limit (W/A)</TableCell>
                          <TableCell style={{backgroundColor: darkTheme ? '#333333' : '#f9f9f9', color: darkTheme ? '#F2E34C' : '#000', fontSize: "16px"}}>Number Phases</TableCell>
                          <TableCell style={{backgroundColor: darkTheme ? '#333333' : '#f9f9f9', color: darkTheme ? '#F2E34C' : '#000', fontSize: "16px"}}>Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {row.chargingSchedulePeriods.map((srow,index) => (
                          <TableRow 
                          >
                            <TableCell style={{paddingLeft:"10px", backgroundColor: darkTheme ? '#333333' : '#f9f9f9', color: darkTheme ? '#fff' : '#000', fontSize: "16px"}}>{srow.startPeriodInSeconds}</TableCell>
                            <TableCell style={{backgroundColor: darkTheme ? '#333333' : '#f9f9f9', color: darkTheme ? '#fff' : '#000', fontSize: "16px"}}>{srow.powerLimit}</TableCell>
                            <TableCell style={{backgroundColor: darkTheme ? '#333333' : '#f9f9f9', color: darkTheme ? '#fff' : '#000', fontSize: "16px"}}>{srow.numberPhases}</TableCell>
                            <TableCell style={{backgroundColor: darkTheme ? '#333333' : '#f9f9f9'}}>
                              <IconButton>
                                <DeleteIcon
                                  onClick={() => handleDeleteTimeSchedule(srow.id)}
                                  fontSize="small"
                                  sx={{ color: pink[500] }}
                                />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableCell>
                }
              >
                <TableCell style={{ whiteSpace: 'pre-wrap',overflowWrap: 'break-word', backgroundColor: darkTheme ? '#333333' : '#f9f9f9', color: darkTheme ? '#fff' : '#000', fontSize: "16px" }} component="th" scope="row">
                  {row.chargingProfilePk}
                </TableCell>
                <TableCell  style={{ whiteSpace: 'pre-wrap',overflowWrap: 'break-word', backgroundColor: darkTheme ? '#333333' : '#f9f9f9', color: darkTheme ? '#fff' : '#000', fontSize: "16px" }} align="center">{row.stackLevel}</TableCell>
                <TableCell style={{ whiteSpace: 'pre-wrap',overflowWrap: 'break-word', backgroundColor: darkTheme ? '#333333' : '#f9f9f9', color: darkTheme ? '#fff' : '#000', fontSize: "16px" }} align="center">{row.description}</TableCell>
                <TableCell style={{ whiteSpace: 'pre-wrap',overflowWrap: 'break-word', backgroundColor: darkTheme ? '#333333' : '#f9f9f9', color: darkTheme ? '#fff' : '#000', fontSize: "16px" }} align="center">{row.chargingProfilePurpose}</TableCell>
                <TableCell style={{ whiteSpace: 'pre-wrap',overflowWrap: 'break-word', backgroundColor: darkTheme ? '#333333' : '#f9f9f9', color: darkTheme ? '#fff' : '#000', fontSize: "16px" }} align="center">{row.chargingProfileKind}</TableCell>
                <TableCell style={{ whiteSpace: 'pre-wrap',overflowWrap: 'break-word', backgroundColor: darkTheme ? '#333333' : '#f9f9f9', color: darkTheme ? '#fff' : '#000', fontSize: "16px" }} align="center">{row.recurrencyKind}</TableCell>
                <TableCell style={{ whiteSpace: 'pre-wrap',overflowWrap: 'break-word', backgroundColor: darkTheme ? '#333333' : '#f9f9f9', color: darkTheme ? '#fff' : '#000', fontSize: "16px" }} align="center">{row.validFromStr}</TableCell>
                <TableCell style={{ whiteSpace: 'pre-wrap',overflowWrap: 'break-word', backgroundColor: darkTheme ? '#333333' : '#f9f9f9', color: darkTheme ? '#fff' : '#000', fontSize: "16px" }} align="center">{row.validToStr}</TableCell>
                <TableCell style={{ whiteSpace: 'pre-wrap',overflowWrap: 'break-word', backgroundColor: darkTheme ? '#333333' : '#f9f9f9', color: darkTheme ? '#fff' : '#000', fontSize: "16px" }} align="center">
                  <IconButton>
                    <AccessTimeIcon
                      onClick={() => handleClickTimePeriod(row.chargingProfilePk)}
                      fontSize="small"
                      sx={{ color: blue[500] }}
                    />
                  </IconButton>
                  <IconButton>
                    <DeleteIcon
                      onClick={() => handleDeleteProfile(row.chargingProfilePk)}
                      fontSize="small"
                      sx={{ color: pink[500] }}
                    />
                  </IconButton>
                </TableCell>
              </ExpandableTableRow>
            ))}
          </TableBody>
        </StyledTable>
      </Paper>
      <Dialog
        open={isModalVisible}
        onClose={handleHandleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            backgroundColor: darkTheme ? '#333333' : '#fff', 
          },
        }} 
      >
        <DialogTitle id="alert-dialog-title" className={classes.title} style={{ color:darkTheme ? '#F2E34C' : 'black' }}>
          Schedule Periods
        </DialogTitle>
        <DialogContent className={classes.content}>
          <div className={classes.text}>
            <TextField
              type="number"
              name="startPeriodInSeconds"
              value={startPeriodInSeconds || ''}
              onChange={handleChange}
              id="standard-basic"
              label="Start Period (in sec)"
              fullWidth
              style={{ borderColor : darkTheme ? '#fff' : '#000' }}
              InputProps={{
                style: { color: darkTheme ? '#fff' : '#000' }, 
              }}
              InputLabelProps={{
                style: { color: darkTheme ? '#fff' : '#000' }, 
              }}
              sx={{
                "& .MuiInput-underline:before": {
                  borderBottomColor: darkTheme ? '#fff' : '#000',
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: darkTheme ? '#fff' : '#000',
                },
                "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                  borderBottomColor: darkTheme ? '#fff' : '#000',
                },
                "& .MuiInputBase-input": {
                  color: darkTheme ? '#fff' : '#000',
                },
              }}
            />

            <TextField
              type="number"
              name="powerLimit"
              value={powerLimit || ''}
              onChange={handleChange}
              id="standard-basic"
              label="Power Limit (in Charging Rate Unit selected above)ail"
              fullWidth
              style={{ borderColor : darkTheme ? '#fff' : '#000' }}
              InputProps={{
                style: { color: darkTheme ? '#fff' : '#000' }, 
              }}
              InputLabelProps={{
                style: { color: darkTheme ? '#fff' : '#000' }, 
              }}
              sx={{
                "& .MuiInput-underline:before": {
                  borderBottomColor: darkTheme ? '#fff' : '#000',
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: darkTheme ? '#fff' : '#000',
                },
                "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                  borderBottomColor: darkTheme ? '#fff' : '#000',
                },
                "& .MuiInputBase-input": {
                  color: darkTheme ? '#fff' : '#000',
                },
              }}
            />

            <TextField
              type="number"
              name="numberPhases"
              value={numberPhases || ''}
              onChange={handleChange}
              id="standard-basic"
              label="Number Phases"
              fullWidth
              style={{ borderColor : darkTheme ? '#fff' : '#000' }}
              InputProps={{
                style: { color: darkTheme ? '#fff' : '#000' }, 
              }}
              InputLabelProps={{
                style: { color: darkTheme ? '#fff' : '#000' }, 
              }}
              sx={{
                "& .MuiInput-underline:before": {
                  borderBottomColor: darkTheme ? '#fff' : '#000',
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: darkTheme ? '#fff' : '#000',
                },
                "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                  borderBottomColor: darkTheme ? '#fff' : '#000',
                },
                "& .MuiInputBase-input": {
                  color: darkTheme ? '#fff' : '#000',
                },
              }}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            style={{
              background: '#2e7d32',
              color: 'white',
              borderRadius: '3px',
              padding: '5px 7px',
            }}
            alignItems="right"
            className={classes.signUpButton}
            onClick={handleSubmitTime}
            variant="contained"
            type="submit"
          >
            <Span sx={{ textTransform: 'capitalize' }}>Submit</Span>
          </Button>

          <Button
            onClick={handleHandleClose}
            style={{
              background: '#e0e0e0',
              color: 'black',
              borderRadius: '3px',
              padding: '5px 7px',
              textTransform: 'initial',
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </div>
  );
}
