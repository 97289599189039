import React, { useState, useEffect } from 'react';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Paper, Slide, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';

import FormControl from '@mui/material/FormControl';
import ChargerApi from '../../ChargerApi';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import moment from 'moment';
import { makeStyles } from '@material-ui/core';
import { useThemeContext } from 'app/ThemeProvider';
import Loading from 'app/components/MatxLoading';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles({
  root: {
    padding: '0',
  },
    subtitleRight: {
        margin: '20px 0 10px 0',
        backgroundColor: 'rgb(7, 128, 235)',
        padding: '10px 10px 10px 15px',
    borderRadius: '0 30px 30px 0',
    color: 'white',
    position: 'relative',
    boxShadow:
      '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
    marginLeft: 'inherit',
    width: 'fit-content',
  },
  field: {
    // height: '50vh',
    width: 'fit-content',
    // borderRadius: ({isMobile}) => isMobile ? "inherit" : '20px',
    margin: '10px 20px',
    '& .MuiOutlinedInput-input': {
      padding: '18.5px 27px',
    },
  },
  fieldDate: {
    // height: '50vh',
    width: 'fit-content',
    // borderRadius: ({isMobile}) => isMobile ? "inherit" : '20px',
    margin: '10px 20px',
    '& .MuiOutlinedInput-input': {
      padding: '18.5px 5px',
    },
  },
});

function OccpUpdateFirmware({
  open,
  handleOpenDialog,
  handleCloseDialog,
  row,
  occpCommnad,
  chargeBoxId,
}) {
  const classes = useStyles();
  const api = new ChargerApi();
  const [values, setValues] = useState([]);
  const [state, setState] = useState({});
  const [disabled, setDisabled] = useState(false);
  const [selectedStartDate, handleStartDateChange] = useState(new Date());
  const { darkTheme } = useThemeContext();

  const handleClickOpen = () => {
    handleOpenDialog();
  };

  const handleClose = () => {
    setValues([]);
    handleCloseDialog();
  };

  const handleChanage = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const saveUpdatedDetails = () => {
    setDisabled(true);
    
    state.chargeBoxId = Number(row);
    let date = new Date(selectedStartDate);
    state.dateTime = moment(date).format('DD/MM/YYYY HH:mm:ss');

    // console.log(state);
    api
      .updateFirmware(state)
      .then(({ data }) => {
        return toast.success('Request ' + data);
      })
      .catch((e) => {
        // console.log('Cannot load event data', e);
        return toast.error('Request failed');

      }).finally(()=>setDisabled(false));
  };

  const { locUri, retries, retriesInvt } = state;

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      //keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      fullWidth
    >
      <DialogTitle id="alert-dialog-slide-title" style={{ padding: '0', backgroundColor: darkTheme ? '#333333' : '#f9f9f9' }}>
      

        <Grid container >
          <Grid container className={classes.subtitleRight} item xs={11}>
            <Grid item xs={6}>
              <Typography variant={'h5'} >
              Update Firmware
              </Typography>
            </Grid>

            <Grid item xs={6} style={{ fontSize: '16px', paddingTop: '3px' }} >

              Charger ID:{chargeBoxId}

            </Grid>

          </Grid>

          <Grid item container xs={0.5}>
            <IconButton aria-label="close" color="inherit" onClick={handleClose}>
              <CloseIcon style={{ color: darkTheme ? '#fff' : '#000' }} />
            </IconButton>
          </Grid>
        </Grid>
        
      </DialogTitle>

      <DialogContent dividers style={{ backgroundColor: darkTheme ? '#333333' : '#f9f9f9', color: darkTheme ? '#fff' : '#000' }}>
        <ValidatorForm onError={() => null}>
          {' '}
          <Grid container justify={'left'} alignItems={'left'} direction={'column'}>
            <FormControl fullWidth style={{ marginBottom: '20px' }}>
              <TextField
                type="text"
                variant="standard"
                name="locUri"
                label="Location (directory URI):"
                onChange={handleChanage}
                value={locUri || ''}
                InputLabelProps={{
                  style: { color: darkTheme ? '#fff' : '#000' },
                }}
                sx={{
                  "& .MuiInput-underline:before": {
                    borderBottomColor: darkTheme ? '#fff' : '#000',
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: darkTheme ? '#fff' : '#000',
                  },
                  "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                    borderBottomColor: darkTheme ? '#fff' : '#000',
                  },
                  "& .MuiInputBase-input": {
                    color: darkTheme ? '#fff' : '#000',
                  },
                }}
              />
            </FormControl>
            <FormControl fullWidth style={{ marginBottom: '20px' }}>
              <TextField
                type="text"
                variant="standard"
                name="retries"
                label="Retries (integer):"
                onChange={handleChanage}
                value={retries || ''}
                InputLabelProps={{
                  style: { color: darkTheme ? '#fff' : '#000' },
                }}
                sx={{
                  "& .MuiInput-underline:before": {
                    borderBottomColor: darkTheme ? '#fff' : '#000',
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: darkTheme ? '#fff' : '#000',
                  },
                  "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                    borderBottomColor: darkTheme ? '#fff' : '#000',
                  },
                  "& .MuiInputBase-input": {
                    color: darkTheme ? '#fff' : '#000',
                  },
                }}
              />
            </FormControl>

            <FormControl fullWidth style={{ marginBottom: '20px' }}>
              <TextField
                type="text"
                variant="standard"
                name="retriesInvt"
                label="Retry Interval (integer):"
                onChange={handleChanage}
                value={retriesInvt || ''}
                InputLabelProps={{
                  style: { color: darkTheme ? '#fff' : '#000' },
                }}
                sx={{
                  "& .MuiInput-underline:before": {
                    borderBottomColor: darkTheme ? '#fff' : '#000',
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: darkTheme ? '#fff' : '#000',
                  },
                  "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                    borderBottomColor: darkTheme ? '#fff' : '#000',
                  },
                  "& .MuiInputBase-input": {
                    color: darkTheme ? '#fff' : '#000',
                  },
                }}
              />
            </FormControl>

            <FormControl fullWidth style={{ marginTop: '20px' }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MobileDateTimePicker
                  label={<Typography style={{ color: darkTheme ? '#fff' : '#000' }}>Retrieve Date/Time:</Typography>}
                //  value={selectedStartDate}
                  onChange={(selectedStartDate) => {
                    handleStartDateChange(selectedStartDate);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>
        </ValidatorForm>
      </DialogContent>
      <DialogActions style={{ backgroundColor: darkTheme ? '#333333' : '#f9f9f9' }}>
      <Button
          type={'submit'}
          size={'large'}
          variant={'contained'}
          style={{ backgroundColor: disabled ? 'white' : 'green', color: 'white' }}
          onClick={saveUpdatedDetails}
          disabled={disabled}
        >
          {disabled ? <Loading /> : "Submit"}
        </Button>

        <Button
          type={'submit'}
          size={'large'}
          variant={'contained'}
          // color="secondry"
          onClick={handleClose}
          style={{ backgroundColor: '#334155', color: 'white' }}
        >
          Close
        </Button>
      </DialogActions>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </Dialog>
    // </div>
  );
}

export default OccpUpdateFirmware;
