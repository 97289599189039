import React, { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import Autocomplete from '@mui/material/Autocomplete';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle'
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Switch from '@mui/material/Switch';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import UserApi from '../UserApi';
import DepoApi from '../../depomgmt/DepoApi';
import { ToastContainer, toast } from 'react-toastify';
import DeleteIcon from '@mui/icons-material/Delete';
import UpdateIcon from '@mui/icons-material/Update';
import { useThemeContext } from 'app/ThemeProvider';

import { CircularProgress, FormControlLabel, FormGroup, Grid, TablePagination, Tooltip } from '@mui/material';
import Loading from 'app/components/MatxLoading';

export const Permissions = ({ open, setOpen, depot, user, allDepot = [] }) => {




    const api = new UserApi();

    const [permissionList, setPermissionList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const { darkTheme } = useThemeContext();

    const [selectedUserDepotPermissionList, setSelectedUserDepotPermissionList] = useState([]);

    const handlePermission = (e, row, index) => {

        debugger;

        if (e.target.checked) {

            row.userId = user.id;
            row.userDepotPermissionId = depot.id;
            row.depotId = depot.depotId;
            let assignedPermissionList = [row];

            let arr = []

            if (selectedUserDepotPermissionList.length > 0) {
                arr = selectedUserDepotPermissionList
                    .map(userDepotPermission => ({
                        userId: user.id,
                        userDepotPermissionId: userDepotPermission.id,
                        depotId: userDepotPermission.depotId,
                        permissionId: row.permissionId
                    }))
            }


            // addAssignedPermission(row, index);
            addAllAssignedPermission([...assignedPermissionList, ...arr])
        }
        else {
            deleteAllAssignedPermission({
                permissionId: row.permissionId
                , userDepotPermissionIdList: [row.userDepotPermissionId
                    , ...selectedUserDepotPermissionList.map(userDepotPermission => userDepotPermission.id)]
            });
        }


    };

    const deleteAssignedPermission = (id) => {

        api
            .deleteAssignedPermission({ id: id })
            .then((response) => {
                toast.success('Permission Revoked Successfully');
            })
            .catch((e) => {
                toast.error('Something Went Wrong')
                // console.log('Cannot load event data', e);
            }).finally(() => fetchAssignedPermission());
    }

    const deleteAllAssignedPermission = (params) => {

        api
            .deleteAllAssignedPermission(params)
            .then((response) => {
                toast.success('Permission Revoked Successfully');
            })
            .catch((e) => {
                toast.error('Something Went Wrong')
                // console.log('Cannot load event data', e);
            }).finally(() => fetchAssignedPermission());
    }



    const addAssignedPermission = (data, index) => {

        data.isDefault = false;
        api
            .addAssignedPermission(data)
            .then((response) => {
                toast.success('Permission Granted Successfully')


            })
            .catch((e) => {
                toast.error('Something Went Wrong')
                // console.log('Cannot load event data', e);
            }).finally(() => fetchAssignedPermission());

    }

    const addAllAssignedPermission = (dataList) => {

        dataList.map(obj => obj.isDefault = false);
        api
            .addAllAssignedPermission(dataList)
            .then((response) => {
                toast.success('Permission Granted Successfully')


            })
            .catch((e) => {
                toast.error('Something Went Wrong')
                // console.log('Cannot load event data', e);
            }).finally(() => fetchAssignedPermission());

    }



    useEffect(() => {
        if (open)
            fetchAssignedPermission();

    }, [open]);

    const fetchAssignedPermission = () => {

        setLoading(true)
        api
            .getAllAssignedPermission({ id: depot.id })
            .then((response) => {
                console.log("t", response)
                setPermissionList([]);
                setTimeout(() => setPermissionList(response.data), 50);



                setLoading(false)

                // setIsDisabled(false);

            })
            .catch((e) => {
                toast.error('Unable to load permissions')

            });
    }

    const handleClose = () => {
        setOpen(false);
        setPermissionList([])
    };


    const handleDepotMultiSelect = (depotList = []) => {

        // console.log(checked, index, depot)

        if (depotList.includes("All"))
            setSelectedUserDepotPermissionList(allDepot.filter(d => d.id !== null && d.id !== depot.id));
        else
            setSelectedUserDepotPermissionList(depotList);

        // else {
        //     selectedUserDepotPermissionList.splice(index, 1);
        //     setSelectedUserDepotPermissionList([...selectedUserDepotPermissionList]);
        // }



    }


    console.log("dpot", selectedUserDepotPermissionList);

    return (
        <div style={{ width: '100%' }}>

            <Dialog
                open={open}
                fullWidth
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"

                maxWidth="xl"  // Options: 'xs', 'sm', 'md', 'lg', 'xl', or false

                PaperProps={{
                    style: {
                        backgroundColor: darkTheme ? '#333333' : '#fff',
                    },
                }}
            >
                <DialogTitle id="alert-dialog-title" style={{ color: darkTheme ? '#F2E34C' : '#000' }}>

                    <Grid container>
                        <Grid item md={12}>
                            Permissions
                        </Grid>



                        <Grid item md={12} paddingBottom='2px'>


                            <Autocomplete
                                multiple
                                options={allDepot.filter(d => d.id !== null && d.id !== depot.id).length > 0
                                    ? ["All", ...allDepot.filter(d => d.id !== null && d.id !== depot.id)] : []}
                                getOptionLabel={(option) => option.depotName || option}
                                filterSelectedOptions
                                value={selectedUserDepotPermissionList}
                                disableCloseOnSelect
                                onChange={(event, value) => handleDepotMultiSelect(value)}
                                renderInput={(params) => (
                                    <TextField {...params}
                                        variant="standard"
                                        label="Select Depots"
                                        placeholder="Update Include Other Depots " />
                                )}
                            />


                        </Grid>

                    </Grid>

                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">



                        {
                            loading ? <Grid
                                style={{ minHeight: "80vh", display: "flex", alignItems: "center", justifyContent: "center" }}

                            >
                                <Loading
                                />
                            </Grid> :

                                <TableContainer style={{ minHeight: "100vh" }} >



                                    <Table stickyHeader >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ backgroundColor: darkTheme ? '#333333' : '#f9f9f9', color: darkTheme ? '#F2E34C' : '#000', fontSize: "16px", paddingLeft: '5px', width: '250px' }}>Permission</TableCell>
                                                <TableCell style={{ backgroundColor: darkTheme ? '#333333' : '#f9f9f9', color: darkTheme ? '#F2E34C' : '#000', fontSize: "16px", width: '250px' }}>Permission Type</TableCell>
                                                <TableCell style={{ backgroundColor: darkTheme ? '#333333' : '#f9f9f9', color: darkTheme ? '#F2E34C' : '#000', fontSize: "16px" }}>Category</TableCell>

                                                {/* <TableCell>Read/Write</TableCell> */}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>

                                            {permissionList.map((row, index) => (
                                                <TableRow key={row.id}>
                                                    <TableCell>

                                                        <FormControlLabel
                                                            control={<Switch style={{ color: darkTheme ? '#fff' : '#000' }}
                                                                disabled={isDisabled}
                                                                checked={!(row.id === null)} onChange={(e) => handlePermission(e, row, index)} />}
                                                            label={<span style={{ color: darkTheme ? '#fff' : '#000' }}>{row.name}</span>}
                                                            style={{ margin: 0, padding: 0 }}
                                                            labelPlacement="end"
                                                        />

                                                    </TableCell>
                                                    <TableCell style={{ color: darkTheme ? '#fff' : '#000' }} >{row.type}</TableCell>
                                                    <TableCell style={{ color: darkTheme ? '#fff' : '#000' }} >
                                                        {row.isDefault === null ? "" : (
                                                            row.isDefault ? "Department" : "Custom"

                                                        )}
                                                    </TableCell>

                                                    {/* <TableCell>
                                                <FormControlLabel
                                                    control={<Switch checked={row.write} onChange={handleReadWritePermission(row,index)} />}
                                                    label="Toggle Switch"
                                                    disabled
                                                    style={{margin:0,padding:0}}
                                                    labelPlacement="end"
                                                />
                                            </TableCell> */}
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                        }


                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    {/* <Button onClick={handleClose}>Disagree</Button> */}
                    <Button onClick={handleClose} autoFocus
                        style={{ background: '#e0e0e0', color: 'black' }}

                        variant='contained'>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}