import axios from 'axios.js';
export default class DepartmentApi {
  constructor() {
    this.api_token = null;
    this.client = null;
    this.user = JSON.parse(localStorage.getItem('user'));
  }

  init = () => {
    this.client = axios.create({
      timeout: 120000,
    });

    return this.client;
  };

  add = (data) => {
    data.username = data.email;
    return this.init().post('/api/department/', data);
  };

  delete = (params) => {
    return this.init().delete('/api/department/', { params:params });
  };

  update = (data) => {
    return this.init().put('/api/department/', data);
  };
  

  getByOrg = (params) => {
    return this.init().get('/api/department/', { params:params });
  };

  getAllDepartmentPermission=(params)=>{
    return this.init().get('/api/department-permission/', { params:params });
  }

  addDepartmentPermission=(data)=>{
    return this.init().post('/api/department-permission/', data);
  }

  
  deleteDepartmentPermission=(params)=>{
    return this.init().delete('/api/department-permission/', {params:params});
  }

}
