import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import TextField from '@mui/material/TextField';
import ChargerApi from '../../ChargerApi';
import { ToastContainer, toast } from 'react-toastify';
import Select from '@material-ui/core/Select';
import MenuItem from "@material-ui/core/MenuItem";
import 'react-toastify/dist/ReactToastify.css';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";


const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles({
    root: {
        padding: '0',
    },
    subtitleRight: {
        margin: '20px 0 10px 0',
        backgroundColor: 'rgb(7, 128, 235)',
        padding: '10px 30px 10px 40px',
        borderRadius: '0 30px 30px 0',
        color: 'white',
        position: 'relative',
        boxShadow:
            '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
        marginLeft: 'inherit',
        width: 'fit-content',
    },
    field: {
        // height: '50vh',
        width: 'fit-content',
        // borderRadius: ({isMobile}) => isMobile ? "inherit" : '20px',
        margin: '10px 20px',
        '& .MuiOutlinedInput-input': {
            padding: '18.5px 27px',
        },
    },
    fieldDate: {
        // height: '50vh',
        width: 'fit-content',
        // borderRadius: ({isMobile}) => isMobile ? "inherit" : '20px',
        margin: '10px 20px',
        '& .MuiOutlinedInput-input': {
            padding: '18.5px 5px',
        },
    },
});

function OccpGetConfigurationDialog({
    open,
    handleOpenDialog,
    handleCloseDialog,
    row,
    occpCommnad,
    chargeBoxId,
}) {
    const classes = useStyles();
    const api = new ChargerApi();
    const [values, setValues] = useState([]);
    const [tags, setTags] = useState([]);
    const [state, setState] = useState({});
    const [disabled, setDisabled] = useState(false);
    const [selected, setSelected] = useState([]);
    const [selectedDel, setSelectedDel] = useState([]);
    const [isChecked, setIsChecked] = useState(false);

    const handleChangeCheckbox = (event) => {
        setIsChecked(event.target.checked);
    };


    const isAllSelected =
        tags.length > 0 && selected.length === tags.length;


   const isAllSelectedDel =
        tags.length > 0 && selectedDel.length === tags.length;  

    const handleChangeSelectAdd = (event) => {
        const value = event.target.value;
        if (value[value.length - 1] === "all") {
            setSelected(selected.length === tags.length ? [] : tags);
            return;
        }
        setSelected(value);
    };


    const handleChangeSelectDelete = (event) => {
        const value = event.target.value;
        if (value[value.length - 1] === "all") {
            setSelectedDel(selectedDel.length === tags.length ? [] : tags);
            return;
        }
        setSelectedDel(value);
    };


    useEffect(() => {

        let isCanceled = false;
        // api
        //     .getChargerConnectorList(null, row)
        //     .then(({ data }) => {
        //         if (!isCanceled) {
        //             setValues(data);
        //         }
        //     })
        //     .catch((e) => {
        //         // console.log('Cannot load event data', e);
        //     });

        showVehicleTgs();

        return () => {
            isCanceled = true;
        };
    }, [row]);

    const showVehicleTgs = () => {
        let isCanceled = false;
        debugger;
        api
            .getVehicleTags(null)
            .then(({ data }) => {
                if (!isCanceled) {
                    const idTags = data.map(entry => entry.idTag);
                    setTags(idTags);
                    
                }
            })
            .catch((e) => {
                // console.log('Cannot load event data', e);
            });
    };

    const handleClickOpen = () => {
        handleOpenDialog();
    };

    const handleClose = () => {
        setValues([]);
        handleCloseDialog();
    };

    const handleUpdateDetails = (name) => (event) => {
        // console.log(event.target.value);
        // console.log(event.target.name);
        setValues({ ...values, [name]: event.target.value });
    };

    const handleChanage = (event) => {
        setState({ ...state, [event.target.name]: event.target.value });
    };



    const saveUpdatedDetails = () => {
        debugger;
        state.chargeBoxId=row;
        state.addList=selected.join(', ');
        state.deleteList=selectedDel.join(', ');
        state.isEmpty=isChecked
        
        setDisabled(true);
        if (occpCommnad === 16) {
            // console.log(state);
            api
                .sendLocalList(row)
                .then(({ data }) => {
                    handleClose()
                    return toast.success('Request ' + data);
                })
                .catch((e) => {
                   
                    return toast.error('Request Failed');
                }).finally(() => setDisabled(false));
        }
       
    };

    const handleChange = (event) => {
        const {
            target: { value }
        } = event;
        setSelected(
            typeof value === "string" ? value.split(",") : value
        );
    };

    const { connectorId, availableType, hashstring, listversion } = state;

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            // //keepMounted
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            fullWidth
        >
            <DialogTitle id="alert-dialog-slide-title" style={{ padding: '0' }}>
                <Grid container >
                    <Grid item xs={10}>
                        <div className={classes.subtitleRight}>
                            <Typography variant={'h5'} align={'left'}>
                                Set Local List
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item container justify={'center'} alignItems={'center'} direction={'column'} xs={2}>
                        <IconButton aria-label="close" color="inherit" onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>

            <DialogContent dividers>
                {/* maybe to do smart form that detect what he get from the props (column & row)  */}
                <form noValidate autoComplete="off">

                    <Grid container alignItems={'left'} direction={'column'}>
                        <FormControl fullWidth>
                            {/* <InputLabel variant="standard" htmlFor="uncontrolled-native">
              Hash (String)
              </InputLabel> */}
                            <FormControl fullWidth>
                                <TextField
                                    type="text"
                                    variant="standard"
                                    name="hashstring"
                                    label="Hash (String)"
                                    onChange={handleChanage}
                                    value={hashstring || ''}
                                />
                            </FormControl>
                        </FormControl>
                    </Grid>
                    <Grid container alignItems={'left'} direction={'column'}>
                        <FormControl fullWidth>
                            {/* <InputLabel variant="standard" htmlFor="uncontrolled-native">
              List Version (integer):
              </InputLabel> */}
                            <TextField
                                type="text"
                                variant="standard"
                                label=" List Version (integer):"
                                name="listversion"
                                // label="List Version (integer):"
                                onChange={handleChanage}
                                value={listversion || ''}
                            />
                        </FormControl>
                    </Grid>


                    <Grid container alignItems={'left'} direction={'column'}>
                        <FormControl fullWidth>
                            <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                Update Type:
                            </InputLabel>
                            <NativeSelect
                                onChange={handleChanage}
                                inputProps={{
                                    name: 'updateType',
                                    id: 'uncontrolled-native',
                                }}
                            >
                                <option value={0}></option>
                                <option value='DIFFERENTIAL'>DIFFERENTIAL</option>
                                <option value='FULL'>FULL</option>
                            </NativeSelect>
                        </FormControl>
                    </Grid>
                    <br />
                    <Grid container alignItems={'left'} direction={'column'}>
                        <FormControl fullWidth>
                            <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                Add/Update List:
                            </InputLabel>

                            <Select
                                labelId="mutiple-select-label"
                                multiple
                                autoWidth
                                value={selected}
                                onChange={handleChangeSelectAdd}
                                renderValue={(selected) => selected.join(", ")}

                            >
                                <MenuItem
                                    value="all"
                                    classes={{
                                        root: isAllSelected ? classes.selectedAll : ""
                                    }}
                                >
                                    <ListItemIcon>
                                        <Checkbox
                                            classes={{ indeterminate: classes.indeterminateColor }}
                                            checked={isAllSelected}
                                            indeterminate={
                                                selected.length > 0 && selected.length < tags.length
                                            }
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        classes={{ primary: classes.selectAllText }}
                                        primary="Select All"
                                    />
                                </MenuItem>
                                {tags.map((s) => (
                                    <MenuItem key={s} value={s}>
                                        <ListItemIcon>
                                            <Checkbox checked={selected.indexOf(s) > -1} />
                                        </ListItemIcon>
                                        <ListItemText primary={s} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <br />
                    <Grid container alignItems={'left'} direction={'column'}>
                        <FormControl fullWidth>
                            <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                Delete List:
                            </InputLabel>

                            <Select
                                labelId="mutiple-select-label"
                                multiple
                                autoWidth
                                value={selectedDel}
                                onChange={handleChangeSelectDelete}
                                renderValue={(selectedDel) => selectedDel.join(", ")}

                            >
                                <MenuItem
                                    value="all"
                                    classes={{
                                        root: isAllSelectedDel ? classes.selectedAll : ""
                                    }}
                                >
                                    <ListItemIcon>
                                        <Checkbox
                                            classes={{ indeterminate: classes.indeterminateColor }}
                                            checked={isAllSelectedDel}
                                            indeterminate={
                                                selectedDel.length > 0 && selectedDel.length < tags.length
                                            }
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        classes={{ primary: classes.selectAllText }}
                                        primary="Select All"
                                    />
                                </MenuItem>
                                {tags.map((s) => (
                                    <MenuItem key={s} value={s}>
                                        <ListItemIcon>
                                            <Checkbox checked={selectedDel.indexOf(s) > -1} />
                                        </ListItemIcon>
                                        <ListItemText primary={s} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <br />
                    <Grid container alignItems={'left'} direction={'column'}>
                        <FormControl fullWidth>
                            <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                Send empty list?:
                            </InputLabel>
                            <Checkbox
                    checked={isChecked}
                    onChange={handleChangeCheckbox}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                />
                        </FormControl>
                    </Grid>

                </form>
            </DialogContent>
            <DialogActions>
                <Button
                    type={'submit'}
                    size={'large'}
                    variant={'contained'}
                    style={{ backgroundColor: 'green', color: 'white' }}
                    onClick={saveUpdatedDetails}
                    disabled={disabled}
                >
                    Submit
                </Button>

                <Button
                    type={'submit'}
                    size={'large'}
                    variant={'contained'}
                    color="secondry"
                    onClick={handleClose}
                >
                    Close
                </Button>
            </DialogActions>
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </Dialog>
        // </div>
    );
}

export default OccpGetConfigurationDialog;
