import UserApi from './UserApi';
import { Button, CircularProgress, FormControlLabel, FormGroup, Grid, Switch, styled } from '@mui/material';
import { Span } from 'app/components/Typography';
import { useEffect, useState, useRef} from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { ToastContainer, toast } from 'react-toastify';
import { makeStyles } from '@material-ui/core/styles';
import 'react-toastify/dist/ReactToastify.css';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import DepartmentApi from '../department/DepartmentApi';
import { useThemeContext } from 'app/ThemeProvider';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { FrontendUrl } from 'utils/constants';
import ReCAPTCHA from "react-google-recaptcha";
import { siteKey } from "app/utils/constant";

const TextField = styled(TextValidator)(() => ({
  width: '100%',
  marginBottom: '16px',
}));

const style = makeStyles({
  titleItemRight: {
    float: 'right',
    position: 'relative',
    transform: 'translateY(-50%)',
    marginTop: 10,
  },
});

const SimpleForm = ({ setRefresh, setOpen }) => {

  const [state, setState] = useState({ orgId: localStorage.getItem('orgId') });
  const [lodaing,setLoading]=useState(false)
  const api = new UserApi();
  const user = JSON.parse(localStorage.getItem('user'));
  const [departmentList, setDepartmentList] = useState([]);
  const classes = style();
  const [roles, setRoles] = useState([
    { id: 1, name: "ROLE_ADMIN" }, { id: 2, name: "ROLE_USER" },{ id: 6, name: "ROLE_ZONAL" }
  ])

  const { darkTheme } = useThemeContext();
  const [captchaToken, setCaptchaToken] = useState(null);
  const recaptchaRef = useRef(null);

  useEffect(() => {
    ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
      if (value !== state.password) return false;

      return true;
    });

    return () => ValidatorForm.removeValidationRule('isPasswordMatch');
  }, [state.password]);

  const handleSubmit = (event) => {

    event.preventDefault();

    if (!captchaToken) {
      toast.error("Please complete the CAPTCHA!");
      return;
    }

    setRefresh(true);
    setLoading(true);
    state.frontendUrl= FrontendUrl;
    state.captchaToken = captchaToken;
    state.password = '123456';
    state.confirmPassword = '123456';

    api
      .addAlertUserUser(state)
      .then((res) => {
        toast.success('Successfully added.');
        setRefresh(false);
        setOpen(false)
      })
      .catch((e) => {
        // console.log('Cannot add  data', e.response.data.message);
        // ✅ Reset CAPTCHA token on failure
        if (recaptchaRef.current) {
          recaptchaRef.current.reset();
        }
        return toast.error(e.response.data.message);
      }).finally(() => {setRefresh(false);setLoading(false)});

  };

  const refreshPage = (event) => {
    setOpen(false);
  };

  const handleChange = (event) => {
    // event.persist();
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const { name, email, confirmPassword, contact, password } = state;

  return (
    <div>
      <ValidatorForm onSubmit={handleSubmit} onError={() => null}>
        <Grid container spacing={6}>
          <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>

            <TextField
              type="text"
              name="name"
              id="standard-basic"
              value={name || ''}
              onChange={(event) => {
                const regex = /^[A-Za-z ]{0,50}$/; // Allows only alphabets and spaces (max 70 chars)
                if (regex.test(event.target.value)) {
                    handleChange(event); // Update state only if input is valid
                }
              }}
              errorMessages={['this field is required']}
              label="Name"
              validators={['required']}
              variant="standard"
              style={{ borderColor : darkTheme ? '#fff' : '#000' }}
              inputProps={{
                maxLength: 50, // Limit the input length to 50 characters
              }}
              InputProps={{
                style: { color: darkTheme ? '#fff' : '#000' }, 
              }}
              InputLabelProps={{
                style: { color: darkTheme ? '#fff' : '#000' }, 
              }}
              sx={{
                "& .MuiInput-underline:before": {
                  borderBottomColor: darkTheme ? '#fff' : '#000',
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: darkTheme ? '#fff' : '#000',
                },
                "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                  borderBottomColor: darkTheme ? '#fff' : '#000',
                },
                "& .MuiInputBase-input": {
                  color: darkTheme ? '#fff' : '#000',
                },
              }}
            />

            <TextField
              type="number"
              name="contact"
              label="Contact No."
              onChange={handleChange}
              value={contact || ''}
              variant="standard"
              errorMessages={['this field is required']}
              style={{ borderColor: darkTheme ? '#fff' : '#000' }}
              InputProps={{
                style: { color: darkTheme ? '#fff' : '#000' },
                inputProps: { 
                  min: 1111111111, max: 9999999999
              }
              }}
              InputLabelProps={{
                style: { color: darkTheme ? '#fff' : '#000' },
              }}
              sx={{
                "& .MuiInput-underline:before": {
                  borderBottomColor: darkTheme ? '#fff' : '#000',
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: darkTheme ? '#fff' : '#000',
                },
                "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                  borderBottomColor: darkTheme ? '#fff' : '#000',
                },
                "& .MuiInputBase-input": {
                  color: darkTheme ? '#fff' : '#000',
                },
              }}
            />

          </Grid>

          <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
            <TextField
              type="email"
              name="email"
              label="Email/Username"
              onChange={handleChange}
              value={email || ''}
              variant="standard"
              style={{ borderColor : darkTheme ? '#fff' : '#000' }}
              inputProps={{
                maxLength: 50, // Limit the input length to 70 characters
              }}
              InputProps={{
                style: { color: darkTheme ? '#fff' : '#000' }, 
              }}
              InputLabelProps={{
                style: { color: darkTheme ? '#fff' : '#000' }, 
              }}
              sx={{
                "& .MuiInput-underline:before": {
                  borderBottomColor: darkTheme ? '#fff' : '#000',
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: darkTheme ? '#fff' : '#000',
                },
                "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                  borderBottomColor: darkTheme ? '#fff' : '#000',
                },
                "& .MuiInputBase-input": {
                  color: darkTheme ? '#fff' : '#000',
                },
              }}
              errorMessages={['this field is required']}
            />

            <br/>
          </Grid>

        </Grid>

        {/* ✅ Google reCAPTCHA Section */}
        <div style={{ marginBottom: "10px" }}>
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={siteKey}
            onChange={(token) => setCaptchaToken(token)}
          />
        </div>

        <Button
          color="info"
          alignItems="right"
          className={classes.titleItemRight}
          variant="contained"
          type="button"
          onClick={refreshPage}
          style={{ margin: '5px', marginBottom: '-20px', background: '#e0e0e0', color: 'black' }}
        >
          <Span sx={{ textTransform: 'capitalize' }}>Cancel</Span>
        </Button>



        {
          (!lodaing)?
          <Button
          color="success"
          alignItems="right"
          className={classes.titleItemRight}
          variant="contained"
          type="submit"
          style={{ margin: '5px', marginBottom: '-20px' }}

        >
          Submit
        </Button>:

        <Button
          color="success"
          disabled
          alignItems="right"
          className={classes.titleItemRight}
          variant="contained"
          type="submit"
          style={{ margin: '5px', marginBottom: '-20px' }}

        >
          <CircularProgress />
        </Button>
        
        }

      </ValidatorForm>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </div>
  );
};

export default SimpleForm;
