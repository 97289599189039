import { Card, Grid, styled, useTheme, Paper, Button, ButtonGroup, Typography, Box, Tab, Tabs } from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
import ChargerTables from './shared/ChargerTable';
import ChargerUsesChart from './shared/ChargerUses';
import DoughnutChart from '../dashboard/shared/Doughnut';
import ChargingStMonitor from './shared/ChargingStMonitor';
import TabPanel from './shared/TabPanel';
import LoadMgmtProfile3 from '../cmsmgmt/loadmgmt/LoadMgmtProfile3';
import LoadMgmtProfile4 from '../cmsmgmt/loadmgmt/LoadMgmtProfile4';
import LoadMgmtProfile1 from '../cmsmgmt/loadmgmt/LoadMgmtProfile1';
import LoadMgmtProfile2 from '../cmsmgmt/loadmgmt/LoadMgmtProfile2';
import LoadMngmtProfile from '../cmsmgmt/loadmgmt/LoadMngmtProfile';
import BarChart from './shared/BarChart';
import ChargerTable2 from './shared/ChargerTable2';
import VehicleStatusTable from '../vehicle/shared/VehicleStatusTable';
import EnergyTable from './shared/EnergyTable';
import { useThemeContext } from 'app/ThemeProvider';
import TagMonitor from './shared/TagMonitor';
import { useNavigate } from 'react-router-dom';

const ContentBox = styled('div')(({ theme }) => ({
  margin: '7px',
  marginTop: '-2px',
  [theme.breakpoints.down('sm')]: { margin: '16px' },
}));

const Title = styled('span')(() => ({
  fontSize: '1rem',
  fontWeight: '500',
  marginRight: '.5rem',
  textTransform: 'capitalize',
}));


export function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ width: "100%", overflow: "auto" }}
      {...other}
    >
      {value === index && (
        <Paper elevation={3}>
          {children}
        </Paper>
      )}
    </div>
  );
}



const Analytics = () => {
  const { palette } = useTheme();
  const [table, setTable] = useState(true);
  const [value, setValue] = useState(0);
  const { darkTheme } = useThemeContext();


  const navigate = useNavigate();



  useEffect(() => {

    const permissions = JSON.parse(localStorage.getItem("permission"))
      .filter(obj => obj.type === 'navigation')
      .map(obj => obj.name) ?? [];



    if (!permissions.includes("Monitor"))
      navigate('/setdepot/default');

  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };



  const backgroundColor = darkTheme ? '#F2E34C' : '#0000ff';
  const textColor = darkTheme ? '#000' : '#fff'; // Text color
  const backgroundColorSelected = darkTheme ? '#F2E34C' : 'black'; // Background color of selected tab
  const backgroundColorUnselected = darkTheme ? '#334155' : '#d9d9d9';
  const textColorSelected = darkTheme ? 'black' : 'white'; // Text color of selected tab
  const textColorUnselected = darkTheme ? 'white' : 'black';

  return (
    <Fragment>
      <ContentBox className="analytics">
        <Grid container spacing={.8}>
          <Grid item lg={4} md={12} sm={12} xs={12} height={"60vh"}>
            <Paper elevation={3} style={{ height: "100%", backgroundColor: darkTheme ? '#333333' : '#f9f9f9' }}>
              <Card style={{ height: "100%", backgroundColor: darkTheme ? '#333333' : '#f9f9f9' }}>
                <Grid xs={8} lg={8} md={8} padding={"20px"}>
                  <Typography variant="h6" component="div" style={{ color: darkTheme ? '#F2E34C' : '#000' }} >
                    Connector Status
                  </Typography>
                </Grid>

                <DoughnutChart darkTheme={darkTheme} />
              </Card>
            </Paper>
          </Grid>



          <Grid item lg={8} md={12} sm={12} xs={12} height={"60vh"}>


            <Paper elevation={3} style={{ height: '100%', backgroundColor: darkTheme ? '#333333' : '#f9f9f9', border: 'none' }}>
              <Card style={{ height: '100%', backgroundColor: darkTheme ? '#333333' : '#f9f9f9', border: 'none' }}>
                <ChargerTables darkTheme={darkTheme} />
              </Card>
            </Paper>
          </Grid>
        </Grid>



        <Grid container>

          <Grid item
            lg={12} md={12} sm={12} xs={12}
            sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

            <Tabs value={value}
              onChange={handleChange}
              textColor="black"
              // backgroundColor="grey"
              style={{ margin: "20px 0 20px 0",

               }}
              variant="scrollable" // Makes the tabs scrollable
              scrollButtons="auto" // Show scroll buttons when necessary


              indicatorColor="grey"
              aria-label="secondary tabs example"
            >
              <Tab
                style={{ backgroundColor: value === 0 ? 'black' : 'lightgrey', color: value === 0 ? 'white' : 'black', borderTopLeftRadius: 15, borderBottomLeftRadius: 15 }}
                label="Charger Monitor " />
              <Tab
                style={{ backgroundColor: value === 1 ? 'black' : 'lightgrey', color: value === 1 ? 'white' : 'black' }}
                label="Vehicle Monitor" />
              <Tab
                style={{ backgroundColor: value === 2 ? 'black' : 'lightgrey', color: value === 2 ? 'white' : 'black' }}
                label="RFID Tag Monitor" />
              <Tab
                style={{ backgroundColor: value === 3 ? 'black' : 'lightgrey', color: value === 3 ? 'white' : 'black', borderTopRightRadius: 15, borderBottomRightRadius: 15 }}
                label="Energy Monitor" />
            </Tabs>
          </Grid>
          <CustomTabPanel value={value} index={0}>

            <Card style={{ width: "100%", backgroundColor: '#f9f9f9' }}>
              {/* <Grid xs={8} lg={8} md={8} padding={"20px"}>
                <Typography variant="h6" component="div" >
                  Charger Monitor
                </Typography>
              </Grid> */}
              <ChargingStMonitor style={{ backgroundColor: '#f9f9f9' }} />
            </Card>

          </CustomTabPanel>
          <CustomTabPanel value={value} index={1} width={"100%"}>


            <Card style={{ backgroundColor: '#f9f9f9' }}>
              {/* <Grid xs={8} lg={8} md={8} padding={"20px"}>

                <Typography variant="h6" component="div" >
                  Vehicle Monitor
                </Typography>

              </Grid> */}
              <VehicleStatusTable />
            </Card>



          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>

            <Card style={{ backgroundColor: '#f9f9f9' }}>
              {/* <Grid xs={8} lg={8} md={8} padding={"20px"}>

                <Typography variant="h6" component="div" >
                  RFID Tag Monitor
                </Typography>

              </Grid> */}
              <TagMonitor />
            </Card>

          </CustomTabPanel>
          <CustomTabPanel value={value} index={3}>

            <Card style={{ backgroundColor: '#f9f9f9' }}>
              {/* <Grid xs={8} lg={8} md={8} padding={"20px"}>

                <Typography variant="h6" component="div" >
                  Energy Monitor
                </Typography>

              </Grid> */}
              <EnergyTable />
            </Card>

          </CustomTabPanel>

        </Grid>

      </ContentBox>
    </Fragment>
  );
};
export default Analytics;