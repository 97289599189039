import { useEffect, useState } from 'react';
import { Grid, Stack, Paper, Typography, Card, Button, TextField } from '@mui/material'
import { useThemeContext } from 'app/ThemeProvider';
import EvStationIcon from '@mui/icons-material/EvStation';
import BoltIcon from '@mui/icons-material/Bolt';
import EnergyChart from 'app/views/charger/shared/charger-detail/EnergyChart';
import ChargingPortChart from 'app/views/charger/shared/charger-detail/ChargingPowerGraph';
import SocChart from 'app/views/charger/shared/charger-detail/SocChart';
import TempChart from 'app/views/charger/shared/charger-detail/TempChart';
import VoltageChart from 'app/views/charger/shared/charger-detail/Voltagechart';
import Currentchart from 'app/views/charger/shared/charger-detail/CurrentChart';
import ChargerApi from 'app/views/charger/ChargerApi';
import ReportApi from '../ReportApi';
import Loading from 'app/components/MatxLoading';
import { DateTime } from 'luxon';

const TransactionAnalytics = ({ receivedTransactionPk = null }) => {

  const { darkTheme } = useThemeContext();
  const [value, setvalue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [transactionPk, setTransactionPk] = useState("");
  const [transactionLogData, setTransactionLogData] = useState([]);
  const chargerApi = new ChargerApi();
  const api = new ReportApi();
  const [connectorList, setConnectorList] = useState(new Set());
  const [data, setReportData] = useState([]);
  const [selectedConnector, setSelectedConnector] = useState(1);
  const timezone = localStorage.getItem("orgTimezone");
  const [chargerId, setChargerId] = useState("");


  const fetchTransactionLog = () => {
    setLoading(true)

    chargerApi.getTransactionLog({ transactionPk: transactionPk })
      .then(({ data }) => {
        // console.log(data);



        setTransactionLogData(data);
        if (Array.isArray(data)) {

          const arr = []

          if (data.filter(o => o.connector_id == 2).length > 0) {
            arr.push(2)
            setSelectedConnector(2);
          }
          if (data.filter(o => o.connector_id == 1).length > 0) {
            arr.push(1)
            setSelectedConnector(1);
          }
          if (data.length > 0) {
            setChargerId(data[0].charge_box_id);
          }

          setConnectorList(arr);




        }
        setLoading(false);
      })
      .catch((e) => {
        console.log('Request history data', e);
      });

  }

  const fetchTransactionLog2 = (transactionPk) => {
    setLoading(true)

    chargerApi.getTransactionLog({ transactionPk: transactionPk })
      .then(({ data }) => {
        // console.log(data);
        setTransactionLogData(data);
        if (Array.isArray(data)) {

          const arr = []

          if (data.filter(o => o.connector_id == 2).length > 0) {
            arr.push(2)
            setSelectedConnector(2);
          }
          if (data.filter(o => o.connector_id == 1).length > 0) {
            arr.push(1)
            setSelectedConnector(1);
          }
          if (data.length > 0) {
            setChargerId(data[0].charge_box_id);
          }

          setConnectorList(arr);

        }
        setLoading(false);
      })
      .catch((e) => {
        console.log('Request history data', e);
      });

  }

  useEffect(() => {
    if (receivedTransactionPk === null)
      getChargingMonitorReport();
    else {
      setTransactionPk(receivedTransactionPk)
      fetchTransactionLog2(receivedTransactionPk)
    }
  }, [receivedTransactionPk]);


  const getChargingMonitorReport = () => {
    let isCanceled = false;
    setLoading(true)
    // console.log(params);
    api
      .showChargerStMonitorReport({})
      .then(({ data }) => {
        if (!isCanceled) {
          console.log(data)

          const arr = data.filter(o => parseFloat(o.energy) > 0);
          if (arr.length > 0) {
            setTransactionPk(arr[0].transaction_pk);
            fetchTransactionLog2(arr[0].transaction_pk);
            
          }

          setReportData(data);
          // if (data.length === 0)
          // alert("Data Not Found")
          setLoading(false);

        }
      })
      .catch((e) => {
        console.log('Cannot load report data', e);
      }).finally(() => { setLoading(false); });

  };




  const backgroundColorSelected = darkTheme ? '#F2E34C' : 'black'; // Background color of selected tab
  const backgroundColorUnselected = darkTheme ? '#334155' : '#d9d9d9';
  const textColorSelected = darkTheme ? 'black' : 'white'; // Text color of selected tab
  const textColorUnselected = darkTheme ? 'white' : 'black';

  const handleConnectorChange = (val) => {
    setLoading(true);
    setSelectedConnector(val);

    setTimeout(() => {
      setLoading(false);
    }, 200);


  }


  return (
    <Grid  >

      <Paper style={{ minHeight: '50vh', maxHeight: '120vh', backgroundColor: darkTheme ? '#333333' : '#f9f9f9' }} elevation={2}>

        <Stack>

          <Grid container >
            <Grid item md={1.5} padding={"10px"}>
              <Typography variant="h6" style={{ color: darkTheme ? '#fff' : '#000' }}>
                Transaction ID</Typography>

            </Grid>
            <Grid item md={7.5} padding={"10px"}>

              <TextField
                variant='standard'
                value={transactionPk}
                name='transactionPk'
                onChange={(e) => setTransactionPk(e.target.value)}

              />&nbsp;&nbsp;
              <Button

                style={{
                  border: '2px solid green', backgroundColor: 'white', margin: 0,
                  color: 'green', fontSize: '15px', fontWeight: 'bold', borderRadius: '25px',
                  padding: "3px 10px",
                }}

                color='primary'
                variant='contained'
                onClick={fetchTransactionLog}>
                Analyze
              </Button>

            </Grid>

            <Grid item md={3} padding={"10px"}>

              {
                Array.from(connectorList).sort((a, b) => a - b).map(connector => {

                  return <Button variant="contained"

                    // disabled={value === 2}
                    style={{
                      backgroundColor: connector === selectedConnector ? backgroundColorSelected : backgroundColorUnselected,
                      color: connector === selectedConnector ? textColorSelected : textColorUnselected, margin: 0, borderRadius: "0"
                    }}
                    onClick={() => handleConnectorChange(connector)}>Connector {connector} </Button>

                })
              }

            </Grid>




          </Grid>
        </Stack>

        <Stack spacing={2} direction="row" padding={'15px'} overflow={'auto'}>
          <Button variant="contained"

            disabled={value === 0}
            style={{
              backgroundColor: value === 0 ? backgroundColorSelected : backgroundColorUnselected,
              color: value === 0 ? textColorSelected : textColorUnselected, margin: 0, borderRadius: "5px 0 0 5px"
              , minWidth: 150
            }}
            onClick={() => { setvalue(0) }}>Charging Power</Button>

          <Button variant="contained"
            disabled={value === 1}
            style={{
              backgroundColor: value === 1 ? backgroundColorSelected : backgroundColorUnselected,
              color: value === 1 ? textColorSelected : textColorUnselected, margin: 0, borderRadius: "0"
              , minWidth: 100

            }}

            onClick={() => { setvalue(1) }}>Energy</Button>
          <Button variant="contained"

            disabled={value === 2}
            style={{
              backgroundColor: value === 2 ? backgroundColorSelected : backgroundColorUnselected,
              color: value === 2 ? textColorSelected : textColorUnselected, margin: 0, borderRadius: "0"

            }}
            onClick={() => { setvalue(2) }}>SoC</Button>

          <Button variant="contained"
            disabled={value === 3}
            style={{
              backgroundColor: value === 3 ? backgroundColorSelected : backgroundColorUnselected,
              color: value === 3 ? textColorSelected : textColorUnselected, margin: 0, borderRadius: "0"
              , minWidth: 120

            }}
            onClick={() => { setvalue(3) }}>Temperature</Button>

          <Button variant="contained"
            disabled={value === 4}
            style={{
              backgroundColor: value === 4 ? backgroundColorSelected : backgroundColorUnselected,
              color: value === 4 ? textColorSelected : textColorUnselected, margin: 0, borderRadius: "0", boxShadow: 0
              , minWidth: 100

            }}
            onClick={() => { setvalue(4) }}>Voltage</Button>

          <Button variant="contained"
            disabled={value === 5}
            style={{
              backgroundColor: value === 5 ? backgroundColorSelected : backgroundColorUnselected,
              color: value === 5 ? textColorSelected : textColorUnselected, margin: 0, borderRadius: "0 5px  5px 0"
              , minWidth: 100

            }}
            onClick={() => { setvalue(5) }}>Current</Button>




        </Stack>



        <Grid textAlign='left' padding={'15px'}>
          <div style={{ padding: '2px', display: 'inline-block', margin: '5px', maxHeight: '50px', backgroundColor: backgroundColorUnselected, borderRadius: '10px' }}>
            <Typography style={{ color: darkTheme ? '#F2E34C' : '#000' }}>
              <span style={{ borderRadius: '10px', padding: '2px', display: 'inline-block', margin: '5px', fontSize: '16px' }}>
                Date: {transactionLogData.length && (
                  <span style={{ padding: '5px', display: 'inline-block', margin: '5px' }}>
                    {/* {new Date(transactionLogData[0].value_timestamp).toDateString()} */}
                    {DateTime.fromISO(transactionLogData[0].value_timestamp).setZone(timezone).toFormat('dd-MM-yyyy')}
                  </span>
                )}
              </span>
              <span style={{ padding: '2px', borderRadius: '10px', display: 'inline-block', margin: '5px', fontSize: '16px' }}>
                Duration: {transactionLogData.length && (
                  <span style={{ padding: '2px', display: 'inline-block', margin: '5px' }}>
                    {/* {((new Date(transactionLogData[transactionLogData.length - 1].value_timestamp).getTime()
                                            - new Date(transactionLogData[0].value_timestamp).getTime()) / 60000).toFixed(2)} Mins */}
                    {((DateTime.fromISO(transactionLogData[transactionLogData.length - 1].value_timestamp).setZone(timezone).toMillis()
                      - DateTime.fromISO(transactionLogData[0].value_timestamp).setZone(timezone).toMillis()) / 60000).toFixed(2)} Mins
                  </span>
                )}
              </span>
              <span style={{ padding: '2px', borderRadius: '10px', display: 'inline-block', margin: '5px', fontSize: '16px' }}>
                Transaction ID: <span style={{ padding: '2px', display: 'inline-block', margin: '5px' }}>{transactionPk}</span>
              </span>
              <span style={{ padding: '2px', borderRadius: '10px', display: 'inline-block', margin: '5px', fontSize: '16px' }}>
                Charger ID: <span style={{ padding: '2px', display: 'inline-block', margin: '5px' }}>{chargerId}</span>
              </span>
            </Typography>
          </div>
        </Grid>



        <Grid width={'100%'} overflow={'auto'}>

          <Grid minWidth={'50rem'}>

            {value === 0 ? (loading ? <Loading /> : <ChargingPortChart darkTheme={darkTheme}
              data={transactionLogData.filter(obj => obj => obj.unit === "W"
                && obj.connector_id === selectedConnector

              )} />) : null}

            {value === 1 ? (loading ? <Loading /> : <EnergyChart darkTheme={darkTheme}
              data={transactionLogData.filter(obj => obj.measurand === "Energy.Active.Import.Register"
                && obj.connector_id === selectedConnector

              )}
            />) : null}

            {value === 2 ? (loading ? <Loading /> : <SocChart darkTheme={darkTheme}
              data={transactionLogData.filter(obj => obj.measurand === "SoC"
                && obj.connector_id === selectedConnector

              )}
            />) : null}

            {value === 3 ? (loading ? <Loading /> : <TempChart darkTheme={darkTheme}
              data={transactionLogData.filter(obj => obj.measurand === "Temperature"
                && obj.connector_id === selectedConnector

              )}
            />) : null}

            {value === 4 ? (loading ? <Loading /> : <VoltageChart darkTheme={darkTheme}
              data={transactionLogData.filter(obj => obj.measurand === "Voltage"
                && obj.connector_id === selectedConnector

              )}
            />) : null}

            {value === 5 ? (loading ? <Loading /> : <Currentchart darkTheme={darkTheme}
              data={transactionLogData.filter(obj => obj.unit === "A"
                && obj.connector_id === selectedConnector

              )}
            />) : null}

          </Grid>
        </Grid>

      </Paper>

    </Grid>
  );

}

export default TransactionAnalytics;