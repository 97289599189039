import UserApi from './UserApi';
import { Button, FormControlLabel, FormGroup, Grid, Switch, styled } from '@mui/material';
import { Span } from 'app/components/Typography';
import { useEffect, useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { ToastContainer, toast } from 'react-toastify';
import { makeStyles } from '@material-ui/core/styles';
import 'react-toastify/dist/ReactToastify.css';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import DepartmentApi from '../department/DepartmentApi';
import { useThemeContext } from 'app/ThemeProvider';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
const TextField = styled(TextValidator)(() => ({
  width: '100%',
  marginBottom: '16px',
}));
const style = makeStyles({
  titleItemRight: {
    float: 'right',
    position: 'relative',
    transform: 'translateY(-50%)',
    marginTop: 10,
  },
});

const UpdateUserForm = ({ setRefresh, setOpeno, row }) => {
  const [state, setState] = useState(row);
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const user = JSON.parse(localStorage.getItem('user'));
  const { darkTheme } = useThemeContext();
  const api = new UserApi();
  const classes = style();
  const [departmentList, setDepartmentList] = useState([]);

  const [allRoles, setAllRoles] = useState([
    { id: 1, name: "ROLE_ADMIN" }, { id: 2, name: "ROLE_USER" }, { id: 6, name: "ROLE_ZONAL" }
  ])

  useEffect(() => {
    ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
      if (state.password !== null && value !== state.password) return false;

      return true;
    });

    ValidatorForm.addValidationRule('isPasswordSixDigit', (value) => {
      if (state.password !== null && value.toString().length < 6) return false;

      return true;
    });

    return () => ValidatorForm.removeValidationRule('isPasswordMatch');
  }, [state.password]);

  const handleSubmit = (event) => {


    debugger;

    // console.log(state);

    setRefresh(true);

    // // console.log(state);
    event.preventDefault();
    api
      .updateUser(state)
      .then((res) => {
        toast.success('Successfully Updated.');
        setOpeno(false)
      })
      .catch((e) => {
        return toast.error(e.message);
        // console.log('Cannot add  data', e);
      }).finally(() => setRefresh(false));

  };

  const handleChecked = (e) => {
    e.preventDefault();
    if (e.target.checked)
      setState({ ...state, status: "enable" });
    else
      setState({ ...state, status: "disable" });
  }

  const refreshPage = (event) => {
    setOpeno(false);
    // handleClose();
  };

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const handleSelectChange = (event) => {
    const inputValue = event.target.value;

    // Use .includes() for a case-sensitive partial match
    const filteredRoles = allRoles.filter((role) => role.name.includes(inputValue));

    setState({ ...state, [event.target.name]: filteredRoles });
  };

  const handleSelectCountry = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  useEffect(() => {
    fetchAllDepartment();
  }, []);

  const fetchAllDepartment = async () => {

    debugger;
    const api = new DepartmentApi();

    api
      .getByOrg({ id: user.orgId })
      .then(({ data }) => {
        if (data) {
          setDepartmentList(data);
        }
      })
      .catch((e) => {
      })
  };

  const { name, email, confirmPassword, contact, password, role, roles, departmentId } = state;

  return (
    <div>
      <ValidatorForm onSubmit={handleSubmit} onError={() => null}>
        <Grid container spacing={6}>
          <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
            <TextField
              type="text"
              name="name"
              id="standard-basic"
              value={name || ''}
              onChange={(event) => {
                const regex = /^[A-Za-z ]{0,50}$/; // Allows only alphabets and spaces (max 70 chars)
                if (regex.test(event.target.value)) {
                    handleChange(event); // Update state only if input is valid
                }
              }}
              errorMessages={['this field is required']}
              label="Name"
              validators={['required']}
              variant="standard"
              style={{ borderColor: darkTheme ? '#fff' : '#000' }}
              inputProps={{
                maxLength: 50, // Limit the input length to 50 characters
              }}
              InputProps={{
                style: { color: darkTheme ? '#fff' : '#000' },
              }}
              InputLabelProps={{
                style: { color: darkTheme ? '#fff' : '#000' },
              }}
              sx={{
                "& .MuiInput-underline:before": {
                  borderBottomColor: darkTheme ? '#fff' : '#000',
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: darkTheme ? '#fff' : '#000',
                },
                "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                  borderBottomColor: darkTheme ? '#fff' : '#000',
                },
                "& .MuiInputBase-input": {
                  color: darkTheme ? '#fff' : '#000',
                },
              }}
            />

            <TextField
              type="password"
              name="password"
              label="Password"
              value={password || ''}
              variant="standard"
              onChange={handleChange}
              validators={[
                // "required",
                "matchRegexp:^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$"
              ]}
              errorMessages={[
                "This field is required",
                "Password must be at least 8 characters long and include an uppercase letter, a lowercase letter, a number, and a special character."
              ]}
              style={{ borderColor: darkTheme ? '#fff' : '#000' }}
              InputProps={{
                style: { color: darkTheme ? '#fff' : '#000' },
                minLength: 8
              }}
              InputLabelProps={{
                style: { color: darkTheme ? '#fff' : '#000' },
              }}
              sx={{
                "& .MuiInput-underline:before": {
                  borderBottomColor: darkTheme ? '#fff' : '#000',
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: darkTheme ? '#fff' : '#000',
                },
                "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                  borderBottomColor: darkTheme ? '#fff' : '#000',
                },
                "& .MuiInputBase-input": {
                  color: darkTheme ? '#fff' : '#000',
                },
              }}
            />

            <FormControl variant="standard" fullWidth>
              <InputLabel id="demo-simple-select-standard-label" style={{ color: darkTheme ? '#fff' : '#000' }}>Role</InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={roles[0].name}
                name="roles"
                validators={['required']}
                errorMessages={['this field is required']}
                fullWidth
                onChange={handleSelectChange}
                sortComparator={(a, b) => a.name.compareTo(b.name)} // Specify the custom comparator
                label="User"
                style={{
                  color: darkTheme ? '#f2e34c' : '#000',
                  borderColor: '#ff0000',
                }}
                sx={{
                  ".MuiOutlinedInput-notchedOutline": {
                    borderColor: darkTheme ? 'white' : 'black',
                  }
                }}

                IconComponent={() => (
                  <ArrowDropDownIcon
                    style={{ color: darkTheme ? '#fff' : '#000' }}
                  />
                )}
                MenuProps={{
                  PaperProps: {
                    style: {
                      backgroundColor: darkTheme ? '#000' : '#f9f9f9',
                      borderColor: '#ff0000',
                    },
                  },
                }}
                InputProps={{
                  style: {
                    color: 'white',
                    borderColor: '#ff0000', // Change border color to red
                  },
                }}
              >
                {
                  allRoles.map((role, index) => {
                    return <MenuItem
                      value={role.name}
                      key={index}
                      style={{
                        color: darkTheme ? '#fff' : '#000',
                        backgroundColor: darkTheme ? '#000' : '#f9f9f9',
                        borderColor: '#ff0000',
                      }}
                    >
                      <em>{role.name.includes("USER") ? "Depot User" : role.name.includes("ZONAL") ? "Zonal Manager" : "Depot Manager"}</em>
                    </MenuItem>
                  })
                }

              </Select>
            </FormControl>

            <FormControl variant="standard" style={{ marginTop: "10px" }} fullWidth>
              <InputLabel id="demo-simple-select-standard-label" style={{ color: darkTheme ? '#fff' : '#000' }}>Department</InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={departmentId}

                name="departmentId"
                validators={['required']}
                errorMessages={['this field is required']}
                fullWidth
                onChange={handleChange}
                label="Department"
                style={{
                  color: darkTheme ? '#f2e34c' : '#000',
                  borderColor: '#ff0000',
                }}
                sx={{
                  ".MuiOutlinedInput-notchedOutline": {
                    borderColor: darkTheme ? 'white' : 'black',
                  }
                }}

                IconComponent={() => (
                  <ArrowDropDownIcon
                    style={{ color: darkTheme ? '#fff' : '#000' }}
                  />
                )}
                MenuProps={{
                  PaperProps: {
                    style: {
                      backgroundColor: darkTheme ? '#000' : '#f9f9f9',
                      borderColor: '#ff0000',
                    },
                  },
                }}
                InputProps={{
                  style: {
                    color: 'white',
                    borderColor: '#ff0000', // Change border color to red
                  },
                }}
              >

                <MenuItem
                  key=""
                  style={{
                    color: darkTheme ? '#fff' : '#000',
                    backgroundColor: darkTheme ? '#000' : '#f9f9f9',
                    borderColor: '#ff0000',
                  }}
                >None</MenuItem>
                {
                  departmentList.map(department => <MenuItem
                    value={department.id}
                    key={department.id}
                    style={{
                      color: darkTheme ? '#fff' : '#000',
                      backgroundColor: darkTheme ? '#000' : '#f9f9f9',
                      borderColor: '#ff0000',
                    }}
                  >
                    {department.name}</MenuItem>)
                }


              </Select>
            </FormControl>
          </Grid>

          <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
            <TextField
              type="email"
              name="email"
              label="Email/Username"
              onChange={handleChange}
              value={email || ''}
              variant="standard"
              errorMessages={['this field is required']}
              disabled
              style={{ borderColor: darkTheme ? '#fff' : '#000' }}
              inputProps={{
                maxLength: 50, // Limit the input length to 70 characters
              }}
              InputProps={{
                style: { color: darkTheme ? '#fff' : '#000' },
              }}
              InputLabelProps={{
                style: { color: darkTheme ? '#fff' : '#000' },
              }}
              sx={{
                "& .MuiInput-underline:before": {
                  borderBottomColor: darkTheme ? '#fff' : '#000',
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: darkTheme ? '#fff' : '#000',
                },
                "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                  borderBottomColor: darkTheme ? '#fff' : '#000',
                },
                "& .MuiInputBase-input": {
                  color: darkTheme ? '#fff' : '#000',
                },
              }}
            />
            <TextField
              type="password"
              name="confirmPassword"
              label="Confirm Password"
              onChange={handleChange}
              value={confirmPassword || ''}
              validators={["isPasswordMatch"]}
              errorMessages={["This field is required", "Passwords do not match"]}
              variant="standard"
              style={{ borderColor: darkTheme ? '#fff' : '#000' }}
              InputProps={{
                style: { color: darkTheme ? '#fff' : '#000' },
                minLength: 8 
              }}
              InputLabelProps={{
                style: { color: darkTheme ? '#fff' : '#000' },
              }}
              sx={{
                "& .MuiInput-underline:before": {
                  borderBottomColor: darkTheme ? '#fff' : '#000',
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: darkTheme ? '#fff' : '#000',
                },
                "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                  borderBottomColor: darkTheme ? '#fff' : '#000',
                },
                "& .MuiInputBase-input": {
                  color: darkTheme ? '#fff' : '#000',
                },
              }}
            />


            <TextField
              type="number"
              name="contact"
              label="Contact No."
              onChange={handleChange}
              value={contact || ''}
              variant="standard"
              errorMessages={['this field is required']}
              style={{ borderColor: darkTheme ? '#fff' : '#000' }}
              InputProps={{
                style: { color: darkTheme ? '#fff' : '#000' },
                inputProps: { 
                  min: 1111111111, max: 9999999999
              }
              }}
              InputLabelProps={{
                style: { color: darkTheme ? '#fff' : '#000' },
              }}
              sx={{
                "& .MuiInput-underline:before": {
                  borderBottomColor: darkTheme ? '#fff' : '#000',
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: darkTheme ? '#fff' : '#000',
                },
                "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                  borderBottomColor: darkTheme ? '#fff' : '#000',
                },
                "& .MuiInputBase-input": {
                  color: darkTheme ? '#fff' : '#000',
                },
              }}
            />

            {/* <FormGroup>
              <FormControlLabel 
                onChange={handleChecked}
                control={<Switch />} label="Status" />
            </FormGroup> */}
            <br />
          </Grid>
        </Grid>

        <Button
          color="info"
          alignItems="right"
          className={classes.titleItemRight}
          variant="contained"
          type="button"
          onClick={refreshPage}
          style={{ margin: '5px', marginBottom: '-20px', background: '#e0e0e0', color: 'black' }}
        >
          <Span sx={{ textTransform: 'capitalize' }}>Cancel</Span>
        </Button>

        <Button
          color="success"
          alignItems="right"
          className={classes.titleItemRight}
          variant="contained"
          type="submit"
          style={{ margin: '5px', marginBottom: '-20px' }}
        >
          <Span sx={{ textTransform: 'capitalize' }}>Submit</Span>
        </Button>

      </ValidatorForm>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </div>
  );
};

export default UpdateUserForm;
