import logo from './logo.svg';
import { Grid, Stack, Paper, Typography, Card, Button, CircularProgress } from '@mui/material'
import Chart from './ChartBar'
import EvStationIcon from '@mui/icons-material/EvStation';
import BoltIcon from '@mui/icons-material/Bolt';
import DropStatus from './DropStatus'
import ChargingPortChart from './ChargingPowerGraph'
import EnergyChart from './EnergyChart'
import TempChart from './TempChart'
import SocChart from './SocChart'
import VoltageChart from './Voltagechart'
import Currentchart from './CurrentChart'
import TransChart from './TransChart'
import { useEffect, useState } from 'react';
import ChargerApi from '../../ChargerApi';
import { useThemeContext } from 'app/ThemeProvider';
import MeterApi from 'app/views/depoView/components/meter-detail/MeterApi';
import { Circle } from '@mui/icons-material';
import ElectricMeterIcon from '@mui/icons-material/ElectricMeter';


function MeterDetail({ row = {}, open }) {

    const api = new ChargerApi();
    const meterApi = new MeterApi();
    const [value, setvalue] = useState(0);
    const [chargerMonitorMap, setChargerMonitorMap] = useState({});
    const [totalChargerConsumtion, setTotalChargerConsumtion] = useState(0);
    const [totalMeterConsumtion, setTotalMeterConsumtion] = useState(0);
    const [loading, setLoading] = useState(false);
    const { darkTheme } = useThemeContext();
    const [connectorPk, setConnectorPk] = useState(0);
    const [meter, setMeterData] = useState({});



    const [meterLogData, setMeterLogData] = useState([]);

    const [selectedTransactionPk, setSelectedTransactionPk] = useState(0);

    // useEffect(() => {

    //     console.log("tr", selectedTransactionPk);

    //     if (selectedTransactionPk !== 0) {
    //         getTransactionLog();
    //     }
    // }, [selectedTransactionPk]);

    console.log(row)

    useEffect(() => {
        // console.log(row);

        getMeterDetail(row.chargeBoxId)


    }, [row])


    const getMeterDetail = (id) => {
        setLoading(true)

        meterApi.getEnergyMeterByDeviceId(id)
            .then(({ data }) => {
                // console.log(data);
                setMeterData(data);
                getMeteLogByMeterId(data.meterId)
                setLoading(false);
            })
            .catch((e) => {
                console.log('Request history data', e);
            });

    }


    const getMeteLogByMeterId = (id) => {
        setLoading(true)
        debugger;

        meterApi.showDeviceMeterLogData({ meterId: id })
            .then(({ data }) => {
                console.log("meterlogs", data);
                setMeterLogData(data);
                setLoading(false);
            })
            .catch((e) => {
                console.log('Request history data', e);
            });

    }



    useEffect(() => {

        const data = JSON.parse(localStorage.getItem("chargerMonitor")) || {};
        setChargerMonitorMap(data);

        const timeout = setInterval(() => {
            const data = JSON.parse(localStorage.getItem("chargerMonitor")) || {};
            setChargerMonitorMap(data);
        }, 5000);

        return () => {
            clearInterval(timeout);
        };


    }, [])

    const backgroundColorSelected = darkTheme ? '#F2E34C' : 'black'; // Background color of selected tab
    const backgroundColorUnselected = darkTheme ? '#334155' : '#d9d9d9';
    const textColorSelected = darkTheme ? 'black' : 'white'; // Text color of selected tab
    const textColorUnselected = darkTheme ? 'white' : 'black';

    return (
        <div className="App" style={{ backgroundColor: darkTheme ? '#000' : '#f9f9f9' }}>
            <Grid container spacing={1} style={{ backgroundColor: darkTheme ? '#000' : '#f9f9f9' }}>


                <Grid item lg={2.5} md={2.5} sm={2.5} xs={2.5}  >
                    <Paper elevation={3} style={{ backgroundColor: darkTheme ? '#333333' : '#f9f9f9', height: "28rem", }}>
                        <Card style={{ backgroundColor: darkTheme ? '#333333' : '#b2c2ee', height: "100%", padding: "10px" }} >
                            <Stack >
                                <Typography sx={{
                                    fontWeight: 'bolder'
                                    , fontSize: 18, textAlign: 'left'
                                    , color: darkTheme ? '#F2E34C' : '#000'
                                }}>Meter Details</Typography>
                            </Stack>

                            <Stack
                                justifyContent="center"
                                alignItems="center"
                                sx={{ width: '100%' }}
                            >
                                <img src="/meter_logo.jpg" width="50%" />

                                {/* <ElectricMeterIcon style={{fontSize:'7rem'}}/> */}
                            </Stack>

                            <Stack direction={'column'}>
                                <Grid container direction={'row'}>
                                    <Grid item xs={5.5} spacing={3} marginBottom={2}>
                                        <br />
                                        <Stack direction={'column'} margin={'auto'}>
                                            <Typography sx={{ fontWeight: 'bold', fontSize: 13, textAlign: 'left', color: darkTheme ? '#F2E34C' : '#000' }}>
                                                {/* {row && row.chargeBoxId} */}

                                                {meter.make ?? "unknown"}
                                            </Typography>
                                            <Typography sx={{ fontWeight: 'regular', fontSize: 12, textAlign: 'left', color: darkTheme ? '#fff' : '#000' }}>
                                                Make
                                            </Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={1}>

                                    </Grid>

                                </Grid>
                            </Stack>

                            <Stack direction={'column'}>
                                <Grid container direction={'row'}>
                                    <Grid item xs={5.5} spacing={3} marginBottom={2}>
                                        <Stack direction={'column'} margin={'auto'}>
                                            <Typography sx={{ fontWeight: 'bold', fontSize: 13, textAlign: 'left', color: darkTheme ? '#F2E34C' : '#000' }}>
                                                {meter.deviceId ?? "unknown"}

                                            </Typography>
                                            <Typography sx={{ fontWeight: 'regular', fontSize: 12, textAlign: 'left', color: darkTheme ? '#fff' : '#000' }}>
                                                Linked Device
                                            </Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={1}>

                                    </Grid>

                                </Grid>
                            </Stack>


                            <Stack direction={'column'}>
                                <Grid container direction={'row'}>
                                    <Grid item xs={5.5} spacing={3} marginBottom={2}>
                                        <Stack direction={'column'} margin={'auto'}>
                                            <Typography sx={{ fontWeight: 'bold', fontSize: 13, textAlign: 'left', color: darkTheme ? '#F2E34C' : '#000' }}>
                                                {meter.meterId ?? "unknown"}
                                            </Typography>
                                            <Typography sx={{ fontWeight: 'regular', fontSize: 12, textAlign: 'left', color: darkTheme ? '#fff' : '#000' }}>
                                                Meter ID
                                            </Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={1}>

                                    </Grid>

                                </Grid>
                            </Stack>

                            {/* <Stack direction={'column'}>
                                <Grid container direction={'row'}>
                                    <Grid item xs={5.5} spacing={3} marginBottom={2}>
                                        <Stack direction={'column'} margin={'auto'}>
                                            <Typography sx={{ fontWeight: 'bold', fontSize: 13, textAlign: 'left', color: darkTheme ? '#F2E34C' : '#000' }}>
                                                {meter.model ?? "unknown"}
                                            </Typography>
                                            <Typography sx={{ fontWeight: 'regular', fontSize: 12, textAlign: 'left', color: darkTheme ? '#fff' : '#000' }}>
                                                Model
                                            </Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={1}>

                                    </Grid>

                                </Grid>
                            </Stack> */}
                            <Stack direction={'column'}>
                                <Grid container direction={'row'}>
                                    <Grid item xs={5.5} spacing={3} marginBottom={2}>
                                        <Stack direction={'column'} margin={'auto'}>
                                            <Typography sx={{ fontWeight: 'bold', fontSize: 13, textAlign: 'left', color: darkTheme ? '#F2E34C' : '#000' }}>
                                                {meter.serialNumber ?? "unknown"}
                                            </Typography>
                                            <Typography sx={{ fontWeight: 'regular', fontSize: 12, textAlign: 'left', color: darkTheme ? '#fff' : '#000' }}>
                                                Serial Number
                                            </Typography>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Stack>
                            <Stack direction={'column'}>
                                <Grid container direction={'row'}>
                                    <Grid item xs={5.5} spacing={3} marginBottom={2}>
                                        <Stack direction={'column'} margin={'auto'}>
                                            <Typography sx={{ fontWeight: 'bold', fontSize: 13, textAlign: 'left', color: darkTheme ? '#F2E34C' : '#000' }}>
                                                Offline
                                            </Typography>
                                            <Typography sx={{ fontWeight: 'regular', fontSize: 12, textAlign: 'left', color: darkTheme ? '#fff' : '#000' }}>
                                                Status
                                            </Typography>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Stack>
                        </Card>
                    </Paper>
                </Grid>



                <Grid item lg={9.5} md={9.5} sm={9.5} xs={9.5} height={"28.5rem"}>
                    <Paper elevation={3} style={{ padding: "15px 15px 2px 15px", width: "100%", height: "100%", backgroundColor: darkTheme ? '#333333' : '#f9f9f9' }} >
                        <Grid container direction={'row'} style={{ width: "100%" }}>
                            <Grid item xs={6} direction={'column'}>
                                <Typography sx={{ fontWeight: 'bolder', fontSize: 18, textAlign: 'left' }} style={{ color: darkTheme ? '#F2E34C' : '#000' }}>Energy Usage</Typography>
                            </Grid>
                            <Grid item xs={6} direction={'row'}>

                                <Circle style={{ color: 'limegreen', fontSize: '1.2rem' }} />&nbsp;
                                <Typography sx={{ fontWeight: 'bold', fontSize: 30, textAlign: 'right', marginRight: 0, display: 'inline-flex' }} style={{ color: darkTheme ? '#fff' : '#000' }}>
                                    {totalMeterConsumtion.toFixed(2)}<Typography style={{ paddingTop: '18px', color: darkTheme ? '#fff' : '#000' }}>kWh</Typography></Typography>
                                &nbsp;&nbsp;&nbsp;
                                <Circle style={{ color: 'royalblue', fontSize: '1.2rem' }} />&nbsp;

                                <Typography sx={{ fontWeight: 'bold', fontSize: 30, textAlign: 'right', marginRight: 0, display: 'inline-flex' }} style={{ color: darkTheme ? '#fff' : '#000' }}>
                                    {totalChargerConsumtion.toFixed(2)}<Typography style={{ paddingTop: '18px', color: darkTheme ? '#fff' : '#000' }}>kWh</Typography></Typography>


                            </Grid>
                            <Grid item md={12} height="100%">
                                <Chart row={row}
                                    setTotalChargerConsumtion={setTotalChargerConsumtion}
                                    setTotalMeterConsumtion={setTotalMeterConsumtion}
                                    darkTheme={darkTheme} />
                            </Grid>
                        </Grid>


                    </Paper>
                </Grid>

            </Grid>




            <Grid style={{ marginTop: '5px' }} >



                <Paper style={{ minHeight: '50vh', maxHeight: '100vh', backgroundColor: darkTheme ? '#333333' : '#f9f9f9' }} elevation={2}>

                    <Stack spacing={2} direction="row" padding={'15px'}>
                        <Button variant="contained"
                            disabled={value === 0}
                            style={{
                                backgroundColor: value === 0 ? backgroundColorSelected : backgroundColorUnselected,
                                color: value === 0 ? textColorSelected : textColorUnselected, margin: 0, borderRadius: "5px 0 0 5px"
                            }}
                            onClick={() => { setvalue(0) }}>Power</Button>

                        <Button variant="contained"
                            disabled={value === 1}
                            style={{
                                backgroundColor: value === 1 ? backgroundColorSelected : backgroundColorUnselected,
                                color: value === 1 ? textColorSelected : textColorUnselected, margin: 0, borderRadius: "0"
                            }}

                            onClick={() => { setvalue(1) }}>Energy</Button>


                        <Button variant="contained"
                            disabled={value === 4}
                            style={{
                                backgroundColor: value === 4 ? backgroundColorSelected : backgroundColorUnselected,
                                color: value === 4 ? textColorSelected : textColorUnselected, margin: 0, borderRadius: "0", boxShadow: 0
                            }}
                            onClick={() => { setvalue(4) }}>Voltage</Button>

                        <Button variant="contained"
                            disabled={value === 5}
                            style={{
                                backgroundColor: value === 5 ? backgroundColorSelected : backgroundColorUnselected,
                                color: value === 5 ? textColorSelected : textColorUnselected, margin: 0, borderRadius: "0 5px  5px 0"
                            }}
                            onClick={() => { setvalue(5) }}>Current</Button>

                    </Stack>





                    {value === 0 ? (loading ? <CircularProgress /> : <ChargingPortChart darkTheme={darkTheme}
                        data={meterLogData.filter(obj => obj.measurand === "3_phase_active_power"
                            || obj.measurand === "3_phase_reactive_power"
                            || obj.measurand === "3_phase_apparent_power"
                        )} />) : null}

                    {value === 1 ? (loading ? <CircularProgress /> : <EnergyChart darkTheme={darkTheme}
                        data={meterLogData.filter(obj => obj.measurand === "active_total_import"
                            &&parseInt(obj.register_value)>99&&parseInt(obj.register_value)<9999999999
                        )}
                    />) : null}


                    {value === 4 ? (loading ? <CircularProgress /> : <VoltageChart darkTheme={darkTheme}
                        data={meterLogData.filter(obj => obj.measurand === "v12_ry_voltage"
                            || obj.measurand === "v23_yb_voltage"
                            || obj.measurand === "v31_br_voltage"
                        )}
                    />) : null}

                    {value === 5 ? (loading ? <CircularProgress /> : <Currentchart darkTheme={darkTheme}
                        data={meterLogData.filter(obj => obj.measurand === "l1_r_phase_line_current"
                            || obj.measurand === "l2_y_phase_line_current"
                            || obj.measurand === "l3_b_phase_line_current"
                        )}
                    />) : null}
                </Paper>

            </Grid>
        </div>
    );
}

export default MeterDetail;