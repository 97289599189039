import logo from './logo.svg';
import './App.css';
import { Grid, Stack, Paper, Typography, Card, Button, CircularProgress } from '@mui/material'
import Chart from './ChartBar'
import EvStationIcon from '@mui/icons-material/EvStation';
import BoltIcon from '@mui/icons-material/Bolt';
import DropStatus from './DropStatus'
import ChargingPortChart from './ChargingPowerGraph'
import EnergyChart from './EnergyChart'
import TempChart from './TempChart'
import SocChart from './SocChart'
import VoltageChart from './Voltagechart'
import Currentchart from './CurrentChart'
import TransChart from './TransChart'
import { useEffect, useState } from 'react';
import ChargerApi from '../../ChargerApi';
import { useThemeContext } from 'app/ThemeProvider';
import { DateTime } from 'luxon';



function ChargerDetail({ row, open }) {

  const api = new ChargerApi();
  const [value, setvalue] = useState(0);
  const [chargerMonitorMap, setChargerMonitorMap] = useState({});
  const [totalConsumtion, setTotalConsumtion] = useState(0);
  const [loading, setLoading] = useState(false);
  const { darkTheme } = useThemeContext();
  const [connectorPk, setConnectorPk] = useState(0);
  const [orgTimezone, setOrgTimezone] = useState('');


  const [transactionLogData, setTransactionLogData] = useState([]);

  const [selectedTransactionPk, setSelectedTransactionPk] = useState(0);

  useEffect(() => {

    // console.log("tr", selectedTransactionPk);

    if (selectedTransactionPk !== 0) {
      getTransactionLog();
    }
  }, [selectedTransactionPk]);

  useEffect(() => {
    setConnectorPk(open ? row.connectorList[1]?.connectorPk : 0);
    // console.log(row);
  }, [])

  const getTransactionLog = () => {
    setLoading(true)

    const timezone = localStorage.getItem("orgTimezone");

    setOrgTimezone(timezone);

    api.getTransactionLog({ transactionPk: selectedTransactionPk })
      .then(({ data }) => {
        // console.log(data);
        setTransactionLogData(data);
        setLoading(false);
      })
      .catch((e) => {
        console.log('Request history data', e);
      });

  }

  useEffect(() => {

    const data = JSON.parse(localStorage.getItem("chargerMonitor")) || {};
    setChargerMonitorMap(data);

    const timeout = setInterval(() => {
      const data = JSON.parse(localStorage.getItem("chargerMonitor")) || {};
      setChargerMonitorMap(data);
    }, 5000);

    return () => {
      clearInterval(timeout);
    };


  }, [])

  const backgroundColorSelected = darkTheme ? '#F2E34C' : 'black'; // Background color of selected tab
  const backgroundColorUnselected = darkTheme ? '#334155' : '#d9d9d9';
  const textColorSelected = darkTheme ? 'black' : 'white'; // Text color of selected tab
  const textColorUnselected = darkTheme ? 'white' : 'black';

  return (
    <div className="App" style={{ backgroundColor: darkTheme ? '#000' : '#f9f9f9' }}>

      <Grid container spacing={1} style={{ backgroundColor: darkTheme ? '#000' : '#f9f9f9' }}>



        <Grid item lg={2.5} md={2.5} sm={2.5} xs={2.5} height={"25rem"} >
          <Paper elevation={3} style={{ backgroundColor: darkTheme ? '#333333' : '#f9f9f9', height: "100%", }}>
            <Card style={{ backgroundColor: darkTheme ? '#333333' : '#b2c2ee', height: "100%", padding: "10px" }} >
              {/* <StackedAreaChart /> */}
              <Stack direction={'column'}>
                <Typography sx={{ fontWeight: 'bolder', fontSize: 18, textAlign: 'left', color: darkTheme ? '#F2E34C' : '#000' }}>Charger Details</Typography>
              </Stack>
              {/* <Stack direction={'row'} spacing={3}>
                <Typography paddingTop={3} sx={{ fontWeight: 'bold', fontSize: 14, textAlign: 'left' }}>Maintainance : OFF </Typography>
                <DropStatus />
              </Stack> */}
              <Stack direction={'column'}>
                <EvStationIcon sx={{ margin: 'auto', height: '40%', width: '50%', color: 'black' }} style={{ color: darkTheme ? '#fff' : '#000', margin: 'auto', height: '40%', width: '50%' }} />
              </Stack>
              <Stack direction={'column'}>
                <Grid container direction={'row'}>
                  <Grid item xs={5.5} spacing={3} marginBottom={2}>
                    <Stack direction={'column'} margin={'auto'}>
                      <Typography sx={{ fontWeight: 'bold', fontSize: 13, textAlign: 'left', color: darkTheme ? '#F2E34C' : '#000' }}>
                        {row && row.chargeBoxId}
                      </Typography>
                      <Typography sx={{ fontWeight: 'regular', fontSize: 12, textAlign: 'left', color: darkTheme ? '#fff' : '#000' }}>
                        Charger ID
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={1}>

                  </Grid>
                  {/* <Grid item xs={5.5} marginBottom={2}>
                    <Stack direction={'column'} >
                      <Typography sx={{ fontWeight: 'bold', fontSize: 13, textAlign: 'left' }}>
                        Unknown
                      </Typography>
                      <Typography sx={{ fontWeight: 'regular', fontSize: 12, textAlign: 'left' }}>
                        Status
                      </Typography>
                    </Stack>
                  </Grid> */}
                </Grid>
              </Stack>
              <Stack direction={'column'}>
                <Grid container direction={'row'}>
                  <Grid item xs={5.5} spacing={3} marginBottom={2}>
                    <Stack direction={'column'} margin={'auto'}>
                      <Typography sx={{ fontWeight: 'bold', fontSize: 13, textAlign: 'left', color: darkTheme ? '#F2E34C' : '#000' }}>
                        {row.make ?? "unknown"}
                      </Typography>
                      <Typography sx={{ fontWeight: 'regular', fontSize: 12, textAlign: 'left', color: darkTheme ? '#fff' : '#000' }}>
                        Model
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={1}>

                  </Grid>
                  {/* <Grid item xs={5.5} marginBottom={2}>
                    <Stack direction={'column'} >
                      <Typography sx={{ fontWeight: 'bold', fontSize: 13, textAlign: 'left' }}>
                        04
                      </Typography>
                      <Typography sx={{ fontWeight: 'regular', fontSize: 12, textAlign: 'left' }}>
                        Location
                      </Typography>
                    </Stack>
                  </Grid> */}
                </Grid>
              </Stack>
              <Stack direction={'column'}>
                <Grid container direction={'row'}>
                  <Grid item xs={5.5} spacing={3} marginBottom={2}>
                    <Stack direction={'column'} margin={'auto'}>
                      <Typography sx={{ fontWeight: 'bold', fontSize: 13, textAlign: 'left', color: darkTheme ? '#F2E34C' : '#000' }}>
                        {row.chargePointSerialNumber}
                      </Typography>
                      <Typography sx={{ fontWeight: 'regular', fontSize: 12, textAlign: 'left', color: darkTheme ? '#fff' : '#000' }}>
                        Serial Number
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={1}>

                  </Grid>
                  <Grid item xs={5.5} marginBottom={2}>
                    <Stack direction={'column'} >

                    </Stack>
                  </Grid>
                </Grid>
              </Stack>
            </Card>
          </Paper>
        </Grid>

        <Grid item lg={2.5} md={2.5} sm={2.5} xs={2.5} height={"25rem"} >
          <Grid container spacing={0.2} height={"100%"}>
            <Grid item direction={'column'} lg={12} md={12} sm={12} xs={12} height={"50%"}>
              <Paper elevation={3} style={{ backgroundColor: darkTheme ? '#333333' : '#f9f9f9', height: "100%" }} >
                <Card style={{ backgroundColor: darkTheme ? '#333333' : '#f9f9f9', height: "100%", padding: "15px" }}>
                  <Stack direction={'column'}>
                    <Typography sx={{ fontWeight: 'bolder', fontSize: 15, marginBottom: 1.5, textAlign: 'left', color: darkTheme ? '#F2E34C' : '#000' }}>Connector 1:</Typography>
                  </Stack>
                  <Stack direction={'column'}>
                    <Grid container direction={'row'}>

                      <Grid item xs={5.5} marginBottom={2}>

                        <Stack direction={'column'} >
                          <Typography sx={{ fontWeight: 'bold', fontSize: 13, color: darkTheme ? '#F2E34C' : '#000' }}>
                            {chargerMonitorMap[row.chargeBoxId + '-1']?.status}
                          </Typography>
                          <Typography sx={{ fontWeight: 'regular', fontSize: 12, color: darkTheme ? '#fff' : '#000' }}>
                            Status
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid item xs={1}>

                      </Grid>
                      <Grid item xs={5.5} spacing={3} marginBottom={2}>
                        <Stack direction={'column'} margin={'auto'}>
                          <Typography sx={{ fontWeight: 'bold', fontSize: 13, color: darkTheme ? '#F2E34C' : '#000' }}>
                            {(row.connectorList && row.connectorList.length > 0 && row.connectorList[1]?.maxPower) ?? 0}
                          </Typography>
                          <Typography sx={{ fontWeight: 'regular', fontSize: 12, color: darkTheme ? '#fff' : '#000' }}>
                            Max Power
                          </Typography>
                        </Stack>
                      </Grid>

                    </Grid>
                  </Stack>
                  <Stack direction={'column'}>
                    <Grid container direction={'row'}>
                      <Grid item xs={5.5} spacing={3} marginBottom={2}>
                        <Stack direction={'column'} margin={'auto'}>
                          <Typography sx={{ fontWeight: 'bold', fontSize: 13, color: darkTheme ? '#F2E34C' : '#000' }}>
                            {row.chargerType ?? "unknown"}
                          </Typography>
                          <Typography sx={{ fontWeight: 'regular', fontSize: 12, color: darkTheme ? '#fff' : '#000' }}>
                            Type
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid item xs={1}>

                      </Grid>
                      <Grid item xs={5.5} marginBottom={2}>

                        <Stack direction={'row'} margin={'auto'} >
                          <BoltIcon style={{
                            color: parseFloat(chargerMonitorMap[row.chargeBoxId + '-1']?.power ?? 0) > 0 ? '#00FF00' : darkTheme ? '#F2E34C' : '#000',
                            animation: parseFloat(chargerMonitorMap[row.chargeBoxId + '-1']?.power ?? 0) > 0 ? 'glow 1s infinite alternate' : 'none',
                          }} />
                          <Stack direction={'column'} >
                            <Typography sx={{ fontWeight: 'bold', fontSize: 13, color: darkTheme ? '#F2E34C' : '#000' }}>
                              {parseFloat(chargerMonitorMap[row.chargeBoxId + '-1']?.power ?? 0).toFixed(2)}
                            </Typography>
                            <Typography sx={{ fontWeight: 'regular', fontSize: 12, color: darkTheme ? '#fff' : '#000' }}>
                              Charging Rate
                            </Typography>
                          </Stack>


                        </Stack>
                      </Grid>
                    </Grid>
                  </Stack>

                </Card>
              </Paper>
            </Grid>
            <Grid item direction={'column'} lg={12} md={12} sm={12} xs={12} height={"50%"}>
              <Paper elevation={3} style={{ backgroundColor: darkTheme ? '#333333' : '#f9f9f9', height: "100%" }} >
                <Card style={{ backgroundColor: darkTheme ? '#333333' : '#f9f9f9', height: "100%", padding: "15px" }}>
                  <Stack direction={'column'}>
                    <Typography sx={{ fontWeight: 'bolder', fontSize: 15, marginBottom: 1.5, justifyContent: 'start', textAlign: 'left', color: darkTheme ? '#F2E34C' : '#000' }}>Connector 2 :</Typography>
                  </Stack>
                  <Stack direction={'column'}>
                    <Grid container direction={'row'}>

                      <Grid item xs={5.5} marginBottom={2}>

                        <Stack direction={'column'} >
                          <Typography sx={{ fontWeight: 'bold', fontSize: 13, color: darkTheme ? '#F2E34C' : '#000' }}>
                            {chargerMonitorMap[row.chargeBoxId + '-2']?.status}
                          </Typography>
                          <Typography sx={{ fontWeight: 'regular', fontSize: 12, color: darkTheme ? '#fff' : '#000' }}>
                            Status
                          </Typography>
                        </Stack>
                      </Grid>

                      <Grid item xs={1}>

                      </Grid>
                      <Grid item xs={5.5} spacing={3} marginBottom={2}>
                        <Stack direction={'column'} margin={'auto'}>
                          <Typography sx={{ fontWeight: 'bold', fontSize: 13, color: darkTheme ? '#F2E34C' : '#000' }}>
                            {(row.connectorList && row.connectorList.length > 0 && row.connectorList[2]?.maxPower) ?? 0}

                          </Typography>
                          <Typography sx={{ fontWeight: 'regular', fontSize: 12, color: darkTheme ? '#fff' : '#000' }}>
                            Max Power
                          </Typography>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Stack>
                  <Stack direction={'column'}>
                    <Grid container direction={'row'}>
                      {/* <Grid item xs={5.5} spacing={3} marginBottom={2}>
                        <Stack direction={'column'} margin={'auto'}>
                          <Typography sx={{ fontWeight: 'bold', fontSize: 13 }}>
                            {row.make ?? "unknown"}
                          </Typography>
                          <Typography sx={{ fontWeight: 'regular', fontSize: 12, }}>
                            Model
                          </Typography>
                        </Stack>
                      </Grid> */}
                      <Grid item xs={1}>

                      </Grid>
                      {/* <Grid item xs={5.5} marginBottom={2}>
                        <Stack direction={'column'} >
                          <Typography sx={{ fontWeight: 'bold', fontSize: 13 }}>
                            Unknown
                          </Typography>
                          <Typography sx={{ fontWeight: 'regular', fontSize: 12, }}>
                            Status
                          </Typography>
                        </Stack>
                      </Grid> */}
                    </Grid>
                  </Stack>


                  <Stack direction={'column'}>
                    <Grid container direction={'row'}>
                      <Grid item xs={5.5} spacing={3} marginBottom={2}>
                        <Stack direction={'column'} margin={'auto'}>
                          <Typography sx={{ fontWeight: 'bold', fontSize: 13, color: darkTheme ? '#F2E34C' : '#000' }}>
                            {row.chargerType ?? "unknown"}
                          </Typography>
                          <Typography sx={{ fontWeight: 'regular', fontSize: 12, color: darkTheme ? '#fff' : '#000' }}>
                            Type
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid item xs={1}>

                      </Grid>
                      <Grid item xs={5.5} marginBottom={2}>

                        <Stack direction={'row'} margin={'auto'} >
                          <BoltIcon style={{
                            color: parseFloat(chargerMonitorMap[row.chargeBoxId + '-2']?.power ?? 0) > 0 ? '#00FF00' : darkTheme ? '#F2E34C' : '#000',
                            animation: parseFloat(chargerMonitorMap[row.chargeBoxId + '-2']?.power ?? 0) > 0 ? 'glow 1s infinite alternate' : 'none',
                          }} />
                          <Stack direction={'column'} >
                            <Typography sx={{ fontWeight: 'bold', fontSize: 13, color: darkTheme ? '#F2E34C' : '#000' }}>
                              {parseFloat(chargerMonitorMap[row.chargeBoxId + '-2']?.power ?? 0).toFixed(2)}
                            </Typography>
                            <Typography sx={{ fontWeight: 'regular', fontSize: 12, color: darkTheme ? '#fff' : '#000' }}>
                              Charging Rate
                            </Typography>
                          </Stack>


                        </Stack>
                      </Grid>
                    </Grid>
                  </Stack>

                </Card>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        
        <Grid item lg={7} md={7} sm={7} xs={7} height={"25rem"}>
          <Paper elevation={3} style={{ padding: "15px 15px 2px 15px", width: "100%", height: "100%", backgroundColor: darkTheme ? '#333333' : '#f9f9f9' }} >
            <Grid container direction={'row'} style={{ width: "100%" }}>
              <Grid item xs={6} direction={'column'}>
                <Typography sx={{ fontWeight: 'bolder', fontSize: 18, textAlign: 'left' }} style={{ color: darkTheme ? '#F2E34C' : '#000' }}>Energy Usage</Typography>
                {/* <Typography sx={{ fontWeight: 'bolder', fontSize: 14, textAlign: 'left', color: 'darkgray' }} style={{ color: darkTheme ? '#F2E34C' : '#000' }}>Last Seven Days</Typography> */}
              </Grid>
              <Grid item xs={6} direction={'row'}>
                <Typography sx={{ fontWeight: 'bold', fontSize: 30, textAlign: 'right', marginRight: 0, display: 'inline-flex' }} style={{ color: darkTheme ? '#fff' : '#000' }}>{parseFloat(totalConsumtion).toFixed(2)}<Typography style={{ paddingTop: '18px', color: darkTheme ? '#fff' : '#000' }}>kWh</Typography></Typography>
                {/* <Typography sx={{ fontWeight: 'bolder', fontSize: 14, textAlign: 'right', marginRight: 12, color: 'darkgray' }} style={{ color: darkTheme ? '#fff' : '#000' }}>Aggregate Energy Usage</Typography> */}

              </Grid>
              <Grid item md={12} height="100%">
                <Chart row={row} setTotalConsumtion={setTotalConsumtion} darkTheme={darkTheme} />
              </Grid>
            </Grid>


          </Paper>
        </Grid>

      </Grid>
      
      {/* <Grid padding={'15px'} >
        <Typography sx={{ fontWeight: 'bolder', fontSize: 18, textAlign: 'left' }}>Charging Session history</Typography>
        <Grid container spacing={1} padding={'15px'}>
          <Grid item lg={4} md={4} sm={4} xs={4}>
            <Paper elevation={2} style={{ backgroundColor: '#f9f9f9' }}>
              <Typography sx={{ fontWeight: 'bold', fontSize: 15, textAlign: 'left', padding: '10px' }}>Transaction ID : 12234</Typography>
            </Paper>
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={4}>
            <Paper elevation={2} style={{ backgroundColor: '#f9f9f9' }} >
              <Typography sx={{ fontWeight: 'bold', fontSize: 15, textAlign: 'left', padding: '10px' }}>Field 1:</Typography>
            </Paper>
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={4}>
            <Paper elevation={2} style={{ backgroundColor: '#f9f9f9' }} >
              <Typography sx={{ fontWeight: 'bold', fontSize: 15, textAlign: 'left', padding: '10px' }}>Field 2:</Typography>
            </Paper>
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={4}>
            <Paper elevation={2} style={{ backgroundColor: '#f9f9f9' }}>
              <Typography sx={{ fontWeight: 'bold', fontSize: 15, textAlign: 'left', padding: '10px' }}>Field 3:</Typography>
            </Paper>
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={4}>
            <Paper elevation={2} style={{ backgroundColor: '#f9f9f9' }}>
              <Typography sx={{ fontWeight: 'bold', fontSize: 15, textAlign: 'left', padding: '10px' }}>Field 4:</Typography>
            </Paper>
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={4}>
            <Paper elevation={2} style={{ backgroundColor: '#f9f9f9' }}>
              <Typography sx={{ fontWeight: 'bold', fontSize: 15, textAlign: 'left', padding: '10px' }}>Field 5:</Typography>
            </Paper>
          </Grid>
        </Grid>
      </Grid> */}

      <Grid lg={12} md={12} sm={12} xs={12} style={{ marginTop: '5px',height:'30rem' }}>
        <Paper style={{  backgroundColor: darkTheme ? '#333333' : '#f9f9f9',height:'100%' }} elevation={2}>
          <TransChart row={row}
            setSelectedTransactionPk={setSelectedTransactionPk}
            connectorPk={connectorPk} setConnectorPk={setConnectorPk} darkTheme={darkTheme} />
        </Paper>

      </Grid>


      <Grid style={{ marginTop: '5px' }} >



        <Paper style={{ height:'33rem', backgroundColor: darkTheme ? '#333333' : '#f9f9f9' }} elevation={2}>

          <Stack spacing={2} direction="row" padding={'15px'}>
            <Button variant="contained"
              disabled={value === 0}
              style={{
                backgroundColor: value === 0 ? backgroundColorSelected : backgroundColorUnselected,
                color: value === 0 ? textColorSelected : textColorUnselected, margin: 0, borderRadius: "5px 0 0 5px"
              }}
              onClick={() => { setvalue(0) }}>Charging Power</Button>

            <Button variant="contained"
              disabled={value === 1}
              style={{
                backgroundColor: value === 1 ? backgroundColorSelected : backgroundColorUnselected,
                color: value === 1 ? textColorSelected : textColorUnselected, margin: 0, borderRadius: "0"
              }}

              onClick={() => { setvalue(1) }}>Energy</Button>
            <Button variant="contained"

              disabled={value === 2}
              style={{
                backgroundColor: value === 2 ? backgroundColorSelected : backgroundColorUnselected,
                color: value === 2 ? textColorSelected : textColorUnselected, margin: 0, borderRadius: "0"
              }}
              onClick={() => { setvalue(2) }}>SoC</Button>

            <Button variant="contained"
              disabled={value === 3}
              style={{
                backgroundColor: value === 3 ? backgroundColorSelected : backgroundColorUnselected,
                color: value === 3 ? textColorSelected : textColorUnselected, margin: 0, borderRadius: "0"
              }}
              onClick={() => { setvalue(3) }}>Temperature</Button>

            <Button variant="contained"
              disabled={value === 4}
              style={{
                backgroundColor: value === 4 ? backgroundColorSelected : backgroundColorUnselected,
                color: value === 4 ? textColorSelected : textColorUnselected, margin: 0, borderRadius: "0", boxShadow: 0
              }}
              onClick={() => { setvalue(4) }}>Voltage</Button>

            <Button variant="contained"
              disabled={value === 5}
              style={{
                backgroundColor: value === 5 ? backgroundColorSelected : backgroundColorUnselected,
                color: value === 5 ? textColorSelected : textColorUnselected, margin: 0, borderRadius: "0 5px  5px 0"
              }}
              onClick={() => { setvalue(5) }}>Current</Button>

          </Stack>

          <Grid textAlign='left' padding={'15px'}>
            <div style={{ padding: '2px', display: 'inline-block', margin: '5px', maxHeight: '50px', backgroundColor: backgroundColorUnselected, borderRadius: '10px' }}>
              <Typography style={{ color: darkTheme ? '#F2E34C' : '#000' }}>
                <span style={{ borderRadius: '10px', padding: '2px', display: 'inline-block', margin: '5px', fontSize: '16px' }}>
                  Date: {transactionLogData.length && (
                    <span style={{ padding: '5px', display: 'inline-block', margin: '5px' }}>
                      {/* {new Date(transactionLogData[0].value_timestamp).toDateString()} */}
                      {DateTime.fromISO(transactionLogData[0].value_timestamp).setZone(orgTimezone).toFormat('dd-MM-yyyy')}
                    </span>
                  )}
                </span>
                <span style={{ padding: '2px', borderRadius: '10px', display: 'inline-block', margin: '5px', fontSize: '16px' }}>
                  Duration: {transactionLogData.length && (
                    <span style={{ padding: '2px', display: 'inline-block', margin: '5px' }}>
                      {/* {((new Date(transactionLogData[transactionLogData.length - 1].value_timestamp).getTime()
                        - new Date(transactionLogData[0].value_timestamp).getTime()) / 60000).toFixed(2)} Mins */}
                        {((DateTime.fromISO(transactionLogData[transactionLogData.length - 1].value_timestamp).setZone(orgTimezone).toMillis()
                          - DateTime.fromISO(transactionLogData[0].value_timestamp).setZone(orgTimezone).toMillis()) / 60000).toFixed(2)} Mins
                    </span>
                  )}
                </span>
                <span style={{ padding: '2px', borderRadius: '10px', display: 'inline-block', margin: '5px', fontSize: '16px' }}>
                  Transaction ID: <span style={{ padding: '2px', display: 'inline-block', margin: '5px' }}>{selectedTransactionPk}</span>
                </span>
              </Typography>
            </div>
          </Grid> 


          {value === 0 ? (loading ? <CircularProgress /> : <ChargingPortChart darkTheme={darkTheme}
            data={transactionLogData.filter(obj => obj => obj.unit === "W"
              && obj.connector_pk === connectorPk)} />) : null}

          {value === 1 ? (loading ? <CircularProgress /> : <EnergyChart darkTheme={darkTheme}
            data={transactionLogData.filter(obj => obj.measurand === "Energy.Active.Import.Register"
              && obj.connector_pk === connectorPk
            )}
          />) : null}

          {value === 2 ? (loading ? <CircularProgress /> : <SocChart darkTheme={darkTheme}
            data={transactionLogData.filter(obj => obj.measurand === "SoC"
              && obj.connector_pk === connectorPk
            )}
          />) : null}

          {value === 3 ? (loading ? <CircularProgress /> : <TempChart darkTheme={darkTheme}
            data={transactionLogData.filter(obj => obj.measurand === "Temperature"
              && obj.connector_pk === connectorPk
            )}
          />) : null}

          {value === 4 ? (loading ? <CircularProgress /> : <VoltageChart darkTheme={darkTheme}
            data={transactionLogData.filter(obj => obj.measurand === "Voltage"
              && obj.connector_pk === connectorPk
            )}
          />) : null}

          {value === 5 ? (loading ? <CircularProgress /> : <Currentchart darkTheme={darkTheme}
            data={transactionLogData.filter(obj => obj.unit === "A"
              && obj.connector_pk === connectorPk
            )}
          />) : null}
        </Paper>

      </Grid>
    </div>
  );
}

export default ChargerDetail;