import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { StyledTable } from 'app/utils/utils';
import { Grid, TablePagination, Tooltip } from '@mui/material';
import DepartmentApi from './DepartmentApi';
import DeleteIcon from '@mui/icons-material/Delete';
import UpdateDepartment from './UpdateDepartment';
import { ToastContainer, toast } from 'react-toastify';
import { useThemeContext } from 'app/ThemeProvider';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Permissions } from './Permission';

export default function DepartmentTable({ refresh, setRefresh }) {

    const [rows, setRow] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const { darkTheme } = useThemeContext();
    const user = JSON.parse(localStorage.getItem('user'));

    const api = new DepartmentApi();

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleDelete = (id) => {

        api
            .delete({ id: id })
            .then(({ data }) => {
                setRow(rows.filter(row=>row.id!==id));
                toast.success('Successfully deleted');

            })
            .catch((e) => {
                toast.error("Remove/Shift all users from this department.");
            })

    }

    React.useEffect(() => {

        if (!refresh) {
            fetchAllDepartment();
        }

    }, [refresh])

    const fetchAllDepartment = async () => {

        debugger;

        api
            .getByOrg({ id: user.orgId })
            .then(({ data }) => {
                if (data) {
                    setRow(data);
                }
            })
            .catch((e) => {
            })
    };


    const backgroundColor = darkTheme ? '#333333' : '#f9f9f9';
    const backgroundColorOdd = darkTheme ? '#333333' : '#f9f9f9';
    const backgroundColorEven = darkTheme ? '#474747' : '#fff';
    const textColor = darkTheme ? '#F2E34C' : 'black';
    const cellColor = darkTheme ? '#fff' : 'black';


    return (
        <Grid height={"100%"} width={"100%"}>
            <Paper sx={{ width: '100%', overflow: 'hidden', backgroundColor: darkTheme? '#333333'  : 'white' }}>
                <TableContainer style={{ height: "60vh", backgroundColor: darkTheme? '#333333'  : 'white' }}>
                    <StyledTable stickyHeader aria-label="a dense table" sx={{ minWidth: 850 }}>
                        <TableHead sx={{ backgroundColor: darkTheme? '#333333'  : 'white' }}>
                            <TableRow>
                                <TableCell style={{ paddingLeft: "20px", backgroundColor: darkTheme ? '#333333' : '#f9f9f9', color: darkTheme ? '#F2E34C' : '#000', fontSize: "16px" }} width={120} >Department ID</TableCell>
                                <TableCell style={{ backgroundColor: darkTheme ? '#333333' : '#f9f9f9', color: darkTheme ? '#F2E34C' : '#000', fontSize: "16px" }} align="center" width={200}>Department Name</TableCell>
                                <TableCell style={{ backgroundColor: darkTheme ? '#333333' : '#f9f9f9', color: darkTheme ? '#F2E34C' : '#000', fontSize: "16px" }}align="center" width={200}>Action</TableCell>
                                <TableCell style={{ backgroundColor: darkTheme ? '#333333' : '#f9f9f9', color: darkTheme ? '#F2E34C' : '#000', fontSize: "16px" }}align="center" width={200}>Action</TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>

                            {rows && rows.map((row, index) => (
                                <TableRow
                                    key={row.source}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    style={{ backgroundColor: darkTheme ? `${index % 2 !== 0 ? ('#333333') : ('#474747')}` : `${index % 2 !== 0 ? ('#e0e0e0') : ('#f9f9f9')}` }}
                                >

                                    <TableCell style={{ paddingLeft: "20px", backgroundColor: darkTheme ? '#333333' : '#f9f9f9', color: darkTheme ? '#fff' : '#000', fontSize: "16px" }} component="th" scope="row">
                                        {row.id}
                                    </TableCell>
                                    <TableCell style={{ backgroundColor: darkTheme ? '#333333' : '#f9f9f9', color: darkTheme ? '#fff' : '#000', fontSize: "16px" }} align="center" width={200}>{row.name}</TableCell>
                                    
                                    <TableCell style={{ backgroundColor: darkTheme ? '#333333' : '#f9f9f9', color: darkTheme ? '#fff' : '#000', fontSize: "16px" }}align="center" width={200}>
                                        <Tooltip title="Permissions" >

                                            <Permissions departmentId={row.id}/>

                                        </Tooltip>
                                        
                                    </TableCell>
                                    
                                    <TableCell style={{ backgroundColor: darkTheme ? '#333333' : '#f9f9f9', color: darkTheme ? '#fff' : '#000', fontSize: "16px" }}align="center" width={200}>
                                        <Tooltip title="Update" >

                                        <UpdateDepartment row={row} setRefresh={setRefresh}/>
                                        </Tooltip>
                                        <Tooltip title="Delete" >
                                            <DeleteIcon color='error' onClick={() => handleDelete(row.id)} />
                                        </Tooltip>
                                    </TableCell>

                                </TableRow>
                            ))}
                        </TableBody>

                    </StyledTable>
                </TableContainer>

                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 50]} // Customize the available rows per page options
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    SelectProps={{
                        style: { color: darkTheme ? '#fff' : '#000' }, // Change the text color of the page size select
                        IconComponent: (props) => (
                          <ArrowDropDownIcon style={{ fill: darkTheme ? '#fff' : '#000' }} /> 
                        )
                      }}
                      labelRowsPerPage={<span style={{ color: darkTheme ? '#fff' : '#000' }}>Rows per page:</span>} // Change the text color of the pagination caption
                      rowsPerPageOptionsLabel={<span style={{ color: darkTheme ? '#fff' : '#000' }}>Rows per page</span>} 
                      labelDisplayedRows={({ from, to, count }) => (
                        <span style={{ color: darkTheme ? '#fff' : '#000' }}>{`${from}-${to} of ${count}`}</span> // Change the color of the text "1-10 of 10"
                      )}
                      nextIconButtonProps={{ 'aria-label': 'Next Page', style: { color: darkTheme ? '#fff' : '#000' } }}
                      backIconButtonProps={{ 'aria-label': 'Previous Page', style: { color: darkTheme ? '#fff' : '#000' } }}
                />
            </Paper>
        </Grid>
    );
}