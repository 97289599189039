import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/system';
import ReactEcharts from 'echarts-for-react';
import { PieChart, Pie, Cell, Legend, Tooltip } from 'recharts';
import { useThemeContext } from 'app/ThemeProvider';
import ReactApexChart from 'react-apexcharts';

import DashboardApi from '../DashboardApi';
import Loading from 'app/components/MatxLoading';

const colors = [
  { color: "blue" },
  { color: "green" },
  { color: "red" },
  { color: "orange" },
  { color: "brown" },
  { color: "yellow" }
];

const DoughnutChart = ({ height, color = [], darkTheme }) => {
  const theme = useTheme();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const api = new DashboardApi();
  const textColor = darkTheme ? '#fff' : '#000'; // Conditionally set text color
  const options = {
    chart: {
      type: 'donut',
    },
    plotOptions: {
      pie: {
        donut: {
          size: '65%'
        },
        customScale: 0.8
      },
    },
    legend: {
      show: true,
      position: 'bottom',
      horizontalAlign: 'center',
      fontSize: '14px',
      color: textColor,
      formatter: function (seriesName, opts) {
        return [`<b style="font-size:20px;color:${textColor}">${opts.w.globals.series[opts.seriesIndex]} &nbsp;</b>`,
        `<span style="color: ${textColor};">${seriesName}</span>`
        ]

      },
      markers: {
        width: 16,
        height: 16,
        radius: 12,
      },
      itemMargin: {
        horizontal: 5,
        vertical: 1,
      }
    },
    colors: ['#008000', '#4169e1', '#FF5733', '#4BC0C0', '#878E98', '#daa520', '#daa520'],
    series: data !== null ? data.map(obj => obj.value) : [],
    labels: data !== null ? data.map(obj => obj.name) : [],
    dataLabels: {
      enabled: true,
      formatter: function (val, opts) {
        return opts.w.config.series[opts.seriesIndex];
      },
      style: {
        fontSize: '14px',
        fontFamily: 'Helvetica, Arial, sans-serif',
        color: 'textColor'
      },
    },
  };

  const loadData = () => {
    api
      .getChargerStatus()
      .then(({ data }) => {
        setData(data);
        setLoading(false);
      })
      .catch((e) => {
        setError(true);
      });
  }

  useEffect(() => {
    setLoading(true);
    loadData();
    const intervalId = setInterval(() => {
      loadData();
    }, 10000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (

    <div style={{ height: "calc(100% - 80px)" }}>
      {
        loading ?
          <Loading /> :
          <ReactApexChart
            options={options}
            series={options.series}
            type="donut"
            height={"100%"}
          />
      }
    </div>

  );
};

export default DoughnutChart;
