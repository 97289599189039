import React, { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import Autocomplete from '@mui/material/Autocomplete';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle'
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { ToastContainer, toast } from 'react-toastify';
import Checkbox from '@mui/material/Checkbox';

import DeleteIcon from '@mui/icons-material/Delete';
import UpdateIcon from '@mui/icons-material/Update';
import { useThemeContext } from 'app/ThemeProvider';

import { CircularProgress, FormControlLabel, FormGroup, Grid, InputLabel, TablePagination, Tooltip } from '@mui/material';
import Loading from 'app/components/MatxLoading';
import DepartmentApi from './DepartmentApi';

export const Permissions = ({ departmentId }) => {


    const api = new DepartmentApi();

    const [permissionList, setPermissionList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const { darkTheme } = useThemeContext();
    const [open, setOpen] = useState(false);
    const[includeCustom,setIncludeCustom]=useState(false);

    const handlePermission = (e, row, index) => {

        if (e.target.checked) {

            row.departmentId = departmentId;
            addDepartmentPermission(row);
        }
        else {
            deleteDepartmentPermission(row.id);
        }


    };

    const deleteDepartmentPermission = (id) => {

        api
            .deleteDepartmentPermission({ id: id,includeCustom:includeCustom })
            .then((response) => {
                toast.success('Permission Revoked Successfully');
            })
            .catch((e) => {
                toast.error('Something Went Wrong')
                // console.log('Cannot load event data', e);
            }).finally(() => fetchDepartmentPermission());
    }

    const addDepartmentPermission = (data) => {

        api
            .addDepartmentPermission(data)
            .then((response) => {
                toast.success('Permission Granted Successfully')

            })
            .catch((e) => {
                toast.error('Something Went Wrong')
                // console.log('Cannot load event data', e);
            }).finally(() => fetchDepartmentPermission());

    }

    useEffect(() => {
        if (open)
            fetchDepartmentPermission();

    }, [open]);

    const fetchDepartmentPermission = () => {

        setLoading(true)
        api
            .getAllDepartmentPermission({ departmentId: departmentId })
            .then((response) => {
                console.log("t", response)
                setPermissionList([]);
                setTimeout(() => setPermissionList(response.data), 50);



                setLoading(false)

                // setIsDisabled(false);

            })
            .catch((e) => {
                toast.error('Unable to load permissions')

            });
    }

    const handleClose = () => {
        setOpen(false);
        setPermissionList([])
    };

    return (
        <>


            <Button onClick={() => setOpen(!open)}>
                Permissions
            </Button>




            <Dialog
                open={open}
                fullWidth='md'
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                    style: {
                        backgroundColor: darkTheme ? '#333333' : '#fff',
                    },
                }}
            >
                <DialogTitle id="alert-dialog-title" style={{ color: darkTheme ? '#F2E34C' : '#000' }}>Permissions

                    <Grid>
                        <InputLabel>
                            Update Include Custom
                            <Checkbox
                                inputProps={{ 'aria-label': 'Checkbox demo' }}
                                value={includeCustom}
                                onClick={(e)=>setIncludeCustom(e.target.checked)}
                            />
                        </InputLabel>
                    </Grid>


                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">


                        {
                            loading ? <Grid
                                style={{ minHeight: "80vh", display: "flex", alignItems: "center", justifyContent: "center" }}

                            >
                                <Loading
                                />
                            </Grid> :

                                <TableContainer style={{ minHeight: "100vh" }} >



                                    <Table stickyHeader >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ backgroundColor: darkTheme ? '#333333' : '#f9f9f9', color: darkTheme ? '#F2E34C' : '#000', fontSize: "16px", paddingLeft: '5px' }}>Permission</TableCell>
                                                <TableCell style={{ backgroundColor: darkTheme ? '#333333' : '#f9f9f9', color: darkTheme ? '#F2E34C' : '#000', fontSize: "16px" }}>Permission Type</TableCell>
                                                {/* <TableCell>Read/Write</TableCell> */}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>

                                            {permissionList.map((row, index) => (
                                                <TableRow key={row.id}>
                                                    <TableCell>

                                                        <FormControlLabel
                                                            control={<Switch style={{ color: darkTheme ? '#fff' : '#000' }}
                                                                disabled={isDisabled}
                                                                checked={!(row.id === null)} onChange={(e) => handlePermission(e, row, index)} />}
                                                            label={<span style={{ color: darkTheme ? '#fff' : '#000' }}>{row.permissionName}</span>}
                                                            style={{ margin: 0, padding: 0 }}
                                                            labelPlacement="end"
                                                        />

                                                    </TableCell>
                                                    <TableCell style={{ color: darkTheme ? '#fff' : '#000' }} >{row.permissionType}</TableCell>
                                                    {/* <TableCell>
                                                <FormControlLabel
                                                    control={<Switch checked={row.write} onChange={handleReadWritePermission(row,index)} />}
                                                    label="Toggle Switch"
                                                    disabled
                                                    style={{margin:0,padding:0}}
                                                    labelPlacement="end"
                                                />
                                            </TableCell> */}
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                        }


                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    {/* <Button onClick={handleClose}>Disagree</Button> */}
                    <Button onClick={handleClose} autoFocus
                        style={{ background: '#e0e0e0', color: 'black' }}

                        variant='contained'>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}