import React, { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import Autocomplete from '@mui/material/Autocomplete';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle'
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Switch from '@mui/material/Switch';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import UserApi from './UserApi';
import DepoApi from '../depomgmt/DepoApi';
import { ToastContainer, toast } from 'react-toastify';
import DeleteIcon from '@mui/icons-material/Delete';
import UpdateIcon from '@mui/icons-material/Update';
import { useThemeContext } from 'app/ThemeProvider';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { FormControlLabel, FormGroup, Grid, TablePagination, Tooltip } from '@mui/material';
import UpdateUserForm from './UpdateUserForm';
import { Row } from './userListTableComponent/AlertRow';
import { AlertRow } from './userListTableComponent/AlertRow';



export default function AlertUserListTable({ refresh, setRefresh }) {


    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [APIData, setAPIData] = useState([]);
    const { darkTheme } = useThemeContext();
    const api = new UserApi();

    const handleChangePage = (_, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    useEffect(() => {
        const orgId = localStorage.getItem('orgId');
        api
            .getUserList(orgId)
            .then((response) => {
                setAPIData(response.data);
                console.log(response.data);
            })
            .catch((e) => {
                // console.log('Cannot load event data', e);
            });
    }, [refresh]);




    return (
        <span container style={{ width: "100%" }}>
            
            <TableContainer
                component={Paper}
                style={{
                    backgroundColor: darkTheme ? '#333333' : '#f9f9f9',
                    height: '70vh',
                    width: '100%',      // Ensure full width
                    overflowX: 'auto',  // Allow horizontal scrolling if needed
                }}
            >
                <Table 
                    aria-label="collapsible table" 
                    style={{ width: '100%', minWidth: '100%', tableLayout: 'auto' }} // Auto layout to expand dynamically
                >
                    <TableHead style={{ position: 'sticky', top: 0, backgroundColor: '#eeeeee', zIndex: 1 }}>
                        <TableRow style={{ backgroundColor: '#eeeeee' }}>
                            <TableCell width="5%"></TableCell>
                            <TableCell width="20%">Name</TableCell>
                            <TableCell width="25%">Email</TableCell>
                            <TableCell width="15%">Role</TableCell>
                            <TableCell width="12.5%">Email</TableCell>
                            <TableCell width="12.5%">SMS</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody style={{ backgroundColor: darkTheme ? '#333333' : '#f9f9f9' }}>
                        {APIData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                            <AlertRow
                                key={index}
                                row={row}
                                setRefresh={setRefresh}
                                page={page}
                                style={{
                                    backgroundColor: darkTheme
                                        ? index % 2 !== 0 ? '#333333' : '#474747'
                                        : index % 2 !== 0 ? '#e0e0e0' : '#f9f9f9'
                                }}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>


            <Grid container style={{ width: "100%" }} textAlign={""}>
                <Grid item md={12}>
                    <TablePagination
                        // sx={{ px: 2 }}
                        page={page}
                        component="div"
                        rowsPerPage={rowsPerPage}
                        count={APIData.length}
                        onPageChange={handleChangePage}
                        rowsPerPageOptions={[5, 10, 25, 50, 75, 100, 150, 200]}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        SelectProps={{
                            style: { color: darkTheme ? '#fff' : '#000' }, // Change the text color of the page size select
                            IconComponent: (props) => (
                                <ArrowDropDownIcon style={{ fill: darkTheme ? '#fff' : '#000' }} />
                            )
                        }}
                        labelRowsPerPage={<span style={{ color: darkTheme ? '#fff' : '#000' }}>Rows per page:</span>} // Change the text color of the pagination caption
                        rowsPerPageOptionsLabel={<span style={{ color: darkTheme ? '#fff' : '#000' }}>Rows per page</span>}
                        labelDisplayedRows={({ from, to, count }) => (
                            <span style={{ color: darkTheme ? '#fff' : '#000' }}>{`${from}-${to} of ${count}`}</span> // Change the color of the text "1-10 of 10"
                        )}
                        nextIconButtonProps={{ 'aria-label': 'Next Page', style: { color: darkTheme ? '#fff' : '#000' } }}
                        backIconButtonProps={{ 'aria-label': 'Previous Page', style: { color: darkTheme ? '#fff' : '#000' } }}
                    />

                </Grid>
            </Grid>

        </span>
    );
}