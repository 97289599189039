import React,{useState,useEffect} from 'react';
import ReactApexChart from 'react-apexcharts';
import ChargerApi from '../ChargerApi';

const BarChart = () => {

  const api = new ChargerApi();

  const [data,setData]=useState([]);

  useEffect(() => {
    const refreshInterval = 10000; // 5 seconds in milliseconds

    const refreshData = () => {
      // Your function to refresh data, e.g., chargerSession()
      chargerSession();
    };

    // Initial call
    refreshData();

    // Set up interval for continuous refresh
    const intervalId = setInterval(refreshData, refreshInterval);

    // Cleanup function to clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, []); // Empty dependency array means the effect runs once on mount



  const chargerSession=()=>{
    api.getChargersRuntime(null).
    then(({data})=>{
      setData(data);
    }).catch((e) => {
      // // console.log('Request history data', e);
    });
  }
  const chartData = Array.isArray(data)
  ? data.map((item) => ({
      x: item.charger,
      y: item.session,
    }))
  : [];

const options = {
  chart: {
    type: 'bar',
    height: 400,
    toolbar: {
      show: false, // Set to false to hide the toolbar (including the menu icon)
    },
  },
  grid: {
    xaxis: {
        lines: {
            show:false
        }
    },   
    yaxis: {
        lines: {
            show: false
        }
    }
},
  
  xaxis: {
    categories: chartData.map((item) => item.x),
    title: {
      text: 'Chargers', // Corrected x-axis title
    },
    style: {
      fontSize: '14px', // Customize font size, color, etc.
      color: '#333',
    },
  },
  yaxis: {
    title: {
      text: 'Running Hours',
    },
    max: 24,
  },
  colors: ['#337ab7'],
  tooltip: {
    // enabled: false, // Disable tooltip
    custom: function ({ series, seriesIndex, dataPointIndex, w }) {
      return '<div class="tooltip-custom">' + series[seriesIndex][dataPointIndex]  + '</div>';
    },
  },
};


  return (
    <div id="chart">
      <ReactApexChart options={options} series={[{ data: chartData }]} type="bar" height={280} />
    </div>
  );
};

BarChart.defaultProps = {
  data: [], // Provide a default value for data (an empty array)
};

export default BarChart;
