import { Card, Grid, styled, useTheme, Paper, Button, ButtonGroup, Typography, Box, Tab, Tabs } from '@mui/material';
import { Fragment, useState } from 'react';
import { componentStyle } from 'app/utils/utils';
import AddDepartment from './AddDepartment';
import DepartmentTable from './DepartmentTable';
import { useThemeContext } from 'app/ThemeProvider';


const ContentBox = styled('div')(({ theme }) => ({
    margin: '30px',
    [theme.breakpoints.down('sm')]: { margin: '16px' },
}));

const Title = styled('span')(() => ({
    fontSize: '1rem',
    fontWeight: '500',
    marginRight: '.5rem',
    textTransform: 'capitalize',
}));

const Analytics = () => {
    const { palette } = useTheme();
    const [table, setTable] = useState(true);
    const [value, setValue] = useState(0);
    const [refresh,setRefresh]=useState(false);
    const { darkTheme } = useThemeContext();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    return (
        <Grid
            margin={componentStyle.margin}
        >
            <Paper elevation={2} sx={{ backgroundColor: darkTheme ? '#333333' : '#fff' }}>
                <Grid container padding={"20px"} justifyContent="left" alignItems="center">

                    <Grid item xs={4} lg={4} md={4} >

                        <Typography variant="h6" component="div" sx={{ color: darkTheme ? '#f2e34c' : '#000' }}>
                            Departments
                        </Typography>

                    </Grid>

                    <Grid item xs={8} lg={8} md={8} >
                        <AddDepartment setRefresh={setRefresh} />

                    </Grid>
                </Grid>

                <Grid
                    container
                >
                    <DepartmentTable refresh={refresh} setRefresh={setRefresh} />
                </Grid>

            </Paper>
        </Grid>
    );
};
export default Analytics;
