// import { Grid, Typography } from '@material-ui/core';

// import { makeStyles } from '@material-ui/core/styles';
// import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
// import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';




// const useStyles = makeStyles((theme) => ({
//    root: {
//       flexGrow: 1,
//    },
//    paper: {
//       // padding: theme.spacing(1),
//       // textAlign: 'center',
//       // color: theme.palette.text.secondary,
//    },
// }));



// function App() {
//    const classes = useStyles();
//    return (
//       <>

//          <div className={classes.root} >
//             <Grid style={{ padding: '10px', borderRadius: '10px' }}  >
//                <Grid container spacing={2} item lg={12} md={12} sm={12} xs={12}>

//                   <Grid container spacing={3} item xs={6} md={6} style={{ padding: '10px', borderRadius: '10px' }}>
//                      <Grid item xs={1}>

//                         <Typography align='center' style={{ fontWeight: 'bold', fontSize: '20px' }}><LocalGasStationIcon style={{ fontSize: '50px' }} /></Typography>
//                      </Grid>

//                      <Grid item xs={1}>
//                         <Typography align='center' style={{ fontWeight: 'bold', fontSize: '20px' }}>1</Typography>
//                         <Typography align='center' style={{ fontWeight: 'bold', fontSize: '17px' }}>Available</Typography>
//                      </Grid>
//                      <Grid item xs={2}>
//                         <Typography align='center' style={{ fontWeight: 'bold', fontSize: '20px' }}>2</Typography>
//                         <Typography align='center' style={{ fontWeight: 'bold', fontSize: '17px' }}>Charging</Typography>
//                      </Grid>
//                      <Grid item xs={1}>
//                         <Typography align='center' style={{ fontWeight: 'bold', fontSize: '20px' }}>3</Typography>
//                         <Typography align='center' style={{ fontWeight: 'bold', fontSize: '17px' }}>Fault</Typography>
//                      </Grid>
//                      <Grid item xs={2}>
//                         <Typography align='center' style={{ fontWeight: 'bold', fontSize: '20px' }}>4</Typography>
//                         <Typography align='center' style={{ fontWeight: 'bold', fontSize: '17px' }}>Reserved</Typography>
//                      </Grid>
//                      <Grid item xs={1}>
//                         <Typography align='center' style={{ fontWeight: 'bold', fontSize: '20px' }}>5</Typography>
//                         <Typography align='center' style={{ fontWeight: 'bold', fontSize: '17px' }}>Offline</Typography>
//                      </Grid>
//                      <Grid item xs={2}>
//                         <Typography align='center' style={{ fontWeight: 'bold', fontSize: '20px' }}>10</Typography>
//                         <Typography align='center' style={{ fontWeight: 'bold', fontSize: '17px' }}>Managed</Typography>
//                      </Grid>
//                      <Grid item xs={2}>
//                         <Typography align='center' style={{ fontWeight: 'bold', fontSize: '20px' }}>10</Typography>
//                         <Typography align='center' style={{ fontWeight: 'bold', fontSize: '17px' }}>UnManaged</Typography>
//                      </Grid>

//                   </Grid>


//                   <Grid container spacing={2} item xs={6} md={6} style={{ padding: '10px', borderRadius: '10px' }} >
//                      <Grid item xs={1}>

//                         <Typography align='center' style={{ fontWeight: 'bold', fontSize: '20px' }}><DirectionsBusIcon style={{ fontSize: '50px' }} /></Typography>
//                      </Grid>

//                      <Grid item xs={1}>
//                         <Typography align='center' style={{ fontWeight: 'bold', fontSize: '20px' }}>5</Typography>
//                         <Typography align='center' style={{ fontWeight: 'bold', fontSize: '17px' }}>Ready</Typography>
//                      </Grid>
//                      <Grid item xs={2}>
//                         <Typography align='center' style={{ fontWeight: 'bold', fontSize: '20px' }}>6</Typography>
//                         <Typography align='center' style={{ fontWeight: 'bold', fontSize: '17px' }}>Charging</Typography>
//                      </Grid>
//                      <Grid item xs={2}>
//                         <Typography align='center' style={{ fontWeight: 'bold', fontSize: '20px' }}>7</Typography>
//                         <Typography align='center' style={{ fontWeight: 'bold', fontSize: '17px' }}>Queued</Typography>
//                      </Grid>
//                      <Grid item xs={2}>
//                         <Typography align='center' style={{ fontWeight: 'bold', fontSize: '20px' }}>8</Typography>
//                         <Typography align='center' style={{ fontWeight: 'bold', fontSize: '17px' }}>Out of Service</Typography>
//                      </Grid>
//                      <Grid item xs={2}>
//                         <Typography align='center' style={{ fontWeight: 'bold', fontSize: '20px' }}>9</Typography>
//                         <Typography align='center' style={{ fontWeight: 'bold', fontSize: '17px' }}>Parked</Typography>
//                      </Grid>
//                      <Grid item xs={2}>
//                         <Typography align='center' style={{ fontWeight: 'bold', fontSize: '20px' }}>10</Typography>
//                         <Typography align='center' style={{ fontWeight: 'bold', fontSize: '17px' }}>Driving</Typography>
//                      </Grid>
//                   </Grid>


//                </Grid>

//             </Grid>


//          </div>
//       </>
//    );
// }

// export default App;




import { Card, Grid, styled, useTheme } from '@mui/material';
import { CardContent, Typography } from '@material-ui/core';
// import MapObjectView from './shared/MapObjectView';
import { Map, MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import React, { Fragment, useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import DepotSetupApi from '../setdepot/DepotSetupApi';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import EvStationIcon from '@mui/icons-material/EvStation';
import FormControl from '@mui/material/FormControl';
import DashboardApi from '../dashboard/DashboardApi';
import InputLabel from '@mui/material/InputLabel';
import { Link } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Campaigns from '../dashboard/shared/Campaigns';
import ChargingBay from '../depoView/components/ChargingBay';
import ConfirmModal from '../dashboard/ConfirmModal';

const ContentBox = styled('div')(({ theme }) => ({
   margin: '30px',
   [theme.breakpoints.down('sm')]: { margin: '16px' },
}));
const Root1 = styled('div')(({ theme }) => ({
   padding: theme.spacing(1),
   [theme.breakpoints.down('md')]: {
      // backgroundColor: red[500],
      width: 150,

      textAlign: 'center',
      marginLeft: 20,
      color: 'black',
      display: 'flex',
   },
   [theme.breakpoints.up('md')]: {
      // backgroundColor: blue[500],
      width: 200,
      // textAlign: "right",

      marginLeft: 20,
      color: 'black',
      display: 'flex',
   },
   [theme.breakpoints.up('lg')]: {
      width: 800,
      marginLeft: 150,
      color: 'black',
      display: 'flex',
   },
}));
const Root2 = styled('div')(({ theme }) => ({
   padding: theme.spacing(1),
   [theme.breakpoints.down('md')]: {
      // backgroundColor: red[500],
      width: 150,

      textAlign: 'center',
      marginLeft: 20,
      color: 'black',
      display: 'flex',
   },
   [theme.breakpoints.up('md')]: {
      // backgroundColor: blue[500],
      width: 200,
      // textAlign: "right",

      marginLeft: 20,
      color: 'black',
      display: 'flex',
   },
   [theme.breakpoints.up('lg')]: {
      width: 800,
      marginLeft: 20,
      color: 'black',
      display: 'flex',
   },
}));
const Root3 = styled('div')(({ theme }) => ({
   padding: theme.spacing(1),
   [theme.breakpoints.down('md')]: {
      // backgroundColor: red[500],
      // width: 150,
      // textAlign: "center",
      marginLeft: 250,
      fontSize: '20px',
      fontWeight: 'bold ',
      color: 'black',
   },
   [theme.breakpoints.up('md')]: {
      // backgroundColor: blue[500],
      // width: 200,
      // textAlign: "right",
      marginLeft: 500,
      fontSize: '25px',
      fontWeight: 'bold ',
      color: 'black',
   },
   [theme.breakpoints.up('lg')]: {
      // backgroundColor: green[500],
      width: 500,
      // textAlign: "left",
      marginLeft: 800,
      marginTop: 40,
      color: 'black',
      fontSize: '27px',
      fontWeight: 'bold ',
   },
}));

const Root4 = styled('div')(({ theme }) => ({
   padding: theme.spacing(1),
   [theme.breakpoints.down('md')]: {
      marginLeft: 20,
      fontSize: '18px',
      fontWeight: 'bold ',
      color: 'black',
   },
   [theme.breakpoints.up('md')]: {
      marginLeft: 20,
      fontSize: '20px',
      fontWeight: 'bold ',
      color: 'black',
   },
   [theme.breakpoints.up('lg')]: {
      width: 500,
      marginLeft: 20,
      marginTop: 5,
      color: 'black',
      fontSize: '20px',
      fontWeight: 'bold ',
   },
}));

const Root5 = styled('div')(({ theme }) => ({
   // padding: theme.spacing(1),
   [theme.breakpoints.down('md')]: {
      marginLeft: 5,
      fontSize: '20px',
      fontWeight: 'bold ',
      color: 'black',
      display: 'flex',
   },
   [theme.breakpoints.up('md')]: {
      marginLeft: 5,
      fontSize: '25px',
      fontWeight: 'bold ',
      color: 'black',
      display: 'flex',
   },
   [theme.breakpoints.up('lg')]: {
      marginLeft: 5,
      color: 'black',
      fontSize: '20px',
      fontWeight: 'bold ',
      display: 'flex',
   },
}));

const Root6 = styled('div')(({ theme }) => ({
   padding: theme.spacing(1),
   [theme.breakpoints.down('md')]: {
      // backgroundColor: red[500],
      // width: 150,
      // textAlign: "center",
      marginLeft: 50,
      fontSize: '20px',
      fontWeight: 'bold ',
      color: 'black',
      // display: "flex"
      flexDirection: 'column',
   },
   [theme.breakpoints.up('md')]: {
      // backgroundColor: 'blue',
      // width: 200,
      // textAlign: "right",
      // marginLeft: 50,
      fontSize: '25px',
      fontWeight: 'bold ',
      color: 'black',
      flexDirection: 'column',
      // display: "flex"
   },
   [theme.breakpoints.up('lg')]: {
      // backgroundColor: 'green',
      // width: 500,
      // textAlign: "left",
      // marginLeft: 100,
      // marginTop: 80,
      // float: 'center',
      color: 'black',
      fontSize: '40px',
      fontWeight: 'bold ',
      display: 'flex',
   },
}));

const Root7 = styled('div')(({ theme }) => ({
   //padding: theme.spacing(1),
   [theme.breakpoints.down('md')]: {
      marginTop: '-20px',
      marginLeft: '25px',
      width: 300,
      fontSize: '20px',
      fontWeight: 'bold ',
      color: 'black',
      display: 'flex',
      flexDirection: 'column',
   },
   [theme.breakpoints.up('md')]: {
      textAlign: 'right',
      marginLeft: '25px',
      marginTop: '-20px',
      fontSize: '20px',
      fontWeight: 'bold ',
      color: 'black',
      display: 'flex',
   },
   [theme.breakpoints.up('lg')]: {
      textAlign: 'right',
      marginTop: '-20px',
      fontSize: '20px',
      fontWeight: 'bold ',
      color: 'black',
      display: 'flex',
   },
}));

const Header = () => {
   const { palette } = useTheme();
   const [depos, setDepot] = useState([]);
   const api1 = new DepotSetupApi();
   const [depotId, setDepotId] = React.useState(0);
   const [depotName, setDepotName] = React.useState('');
   const [position, setCoords] = useState([28.5355, 77.391]);
   const [confirmOpen, setConfirmOpen] = useState(false);

   const [totalVehicle, setTotalVehicle] = React.useState(0);
   const [totalCharger, setTotalCharger] = React.useState(0);
   const [charging, setCharging] = useState(0);
   const [charged, setCharged] = useState(0);
   const [chargedDelay, setChargedDelay] = useState(0);
   const [statusData, setStatusData] = useState(null)

   const api2 = new DashboardApi();
   useEffect(() => {
      const intervalId = setInterval(() => {
         check_prv_depot();
         fetchFleetStatus();
         fetchDepotList();
       
      }, 10000);
      return () => {
         clearInterval(intervalId);
      };
   }, []);

   const fetchFleetStatus=()=>{
      let isCanceled = false;

      api2
      .getFleetStatus(null)
      .then(({ data }) => {
         if (!isCanceled) {
            setCharging(data.fleet_details.charging);
            setCharged(data.fleet_details.chargedTime);
            setChargedDelay(data.fleet_details.chargeDelay);
         }
      })
      .catch((e) => {
         // console.log('Request history data', e);
      });
   }

   const handleChange = (event, index) => {
      setDepotId(event.target.value);
      setDepotName(index.props.children);
      localStorage.setItem('selected_depot', event.target.value);
      localStorage.setItem('selected_depot_name', index.props.children);
      countDepotChargerVech(event.target.value);
      depos.map(function (d) {
         if (d.id === event.target.value) {
            setCoords([d.latitude, d.longitude]);
            return;
         }
      });

      window.location.reload();
   };

   useEffect(() => {



   }, []);


   useEffect(() => {

      fetchDepotList();
      check_prv_depot();
      fetchFleetStatus();

      // console.log("depos" + depos.length);


      if (localStorage.getItem("selected_depot") !== undefined) {
         setDepotId(localStorage.getItem("selected_depot"));
      }

   }, [depos.length]);





   useEffect(() => {
      const intervalId = setInterval(() => {
         let isCanceled = false;
         api2
            .getChargerStatus()
            .then(({ data }) => {
               if (!isCanceled) {
                  // console.log(data)
                  setStatusData(data);
               }
            })
            .catch((e) => {
               // console.log('Request history data', e);
            });
      }, 10000);

      return () => {
         clearInterval(intervalId);
         // source.cancel();
         // isCanceled = true;
      };
   }, [depotId]);

   const handleSetupDepot = () => {
      setConfirmOpen(false);
   };

   const fetchDepotList = () => {

      const user = JSON.parse(localStorage.getItem("user"));
      // console.log(user.username + " " + depos);
      if (user === undefined || user === null) {
         return alert("Login again");
      }
      var state = {};
      state.username = user.username.trim();
      api1
         .getPermittedDepotList2()
         .then((response) => {

            setDepot(response.data.body);
            if (response.data.body.length <= 0) {
               setConfirmOpen(true);
            }

         })
         .catch((e) => {
            // console.log('Cannot load event data', e);
         });
   };

   const check_prv_depot = () => {
      const prv_depotId = localStorage.getItem('selected_depot');
      if (prv_depotId) {
         setDepotId(prv_depotId);
         countDepotChargerVech(prv_depotId);
      }
   };

   const countDepotChargerVech = (depotId) => {
      api1
         .countDepotChargerVech(null, depotId)
         .then((response) => {
            setTotalVehicle(response.data.v_count);
            setTotalCharger(response.data.c_count);
         })
         .catch((e) => {
            // console.log('Cannot load event data', e);
         });
   };


   return (
         <Grid container rowSpacing={1}
            // style={{ backgroundColor: '#EEEEEE', border: '1px solid gray', borderRadius: '20px', padding: '10px' }}
            >

            <Grid item lg={1.5} md={1.5}
            >

               <FormControl fullWidth >
                  <InputLabel id="demo-simple-select-label">Depot</InputLabel>
                  <Select labelId="demo-simple-select-label"
                     id="demo-simple-select"
                     value={depotId}
                     label="Depot"
                     onChange={handleChange}
                     fullWidth
                     style={{padding:"2px 2px 2px 2px"}}
                  >

                     {depos.map((c, index) => (
                        <MenuItem style={{display:"block",padding:"10px"}} value={c.depotId}>{c.depotName}</MenuItem>
                     ))}
                  </Select>
               </FormControl>
            </Grid>
            <Grid item xs={10.5} lg={10.5} 
               justifyContent={"flex-end"} justifyItems={"flex-end"}>
               <Root5>


                  <div style={{ display: "flex", alignItems: "center" }}>
                     <DirectionsBusIcon style={{ marginLeft: '', fontSize: '50px' }} />
                     <span>{totalVehicle}</span>
                  </div>



                  <div style={{ marginLeft: '2px', display: "flex", alignItems: "center" }}>
                     <b style={{ marginLeft: '20px', fontSize: '12px' }}>Charging</b>
                     <b style={{ marginLeft: '3px', fontSize: '20px' }}> {charging}</b>

                  </div>

                  <div style={{ marginLeft: '2px', display: "flex", alignItems: "center" }}>
                     <b style={{ marginLeft: '20px', fontSize: '12px' }}>Connected</b>
                     <b style={{ marginLeft: '3px', fontSize: '20px' }}> {charged}</b>

                  </div>

                  <div style={{ marginLeft: '2px', display: "flex", alignItems: "center" }}>
                     <b style={{ marginLeft: '20px', fontSize: '12px' }}>Driving</b>
                     <b style={{ marginLeft: '3px', fontSize: '20px' }}>{chargedDelay}</b>

                  </div>

                  <div style={{ marginLeft: '2px', display: "flex", alignItems: "center" }}>
                     <b style={{ marginLeft: '20px', fontSize: '12px' }}>Parked</b>
                     <b style={{ marginLeft: '3px', fontSize: '20px' }}> {charged}</b>

                  </div>

                  <div style={{ marginLeft: '2px', display: "flex", alignItems: "center" }}>
                     <b style={{ marginLeft: '20px', fontSize: '12px' }}>Under Maintenance</b>
                     <b style={{ marginLeft: '3px', fontSize: '20px' }}>{chargedDelay}</b>

                  </div>

                  <div style={{ marginLeft: '2px', display: "flex", alignItems: "center" }}>
                     &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
                  </div>



                  <div style={{ marginLeft: '2px', display: "flex", alignItems: "center" }}>
                     <EvStationIcon style={{ fontSize: '50px' }} />
                     <span>{totalCharger}</span>
                  </div>

                  <div style={{ marginLeft: '2px', display: "flex", alignItems: "center" }}>
                     <b style={{ marginLeft: '20px', fontSize: '12px' }}>Charging</b>
                     <b style={{ marginLeft: '3px', fontSize: '20px' }}>{statusData !== null ? statusData[0].value : 0}</b>

                  </div>
                  <div style={{ marginLeft: '2px', display: "flex", alignItems: "center" }}>
                     <b style={{ marginLeft: '20px', fontSize: '12px' }}>Available</b>
                     <b style={{ marginLeft: '3px', fontSize: '20px' }}>{statusData !== null ? statusData[1].value : 0}</b>

                  </div>
                  <div style={{ marginLeft: '2px', display: "flex", alignItems: "center" }}>
                     <b style={{ marginLeft: '20px', fontSize: '12px' }}>Faulted</b>
                     <b style={{ marginLeft: '3px', fontSize: '20px' }}>{statusData !== null ? statusData[2].value : 0}</b>

                  </div>
                  <div style={{ marginLeft: '2px', display: "flex", alignItems: "center" }}>
                     <b style={{ marginLeft: '20px', fontSize: '12px' }}>Reserved</b>
                     <b style={{ marginLeft: '3px', fontSize: '20px' }}>{statusData !== null ? statusData[3].value : 0}</b>

                  </div>
                  <div style={{ marginLeft: '2px', display: "flex", alignItems: "center" }}>
                     <b style={{ marginLeft: '20px', fontSize: '12px' }}>Unavailable</b>
                     <b style={{ marginLeft: '3px', fontSize: '20px' }}>{statusData !== null ? statusData[4].value : 0}</b>

                  </div>



               </Root5>

            </Grid>

         </Grid>
   );
};
export default Header;


