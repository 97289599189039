import React, { useEffect, useState, useRef } from 'react';
import ReactECharts from 'echarts-for-react';
import { chartOptions } from 'app/utils/utils';
import ChargerApi from '../../ChargerApi';
import { Grid, Stack, Paper, Typography, Card, Button } from '@mui/material'
import Loading from 'app/components/MatxLoading';


const WeeklyConsumptionChart = ({ row, setSelectedTransactionPk, connectorPk, setConnectorPk, darkTheme }) => {
  const api = new ChargerApi();
  const [weeklyData, setWeeklyData] = useState([]);
  const [loading, setLoading] = useState(true);
  const chartRef = useRef(null);
  const [backupData, setBackupData] = useState([]);
  const [connectorId, setConnectorId] = useState(1);


  useEffect(() => {
    fetchWeeklyCost();

    // const intervalId = setInterval(() => {
    //   fetchWeeklyCost();
    // }, 300000);

    // return () => {
    //   clearInterval(intervalId);
    // };
  }, []);

  const fetchWeeklyCost = () => {
    api.getChargerWeeklyTransaction({ chargerBoxPk: row.chargeBoxPk })
      .then(({ data }) => {
        console.log(data);

        // setBackupData(data)
        const d = data.sort((o1, o2) => parseInt(o1.duration) - parseInt(o2.duration)) ?? []
        setWeeklyData(d);
        let temp = d.filter(o => o.connector_pk === row.connectorList[1]?.connectorPk);
        if (temp.length === 0)
          temp = d.filter(o => o.connector_pk === row.connectorList[2]?.connectorPk);

        setSelectedTransactionPk(temp[temp.length - 1].transaction_pk);

        setLoading(false);
      })
      .catch((e) => {
        console.log('Request history data', e);
      });
  };

  const textColor = darkTheme ? '#F2E34C' : '#000'; // Conditionally set text color
  const titleColor = darkTheme ? '#F2E34C' : '#000';
  const tooltipColor = darkTheme ? '#333333' : '#ffffff';
  const labelColor = darkTheme ? '#000' : '#6a7985';
  const backgroundColorSelected = darkTheme ? '#F2E34C' : 'black'; // Background color of selected tab
  const backgroundColorUnselected = darkTheme ? '#334155' : '#d9d9d9';
  const textColorSelected = darkTheme ? 'black' : 'white'; // Text color of selected tab
  const textColorUnselected = darkTheme ? 'white' : 'black';


  const options = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        label: {
          backgroundColor: labelColor,
          color: textColor
        }
      },
      backgroundColor: tooltipColor,
      textStyle: {
        color: darkTheme ? '#F2E34C' : '#000' // Set text color based on theme
      },
      formatter: function (params) {
        return params.map(function (item) {
          return item.axisValueLabel + '<br/>' + item.seriesName + ': ' + item.value.toLocaleString() + ' mins';
        }).join('<br>');
      }
    },

    dataZoom: [
      {
        show: true,
        realtime: true,
        // start: 65,
        // end: 85

      },
      {
        type: 'inside',
        realtime: true,
        // start: 65,
        // end: 85
      }
    ],
    grid: {
      left: '6%',
      right: '4%',
      bottom: '10%',
      containLabel: true
    },
    toolbox: {
      show: true,
      top: '10px',
      right: 45,
      feature: {
        saveAsImage: {
          show: true,
          title: 'Download',
          name: 'Charger Connector Info',
          type: 'png'
        }
      },
      iconStyle: {
        borderColor: darkTheme ? '#F2E34C' : '#000'
      }
    },
    xAxis: {
      type: 'category',
      data: weeklyData ? weeklyData.filter(obj => obj.connector_pk === connectorPk).map(obj => obj.transaction_pk) : [],
      name: 'Transactions',
      axisLabel: {
        inside: true,
        color: darkTheme ? 'yellow' : '#000',
        rotate: 90,
        fontWeight: 'bold',
        fontSize: 16
      },
      axisTick: {
        show: false
      },
      axisLine: {
        show: false
      },
      z: 10,
      nameLocation: 'middle',
      nameGap: 15,
      nameTextStyle: {
        ...chartOptions().nameTextStyle,
        color: textColor // Change text color based on dark theme
      },
    },
    yAxis: {
      type: 'value',
      name: 'Charging Duration (Mins)',
      nameLocation: 'middle',
      nameGap: 40,
      nameTextStyle: {
        ...chartOptions().nameTextStyle,
        color: textColor // Change text color based on dark theme
      },
      axisLabel: chartOptions().axisLabel,
      axisLabel: {
        color: textColor // Set text color based on theme
      }
    },
    series: [
      {
        name: 'Values',
        type: 'bar',
        data: weeklyData ? weeklyData.filter(obj => obj.connector_pk === connectorPk).map(obj => obj.duration) : [],
        // Adjust the bar width here (default is 30%)
        barWidth: '60%', // Change this value as needed
      },
      // {
      //   type: 'line',
      //   data: [[0, 105], [weeklyData.length, 105]], // Static line at y = 105
      //   lineStyle: {
      //     color: 'red', // Line color
      //     width: 1, // Line width
      //   },
      //   markLine: {
      //     silent: true, // Hide label and tooltip
      //     lineStyle: {
      //       color: 'red', // Line color
      //       width: 1, // Line width
      //     },
      //     label: {
      //       fontWeight: '1600', // Make the label bold
      //       formatter: '{c}', // Display the y-axis value
      //     },
      //     data: [
      //       { yAxis: 105 } // Y-axis value where the mark line is placed
      //     ]
      //   }
      // }
    ],
  };



  useEffect(() => {
    if (chartRef.current) {
      let instance = chartRef.current.getEchartsInstance();
      instance.on('click', (params) => {

        console.log("test", params, params.name); // contains echarts usual params
        setSelectedTransactionPk(parseInt(params.name));
      });
    }


  }, [chartRef.current]);

  return (
    <Grid container style={{ height: '100%' }}>
      <Grid item md={6}>
        <Stack spacing={2} direction="row" padding={'15px'}>
          <Button variant="contained"
            disabled={row && row.connectorList && row.connectorList.length >= 2 && connectorPk === row.connectorList[1]?.connectorPk}
            onClick={() => setConnectorPk(row?.connectorList && row.connectorList.length >= 2 ? row.connectorList[1]?.connectorPk : 0)}
            style={{
              backgroundColor: connectorPk === (row && row.connectorList && row.connectorList.length >= 2 && row.connectorList[1]?.connectorPk) ? backgroundColorSelected : backgroundColorUnselected,
              color: connectorPk === (row && row.connectorList && row.connectorList.length >= 2 && row.connectorList[1]?.connectorPk) ? textColorSelected : textColorUnselected, margin: 0, borderRadius: "5px 0 0 5px"
            }}>Connector 1</Button>
          <Button variant="contained"
            disabled={row && row.connectorList && row.connectorList.length >= 3 && connectorPk === row.connectorList[2]?.connectorPk}
            onClick={() => setConnectorPk(row && row.connectorList && row.connectorList.length >= 3 ? row.connectorList[2]?.connectorPk : 0)}
            style={{
              backgroundColor: connectorPk === (row && row.connectorList && row.connectorList.length >= 3 && row.connectorList[2]?.connectorPk) ? backgroundColorSelected : backgroundColorUnselected,
              color: connectorPk === (row && row.connectorList && row.connectorList.length >= 3 && row.connectorList[2]?.connectorPk) ? textColorSelected : textColorUnselected, margin: 0, borderRadius: "0 5px  5px 0"
            }}>Connector 2</Button>
        </Stack>
      </Grid>
      {/* <Grid item md={6} display='flex' justifyContent='flex-end'>
        <Stack spacing={2} direction="row" padding={'15px'}>
          <Button
            variant="contained"
            onClick={() =>
              setWeeklyData(weeklyData
                .sort((o1, o2) => parseInt(o1.duration) - parseInt(o2.duration)))} >Sort</Button>
        </Stack>
      </Grid> */}
      {loading ? (
        <Grid item xs={12} style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Loading />
        </Grid>
      ) :
        <ReactECharts
          option={options}
          ref={chartRef}
          style={{ height: '25rem', width: '100%' }}
          opts={{ renderer: 'canvas' }} // Use 'canvas' or 'svg' renderer based on your preference
        />
      }


    </Grid>
  );
};

export default WeeklyConsumptionChart;
