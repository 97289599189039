import React, { useState, useEffect } from 'react';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Paper, Slide, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { color } from '@mui/system';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { makeStyles } from '@material-ui/core';
import { useThemeContext } from 'app/ThemeProvider';
import Loading from 'app/components/MatxLoading';
import ChargerApi from 'app/views/charger/ChargerApi';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles({
  root: {
    padding: '0',
  },
  subtitleRight: {
    margin: '20px 0 10px 0',
    backgroundColor: 'rgb(7, 128, 235)',
    padding: '10px 10px 10px 15px',
    borderRadius: '0 30px 30px 0',
    color: 'white',
    position: 'relative',
    boxShadow:
      '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
    marginLeft: 'inherit',
    width: 'fit-content',
  },
  field: {
    // height: '50vh',
    width: 'fit-content',
    // borderRadius: ({isMobile}) => isMobile ? "inherit" : '20px',
    margin: '10px 20px',
    '& .MuiOutlinedInput-input': {
      padding: '18.5px 27px',
    },
  },
  fieldDate: {
    // height: '50vh',
    width: 'fit-content',
    // borderRadius: ({isMobile}) => isMobile ? "inherit" : '20px',
    margin: '10px 20px',
    '& .MuiOutlinedInput-input': {
      padding: '18.5px 5px',
    },
  },
});

function OccpInstallCertificate({
  open,
  handleOpenDialog,
  handleCloseDialog,
  row,
  occpCommnad,
  chargeBoxId,
}) {
  const classes = useStyles();
  const api = new ChargerApi();
  const [values, setValues] = useState([]);
  const [installCertificateParams, setInstallCertificateParams] = useState({});
  const { darkTheme } = useThemeContext();
  const [disabled, setDisabled] = useState(false);


  console.log("test", row);


  // useEffect(() => {
  //   setDisabled(true);
  //   if (occpCommnad === '1') {
  //     setDisabled(false);
  //   }
  //   if (occpCommnad === '3') {
  //     setDisabled(false);
  //   }
  // }, [occpCommnad]);

  useEffect(() => {
    let isCanceled = false;
    api
      .getChargerConnectorList(null, row)
      .then(({ data }) => {
        if (!isCanceled) {
          setValues(data);
        }
      })
      .catch((e) => {
        // console.log('Cannot load event data', e);
      });
    return () => {
      isCanceled = true;
    };
  }, [row]);

  const handleClickOpen = () => {
    handleOpenDialog();
  };

  const handleClose = () => {
    setValues([]);
    handleCloseDialog();
  };

  const handleChange = (event) => {
    setInstallCertificateParams({ ...installCertificateParams, [event.target.name]: event.target.value });
  };

  const saveUpdatedDetails = () => {
    // if (occpCommnad == '1') {
    // console.log(state);
    setDisabled(true);
    api
      .installCertificate(installCertificateParams, chargeBoxId)
      .then(({ data }) => {
        return toast.success('Request ' + data);
      })
      .catch((e) => {
        return toast.error('Request failed');

        // console.log('Cannot load event data', e);
      }).finally(() => setDisabled(false));
    // }
  };
  const { certificateType, certificate } = installCertificateParams;

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      // //keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      fullWidth
    >
      <DialogTitle id="alert-dialog-slide-title" style={{ padding: '0', backgroundColor: darkTheme ? '#333333' : '#eeeeee' }}>

        <Grid container >
          <Grid container className={classes.subtitleRight} item xs={11}>
            <Grid item xs={6}>
              <Typography variant={'h5'} >
                Install Certificate
              </Typography>
            </Grid>

            <Grid item xs={6} style={{ fontSize: '16px', paddingTop: '3px' }} >

              Charger ID:{chargeBoxId}

            </Grid>

          </Grid>

          <Grid item container xs={0.5}>
            <IconButton aria-label="close" color="inherit" onClick={handleClose}>
              <CloseIcon style={{ color: darkTheme ? '#fff' : '#000' }} />
            </IconButton>
          </Grid>
        </Grid>


      </DialogTitle>

      <DialogContent dividers style={{ backgroundColor: darkTheme ? '#333333' : '#eeeeee', color: darkTheme ? '#ffffff' : '#000000' }}>
        <form noValidate autoComplete="off">
          {' '}
          <Grid container justify={'left'} alignItems={'left'} direction={'column'}>
            <FormControl fullWidth style={{ marginBottom: '20px' }}>
              <InputLabel variant="standard" htmlFor="uncontrolled-native" style={{ color: darkTheme ? '#ffffff' : '#000000', fontSize: '18px' }}>
                Certificate Type
              </InputLabel>
              <NativeSelect
                onChange={handleChange}
                inputProps={{
                  name: 'certificateType',
                  id: 'uncontrolled-native',
                }}
                value={certificateType}
                style={{ color: darkTheme ? '#ffffff' : '#000000' }}
                IconComponent={() => <ArrowDropDownIcon style={{ color: darkTheme ? '#ffffff' : '#000000' }} />}
                sx={{
                  "& .MuiSelect-icon": {
                    color: darkTheme ? '#ffffff' : '#000000',
                  },
                  "&:before, &:after": {
                    borderBottom: '1px solid',
                    borderColor: darkTheme ? '#ffffff' : '#000000'
                  },
                  "& option": {
                    backgroundColor: darkTheme ? '#000000' : '#eeeeee',
                    color: darkTheme ? '#ffffff' : '#000000',
                  },
                }}
              >
                <option style={{ backgroundColor: darkTheme ? '#000000' : '#eeeeee', color: darkTheme ? '#ffffff' : '#000000', }} value={0}></option>
                <option style={{ backgroundColor: darkTheme ? '#000000' : '#eeeeee', color: darkTheme ? '#ffffff' : '#000000', }} value={"CentralSystemRootCertificate"}>CentralSystemRootCertificate</option>
                <option style={{ backgroundColor: darkTheme ? '#000000' : '#eeeeee', color: darkTheme ? '#ffffff' : '#000000', }} value={"ManufacturerRootCertificate"}>ManufacturerRootCertificate</option>

              </NativeSelect>
            </FormControl>
            <FormControl>
              <TextField
                label="Certificate"
                multiline
                value={certificate}
                name="certificate"
                onChange={handleChange}
                minRows={4}
                variant="outlined" // Options: outlined, filled, standard
                InputLabelProps={{
                  style: { color: darkTheme ? '#fff' : '#000' },
                }}
                sx={{
                  "& .MuiInput-underline:before": {
                    borderBottomColor: darkTheme ? '#fff' : '#000',
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: darkTheme ? '#fff' : '#000',
                  },
                  "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                    borderBottomColor: darkTheme ? '#fff' : '#000',
                  },
                  "& .MuiInputBase-input": {
                    color: darkTheme ? '#fff' : '#000',
                  },
                }}
                fullWidth
              />
            </FormControl>


          </Grid>
        </form>
      </DialogContent>
      <DialogActions style={{ backgroundColor: darkTheme ? '#333333' : '#eeeeee' }}>
        <Button
          type={'submit'}
          size={'large'}
          variant={'contained'}
          style={{ backgroundColor: disabled ? 'white' : 'green', color: 'white' }}
          onClick={saveUpdatedDetails}
          disabled={disabled}
        >
          {disabled ? <Loading /> : "Submit"}
        </Button>

        <Button
          type={'submit'}
          size={'large'}
          variant={'contained'}
          // color="secondry"
          onClick={handleClose}
          style={{ backgroundColor: '#334155', color: 'white' }}
        >
          Close
        </Button>
      </DialogActions>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </Dialog>
    // </div>
  );
}

export default OccpInstallCertificate;
