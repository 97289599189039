import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { AppBar, Grid, IconButton, InputBase, Toolbar, Typography, Tab, Tabs, Card } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { styled, alpha } from '@mui/material/styles';
import SearchApi from './SearchApi';
import { toast } from 'react-toastify';
import Sessions from './shared/Sessions';
import { CustomTabPanel } from '../charger/ChargerAnalytics';
import Loading from 'app/components/MatxLoading';
import TransactionAnalytics from '../reports/shared/TransactionAnalytics';



const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    width: '100%',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        [theme.breakpoints.up('sm')]: {
            width: '50rem',
            '&:focus': {
                width: '50rem',
            },
        },
    },
}));


const SearchAnalytics = ({ toggleDrawer }) => {

    const [searchRequestDto, setSearchRequestDto] = React.useState({ parameter: "sessions" });
    const [loading, setLoading] = React.useState(false);
    const [data, setData] = React.useState([]);
    const [value, setValue] = React.useState(0);
    const [transactionPk, setTransactionPk] = React.useState(null);

    const gridRef = React.useRef(null); // Create a reference for the Grid

    React.useEffect(() => {
        if (gridRef.current && transactionPk !== null) {
            gridRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
        }
    }, [transactionPk]);


    const api = new SearchApi();

    const fetchSearchData = () => {

        console.log(searchRequestDto)

        setLoading(true)
        api
            .getBySearchRequestDto(searchRequestDto)
            .then(({ data }) => {


                if (data.length === 0)
                    alert("no data avaibale");
                console.log(data)

                setData(data.map((o, index) => ({ ...o, id: index })));

            })
            .catch((e) => {
                console.log('Cannot load report data', e);
            }).finally(() => setLoading(false));

    }

    const handleTabChange = (event, newValue) => {
        setValue(newValue);

        // console.log("event s:",event)

    };



    return (
        <Grid container >

            <Grid item lg={12} sx={{ backgroundColor: 'white' }}>


                <Box

                    sx={{ width: '100%', borderBottom: 1, borderColor: 'divider', }}

                    role="presentation"
                // onClick={toggleDrawer(anchor, false)}
                // onKeyDown={toggleDrawer(anchor, false)}
                >

                    <AppBar position="static" >
                        <Toolbar>
                            <Grid container>
                                <Grid item lg={10.9}>
                                    <Search>
                                        <SearchIconWrapper>
                                            <SearchIcon />
                                        </SearchIconWrapper>
                                        <StyledInputBase
                                            placeholder="Search…"
                                            onChange={(e) => setSearchRequestDto({ ...searchRequestDto, value: e.target.value })}
                                            inputProps={{ 'aria-label': 'search' }}
                                        />
                                    </Search>

                                </Grid>

                                <Grid item lg={1.1}>

                                    <Button variant='contained' color='success' onClick={fetchSearchData} >
                                        Search
                                    </Button>&nbsp;

                                    <IconButton variant='contained' color='success' onClick={() => toggleDrawer(false)} >
                                        <CloseIcon />
                                    </IconButton>

                                </Grid>
                            </Grid>

                        </Toolbar>
                    </AppBar>
                </Box>

            </Grid>





            {
                !loading && data && data.length > 0 ? (

                    <Grid item lg={12} container padding="20px" sx={{ backgroundColor: 'white' }}>


                        <Grid item
                            lg={12} md={12} sm={12} xs={12}
                            sx={{ borderBottom: 1, borderColor: 'divider', }}
                        >

                            <Tabs value={value}
                                onChange={handleTabChange}
                                textColor="black"
                                // backgroundColor="grey"
                                style={{
                                    margin: "20px 0 20px 0",

                                }}
                                variant="scrollable" // Makes the tabs scrollable
                                scrollButtons="auto" // Show scroll buttons when necessary


                                indicatorColor="grey"
                                aria-label="secondary tabs example"
                            >
                                <Tab
                                    style={{ backgroundColor: value === 0 ? 'black' : 'lightgrey', color: value === 0 ? 'white' : 'black', borderTopLeftRadius: 15, borderBottomLeftRadius: 15 }}
                                    label="sessions" />

                            </Tabs>
                        </Grid>
                        <CustomTabPanel value={value} index={0}>

                            <Card style={{ width: "100%", backgroundColor: '#f9f9f9' }}>

                                <Sessions data={data} setTransactionPk={setTransactionPk} />
                            </Card>

                        </CustomTabPanel>



                    </Grid>) : ""
            }















            {
                loading ?

                    <Grid width={"100%"} height={'90vh'} item lg={12}  >
                        <Loading />

                    </Grid>

                    : ""
            }


            {
                transactionPk !== null && !loading ?
                    <Grid width={"100%"} item lg={12} padding="20px" backgroundColor="white" color={"black"} ref={gridRef}   >

                        <TransactionAnalytics receivedTransactionPk={transactionPk} />
                    </Grid>
                    : ""
            }



        </Grid>

    );

}
export default SearchAnalytics;