import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, Grid, styled } from '@mui/material';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import Stack from '@mui/material/Stack';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import TableContainer from '@mui/material/TableContainer';
import DeleteIcon from '@mui/icons-material/Delete';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { pink, blue } from '@mui/material/colors';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Checkbox from '@mui/material/Checkbox';
import { useThemeContext } from 'app/ThemeProvider';
import Button from '@mui/material/Button';
import LoadMgmtApi from './LoadMgmtApi';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Span } from 'app/components/Typography';
// import styles from './FrameComponent.module.css';
// import stylestbl from './FrameComponent5.module.css';
import TextField from '@material-ui/core/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Paper from '@material-ui/core/Paper';
import { color } from 'echarts';
import { fontWeight } from '@mui/system';
import { StyledTable, componentStyle } from 'app/utils/utils';


const classes = {};
const ContentBox = styled('div')(({ theme }) => ({
  margin: '30px',
  [theme.breakpoints.down('sm')]: { margin: '16px' },
}));
const Title = styled('span')(() => ({
  fontSize: '1rem',
  fontWeight: '500',
  marginRight: '.5rem',
  textTransform: 'capitalize',
}));

const useStyles = makeStyles({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
    '& .MuiTableCell-root': {
      borderLeft: '1px solid rgba(224, 224, 224, 1)',
    },
  },
});

const useStyless = makeStyles({
  table: {
    minWidth: 650,
    width: '100%',
    display: 'flex',
    '& .MuiTableCell-root': {
      borderLeft: '1px solid rgba(224, 224, 224, 1)',
      display: 'table-cell',
      padding: 5,
    },
  },
  tableHead: {
    display: 'flex',
    flexDirection: 'column',
  },
  tableBody: {
    display: 'flex',
  },
  tableRow: {
    display: 'flex',
    flexDirection: 'column',
  },
});

function createData(name, mon, tue, wed, thu, fri, sat, sun) {
  return { name, mon, tue, wed, thu, fri, sat, sun };
}

const rows = [
  createData('1 AM', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'),
  createData('2 AM', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'),
  createData('3 AM', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'),
  createData('4 AM', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'),
  createData('5 AM', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'),
  createData('6 AM', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'),
  createData('7 AM', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'),
  createData('8 AM', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'),
  createData('9 AM', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'),
  createData('10 AM', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'),
  createData('11 AM', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'),
  createData('12 PM', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'),
  createData('1 PM', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'),
  createData('2 PM', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'),
  createData('3 PM', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'),
  createData('4 PM', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'),
  createData('5 PM', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'),
  createData('6 PM', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'),
  createData('7 PM', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'),
  createData('8 PM', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'),
  createData('9 PM', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'),
  createData('10 PM', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'),
  createData('11 PM', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'),
];
const ExpandableTableRow = ({ children, expandComponent, ...otherProps }) => {
  const [isExpanded, setIsExpanded] = React.useState(false);

  return (
    <>
      <TableRow {...otherProps}>
        <TableCell padding="checkbox">
          <IconButton onClick={() => setIsExpanded(!isExpanded)}>
            {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {children}
      </TableRow>
      {isExpanded && (
        <TableRow>
          <TableCell padding="checkbox" />
          {expandComponent}
        </TableRow>
      )}
    </>
  );
};

export default function LoadMgmtProfile3({ profile, setProfile }) {
  const classes = useStyles();
  const classes2 = useStyless();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [state, setState] = useState({});
  const [profileId, setProfileId] = useState(0);
  const [APIData, setAPIData] = useState([]);
  const [timeSeries, setTimeSeries] = useState([]);
  const [data, setReportData] = useState({});
  const [show, setShow] = useState(false);
  const [activeProfile, setActiveProfile] = useState('');
  const api = new LoadMgmtApi();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const { darkTheme } = useThemeContext();
  const { profileName } = state;

  const columns = [
    { id: 'name', label: 'Name', minWidth: 100, align: 'center' },
    { id: 'totalCharger', label: 'No. of chargers', minWidth: 100, align: 'center' },
    {
      id: 'totalConnctPower',
      label: 'Connected Power',
      maxWidth: 100,
      align: 'center',
      format: (value) => value.toLocaleString('en-US'),
    },
    {
      id: 'peakLimit',
      label: 'Peak Limit',
      maxWidth: 100,
      align: 'center',
      format: (value) => value.toLocaleString('en-US'),
    },
  ];

  useEffect(() => {
    fetchChargerWayList();
    fetchChargerLoad();
  }, []);

  const addDepotLoadProfile = () => {
    const depotId = localStorage.getItem('selected_depot');
    if (state.profileName === undefined && activeProfile === '') {
      state.profileName = 'uncont_charging';
    } else if (state.profileName !== undefined) {
      state.profileName = state.profileName;
    } else {
      state.profileName = activeProfile;
    }

    state.loadProfile = state.profileName;
    state.depotId = depotId;
    state.status = 'Enable';
    api
      .addDepotLoadPrf(state)
      .then((response) => {
        if (response.data.status > 0) {
          toast.success('Successfully Load Profile applied.');
          setActiveProfile(response.data.profile_name);
          if (state.profileName !== 'uncont_charging') {
            addChargingPeriods();
          }
        } else {
          return toast.error('Try after some times. !!!');
        }
      })
      .catch((e) => {
        // console.log('Cannot load event data', e);
      });
  };

  const addChargingPeriods = () => {


    const depotId = localStorage.getItem('selected_depot');
    state.loadProfile = activeProfile;
    state.depotId = depotId;
    state.status = 'Enable';
    state.timePeriods = timeSeries;

    return timeSlots();

    api
      .addChargingTimePeriods(state)
      .then((response) => {
        if (response.data.status > 0) {
          toast.success('Successfully Load Profile applied.');
          setActiveProfile(response.data.profile_name);
        } else {
          return toast.error('Try after some times. !!!');
        }
      })
      .catch((e) => {
        // console.log('Cannot load event data', e);
      });
  };

  const timeSlots = () => {
    const columns = [
      '1 AM',
      '2 AM',
      '3 AM',
      '4 AM',
      '5 AM',
      '6 AM',
      '7 AM',
      '8 AM',
      '9 AM',
      '10 AM',
      '11 AM',
      '12 PM',
      '1 PM',
      '2 PM',
      '3 PM',
      '4 PM',
      '5 PM',
      '6 PM',
      '7 PM',
      '8 PM',
      '9 PM',
      '10 PM',
      '11 PM',
    ];

    const rows = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

    var slot_time_series = [];
    rows.map((row, index) => {
      var startSlot = '';
      var startIndex = 0;
      columns.map((colm, index) => {
        var item = row + '_' + colm.replace(/ /g, '_');
        var endSlot = item;
        if (timeSeries.includes(item)) {
          if (startIndex < 1) {
            startSlot = item;
            startIndex = 1;
          }
        } else {
          slot_time_series.push(startSlot + '-' + endSlot);
          startIndex = 0;
          startSlot = '';
        }
      });
    });
    return slot_time_series;
  };

  const fetchChargerWayList = () => {
    const depotId = localStorage.getItem('selected_depot');
    api
      .getChargerBayList(null, depotId)
      .then((response) => {
        setAPIData(response.data);
      })
      .catch((e) => {
        // console.log('Cannot load event data', e);
      });
  };

  const fetchChargerLoad = () => {
    api
      .getChargerLoad(null)
      .then((response) => {
        setReportData(response.data);
      })
      .catch((e) => {
        // console.log('Cannot load event data', e);
      });
  };

  const fetchProfileList = () => {
    api
      .getChargeProfileList()
      .then((response) => {
        setAPIData(response.data);
      })
      .catch((e) => {
        // console.log('Cannot load event data', e);
      });
  };
  const handleClick = (event, id, cell) => {
    id = id.replace(/ /g, '_');
    if (event.target.style.backgroundColor === 'rgb(240, 128, 128)') {
      event.target.style.backgroundColor = '#FFFFFF';
      removeTimeSeries(cell + '_' + id);
    } else {
      event.target.style.backgroundColor = '#F08080';
      addTimeSeries(cell + '_' + id);
    }
  };

  const handleDragEnd = (event, id, cell) => {
    event.target.style.backgroundColor = '#F08080';
    id = id = id.replace(/ /g, '_');
    addTimeSeries(cell + '_' + id);
  };

  const handleDragStart = (event, id, cell) => {
    event.target.style.backgroundColor = '#F08080';
    id = id = id.replace(/ /g, '_');
    addTimeSeries(cell + '_' + id);
  };

  const handleDragOver = (event, id, cell) => {
    // console.log('handleDragOver' + cell + id);
    event.target.style.backgroundColor = '#F08080';
    id = id = id.replace(/ /g, '_');
    addTimeSeries(cell + '_' + id);
  };

  const handleClickTimePeriod = (id) => {
    setIsModalVisible(true);
    setProfileId(id);
  };

  const handleClickTimeSlidePopUp = (id) => {
    setIsModalVisible(true);
    setProfileId(id);
  };

  const handleHandleClose = () => {
    setIsModalVisible(false);
    window.location.reload(false);
  };

  const handleSubmitTime = () => {
    addDepotLoadProfile();
    return;
    // state.chargingProfilePk = profileId;
    // api
    //   .addTimeSchedule(state)
    //   .then((response) => {
    //     toast.success('Successfully  Added');
    //     fetchProfileList();
    //   })
    //   .catch((e) => {
    //     // console.log('Cannot load event data', e);
    //   });
  };

  const onChangeRadioLoadMgmt = (event, value) => {
    if (value === 'uncont_charging') {
      setShow(false);
    } else {
      setShow(true);
    }
    event.persist();
    setState({ ...state, profileName: value });
    // setState((prev) => ({
    //   ...prev,
    //   AM_time_in_value: value,
    // }));
  };

  const addTimeSeries = (val) => {
    if (val.length > 0) {
      if (timeSeries.indexOf(val) === -1) {
        setTimeSeries([...timeSeries, val]);
      }
    }
  };

  const removeTimeSeries = (val) => {
    let index = timeSeries.indexOf(val);
    if (index !== -1) {
      timeSeries.splice(index, 1);
      setTimeSeries(timeSeries);
    }
  };

  return (
    <Grid >
      <Grid container spacing={2}>
        <Grid item xs={6} lg={6} md={6}>
          <Paper elevation={2}>

            <Grid item container padding={"30px"} style={{ backgroundColor: darkTheme ? '#333333' : '#f9f9f9', marginTop: '10px' }}>
              <Grid item lg={12} md={12} sm={12} xs={12}  >
                {/* <Title style={{ fontSize: 20 }}> */}
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="gender"
                    name="gender1"
                    value={profile}
                    onChange={() => setProfile(1)}
                  >
                    <FormControlLabel value={1} control={<Radio style ={{ color: darkTheme ? '#fff' : '#000' }} />} label={<Title style={{ fontSize: 20, color: darkTheme ? '#F2E34C' : '#000' }} > &nbsp; Load Management e-DMS</Title>} />

                  </RadioGroup>
                </FormControl>
                {/* Load Management e-DMS</Title> */}
              </Grid>


              <Grid item lg={12} md={12} sm={12} xs={12} width={"100%"} paddingTop={"20px"}>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="uncont_charging"
                  name="radio-buttons-group"
                  onChange={onChangeRadioLoadMgmt}

                >

                  <FormControlLabel
                    value="managed_charging_peak"
                    control={<Radio style ={{ color: darkTheme ? '#fff' : '#000' }} />}
                    label={<span style={{ color: darkTheme ? '#fff' : '#000' }}>Managed Charging – Limit to Peak Demand</span>}
                    style={{ padding: "3px", color: darkTheme ? '#fff' : '#000' }}
                    disabled={profile!==1}
                  />
                  <FormControlLabel
                    value="uncont_charging"
                    control={<Radio style ={{ color: darkTheme ? '#fff' : '#000' }}/>}
                    label={<span style={{ color: darkTheme ? '#fff' : '#000' }}>Unmanaged Charging </span>}
                    style={{ padding: "3px", color: darkTheme ? '#fff' : '#000' }}
                    disabled={profile!==1}
                  />
                  <FormControlLabel 
                    value="uncont_charging_rest"
                    disabled="true"
                    control={<Radio style ={{ color: darkTheme ? '#fff' : '#000' }}/>}
                    label={<span style={{ color: darkTheme ? '#858585' : '#d9d9d9' }}>Uncontrolled Charging – Restrict Charging Windows </span>}
                    style={{ padding: "3px", color: darkTheme ? '#fff' : '#000' }}
                  />

                  <FormControlLabel 
                    value="managed_charging_windows"
                    disabled="true"
                    control={<Radio style ={{ color: darkTheme ? '#fff' : '#000' }}/>}
                    label={<span style={{ color: darkTheme ? '#858585' : '#d9d9d9' }}>Managed Charging – Restrict Charging Windows </span>}
                    style={{ padding: "3px", color: darkTheme ? '#fff' : '#000' }}
                  />
                  <FormControlLabel 
                    value="tariff_opt_charging"
                    disabled="true"
                    // checked="1"
                    control={<Radio style ={{ color: darkTheme ? '#fff' : '#000' }}/>}
                    label={<span style={{ color: darkTheme ? '#858585' : '#d9d9d9' }}>Tariff Optimized Charging </span>}
                    style={{ padding: "3px", color: darkTheme ? '#fff' : '#000' }}
                  />
                  <FormControlLabel 
                    value="tariff_feeder_charging"
                    disabled="true"
                    // checked="1"
                    control={<Radio style ={{ color: darkTheme ? '#fff' : '#000' }}/>}
                    label={<span style={{ color: darkTheme ? '#858585' : '#d9d9d9' }}>Tariff and Feeder Capacity Optimized Charging </span>}
                    style={{ padding: "3px", color: darkTheme ? '#fff' : '#000' }}
                  />
                  <FormControlLabel 
                    value="der_integration"
                    disabled="true"
                    // checked="1"
                    control={<Radio style ={{ color: darkTheme ? '#fff' : '#000' }}/>}
                    label={<span style={{ color: darkTheme ? '#858585' : '#d9d9d9' }}>DER Integration </span>}
                    style={{ padding: "3px", color: darkTheme ? '#fff' : '#000' }}
                  />
                  <FormControlLabel 
                    value="smart_charging"
                    disabled="true"
                    //  checked="1"
                    control={<Radio style ={{ color: darkTheme ? '#fff' : '#000' }}/>}
                    label={<span style={{ color: darkTheme ? '#858585' : '#d9d9d9' }}>Smart Charging - Route Energy Optimized Charging </span>}
                    style={{ padding: "3px", color: darkTheme ? '#fff' : '#000' }}
                  />
                </RadioGroup>
              </Grid>
              {/* <Grid item lg={7} md={7} sm={12} xs={12}>
            <Card
              sx={{ px: 1, py: 1, mb: 1 }}
              style={{ border: '1px solid gray', background: '#F5F5F5' }}
            >
              <div>
                {!show && (
                  <Stack
                    direction="row"
                    mt={2}
                    color={'white'}
                    justifyContent="left"
                    alignItems="left"
                    spacing={2}
                  >
                    <Button
                      variant="contained"
                      size="small"
                      onClick={addDepotLoadProfile}
                      style={{
                        background: 'rgb(17, 145, 242)',
                        borderRadius: '5px',
                        padding: '5px',
                      }}
                    >
                      Submit
                    </Button>
                  </Stack>
                )}

                <div>
                  <span className={styles.connectedPower}></span>
                </div>
                <div>
                  <span className={styles.connectedPower}>{`Connected Power `}</span>
                  <i>(kW)</i>
                  <span className={styles.connectedPower}> : {data.connected_power}</span>
                </div>
                <div style={{ marginTop: 8 }}>
                  <span className={styles.connectedPower}>{`Sanctioned Power `}</span>
                  <i>(kW)</i>
                  <span className={styles.connectedPower}> : {data.sanctioned_power}</span>
                </div>
                {show && (
                  <div style={{ marginTop: 8 }}>
                    <span className={styles.connectedPower}>{`Restrict Charging Window`}</span>
                    <i></i>
                    <span className={styles.connectedPower}>
                      <Button
                        variant="contained"
                        size="small"
                        onClick={handleClickTimeSlidePopUp}
                        style={{
                          background: 'rgb(17, 145, 242)',
                          borderRadius: '8px',
                          height: 32,
                          width: 105,
                          marginLeft: 12,
                        }}
                      >
                        Select
                      </Button>
                    </span>
                  </div>
                )}

            

                <Grid
                  item
                  style={{
                    borderRadius: '20px',
                    background: '#D9D9D9',
                    marginTop: '20px',
                    height: 380,
                  }}
                >
                  <br></br>
                  <Title style={{ marginLeft: '13px', fontSize: '14px', marginTop: '15px' }}>
                    Charging Ways
                  </Title>

                  <TableContainer
                    sx={{
                      maxHeight: 280,
                      maxWidth: '97%',
                      marginLeft: 1,
                      borderRadius: 3,
                      marginTop: 2,
                    }}
                  >
                    <Table
                      stickyHeader
                      aria-label="sticky table"
                      options={{
                        filtering: true,
                      }}
                    >
                      <TableHead>
                        <TableRow>
                          {columns.map((column) => (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              style={{
                                background: '#333333',
                                color: 'white',
                              }}
                            >
                              {column.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {APIData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(
                          (row) => {
                            return (
                              <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                {columns.map((column) => {
                                  const value = row[column.id];
                                  return (
                                    <TableCell key={column.id} align={column.align}>
                                      {column.format && typeof value === 'number'
                                        ? column.format(value)
                                        : value}
                                    </TableCell>
                                  );
                                })}
                              </TableRow>
                            );
                          }
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </div>
            </Card>
          </Grid> */}
            </Grid>
          </Paper>
        </Grid>


        <Grid item xs={6}>
          <Paper elevation={2}>
          <Grid item container padding={"30px"} style={{ backgroundColor: darkTheme ? '#333333' : '#f9f9f9', marginTop: '10px' }}>
            <Grid item lg={12} md={12} sm={12} xs={12} style={{ marginTop: '10px' }}  >
              {/* <Title style={{ fontSize: 20 }}> */}
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="gender"
                  name="gender1"
                  value={profile}
                  onChange={() => setProfile(2)}
                >
                  <FormControlLabel value={2} control={<Radio style ={{ color: darkTheme ? '#fff' : '#000'}}/>} label={<Title style={{ fontSize: 20, color: darkTheme ? '#F2E34C' : '#000' }} >&nbsp; Load Management CMS</Title>} />

                </RadioGroup>
              </FormControl>
              {/* Load Management e-DMS</Title> */}
            </Grid>

            <Grid style={{ marginLeft: '0' }} item lg={5} md={5} sm={12} xs={12} paddingTop={"20px"}>
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="uncont_charging"
                  name="radio-buttons-group"

                  onChange={onChangeRadioLoadMgmt}
                >

                  <FormControlLabel style={{ padding: "3px" }}
                    value="managed_charging_peak"
                    control={<Radio style ={{ color: darkTheme ? '#fff' : '#000'}}/>}
                    disabled={profile!==2}

                    label={<span style={{ color: darkTheme ? '#fff' : '#000' }}>Peak Load management </span>}
                  />
                  <FormControlLabel style={{ padding: "3px" }}
                    value="uncont_charging"
                  disabled={profile!==2}

                    control={<Radio style ={{ color: darkTheme ? '#fff' : '#000'}}/>}
                    label={<span style={{ color: darkTheme ? '#fff' : '#000' }}>Block High Tariff Windows </span>}
                  />

                </RadioGroup>
              </FormControl>
              </Grid>
            </Grid>
            {/* <Grid item lg={7} md={7} sm={12} xs={12}>
            <Card
              sx={{ px: 1, py: 1, mb: 1 }}
              style={{ border: '1px solid gray', background: '#F5F5F5' }}
            >
              <div>
                {!show && (
                  <Stack
                    direction="row"
                    mt={2}
                    color={'white'}
                    justifyContent="left"
                    alignItems="left"
                    spacing={2}
                  >
                    <Button
                      variant="contained"
                      size="small"
                      onClick={addDepotLoadProfile}
                      style={{
                        background: 'rgb(17, 145, 242)',
                        borderRadius: '5px',
                        padding: '5px',
                      }}
                    >
                      Submit
                    </Button>
                  </Stack>
                )}

                <div>
                  <span className={styles.connectedPower}></span>
                </div>
                <div>
                  <span className={styles.connectedPower}>{`Connected Power `}</span>
                  <i>(kW)</i>
                  <span className={styles.connectedPower}> : {data.connected_power}</span>
                </div>
                <div style={{ marginTop: 8 }}>
                  <span className={styles.connectedPower}>{`Sanctioned Power `}</span>
                  <i>(kW)</i>
                  <span className={styles.connectedPower}> : {data.sanctioned_power}</span>
                </div>
                {show && (
                  <div style={{ marginTop: 8 }}>
                    <span className={styles.connectedPower}>{`Restrict Charging Window`}</span>
                    <i></i>
                    <span className={styles.connectedPower}>
                      <Button
                        variant="contained"
                        size="small"
                        onClick={handleClickTimeSlidePopUp}
                        style={{
                          background: 'rgb(17, 145, 242)',
                          borderRadius: '8px',
                          height: 32,
                          width: 105,
                          marginLeft: 12,
                        }}
                      >
                        Select
                      </Button>
                    </span>
                  </div>
                )}

            

                <Grid
                  item
                  style={{
                    borderRadius: '20px',
                    background: '#D9D9D9',
                    marginTop: '20px',
                    height: 380,
                  }}
                >
                  <br></br>
                  <Title style={{ marginLeft: '13px', fontSize: '14px', marginTop: '15px' }}>
                    Charging Ways
                  </Title>

                  <TableContainer
                    sx={{
                      maxHeight: 280,
                      maxWidth: '97%',
                      marginLeft: 1,
                      borderRadius: 3,
                      marginTop: 2,
                    }}
                  >
                    <Table
                      stickyHeader
                      aria-label="sticky table"
                      options={{
                        filtering: true,
                      }}
                    >
                      <TableHead>
                        <TableRow>
                          {columns.map((column) => (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              style={{
                                background: '#333333',
                                color: 'white',
                              }}
                            >
                              {column.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {APIData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(
                          (row) => {
                            return (
                              <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                {columns.map((column) => {
                                  const value = row[column.id];
                                  return (
                                    <TableCell key={column.id} align={column.align}>
                                      {column.format && typeof value === 'number'
                                        ? column.format(value)
                                        : value}
                                    </TableCell>
                                  );
                                })}
                              </TableRow>
                            );
                          }
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </div>
            </Card>
          </Grid> */}
          </Paper>
        </Grid>

      </Grid>
      <Dialog
        maxWidth="lg"
        fullWidth="true"
        open={isModalVisible}
        onClose={handleHandleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className={classes.title}>
          Schedule Periods
        </DialogTitle>
        <DialogContent className={classes2.content}>
          <TableContainer>
            <Table
              className={classes2.table}
              aria-label="simple table"
              style={{ fontSize: 'smaller' }}
            >
              <TableRow className={classes2.tableHead}>
                <TableCell>#</TableCell>
                <TableCell align="left" style={{ color: 'black', fontWeight: 680 }}>
                  Mon
                </TableCell>
                <TableCell align="left" style={{ color: 'black', fontWeight: 680 }}>
                  Tue
                </TableCell>
                <TableCell align="left" style={{ color: 'black', fontWeight: 680 }}>
                  Wed
                </TableCell>
                <TableCell align="left" style={{ color: 'black', fontWeight: 680 }}>
                  Thu
                </TableCell>
                <TableCell align="left" style={{ color: 'black', fontWeight: 680 }}>
                  Fri
                </TableCell>
                <TableCell align="left" style={{ color: 'black', fontWeight: 680 }}>
                  {' '}
                  Sat
                </TableCell>
                <TableCell align="left" style={{ color: 'black', fontWeight: 680 }}>
                  {' '}
                  Sun
                </TableCell>
              </TableRow>
              <TableBody className={classes2.tableBody}>
                {rows.map((row) => (
                  <TableRow className={classes2.tableRow} key={row.name}>
                    <TableCell
                      component="th"
                      scope="row"
                      style={{ color: 'black', fontWeight: 680 }}
                    >
                      {row.name}
                    </TableCell>
                    <TableCell
                      align="right"
                      style={{ cursor: 'pointer', height: 31 }}
                      draggable
                      onDragStart={(event) => handleDragStart(event, row.name, row.mon)}
                      onDragOver={(event) => handleDragStart(event, row.name, row.mon)}
                      onDragEnd={(event) => handleDragEnd(event, row.name, row.mon)}
                      onClick={(event) => handleClick(event, row.name, row.mon)}
                    ></TableCell>
                    <TableCell
                      align="right"
                      style={{ cursor: 'pointer', height: 31 }}
                      draggable
                      onDragStart={(event) => handleDragStart(event, row.name, row.tue)}
                      onDragOver={(event) => handleDragStart(event, row.name, row.tue)}
                      onDragEnd={(event) => handleDragEnd(event, row.name, row.tue)}
                      onClick={(event) => handleClick(event, row.name, row.tue)}
                    ></TableCell>
                    <TableCell
                      align="right"
                      style={{ cursor: 'pointer', height: 31 }}
                      draggable
                      onDragStart={(event) => handleDragStart(event, row.name, row.wed)}
                      onDragOver={(event) => handleDragStart(event, row.name, row.wed)}
                      onDragEnd={(event) => handleDragEnd(event, row.name, row.wed)}
                      onClick={(event) => handleClick(event, row.name, row.wed)}
                    ></TableCell>
                    <TableCell
                      align="right"
                      style={{ cursor: 'pointer', height: 31 }}
                      draggable
                      onDragStart={(event) => handleDragStart(event, row.name, row.thu)}
                      onDragOver={(event) => handleDragStart(event, row.name, row.thu)}
                      onDragEnd={(event) => handleDragEnd(event, row.name, row.thu)}
                      onClick={(event) => handleClick(event, row.name, row.thu)}
                    ></TableCell>
                    <TableCell
                      align="right"
                      style={{ cursor: 'pointer', height: 31 }}
                      draggable
                      onDragStart={(event) => handleDragStart(event, row.name, row.fri)}
                      onDragOver={(event) => handleDragStart(event, row.name, row.fri)}
                      onDragEnd={(event) => handleDragEnd(event, row.name, row.fri)}
                      onClick={(event) => handleClick(event, row.name, row.fri)}
                    ></TableCell>
                    <TableCell
                      align="right"
                      style={{ cursor: 'pointer', height: 31 }}
                      draggable
                      onDragStart={(event) => handleDragStart(event, row.name, row.sat)}
                      onDragOver={(event) => handleDragStart(event, row.name, row.sat)}
                      onDragEnd={(event) => handleDragEnd(event, row.name, row.sat)}
                      onClick={(event) => handleClick(event, row.name, row.sat)}
                    ></TableCell>
                    <TableCell
                      align="right"
                      style={{ cursor: 'pointer', height: 31 }}
                      draggable
                      onDragStart={(event) => handleDragStart(event, row.name, row.sun)}
                      onDragOver={(event) => handleDragStart(event, row.name, row.sun)}
                      onDragEnd={(event) => handleDragEnd(event, row.name, row.sun)}
                      onClick={(event) => handleClick(event, row.name, row.sun)}
                    ></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button
            style={{
              background: '#2e7d32',
              color: 'white',
              borderRadius: '3px',
              padding: '5px 7px',
            }}
            alignItems="right"
            className={classes.signUpButton}
            onClick={handleSubmitTime}
            variant="contained"
            type="submit"
          >
            <Span sx={{ textTransform: 'capitalize' }}>Submit</Span>
          </Button>

          <Button
            onClick={handleHandleClose}
            style={{
              background: '#e0e0e0',
              color: 'black',
              borderRadius: '3px',
              padding: '5px 7px',
              textTransform: 'initial',
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </Grid>
  );
}
