import axios from 'axios.js';
export default class UserApi {
  constructor() {
    this.api_token = null;
    this.client = null;
    this.user = JSON.parse(localStorage.getItem('user'));
  }

  init = () => {
    this.client = axios.create({
      timeout: 120000,
    });

    return this.client;
  };

  addUser = (data) => {
    data.username = data.email;
    return this.init().post('/api/usr/', data);
  };

  addAlertUserUser = (data) => {
    data.username = data.email;
    return this.init().post('/api/usr/saveAlertUser', data);
  };

  getUserList = (orgId) => {
    return this.init().get('/api/usr/alert_user_by_org_id/'+orgId);
  };

  getUserById = (id) => {
    return this.init().get('/api/usr/id/'+id,);
  };


  deleteUser = (params) => {
    return this.init().delete('/api/usr/', { params:params });
  };

  updateUser = (data) => {
    return this.init().put('/api/usr/', data);
  };


  updatePassword = (data) => {
    return this.init().patch('/api/usr/', data);
  };


  getUserDepotByUsername = (params) => {
    return this.init().get('/api/user-depot-permission/', { params:params });
  };

  deleteUserDepot = (params) => {
    return this.init().delete('/api/user-depot-permission/', { params:params });
  };

  addUserDepot = (data) => {
    return this.init().post('/api/user-depot-permission/', data);
  };

  getAllAssignedPermission=(params)=>{
    return this.init().get('/api/permission-assigned/all/', { params:params });
  }
  getAssignedPermission=(params)=>{
    return this.init().get('/api/permission-assigned/', { params:params });
  }
  addAssignedPermission=(data)=>{
    return this.init().post('/api/permission-assigned/', data);
  }

  addAllAssignedPermission=(data)=>{
    return this.init().post('/api/permission-assigned/all', data);
  }

  deleteAssignedPermission=(params)=>{
    return this.init().delete('/api/permission-assigned/', { params:params });
  }

  deleteAllAssignedPermission=(params)=>{
    return this.init().delete('/api/permission-assigned/all', { params:params });
  }

  deleteByUserDepotPermissionId = (params) => {
    return this.init().delete('/api/permission-assigned/user-depot-permission-id/', { params:params });
  };

  addUserDepotAlert = (data) => {
    return this.init().post('/api/notification_permissions/save', data);
  };

}
