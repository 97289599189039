import React, { useState, useEffect } from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { FormControlLabel, Paper, Switch } from '@mui/material';
import { toast } from 'react-toastify';
import NotificationApi from '../NotificationApi';



export const NotificationGroupTable = ({ userDepotPermissionId, categoryId }) => {

    const darkTheme = false;
    const [rows, setRows] = useState([]);
    const api = new NotificationApi();
    const orgId=localStorage.getItem("orgId");




        useEffect(() => {
            fetchAllManageByOrgIdAndCategoryId();


        }, []);

    const fetchAllManageByOrgIdAndCategoryId = () => {



        api
            .getAllOrgNotificationGroupByUserDepotPermissionIdAndCategoryId({ userDepotPermissionId, categoryId,orgId })
            .then(({ data }) => {
                setRows([])
                setTimeout(() =>setRows(data.data), 50);
                console.log(data);
            })
            .catch((e) => {
                console.log('Request history data', e);
            });
    }


    const addOrgNotificationGroupUserDepotPermission = (row) => {


        api
            .addOrgNotificationGroupUserDepotPermission(row)
            .then((res) => {
                toast.success('Successfully enabled.');
                fetchAllManageByOrgIdAndCategoryId();

            })
            .catch((e) => {
                console.log('Cannot add  data', e);
                return toast.error(e.response.data.message);
            });
    }

    const updateOrgNotificationGroupUserDepotPermission=(row)=>{

        api
        .updateOrgNotificationGroupUserDepotPermission(row)
        .then((res) => {
            toast.success('Successfully updated.');
            fetchAllManageByOrgIdAndCategoryId();

        })
        .catch((e) => {
            console.log('Cannot add  data', e);
            return toast.error(e.response.data.message);
        });

    }

    const deleteOrgNotificationGroupUserDepotPermissionById = (row) => {


        api
            .deleteOrgNotificationGroupUserDepotPermissionById({id:row.id})
            .then((res) => {
                toast.success('Successfully disabled.');
                fetchAllManageByOrgIdAndCategoryId();

            })
            .catch((e) => {
                console.log('Cannot add  data', e);
                return toast.error(e.response.data.message);
            });
    }

    



    const handleChange = (e, row, index) => {
        console.log(e.target)
        if (e.target.name === "enabled"){
            if(e.target.checked){
                row.userDepotPermissionId=userDepotPermissionId
                addOrgNotificationGroupUserDepotPermission(row);
            } else deleteOrgNotificationGroupUserDepotPermissionById(row)
        }
        else{
            row[e.target.name]=e.target.checked;
            updateOrgNotificationGroupUserDepotPermission(row);
        }

    }

    return (
        <>

            <TableContainer component={Paper} style={{ height: "50vh", backgroundColor: darkTheme ? '#848482' : '#eeeeee' }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ padding: "10px", color: darkTheme ? '#fff' : '#000' }}>Enabled</TableCell>
                            <TableCell style={{ padding: "10px", color: darkTheme ? '#fff' : '#000' }}>Name</TableCell>
                            <TableCell style={{ padding: "10px", color: darkTheme ? '#fff' : '#000' }}>Email</TableCell>
                            <TableCell style={{ padding: "10px", color: darkTheme ? '#fff' : '#000' }}>SMS</TableCell>
                            {/* <TableCell style={{ padding: "10px",  }}>Action</TableCell> */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows && rows.map((row, index) => (
                            <TableRow key={row.id}>


                                <TableCell style={{ padding: "10px", color: darkTheme ? '#fff' : '#000' }}>
                                    <FormControlLabel
                                        control={<Switch
                                            name='enabled'
                                            checked={row.id !== null}
                                            onChange={(e) => handleChange(e, row, index)} />}
                                    // label={checked ? 'On' : 'Off'}

                                    />

                                </TableCell>


                                <TableCell style={{ padding: "10px", color: darkTheme ? '#fff' : '#000' }}>

                                    {row.orgNotificationGroupName}
                                </TableCell>
                                <TableCell style={{ padding: "10px", color: darkTheme ? '#fff' : '#000' }}>
                                    <FormControlLabel
                                        control={<Switch
                                            name='isEmailEnabled'
                                            disabled={row.id === null||row.orgNotificationGroupIsEmailEnabled===null||row.orgNotificationGroupIsEmailEnabled===false}
                                            checked={row.isEmailEnabled}
                                            onChange={(e) => handleChange(e, row, index)} />}
                                    // label={checked ? 'On' : 'Off'}

                                    />
                                </TableCell>
                                <TableCell style={{ padding: "10px", color: darkTheme ? '#fff' : '#000' }}>
                                    <FormControlLabel
                                        control={<Switch
                                            name='isSmsEnabled'
                                            disabled={row.id === null||row.orgNotificationGroupIsSmsEnabled===null||row.orgNotificationGroupIsSmsEnabled===false}
                                            checked={row.isSmsEnabled}
                                            onChange={(e) => handleChange(e, row, index)} />}
                                    // label={checked ? 'On' : 'Off'}

                                    />

                                </TableCell>

                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

        </>
    );


}