import axios from 'axios.js';
export default class EnergyMeterApi {
  constructor() {
    this.api_token = null;
    this.client = null;
    this.user = JSON.parse(localStorage.getItem('user'));
  }

  init = () => {
    this.client = axios.create({
      timeout: 120000,
    });

    return this.client;
  };

  add = (data) => {
    return this.init().post('/api/device-meter/', data);
  };

  delete = (params) => {
    return this.init().delete('/api/device-meter/', { params:params });
  };

  update = (data) => {
    return this.init().put('/api/device-meter/', data);
  };
  

  getByDepotId = (params) => {
    return this.init().get('/api/device-meter/', { params:params });
  };

  

  getAllEnergyMeterValue = (params) => {
    return this.init().get('/api/device-meter/current_meter_data', { params:params });
  };

}
