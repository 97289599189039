import React, { useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import { Grid } from '@mui/material';
import { chartOptions } from 'app/utils/utils';
import ChargerApi from '../../ChargerApi';
import Loading from 'app/components/MatxLoading';

const WeeklyConsumptionChart = ({ row, setTotalConsumtion, setSelectedTransactionPk, darkTheme }) => {
  const api = new ChargerApi();
  const [weeklyData, setWeeklyData] = useState(null);
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    fetchWeeklyCost();

    // const intervalId = setInterval(() => {
    //   fetchWeeklyCost();
    // }, 300000);

    // return () => {
    //   clearInterval(intervalId);
    // };
  }, []);

  const fetchWeeklyCost = () => {
    api.getChargerWeeklyConsumtion({ chargerBoxPk: row.chargeBoxPk })
      .then(({ data }) => {
        console.log(data);
        if (data && data.length) {
          setTotalConsumtion(data.map(obj => obj.consumption).reduce((v1, v2) => v1 + v2));
        }
        setWeeklyData(data);
        setLoading(false);
      })
      .catch((e) => {
        console.log('Request history data', e);
      });
  };
  const textColor = darkTheme ? '#F2E34C' : '#000'; // Conditionally set text color
  const titleColor = darkTheme ? '#F2E34C' : '#000';
  const tooltipColor = darkTheme ? '#333333' : '#ffffff';
  const labelColor = darkTheme ? '#000' : '#6a7985';

  const options = {
    // title: {
    //   text: 'Weekly Energy Delivered ',
    // },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        label: {
          backgroundColor: labelColor,
          color: textColor
        }
      },
      backgroundColor: tooltipColor,
      textStyle: {
        color: darkTheme ? '#F2E34C' : '#000' // Set text color based on theme
      },
      //   formatter: function (params) {
      //     let tooltip = params[0].name + '<br/>';
      //     params.forEach(param => {
      //         tooltip += param.marker + ' ' + param.seriesName + ' : ' + param.value.toLocaleString() + ' kWh<br/>';
      //     });
      //     return tooltip;
      // }
    },
    grid: {
      left: '6%',
      right: '4%',
      bottom: '5%',

      containLabel: true
    },
    toolbox: {
      show: true,
      top: '10px',
      right: 25,
      feature: {
        saveAsImage: {
          show: true,
          title: 'Download',
          name: 'Energy Consumption',
          type: 'png'
        }
      },
      iconStyle: {
        borderColor: darkTheme ? '#F2E34C' : '#000'
      }
    },
    xAxis: {
      type: 'category',
      data: weeklyData ? weeklyData.map(obj => obj.day) : [],
      name: 'Week Days',
      axisLabel: chartOptions().axisLabel,
      nameLocation: 'middle',
      nameGap: 35,
      nameTextStyle: {
        ...chartOptions().nameTextStyle,
        color: textColor // Change text color based on dark theme
      },
      axisLabel: {
        color: textColor // Set text color based on theme
      }
      // boundaryGap: false,
    },
    yAxis: {
      type: 'value',
      name: 'Consumption (kWh)',
      nameLocation: 'middle',
      nameGap: 40,
      nameTextStyle: {
        ...chartOptions().nameTextStyle,
        color: textColor // Change text color based on dark theme
      },
      axisLabel: chartOptions().axisLabel,
      axisLabel: {
        color: textColor // Set text color based on theme
      }
    },
    series: [
      {
        name: 'Values',
        type: 'bar',
        data: weeklyData ? weeklyData.map(obj => obj.consumption) : [],
        // Adjust the bar width here (default is 30%)
        barWidth: '60%', // Change this value as needed
      },
    ],
  };

  // const onChartClick = (params) => {
  //   debugger;
  //   console.log('Chart clicked', params);
  // };







  return (
    <Grid container style={{ height: '100%' }}>
      <Grid item xs={12} style={{ height: '100%' }}>
        {loading ? (
          <Grid
            style={{
              height: '15rem',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Loading />
          </Grid>
        ) :
          <ReactECharts
            option={options}
            // onEvents={{ click: onChartClick }}

            style={{ height: '15rem', width: '100%' }}
            opts={{ renderer: 'canvas' }} // Use 'canvas' or 'svg' renderer based on your preference
          />
        }


      </Grid>
    </Grid>
  );
};

export default WeeklyConsumptionChart;
