import React, { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import Autocomplete from '@mui/material/Autocomplete';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle'
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Switch from '@mui/material/Switch';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import UserApi from '../UserApi';
import DepoApi from '../../depomgmt/DepoApi';
import { ToastContainer, toast } from 'react-toastify';
import DeleteIcon from '@mui/icons-material/Delete';
import UpdateIcon from '@mui/icons-material/Update';
import { useThemeContext } from 'app/ThemeProvider';


import { CircularProgress, FormControlLabel, FormGroup, Grid, TablePagination, Tooltip } from '@mui/material';
import { Permissions } from './Permissions';
import NotificationMngmnt from './NotificationsMngmnt';


export const UserDepot = ({ getUserDepot, depot, row, allDepot = [] }) => {


    const [open, setOpen] = useState(false);
    const { darkTheme } = useThemeContext();
    const [loading, setLoading] = useState(false);
    const [buttonClicked, setButtonClicked] = useState(false);



    const api = new UserApi();

    // useEffect(() => {
    //     if (!loading)
    //         getUserDepot();

    // }, [loading])

    const addUserDepot = (d) => {
        setLoading(true);
        api
            .addUserDepot([d])
            .then((res) => {
                toast.success('Successfully assigned depot');
                setLoading(false);
            })
            .catch((e) => {
                return toast.error(e.message);
            })
            .finally(() => { getUserDepot() });
    }

    const deleteUserDepot = (d) => {
        setLoading(true);
        api
            .deleteUserDepot(d)
            .then((res) => {
                toast.success('Successfully removed depot');
                setLoading(false)
            })
            .catch((e) => {
                setLoading(false);
                return toast.error('Remove all permissions before delete');
                // console.log('Cannot add  data', e);
            }).finally(() => { getUserDepot() });
    }



    const handleChecked = (e) => {
        console.log(e.target.checked);
        if (e.target.checked) {

            depot.userId = row.id;
            depot.username = row.username;
            addUserDepot(depot)
        }
        else {
            deleteUserDepot(depot);
        }

    }





    return (
        <TableRow >
            <TableCell style={{ padding: "10px", color: darkTheme ? '#fff' : '#000' }}>
                <FormGroup>

                    {
                        loading ? <CircularProgress /> : <FormControlLabel
                            checked={!(depot.id === null)}
                            onChange={handleChecked}
                            control={<Switch style={{ color: darkTheme ? '#fff' : '#000' }} />} />

                    }


                </FormGroup>

            </TableCell>
            <TableCell style={{ color: darkTheme ? '#fff' : '#000' }}>{depot.depotId}</TableCell>
            <TableCell style={{ color: darkTheme ? '#fff' : '#000' }}>{depot.depotName}</TableCell>
            <TableCell style={{ color: darkTheme ? '#fff' : '#000' }}>{depot.depotZone}</TableCell>
            <TableCell ><Button
                disabled={depot.id === null}
                variant="contained" size="small"
                onClick={() => setOpen(true)}
                style={{
                    backgroundColor: depot.id === null ? 'gray' : '#2196f3', // Change background color when disabled
                    color: depot.id === null ? 'white' : 'white', // Change text color when disabled
                }}
            >
                Permissions
            </Button>


                {
                    depot.id && <>
                        <Permissions open={open} setOpen={setOpen} depot={depot} user={row} allDepot={allDepot} />

                    </>
                }

            </TableCell>

            <TableCell >

                <NotificationMngmnt depot={depot} />


            </TableCell>
        </TableRow>
    )
}

