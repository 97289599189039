import { Stack, Button, Icon, Paper, Grid, Typography } from '@mui/material';
import { Box, styled } from '@mui/system';
import { SimpleCard } from 'app/components';
import UserListTable from './UserListTable';
import AddUserForm from './AddUserForm';
import { useState } from 'react';
import UserListTable2 from './UserListTable2';
import { componentStyle } from 'app/utils/utils';
import { useThemeContext } from 'app/ThemeProvider';

const UserForm = () => {

  const [refresh, setRefresh] = useState(false);
  const { darkTheme } = useThemeContext();
  return (
    <Grid
      margin={componentStyle.margin}
    >
      <Paper elevation={2} style={{ backgroundColor: darkTheme ? '#333333' : '#f9f9f9' }}>


        {/* <Grid container padding={"20px"} justifyContent="left" alignItems="center">

          <Grid item xs={4} lg={4} md={4} >

            <Typography variant="h6" component="div" style={{ color: darkTheme ? '#F2E34C' : '#000' }}>
              Registered Users
            </Typography>

          </Grid>

          <Grid item xs={8} lg={8} md={8} >

            <AddUserForm setRefresh={setRefresh} />

          </Grid>
        </Grid> */}

        <Grid
          container
        >
          <UserListTable2 refresh={refresh} setRefresh={setRefresh} />
        </Grid>

      </Paper>
    </Grid>
  );
};

export default UserForm;
// Registered Users