export const topBarHeight = '4rem';
export const sideNavWidth = 400;
export const navbarHeight = 60;
export const sidenavCompactWidth = 80;
export const containedLayoutWidth = 1200;
export const defaultDistanceUnit = 'km';
export const defaultVehicleSize = 'm';
export const defaultBatteryPower  = 'kWh';
export const defaultEfficiency = 'kWh/km';
export const defaultChargingPower = 'kW';
export const siteKey="6Lckl-sqAAAAAJdMShzpal6dY5BDUa7aY-DohoLz";
