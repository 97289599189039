import axios from 'axios.js';
export default class OrgApi {
  constructor() {
    this.api_token = null;
    this.client = null;
    this.user = JSON.parse(localStorage.getItem('user'));
  }

  init = () => {
    this.client = axios.create({
      timeout: 120000,
    });

    return this.client;
  };

  addChargeProfile = (data) => {
    return this.init().post('/api/charger/add_charge_profile', data);
  };

  addTimeSchedule = (data) => {
    return this.init().post('/api/charger/add_charge_profile_time_slot', data);
  };

  getChargeProfileList = (params) => {
    return this.init().get('/api/charger/show_charge_profiles', { params: params });
  };

  deleteChargeProfile = (id) => {
  
    return this.init().post('/api/charger/delete_charge_profile/' + id);
  };

  deleteTimePeriod = (id) => {
    return this.init().post('/api/charger/delete_time_period/' + id);
  };

  updateOrg = (data) => {
    return this.init().post('/api/charger/update_chargerbay', data);
  };
}
