import axios from 'axios.js';
export default class NotificationApi {
  constructor() {
    this.api_token = null;
    this.client = null;
    this.user = JSON.parse(localStorage.getItem('user'));
  }

  init = () => {
    this.client = axios.create({
      timeout: 120000,
    });

    return this.client;
  };

  getAllNotificationCategory = () => {
    return this.init().get('/api/notification-category/');
  };


  getAllOrgNotificationGroupByUserDepotPermissionIdAndCategoryId=(params)=>{
    return this.init().get('/api/org-notification-group-user-depot-permission/manage',{params:params}); 
  }

  addOrgNotificationGroupUserDepotPermission=(data)=>{
    return this.init().post('/api/org-notification-group-user-depot-permission/',data); 
  }

  
  deleteOrgNotificationGroupUserDepotPermissionById=(params)=>{
    return this.init().delete('/api/org-notification-group-user-depot-permission/',{params:params}); 
  }

  updateOrgNotificationGroupUserDepotPermission=(data)=>{
    return this.init().put('/api/org-notification-group-user-depot-permission/',data); 
  }

}
