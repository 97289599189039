import axios from 'axios.js';

export default class ReportApi {
  constructor() {
    this.api_token = null;
    this.client = null;
    this.user = JSON.parse(localStorage.getItem('user'));
  }

  init = () => {
    this.client = axios.create({
      timeout: 360000,
    });

    return this.client;
  };

  getByChargeBoxPk = (params) => {
    
    return this.init().get('/api/firmware_history/',{params:params});
  };
  getAllChargerByDepotId = () => {
    return this.init().get('/api/charger/');
  };
  getChargerLogs = (chargeBoxId,params) => {
    return this.init().post('/api/logloader/download/'+chargeBoxId,params);
  };

  showChargerReport = (data) => {
    return this.init().post('/api/report/show_report', data);
  };


  showChargerStMonitorReport = (data) => {
    return this.init().post('/api/report/show_charging_st_monitor_report', data);
  };

  showFleetStMonitorReport = (data) => {
    return this.init().post('/api/report/show_fleet_st_monitor_report', data);
  };

  showBusStMonitorReport = (data) => {
    return this.init().post('/api/report/show_bus_st_monitor_report', data);
  };

  showVehicleSummaryReport = (data) => {
    return this.init().post('/api/report/show_bus_st_summary_report', data);
  };

  showOCPPMessageReport = (data) => {
    return this.init().post('/api/report/show_occp_msg/' + this.user.id, data);
  };

  showChargerStWayReport = (data) => {
   
    return this.init().post('/api/report/show_bay_consumption', data);
  };
  showChargerTemperatureMonitor = (data) => {
   
    return this.init().post('/api/report/charger_temperature_monitor', data);
  };

  showChargerFirmwareHistory = (data) => {
   
    return this.init().get('/api/report/charger_firmware_history', data);
  };

  showChargerStWayReportMaxUtilization = (data) => {
    return this.init().post('/api/report/show_max_utilization_report', data);
  };

  getChargerAndMeterWeeklyConsumptionChartData = (params) => {
    return this.init().get('/api/report/charger_meter_weekly_consumption',{params:params});
  };

  showDeviceMeterLogReport=(params)=>{
    return this.init().get('/api/report/show_device_meter_report',{params:params});

  }

  getAllCategorizedChargingDuration=(params)=>{

    return this.init().get('/api/report/categorized_charging_duration',{params:params});

  }

  getAllCategorizedChargingChartData=(params)=>{
    
    return this.init().get('/api/report/categorized_charging_chart_data',{params:params});

  }

  getNotificationData=(params)=>{
    
    return this.init().get('/api/notification/',{params:params});

  }

  getVehicleSessionChartData=(params)=>{
    return this.init().get('/api/report/vehicle_session_chart_data',{params:params});

  }

  getCarbonEmission=(params)=>{
    return this.init().get('/api/report/carbon_emission',{params:params});

  }
  

  
}