import React, { useState, useEffect } from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ChargerViewDialog from './ChargerViewDialog';
import Chip from '@mui/material/Chip';
import { makeStyles, createMuiTheme } from '@material-ui/core/styles';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ChargerApi from '../ChargerApi';
import LinearProgress from '@mui/material/LinearProgress';
import OccpCommandViewDialog from './OccpCommandViewDialog';
import OccpChangeAvailabilityViewDialog from './occpview/OccpChangeAvailabilityViewDialog';
import OccpResetCommand from './occpview/OccpResetCommand';
import OccpUnlockConnector from './occpview/OccpUnlockConnector';
import OccpReserveModal from './occpview/OccpReserveModal';
import OccpCancelReservation from './occpview/OccpCancelReservation';
import OccpChangeConfiguration from './occpview/OccpChangeConfiguration';
import OccpUpdateFirmware from './occpview/OccpUpdateFirmware';
import OccpGetDiagnosticsModal from './occpview/OccpGetDiagnosticsModal';
import OccpSetChargingProfile from './occpview/OccpSetChargingProfile';
import OccpDataTransfer from './occpview/OccpDataTransfer';
import OccpTriggerMessageModal from './occpview/OccpTriggerMessageModal';
import OccpClearChargingProfileModal from './occpview/OccpClearChargingProfileModal';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
  GridCloseIcon,
} from '@mui/x-data-grid';
import { indexOf } from 'lodash';
import { Box, Card, Grid, TableContainer, TablePagination, Button, InputLabel, Dialog, DialogActions, DialogContent, DialogTitle, Paper, Typography, Select, MenuItem, IconButton, Tabs, Tab } from '@mui/material';
import OccpSetLocalListViewDialog from './occpview/OccpSetLocalListViewDialog';
import OccpGetLocalListViewDialog from './occpview/OccpGetLocalListViewDialog';
import OccpGetConfigurationDialog from './occpview/OccpGetConfigurationDialog';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { StyledDataGrid } from 'app/utils/utils';
import ConfigDialog from './ConfigDialog';
import ChargerDetail from './charger-detail/ChargerDetail';
import CloseIcon from '@mui/icons-material/Close';
import { useThemeContext } from 'app/ThemeProvider';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage } from '@material-ui/icons';
import { Span } from 'app/components/Typography';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import MeterDetail from './meter-detail/MeterDetail';
import Loading from 'app/components/MatxLoading';
import OccpGetCompositeSchedule from './occpview/OcppGetCompositeSchedule';
import OccpGetLog from './occpview/security/OcppGetLog';
import OccpCertificateSigned from './occpview/security/OcppCertificateSigned';
import OccpSignedUpdateFirmware from './occpview/security/OcppSignedUpdateFirmware';
import OccpSignCertificate from './occpview/security/OcppSignCertificate';
import OccpGetInstalledCertificateIds from './occpview/security/OcppGetInstalledCertificateIds';
import OccpExtendedTriggerMessage from './occpview/security/OcppExtendedTriggerMessage';
import OccpDeleteCertificate from './occpview/security/OcppDeleteCertificate';
import OccpInstallCertificate from './occpview/security/OcppInstallCertificate';




export const ChargerDetailDialog = (props) => {
  const [open, setOpen] = useState(false);
  const [row, setRow] = useState({});
  const [value, setValue] = useState(0);

  const { darkTheme } = useThemeContext();

  const handleClickOpen = () => {
    setRow(props.row)
    setOpen(true);
  };

  const handleClose = () => {
    setRow({})
    setOpen(false);
  };

  const style = makeStyles({
    titleItemRight: {
      float: 'right',
      position: 'relative',
      transform: 'translateY(-50%)',
    },
  });


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const classes = style();

  return (
    <div>
      <Tooltip title={props.row.powerSource === 'Backup Power' ? props.row.powerSource : ""}>
        <Button style={{
          color: darkTheme ? '#F2E34C' : '#000',
          color: props.row.powerSource === 'Backup Power' ? 'orange' : darkTheme ? 'white' : 'black', textDecoration: 'underline', cursor: 'pointer'
        }} onClick={handleClickOpen}>
          {props.row.chargeBoxId}
          {
            props.row.powerSource === 'Backup Power' && <InfoOutlinedIcon
              style={{ color: darkTheme ? '#F2E34C' : 'black', width: '15px', height: '15px' }}
            />
          }

        </Button>
      </Tooltip>

      <Dialog

        maxWidth="xl"
        open={open}
        onClose={handleClose}
        fullWidth
      >
        <Paper style={{ backgroundColor: darkTheme ? '#000' : '#f9f9f9' }}>

          {/* <DialogTitle>Dialog Title</DialogTitle> */}
          <DialogActions >
            <Grid container>
              <Grid item md={4}>



                <Tabs value={value}
                  onChange={handleChange}
                  textColor="black"
                  style={{ margin: "0px", padding: 0 }}
                  // backgroundColor="grey"

                  indicatorColor="grey"
                  aria-label="secondary tabs example"
                >
                  <Tab sx={{ padding: '0px' }}
                    style={{
                      backgroundColor: value === 0 ? 'black' : 'lightgrey', padding: '0px', margin: "0px"
                      , color: value === 0 ? 'white' : 'black'
                      , borderTopLeftRadius: 15, borderBottomLeftRadius: 15
                    }}
                    label="Charger" />
                  <Tab
                    style={{
                      backgroundColor: value === 1 ? 'black' : 'lightgrey', padding: '0px', margin: "0px",
                      borderTopRightRadius: 15, borderBottomRightRadius: 15
                      , color: value === 1 ? 'white' : 'black'
                    }}
                    label="Meter" />

                </Tabs>

              </Grid>

              <Grid container md={8} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>

                <Grid item>
                  <GridCloseIcon onClick={handleClose} />
                </Grid>


              </Grid>
            </Grid>



          </DialogActions>

          <DialogContent style={{ padding: '10px' }} >

            {
              value === 0
                ? <ChargerDetail row={row} open={open} />
                : <MeterDetail row={row} open={open} />

            }




          </DialogContent>

        </Paper>
      </Dialog>
    </div>
  );

}

const useStyles = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
    display: 'flex',
    alignItems: 'center',
  },
  select: {
    marginLeft: theme.spacing(1),
  },
}));

const CustomPagination = ({ count, page, rowsPerPage, onChangePage, onChangeRowsPerPage, darkTheme }) => {
  const classes = useStyles();

  const handleFirstPageButtonClick = () => {
    onChangePage(0);
  };

  const handleBackButtonClick = () => {
    onChangePage(page - 1);
  };

  const handleNextButtonClick = () => {
    onChangePage(page + 1);
  };

  const handleLastPageButtonClick = () => {
    onChangePage(Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  const backgroundColor = darkTheme ? '#333333' : '#f9f9f9';
  const textColor = darkTheme ? '#fff' : 'rgba(0, 0, 0, 0.87)';
  const dropdownBackgroundColor = darkTheme ? '#000' : '#fff';
  const dropdownTextColor = darkTheme ? '#fff' : 'rgba(0, 0, 0, 0.87)';

  return (
    <Box className={classes.root} style={{ backgroundColor, color: textColor, marginRight: '10px', height: '48px' }}>
      <Select
        className={classes.select}
        value={rowsPerPage}
        onChange={(e) => onChangeRowsPerPage(e)}
        style={{ color: textColor }}
        IconComponent={() => <ArrowDropDownIcon style={{ color: darkTheme ? '#fff' : '#000', position: 'absolute', right: '8px', top: '50%', transform: 'translateY(-50%)' }} />}
        MenuProps={{
          PaperProps: {
            style: {
              backgroundColor: dropdownBackgroundColor,
              color: dropdownTextColor,
            },
          },
        }}
      >
        {[10, 20].map((pageSize) => ( // Added -1 for "All" option
          <MenuItem key={pageSize} value={pageSize}>
            {pageSize === -1 ? 'All' : pageSize} {/* Display "All" instead of -1 */}
          </MenuItem>
        ))}
      </Select>

      <Typography variant="body2" style={{ color: textColor }}>Rows per page</Typography>
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
        <FirstPage style={{ color: darkTheme ? '#fff' : 'rgba(0, 0, 0, 0.54)' }} />
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        <KeyboardArrowLeft style={{ color: darkTheme ? '#fff' : 'rgba(0, 0, 0, 0.54)' }} />
      </IconButton>
      <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
        <KeyboardArrowRight style={{ color: darkTheme ? '#fff' : 'rgba(0, 0, 0, 0.54)' }} />
      </IconButton>
      <IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="last page">
        <LastPage style={{ color: darkTheme ? '#fff' : 'rgba(0, 0, 0, 0.54)' }} />
      </IconButton>
      <Typography variant="body2">{`${page + 1} of ${Math.ceil(count / rowsPerPage)}`}</Typography>
    </Box>
  );
};


const ChargerTables = ({ darkTheme }) => {
  const api = new ChargerApi();
  const [True, setTrue] = useState(0)
  const [data, setData] = useState([]);
  const [loaded, setReady] = useState(false);
  const [error, setError] = useState(false);
  const [openViewDialog, setOpenViewDialog] = useState(false);
  const [openOccpViewDialog, setOpenOccpViewDialog] = useState(false);
  const [rowToView, setRowToView] = useState([]);
  const [chipData, setChipData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [connectors, setConnectors] = useState(0);
  const [chargeBoxId, setChargeBoxId] = useState(0);
  const [selectedChargeBoxId, setSelectedChargeBoxId] = useState("");

  const [occpCommnad, setOccpCommnad] = useState(0);
  const [chngAvlityDialog, setChngAvlityDialog] = useState(false);
  const [openReset, setOpenReset] = useState(false);
  const [openUnlock, setOpenUnlock] = useState(false);
  const [openReserve, setOpenReserve] = useState(false);
  const [openCancReserve, setOpenCancReserve] = useState(false);
  const [openChange, setOpenChange] = useState(false);
  const [openFirmware, setOpenFirmware] = useState(false);
  const [openDiagnost, setOpenDiagnost] = useState(false);
  const [openChargingProfile, setOpenChargingProfile] = useState(false);
  const [openDataTransfer, setDataTransfer] = useState(false);
  const [openTriggerMsg, setTriggerMsg] = useState(false);
  const [openClearProfile, setClearProfile] = useState(false);
  const [sendlocallist, setSendLocalList] = useState(false);
  const [getlocallist, setGetLocalList] = useState(false);
  const [getConfiguration, setGetConfiguration] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [loading, setLoading] = useState(false);
  const [getCompositeSchedule, setGetCompositeSchedule] = useState(false)

  const [getLog, setGetLog] = useState(false);
  const [installCertificate, setInstallCertificate] = useState(false);
  const [deleteCertificate, setDeleteCertificate] = useState(false);
  const [extendedTriggerMessage, setExtendedTriggerMessage] = useState(false);
  const [getInstalledCertificateIds, setGetInstalledCertificateIds] = useState(false);
  const [signCertificate, setSignCertificate] = useState(false);
  const [signedUpdateFirmware, setSignedUpdateFirmware] = useState(false);
  const [certificateSigned, setCertificateSigned] = useState(false);
  // const [getLog,setGetLog]=useState(false);



  console.log(selectedChargeBoxId);

  console.log(selectedChargeBoxId);

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 'none',
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }));


  const [CommandOptionList, setCommandOptionList] = useState([]);



  React.useEffect(() => {


    if (CommandOptionList.length === 0 && localStorage.getItem("permission"))

      setCommandOptionList(JSON.parse(localStorage.getItem("permission"))
        .filter(obj => obj.type === 'command')
        .map(obj => obj.name))

  }, [CommandOptionList.length])

  console.log(CommandOptionList);


  const handleClose = () => {
    setAnchorEl(null);
  };
  const add = () => {
    // // console.log('Add: ', data.name);
    handleClose();
  };
  const remove = () => {
    // // console.log('remove: ', data.name);
    handleClose();
  };

  const handleOCCPChange = (event, column) => {
    let text = event.target.selectedOptions[0].text;
    let value = 0;
    if (text === 'Change Availability') {
      value = 1;
      handlesetChngAvlityDialog();
    }
    if (text === 'Remote Start') {
      value = 2;
      handleOCCPOpenViewDialog();
    }
    if (text === 'Remote Stop') {
      value = 3;
      handleOCCPOpenViewDialog();
    }
    if (text === 'Restart') {
      value = 4;
      handleOCCPOpenReset();
    }
    if (text === 'Change Configuration') {
      value = 5;
      handleOCCPChangeModal();
    }
    if (text === 'Clear Cache') {
      value = 6;
      clearCache(event.target.value);
    }
    if (text === 'Clear Charging Profile') {
      value = 7;
      handleClearProfile();
    }

    if (text === 'Cancel Reservation') {
      value = 8;
      handleOCCPCancelModal();
    }
    if (text === 'Get Diagnostics') {
      value = 9;
      handleOCCPGetDiagnostics();
    }
    if (text === 'Get Configuration') {
      value = 10;
    }
    if (text === 'Set Charging Profile') {
      value = 11;
      handleOCCPChargingProfile();
    }

    if (text === 'Unlock Connector') {
      value = 12;
      handleOCCPUnlockConnector();
    }

    if (text === 'Update Firmware') {
      value = 13;
      handleOCCPUpdFirmware();
    }

    if (text === 'Reserve') {
      value = 13;
      handleOCCPReserve();
    }

    if (text === 'Data Transfer') {
      value = 14;
      handleDataTransfer();
    }

    if (text === 'Trigger Message') {
      value = 15;

      handleTriggerMessage();
    }

    if (text === 'Send Local List') {
      value = 16;
      handleSendLocalList();
    }

    if (text === 'Get Local List') {
      value = 17;
      handleGetLocalList();
    }

    if (text === 'Get Configuration') {
      value = 18;
      handleGetConfiguration();
    }

    if (text === 'Get Composite Schedule') {
      value = 19;
      handleGetCompositeSchedule();
    }

    if (text === "Get Log") {
      setGetLog(true);
      value = 20;
    }

    if (text === "Install Certificate") {
      setInstallCertificate(true)
      value = 21;

    }


    if (text === "Delete Certificate") {
      setDeleteCertificate(true)
      value = 22;

    }

    if (text === "Extended Trigger Message") {
      setExtendedTriggerMessage(true)
      value = 23;

    }

    if (text === "Get Installed Certificate Ids") {
      setGetInstalledCertificateIds(true)
      value = 24;

    }


    // if (text === "Sign Certificate") {
    //   setSignCertificate(true)
    //   value = 25;

    // }

    if (text === "Signed Update Firmware") {
      setSignedUpdateFirmware(true)
      value = 26;

    }

    if (text === "Certificate Signed") {
      setCertificateSigned(true)
      value = 27;

    }

    setSelectedChargeBoxId(column.chargeBoxId)

    setConnectors(column.chargeBoxPk);
    setOccpCommnad(value);

    event.target.value = '';
  };

  const handleOCCPChargingProfile = () => {
    setOpenChargingProfile(true);
  };

  const handleOCCPGetDiagnostics = () => {
    setOpenDiagnost(true);
  };

  const handleOCCPUpdFirmware = () => {
    setOpenFirmware(true);
  };

  const handleOCCPChangeModal = () => {
    setOpenChange(true);
  };

  const handleOCCPCancelModal = () => {
    setOpenCancReserve(true);
  };

  const handleClearProfile = () => {
    setClearProfile(true);
  };

  const handleOCCPOpenViewDialog = (column, command) => {
    setOpenOccpViewDialog(true);
    setConnectors(column.chargeBoxPk);
    setSelectedChargeBoxId(column.chargeBoxId)

    // Set the appropriate command based on the passed parameter
    if (command === 'Remote Start') {
      setOccpCommnad(2);
    } else if (command === 'Remote Stop') {
      setOccpCommnad(3);
    }
  };


  const handleOCCPOpenReset = (column) => {
    setConnectors(column.chargeBoxPk);
    setSelectedChargeBoxId(column.chargeBoxId)
    setOpenReset(true);
    setOccpCommnad(4); // Set the command for "Restart"
  };

  const handleOCCPUnlockConnector = () => {
    setOpenUnlock(true);
  };

  const handleOCCPReserve = () => {
    setOpenReserve(true);
  };

  const handleDataTransfer = () => {
    setDataTransfer(true);
  };

  const handleTriggerMessage = () => {
    setTriggerMsg(true);
  };


  const handleSendLocalList = () => {
    setSendLocalList(true);
  };

  const handleGetLocalList = () => {
    setGetLocalList(true);
  };


  const handleGetConfiguration = () => {
    setGetConfiguration(true);
  };

  const handleGetCompositeSchedule = () => {
    setGetCompositeSchedule(true);
  };



  const handleCloseOCCPViewDialog = () => {
    setOpenOccpViewDialog(false);
    setChngAvlityDialog(false);
    setOpenReset(false);
    setOpenUnlock(false);
    setOpenReserve(false);
    setOpenCancReserve(false);
    setOpenChange(false);
    setOpenFirmware(false);
    setOpenDiagnost(false);
    setDataTransfer(false);
    setTriggerMsg(false);
    setClearProfile(false);
    setOpenChargingProfile(false);
    setSendLocalList(false);
    setGetLocalList(false);
    setGetConfiguration(false);
    setConnectors(0);
    setSelectedChargeBoxId("");
    setOccpCommnad(0);
    setGetCompositeSchedule(false);

  };

  const handleOpenViewDialog = (row) => {
    setOpenViewDialog(true);
    setRowToView(row);
  };

  const handleCloseViewDialog = () => {
    setOpenViewDialog(false);
  };

  const instantPower = (currentPower, totalPower) => {
    return (
      <div style={{ width: "80%" }}>
        <div style={{ padding: "1px" }}>
          {currentPower} / {totalPower} kW
        </div>
        <LinearProgress variant="determinate" value={currentPower * 100 / totalPower} />
      </div>
    );
  };

  const connectorColor = (status) => {
    let color;
    if (status === 'Unavailable') {
      color = 'info';
    }
    if (status === 'Available') {
      color = 'primary';
    }
    if (status === 'Charging') {
      color = 'success';
    }
    if (status === 'Preparing' || status === 'Finishing') {
      color = 'warning';
    }
    if (status === 'Faulted') {
      color = 'error';
    }
    return color;
  };

  // Function to convert MUI color name to hex format
  const convertMuiColorToHex = (muiColor) => {
    const theme = createMuiTheme();
    try {
      return theme.palette[muiColor].main;
    } catch {
      return '#000000';
    }
  };



  const handleCommandMenu = (column) => {
    return (
      <Box display="flex" alignItems="center">
        {/* Clickable icons for start, stop, and restart */}
        <Tooltip title="Start">

          <IconButton style={{ margin: 0, padding: 0 }} disabled={!CommandOptionList.includes("Remote Start")}>
            <PlayArrowIcon
              style={{ color: CommandOptionList.includes("Remote Start") ? 'green' : 'gray', marginRight: '8px', cursor: 'pointer' }}
              onClick={() => handleOCCPOpenViewDialog(column, 'Remote Start')}
              disabled
            />

          </IconButton>

        </Tooltip>
        <Tooltip title="Stop">
          <IconButton style={{ margin: 0, padding: 0 }} disabled={!CommandOptionList.includes("Remote Stop")}>

            <StopIcon
              style={{ color: CommandOptionList.includes("Remote Stop") ? 'red' : 'gray', marginRight: '8px', cursor: 'pointer' }}
              onClick={() => handleOCCPOpenViewDialog(column, 'Remote Stop')}
            />
          </IconButton>

        </Tooltip>
        <Tooltip title="Restart">
          <IconButton style={{ margin: 0, padding: 0 }} disabled={!CommandOptionList.includes("Restart")}>

            <RestartAltIcon
              style={{ color: CommandOptionList.includes("Restart") ? '#daa520' : 'gray', marginRight: '8px', cursor: 'pointer' }}
              onClick={() => handleOCCPOpenReset(column)}
            />
          </IconButton>

        </Tooltip>

        {/* Dropdown */}
        <FormControl fullWidth>
          <NativeSelect
            onChange={(e) => handleOCCPChange(e, column)}
            fullWidth
            inputProps={{
              name: 'oocpcommand',
              id: 'uncontrolled-native',
            }}
            style={{ fontSize: '10', marginRight: '8px' }}
            IconComponent={() => <ArrowDropDownIcon style={{ color: darkTheme ? '#fff' : '#000' }} />}
            sx={{
              "& .MuiSelect-icon": {
                color: darkTheme ? '#fff' : '#000'
              },
              "&:before, &:after": {
                borderBottom: '1px solid',
                borderColor: darkTheme ? '#fff' : '#000'
              },
              "& option": {
                backgroundColor: darkTheme ? '#000' : '#fff',
                color: darkTheme ? '#fff' : '#000',
              },
            }}
          >
            <option style={{ backgroundColor: darkTheme ? '#000' : '#fff', color: darkTheme ? '#fff' : '#000' }} value={0}></option>
            {CommandOptionList.filter(val => !['Remote Start', 'Remote Stop', 'Restart', 'Config'].includes(val)).map((val) => <option style={{ backgroundColor: darkTheme ? '#000' : '#fff', color: darkTheme ? '#fff' : '#000' }} value={column.chargeBoxPk}>{val}</option>)
            }
            <option style={{ backgroundColor: darkTheme ? '#000' : '#fff', color: darkTheme ? '#fff' : '#000' }} value={column.chargeBoxPk}>{"Get Log"}</option>
            <option style={{ backgroundColor: darkTheme ? '#000' : '#fff', color: darkTheme ? '#fff' : '#000' }} value={column.chargeBoxPk}>{"Install Certificate"}</option>
            <option style={{ backgroundColor: darkTheme ? '#000' : '#fff', color: darkTheme ? '#fff' : '#000' }} value={column.chargeBoxPk}>{"Delete Certificate"}</option>
            <option style={{ backgroundColor: darkTheme ? '#000' : '#fff', color: darkTheme ? '#fff' : '#000' }} value={column.chargeBoxPk}>{"Extended Trigger Message"}</option>
            <option style={{ backgroundColor: darkTheme ? '#000' : '#fff', color: darkTheme ? '#fff' : '#000' }} value={column.chargeBoxPk}>{"Get Installed Certificate Ids"}</option>
            {/* <option style={{ backgroundColor: darkTheme ? '#000' : '#fff', color: darkTheme ? '#fff' : '#000' }} value={column.chargeBoxPk}>{"SignCertificate"}</option> */}
            <option style={{ backgroundColor: darkTheme ? '#000' : '#fff', color: darkTheme ? '#fff' : '#000' }} value={column.chargeBoxPk}>{"Signed Update Firmware"}</option>
            <option style={{ backgroundColor: darkTheme ? '#000' : '#fff', color: darkTheme ? '#fff' : '#000' }} value={column.chargeBoxPk}>{"Certificate Signed"}</option>
            {/* <option style={{ backgroundColor: darkTheme ? '#000' : '#fff', color: darkTheme ? '#fff' : '#000' }} value={column.chargeBoxPk}>{val}</option> */}



            {/* {console.log("value of dropdown", CommandOptionList)} */}
          </NativeSelect>
        </FormControl>

        {/* ConfigDialog */}
        <ConfigDialog row={column} CommandOptionList={CommandOptionList} />
      </Box>
    );
  };


  const columns = [
    {
      field: 'action',
      headerName: '',
      headerClassName: 'MuiDataGrid-header',
      width: 10,
      flex: 2,
      renderCell: (params) => {
        return <VisibilityIcon onClick={() => handleOpenViewDialog(params.row)} />
      },
    },
    {
      field: 'chargeBoxId',
      headerClassName: 'MuiDataGrid-header',
      minWidth: 170,
      flex: 5,
      headerName: 'Charger ID',
      renderCell: (params) => {
        return <ChargerDetailDialog row={params.row} />
      },
    },
    {
      field: 'powerSource',
      headerClassName: 'MuiDataGrid-header',
      minWidth: 120,
      flex: 5,
      headerName: 'Source',

    },
    {
      field: 'status',
      headerClassName: 'MuiDataGrid-header',
      minWidth: 100,
      flex: 4,
      headerName: 'Status',

      renderCell: (params) => {
        let hexColor;

        // Hardcode color for 'Unavailable' status
        if (params.value === 'Unavailable') {
          hexColor = '#878e98';
        } else {
          // For other statuses, use Material-UI color logic
          const muiColor = connectorColor(params.value);
          hexColor = convertMuiColorToHex(muiColor);
        }

        // console.log('Color is:', hexColor);

        return (
          <div>
            <div
              style={{
                background: hexColor,
                borderRadius: '25px',
                borderColor: 'white',
                padding: '5px',
                color: 'white',
              }}
            >
              {params.value}
            </div>
          </div>
        );
      },
    },
    {
      field: 'connectors',
      headerName: 'Connectors',
      headerClassName: 'MuiDataGrid-header',
      align: 'left',
      flex: 4,
      minWidth: 120,
      renderCell: (params) => (

        <div >
          {params.row.connectorList
            .filter((type) => type.name !== 'C0')
            .map((type, index) => {
              let hexColor;

              // Hardcode color for 'Unavailable' status
              if (type.status === 'Unavailable') {
                hexColor = '#878e98';
              } else {
                // For other statuses, use Material-UI color logic
                const muiColor = connectorColor(type.status);
                hexColor = convertMuiColorToHex(muiColor);
              }
              // console.log('Color of connector is:', type.status);

              return type.status === 'Faulted' ? (
                <HtmlTooltip
                  title={
                    <React.Fragment>
                      <Typography color="inherit">Error Code : <b>{type.chargerErrorCode.errorCode} </b></Typography>
                      <Typography color="inherit">Message : <b>{type.chargerErrorCode.message}</b></Typography>
                      {/* <em>{"And here's"}</em> <b>{'some'}</b> <u>{'amazing content'}</u>.{' '}
                      {"It's very engaging. Right?"} */}

                    </React.Fragment>
                  } arrow >

                  <div
                    key={index}
                    style={{
                      display: 'inline-block',
                      margin: '2px', // Adjust margin as needed
                      padding: '3px',
                      borderRadius: '10px',
                      borderColor: 'white',
                      background: hexColor,
                      color: 'white',
                    }}
                  >
                    {type.name}
                  </div>

                </HtmlTooltip>
              ) : (
                <div
                  key={index}
                  style={{
                    display: 'inline-block',
                    margin: '2px', // Adjust margin as needed
                    padding: '3px',
                    borderRadius: '10px',
                    borderColor: 'white',
                    background: hexColor,
                    color: 'white',
                  }}
                >
                  {type.name}
                </div>
              );
            })}
        </div>
      ),

    },
    {
      field: 'instantPower',
      headerName: 'Instant Power (kW)',
      flex: 6,
      headerClassName: 'MuiDataGrid-header',
      minWidth: 140,
      filterable: false,
      renderCell: (params) =>
        instantPower(params.row.totalConsumption, params.row.totalChargerPower),
    },
    {
      field: 'operations',
      headerName: 'Command Management',
      minWidth: 200,
      flex: 12,
      headerClassName: 'MuiDataGrid-header',
      renderCell: (params) => handleCommandMenu(params.row),
    },
  ];
  useEffect(() => {

    setLoading(true);
    api
      .getChargersStatusList()
      .then(({ data, index }) => {
        if (!isCanceled) {
          if (data) {
            console.log("status", data)
            setData(data.map((obj, index) => ({
              ...obj,
              id: index
            })));
          }

          // console.log(data)
          setLoading(false);

          setReady(true);
          setTrue(index)
        }
      })
      .catch(() => {
        setError(true);
        // console.log('Cannot load event data', e);
      });

    let isCanceled = false;
    const intervalId = setInterval(() => {
      isCanceled = false;
      api
        .getChargersStatusList()
        .then(({ data }) => {
          if (!isCanceled) {
            if (data) {
              setData(data.map((obj, index) => ({
                ...obj,
                id: index
              })));
            }
            // console.log(data)
            setReady(true);
          }
        })
        .catch(() => {
          setError(true);
          // console.log('Cannot load event data', e);
        });
    }, 10000);

    return () => {
      isCanceled = true;
      clearInterval(intervalId);
    };
  }, [error]);

  const clearCache = (chargeBoxId) => {
    chargeBoxId = Number(chargeBoxId);
    api
      .clearCache(chargeBoxId)
      .then(() => {
        return toast.success('Request Submitted');
      })
      .catch(() => {
        // console.log('Cannot load event data', e);
      });
  };
  const handlesetChngAvlityDialog = () => {
    setChngAvlityDialog(true);
  };

  // console.log(True, 'fdff')
  // Define background color and text color based on dark theme
  const backgroundColor = darkTheme ? '#333333' : '#f9f9f9';
  const backgroundColorOdd = darkTheme ? '#333333' : '#f9f9f9';
  const backgroundColorEven = darkTheme ? '#474747' : '#e0e0e0';
  const textColor = darkTheme ? '#F2E34C' : 'black';
  const cellColor = darkTheme ? '#fff' : 'black';
  const useStyles = makeStyles((theme) => ({
    exportButton: {
      display: 'flex',
      alignItems: 'center',
    },
  }));

  function CustomExportButton() {
    const classes = useStyles();

    return (
      <div className={classes.exportButton}>
        <GridToolbarExport style={{ color: darkTheme ? '#fff' : '#333' }} />
        <Typography variant="body2" style={{ color: darkTheme ? '#e0e0e0' : '#333', fontSize: '10px', marginLeft: '5px' }}>[Note: Select rows per page to export the data.]</Typography>
      </div>
    );
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton style={{ color: darkTheme ? '#fff' : '#333' }} />
        <GridToolbarFilterButton style={{ color: darkTheme ? '#fff' : '#333' }} />
        <GridToolbarDensitySelector style={{ color: darkTheme ? '#fff' : '#333' }} />
        {/* <CustomExportButton  /> */}
      </GridToolbarContainer>
    );
  }

  console.log(chargeBoxId)

  return (
    <Grid height={"100%"} style={{ backgroundColor, border: 'none' }} sx={{ width: '100%' }}>
      <ChargerViewDialog
        open={openViewDialog}
        handleOpenDialog={handleOpenViewDialog}
        handleCloseDialog={handleCloseViewDialog}
        row={rowToView}
        chargeBoxId={selectedChargeBoxId}

      />

      <OccpCommandViewDialog
        open={openOccpViewDialog}
        handleOpenDialog={handleOCCPOpenViewDialog}
        handleCloseDialog={handleCloseOCCPViewDialog}
        row={connectors}
        occpCommnad={occpCommnad}
        chargeBoxId={selectedChargeBoxId}
      />

      <OccpChangeAvailabilityViewDialog
        open={chngAvlityDialog}
        handleOpenDialog={handlesetChngAvlityDialog}
        handleCloseDialog={handleCloseOCCPViewDialog}
        row={connectors}
        occpCommnad={occpCommnad}
        chargeBoxId={selectedChargeBoxId}

      />

      <OccpResetCommand
        open={openReset}
        handleOpenDialog={handleOCCPOpenReset}
        handleCloseDialog={handleCloseOCCPViewDialog}
        row={connectors}
        occpCommnad={occpCommnad}
        chargeBoxId={selectedChargeBoxId}

      />
      <OccpUnlockConnector
        open={openUnlock}
        handleOpenDialog={handleOCCPUnlockConnector}
        handleCloseDialog={handleCloseOCCPViewDialog}
        row={connectors}
        occpCommnad={occpCommnad}
        chargeBoxId={selectedChargeBoxId}

      />


      <OccpSetLocalListViewDialog
        open={sendlocallist}
        handleOpenDialog={handleSendLocalList}
        handleCloseDialog={handleCloseOCCPViewDialog}
        row={connectors}
        occpCommnad={occpCommnad}
        chargeBoxId={selectedChargeBoxId}

      />

      <OccpGetLocalListViewDialog
        open={getlocallist}
        handleOpenDialog={handleGetLocalList}
        handleCloseDialog={handleCloseOCCPViewDialog}
        row={connectors}
        occpCommnad={occpCommnad}
        chargeBoxId={selectedChargeBoxId}

      />
      <OccpGetConfigurationDialog
        open={getConfiguration}
        handleOpenDialog={handleGetConfiguration}
        handleCloseDialog={handleCloseOCCPViewDialog}
        row={connectors}
        occpCommnad={occpCommnad}
        chargeBoxId={selectedChargeBoxId}

      />

      <OccpReserveModal
        open={openReserve}
        handleOpenDialog={handleOCCPReserve}
        handleCloseDialog={handleCloseOCCPViewDialog}
        row={connectors}
        occpCommnad={occpCommnad}
        chargeBoxId={selectedChargeBoxId}

      />

      <OccpCancelReservation
        open={openCancReserve}
        handleOpenDialog={handleOCCPCancelModal}
        handleCloseDialog={handleCloseOCCPViewDialog}
        row={connectors}
        occpCommnad={occpCommnad}
        chargeBoxId={selectedChargeBoxId}

      />

      <OccpChangeConfiguration
        open={openChange}
        handleOpenDialog={handleOCCPChangeModal}
        handleCloseDialog={handleCloseOCCPViewDialog}
        row={connectors}
        occpCommnad={occpCommnad}
        chargeBoxId={selectedChargeBoxId}

      />

      <OccpUpdateFirmware
        open={openFirmware}
        handleOpenDialog={handleOCCPUpdFirmware}
        handleCloseDialog={handleCloseOCCPViewDialog}
        row={connectors}
        occpCommnad={occpCommnad}
        chargeBoxId={selectedChargeBoxId}

      />

      <OccpGetDiagnosticsModal
        open={openDiagnost}
        handleOpenDialog={handleOCCPGetDiagnostics}
        handleCloseDialog={handleCloseOCCPViewDialog}
        row={connectors}
        occpCommnad={occpCommnad}
        chargeBoxId={selectedChargeBoxId}

      />

      <OccpSetChargingProfile
        open={openChargingProfile}
        handleOpenDialog={handleOCCPChargingProfile}
        handleCloseDialog={handleCloseOCCPViewDialog}
        row={connectors}
        occpCommnad={occpCommnad}
        chargeBoxId={selectedChargeBoxId}

      />

      <OccpDataTransfer
        open={openDataTransfer}
        handleOpenDialog={handleDataTransfer}
        handleCloseDialog={handleCloseOCCPViewDialog}
        row={connectors}
        occpCommnad={occpCommnad}
        chargeBoxId={selectedChargeBoxId}

      />

      <OccpTriggerMessageModal
        open={openTriggerMsg}
        handleOpenDialog={handleTriggerMessage}
        handleCloseDialog={handleCloseOCCPViewDialog}
        row={connectors}
        occpCommnad={occpCommnad}
        chargeBoxId={selectedChargeBoxId}

      />

      <OccpClearChargingProfileModal
        open={openClearProfile}
        handleOpenDialog={handleClearProfile}
        handleCloseDialog={handleCloseOCCPViewDialog}
        row={connectors}
        occpCommnad={occpCommnad}
        chargeBoxId={selectedChargeBoxId}

      />

      <OccpGetCompositeSchedule

        open={getCompositeSchedule}
        handleOpenDialog={handleGetCompositeSchedule}
        handleCloseDialog={handleCloseOCCPViewDialog}
        row={connectors}
        occpCommnad={occpCommnad}
        chargeBoxId={selectedChargeBoxId}

      />

      <OccpGetLog

        open={getLog}
        handleCloseDialog={() => setGetLog(false)}
        row={connectors}
        occpCommnad={occpCommnad}
        chargeBoxId={selectedChargeBoxId}

      />


      <OccpInstallCertificate

        open={installCertificate}
        handleCloseDialog={() => setInstallCertificate(false)}
        row={connectors}
        occpCommnad={occpCommnad}
        chargeBoxId={selectedChargeBoxId}

      />
      <OccpDeleteCertificate

        open={deleteCertificate}
        handleCloseDialog={() => setDeleteCertificate(false)}
        row={connectors}
        occpCommnad={occpCommnad}
        chargeBoxId={selectedChargeBoxId}

      />
      <OccpExtendedTriggerMessage

        open={extendedTriggerMessage}
        handleCloseDialog={() => setExtendedTriggerMessage(false)}
        row={connectors}
        occpCommnad={occpCommnad}
        chargeBoxId={selectedChargeBoxId}

      />
      <OccpGetInstalledCertificateIds

        open={getInstalledCertificateIds}
        handleCloseDialog={() => setGetInstalledCertificateIds(false)}
        row={connectors}
        occpCommnad={occpCommnad}
        chargeBoxId={selectedChargeBoxId}

      />



      <OccpSignedUpdateFirmware

        open={signedUpdateFirmware}
        handleCloseDialog={() => setSignedUpdateFirmware(false)}
        row={connectors}
        occpCommnad={occpCommnad}
        chargeBoxId={selectedChargeBoxId}

      />


      <OccpCertificateSigned

        open={certificateSigned}
        handleCloseDialog={() => setCertificateSigned(false)}
        row={connectors}
        occpCommnad={occpCommnad}
        chargeBoxId={selectedChargeBoxId}

      />

{/* 
      <OccpSignCertificate

        open={signCertificate}
        handleCloseDialog={() => setSignCertificate(false)}
        row={connectors}
        occpCommnad={occpCommnad}
        chargeBoxId={selectedChargeBoxId}

      /> */}










      {/* <Box style={{ height: "100%", overflow: 'auto', display: "block", backgroundColor, border: 'none' }} sx={{ width: '100%' }}> */}


      {
        loading ? <Loading /> :
          <DataGrid
            rows={data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
            columns={columns}
            pageSize={rowsPerPage}
            page={page}
            width={'100%'}
            onPageChange={(newPage) => setPage(newPage)}
            rowsPerPageOptions={[5, 10, 25, 50]}
            onRowsPerPageChange={(pageSize) => {
              setRowsPerPage(pageSize);
              setPage(0);
            }}
            components={{
              Toolbar: CustomToolbar, // Custom toolbar component
              Pagination: (props) => (
                <CustomPagination
                  count={data.length}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  darkTheme={darkTheme}
                />
              ),

              NoRowsOverlay: () => (
                <div style={{ textAlign: 'center', color: textColor }}>
                  {/* Custom no rows overlay */}
                </div>
              ),
            }}
            sx={{
              '& .MuiDataGrid-cell': {
                color: cellColor,
                border: 'none',
              },
              '& .MuiDataGrid-row:nth-of-type(odd)': {
                backgroundColor: backgroundColorOdd, // Background color for odd rows
                border: 'none',
              },
              '& .MuiDataGrid-row:nth-of-type(even)': {
                backgroundColor: backgroundColorEven, // Background color for even rows
                border: 'none',
              },
              '& .MuiDataGrid-columnHeader': {
                backgroundColor: backgroundColor,
                color: textColor,
              },
              '& .MuiDataGrid-iconButtonContainer': {
                visibility: 'visible',
              },
              '& .MuiDataGrid-sortIcon': {
                opacity: 'inherit !important',
                color: darkTheme ? '#fff' : '#000'
              },
              '& .MuiDataGrid-menuIconButton': {
                opacity: 'inherit !important',
                color: darkTheme ? '#fff' : '#000',
              }
            }}
          />
      }
      {/* </Box> */}
    </Grid>
  );
};
export default ChargerTables;

// autoHeight // Enable autoHeight
// disableSelectionOnClick // Disable row selection on click
// disableColumnFilter // Disable column filters
// disableColumnMenu // Disable column menu
// rowHeight={32}