
import React, { useState, useEffect, useRef } from 'react';
import MaterialTable from '@material-table/core';
// import IconButton from '@material-ui/core/IconButton';
import VehicleGraphApi from '../VehicleGraphApi';
import VehicleStatusViewDialog from './VehicleStatusViewDialog';
import LinearWithValueLabel from './LinearWithValueLabel';
import Box from '@mui/material/Box';
import { FormControl, Grid, MenuItem, Select, TablePagination, TextField, Typography, IconButton, LinearProgress } from '@mui/material';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid';
import { FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage } from '@material-ui/icons';
import { useTheme } from '@mui/system';
import { makeStyles } from '@material-ui/core/styles';
//import ReactEcharts from 'echarts-for-react';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
// import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Title } from '@mui/icons-material';
import { StyledTable } from 'app/utils/utils';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import Loading from 'app/components/MatxLoading';

const useStyles = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
    display: 'flex',
    alignItems: 'center',
  },
  select: {
    marginLeft: theme.spacing(1),
  },
}));

const CustomPagination = ({ count, page, rowsPerPage, onChangePage, onChangeRowsPerPage, darkTheme }) => {
  const classes = useStyles();

  const handleFirstPageButtonClick = () => {
    onChangePage(0);
  };

  const handleBackButtonClick = () => {
    onChangePage(page - 1);
  };

  const handleNextButtonClick = () => {
    onChangePage(page + 1);
  };

  const handleLastPageButtonClick = () => {
    onChangePage(Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  const backgroundColor = darkTheme ? '#333333' : '#f9f9f9';
  const textColor = darkTheme ? '#fff' : 'rgba(0, 0, 0, 0.87)';
  const dropdownBackgroundColor = darkTheme ? '#000' : '#fff'; 
  const dropdownTextColor = darkTheme ? '#fff' : 'rgba(0, 0, 0, 0.87)';

  return (
    <Box className={classes.root} style={{ backgroundColor, color: textColor, marginRight: '10px', height: '48px' }}> 
       <Select
          className={classes.select}
          value={rowsPerPage}
          onChange={(e) => onChangeRowsPerPage(e)}
          style={{ color: textColor }}
          IconComponent={() => <ArrowDropDownIcon style={{ color: darkTheme ? '#fff' : '#000', position: 'absolute', right: '8px', top: '50%', transform: 'translateY(-50%)' }} />}
          MenuProps={{
            PaperProps: {
              style: {
                backgroundColor: dropdownBackgroundColor,
                color: dropdownTextColor,
              },
            },
          }}
        >
          {[10, 20].map((pageSize) => ( // Added -1 for "All" option
            <MenuItem key={pageSize} value={pageSize}>
              {pageSize === -1 ? 'All' : pageSize} {/* Display "All" instead of -1 */}
            </MenuItem>
          ))}
        </Select>

      <Typography variant="body2" style={{ color: textColor }}>Rows per page</Typography>
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
        <FirstPage style={{ color: darkTheme ? '#fff' : 'rgba(0, 0, 0, 0.54)' }} />
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        <KeyboardArrowLeft style={{ color: darkTheme ? '#fff' : 'rgba(0, 0, 0, 0.54)' }} />
      </IconButton>
      <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
        <KeyboardArrowRight style={{ color: darkTheme ? '#fff' : 'rgba(0, 0, 0, 0.54)' }} />
      </IconButton>
      <IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="last page">
        <LastPage style={{ color: darkTheme ? '#fff' : 'rgba(0, 0, 0, 0.54)' }} />
      </IconButton>
      <Typography variant="body2">{`${page + 1} of ${Math.ceil(count / rowsPerPage)}`}</Typography>
    </Box>
  );
};



const VehicleStatusTable = ({ darkTheme }) => {
  const addActionRef = React.useRef();
  const api = new VehicleGraphApi();
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [filteredData, setFilteredData] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(200);
  const searchData = React.useRef({});
  const [pageSize, setPageSize] = React.useState(10);

  React.useEffect(() => {
    let isCanceled = false;

    setLoading(true);

    const fetchData = () => {
      api
        .getVehicleChargingStatus(null)
        .then(({ data }) => {
          if (!isCanceled) {
            // Assign unique ids to each row
            const newData = data.map((row, index) => ({ ...row, id: index + 1 }));
            setData(newData);
            setFilteredData(
              newData.filter((o) => {
                let res = true;
                if (
                  searchData.current.hasOwnProperty('charge_box_id') &&
                  o.hasOwnProperty('charge_box_id')
                )
                  res =
                    res &&
                    o['charge_box_id']
                      .toLocaleString()
                      .includes(searchData.current['charge_box_id']);
                if (searchData.current.hasOwnProperty('end_soc'))
                  res = res && o['end_soc'].toLocaleString().includes(searchData.current['end_soc']);
                if (
                  searchData.current.hasOwnProperty('vehicle_id') &&
                  o['vehicle_id']
                )
                  res =
                    res &&
                    o['vehicle_id'].toLocaleString().includes(searchData.current['vehicle_id']);
                if (searchData.current.hasOwnProperty('status'))
                  res = res && o['status'].toLocaleString().includes(searchData.current['status']);
                return res;
              })
            );
            setLoading(false);

          }
        })
        .catch((e) => {
          setError(true);
          console.log('Cannot load event data', e);
        });
    };

    // Fetch data initially
    fetchData();

    // Set up interval for data refresh
    const intervalId = setInterval(fetchData, 10000); // Refresh every 3 seconds

    return () => {
      isCanceled = true;
      clearInterval(intervalId); // Clear interval on component unmount
    };
  }, [error]);

  const handleRefreshSearch = async () => {
    setFilteredData(
      data.filter((o) => {
        let res = true;
        if (
          searchData.current.hasOwnProperty('charge_box_id') &&
          o.hasOwnProperty('charge_box_id')
        )
          res =
            res &&
            o['charge_box_id'].toLocaleString().includes(searchData.current['charge_box_id']);
        if (searchData.current.hasOwnProperty('end_soc'))
          res = res && o['end_soc'].toLocaleString().includes(searchData.current['end_soc']);
        if (
          searchData.current.hasOwnProperty('vehicle_id') &&
          o['vehicle_id']
        )
          res =
            res &&
            o['vehicle_id'].toLocaleString().includes(searchData.current['vehicle_id']);
        if (searchData.current.hasOwnProperty('status'))
          res = res && o['status'].toLocaleString().includes(searchData.current['status']);
        return res;
      })
    );
  };

  const handleSearch = (e) => {
    searchData.current = { ...searchData.current, [e.target.name]: e.target.value };
    handleRefreshSearch();
  };

  const dateTimeFormatter = (str) => {
    if (str === undefined)
      return " ";
    // const inputDateString = "2024-01-07T17:30:34.646+05:30";
    // console.log(str);
    const inputDate = new Date(str.toString());

    // Step 2: Extract components
    const dayOfWeek = new Intl.DateTimeFormat('en-IN', { weekday: 'long' })
      .format(inputDate).toString().substring(0, 3);

    const day = inputDate.getDate();
    const month = new Intl.DateTimeFormat('en-IN', { month: 'long' }).format(inputDate);
    const year = inputDate.getFullYear();

    // Step 3: Format time
    const hours = inputDate.getHours();
    const minutes = inputDate.getMinutes();
    const seconds = inputDate.getSeconds();

    const formattedTime = `Started At ${hours}:${minutes}`;

    // Final formatted date and time
    // const formattedDateTime = ` ${dayOfWeek}, ${day} ${month} ${year} | ${formattedTime} | `;

        const formattedDateTime = `| ${formattedTime} |`;


    // console.log(formattedDateTime);

    return formattedDateTime;
  }

  

  const statusColors = {
    Charging: '#008000',
    Available: '#4169e1',
    Preparing: '#FFAF38',
    Faulted: '#FF5733',
    Reserved: '#4BC0C0',
    Unavailable: '#878E98',
  };

 const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const columns = [
    { field: 'id', headerName: 'ID', width: 90,flex:1 },
    { field: 'vehicleLicensePlate', headerName: 'Vehicle License Plate', width: 200,flex:1 },
    { field: 'charge_box_id', headerName: 'Charger ID-Connector', width: 220,flex:1 },
    { field: 'power', headerName: 'Charging Power (kW)', width: 220,flex:1, valueGetter: (params) => params.row['power'] !== undefined ? parseFloat(params.row['power']).toFixed(2) : '' },
    { field: 'duration', headerName: 'Charging Duration', width: 200,flex:1 },
    { field: 'preConditioning', headerName: 'Pre-Conditioning', width: 180,flex:1 },
    { field: 'status', headerName: 'Status', width: 120,flex:1 },
    {
      field: 'end_soc',
      headerName: 'Battery Info.',
      width: 300,
      flex:1,
      renderCell: renderProgress,
    },
  ];

  // function renderProgress(params) {
  //   return(
  //     <div style={{ position: "relative", display: "flex", alignItems: "center", height: "3rem", marginLeft: "5px", padding: 0 }}>
  //                                <div style={{
  //                                     height: "100%", zIndex: "1", width: "100%", position: "absolute", top: 0, right: 0
  //                                     , marginLeft: "2px", marginRight: "2px"
  //                                   }}>
  //                                     <LinearWithValueLabel value={params.row['end_soc']} />
  //                                   </div>
  //                                   <div style={{ color: "white", display: "flex", align: "center", padding: "0 3px 0 3px", zIndex: "1", position: "relative" }}>
  //                                   {params.row['end_soc'] && params.row['end_soc'] + "/100%"} 
  //                                   {/* <a style={{ fontSize: "13px" }}>{dateTimeFormatter(params.row['start_time'])}</a>
  //                                     &nbsp;{parseInt(params.row['energy'])} kWh */}
  //                                   </div>
  //                                 </div>
  //   )
  // };

  
  function renderProgress(params) {
    return(
    <div  style={{ position: "relative", display: "flex", alignItems: "center", textAlign: "center",width: '225px', height: "3rem", marginLeft: "5px", padding: 0 }}>
        <div style={{
                                                height: "100%", zIndex: "1", width: "100%", position: "absolute", top: 0, right: 0
                                                , marginLeft: "2px", marginRight: "2px"
                                            }}><LinearWithValueLabel value={params.row['end_soc']} />
                                            </div>
                                            <div style={{ color: "white", textAlign: "center", width: "100%", align: "center", padding: "0 3px 0 3px", zIndex: "1", position: "relative" }}>
                                                {params.row['end_soc'] && params.row['end_soc'] + "/100%"}
                                                {/* <a style={{ fontSize: "13px" }}>{dateTimeFormatter(row['start_time'])}</a> */}
                                                {/* &nbsp;{parseInt(row['kWh'])} kWh */}
                                            </div>
    </div> 
    )
}

  // Define background color and text color based on dark theme
const backgroundColor = darkTheme ? '#333333' : '#f9f9f9';
const backgroundColorOdd = darkTheme ? '#333333' : '#f9f9f9';
const backgroundColorEven = darkTheme ? '#474747' : '#e0e0e0';
const textColor = darkTheme ? '#F2E34C' : 'black';
const cellColor = darkTheme ? '#fff' : 'black';

const useStyles = makeStyles((theme) => ({
  exportButton: {
    display: 'flex',
    alignItems: 'center',
  },
}));
function CustomExportButton() {
  const classes = useStyles();

  return (
    <div className={classes.exportButton}>
      <GridToolbarExport style={{ color: darkTheme ? '#fff' : '#333' }}  csvOptions={{fileName: "Vehicle Status"}} />
      <Tooltip title="[Note: Select rows per page to export the data.] ">
      <InfoIcon style={{ color: darkTheme ? '#e0e0e0' : '#333', fontSize: '15px', marginLeft: '5px' }} />
      </Tooltip>
    </div>
  );
}

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton style={{ color: darkTheme ? '#fff' : '#333' }} />
      <GridToolbarFilterButton style={{ color: darkTheme ? '#fff' : '#333' }} />
      <GridToolbarDensitySelector style={{ color: darkTheme ? '#fff' : '#333' }}/>
      <CustomExportButton />
    </GridToolbarContainer>
    
  );
}

// Remove the ID column from the columns array
const filteredColumns = columns.filter(column => column.field !== 'id');

  return (
    <div style={{ backgroundColor }}>
      <Paper style={{ height: '60vh', width: '100%', backgroundColor }}>
      {
        loading?<Loading/>:
      <DataGrid
        rows={filteredData.map((row, index) => ({ ...row, id: index + 1 })).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
        columns={filteredColumns}
                    pageSize={pageSize}
                    page={page}
                    onPageChange={(newPage) => setPage(newPage)}
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    rowCount={filteredData.length}
                    pagination
                    getRowId={(row) => row.id}
                    components={{
                      Toolbar: CustomToolbar, // Custom toolbar component
                      Pagination: (props) => (
                        <CustomPagination
                        count={data.length}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        darkTheme={darkTheme}
                      />
                      ),
          NoRowsOverlay: () => (
            <div style={{ textAlign: 'center', color: textColor }}>
              {/* Custom no rows overlay */}
            </div>
          ),
        }}
      sx={{
        '& .MuiDataGrid-cell': {
          color: cellColor,
          border: 'none',
          fontSize: '16px'
        },
        '& .MuiDataGrid-row:nth-of-type(odd)': {
          backgroundColor: backgroundColorOdd, // Background color for odd rows
          border: 'none',
        },
        '& .MuiDataGrid-row:nth-of-type(even)': {
          backgroundColor: backgroundColorEven, // Background color for even rows
          border: 'none',
        },
        '& .MuiDataGrid-columnHeader': {
          backgroundColor: backgroundColor,
          color: textColor,
          fontSize: '16px'
        },
        '& .MuiDataGrid-iconButtonContainer' : {
          visibility: 'visible',
        },
        '& .MuiDataGrid-sortIcon' : {
          opacity: 'inherit !important',
          color: darkTheme ? '#fff' : '#000'
        },
        '& .MuiDataGrid-menuIconButton' : {
          opacity: 'inherit !important',
          color: darkTheme ? '#fff' : '#000',
        }
      }}
      />
    }

      </Paper>
    </div>
  );
};

export default VehicleStatusTable;

// import React, { useState, useEffect, useRef } from 'react';
// import MaterialTable from '@material-table/core';
// import IconButton from '@material-ui/core/IconButton';
// import VehicleGraphApi from '../VehicleGraphApi';
// import VehicleStatusViewDialog from './VehicleStatusViewDialog';
// import LinearWithValueLabel from './LinearWithValueLabel';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import { Box, Divider, FormControl, InputLabel, MenuItem, Select, TablePagination, TextField } from '@mui/material';
// import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
// import { useTheme } from '@mui/system';
// import { makeStyles } from '@material-ui/core/styles';
// //import ReactEcharts from 'echarts-for-react';
// import Table from '@mui/material/Table';
// import Paper from '@mui/material/Paper';
// import TableBody from '@mui/material/TableBody';
// import TableCell from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
// import SearchIcon from '@mui/icons-material/Search';
// import InputAdornment from '@mui/material/InputAdornment';

// // import TablePagination from '@mui/material/TablePagination';
// import TableRow from '@mui/material/TableRow';
// import { Title } from '@mui/icons-material';
// import { StyledTable } from 'app/utils/utils';

// const useStyles = makeStyles({
//   table: {
//     '& .MuiTableCell-root': {
//       borderLeft: '1px solid rgba(224, 224, 224, 1)',
//     },

//   },
//   lensIcon: {
//     color: 'white', 
//   },
//   // columnHeader: {
//   //   borderBottom: '2px solid white', // Change the color of the line below column headers
//   // },
// });

// const VehicleStatusTable = ({ darkTheme }) => {
//   const addActionRef = React.useRef();
//   const api = new VehicleGraphApi();
//   const [data, setData] = useState([]);
//   const [loaded, setReady] = useState(false);
//   const [error, setError] = useState(false);
//   const [filteredData, setFilteredData] = useState([]);
//   const [page, setPage] = React.useState(0);
//   const [rowsPerPage, setRowsPerPage] = React.useState(10);
//   const searchData = useRef({});
//   const classes = useStyles();


//   let columns = [
//     // { title: 'Action', field: 'name' },
//     // {
//     //   title: 'Action',
//     //   editable: false,
//     //   render: (rowData) =>
//     //     rowData && (
//     //       <IconButton onClick={() => addActionRef.current.click()}>
//     //         <VehicleStatusViewDialog />
//     //       </IconButton>
//     //     ),
//     // },
//     // { label: 'Depot Id', id: 'DepotId', align: "center" },

//     // { label: 'Vehicle Name', id: 'vehicle_id', align: "left", width: 150 },
    
//     { label: 'Vehicle License Plate', id: 'vehicleLicensePlate', align: "left", width: 200 },


//     { label: 'Charger ID-Connector', id: 'charge_box_id', align: "left", width: 200 },

//     // { id: 'transaction_pk', label: 'Transaction id', width: 130, align: 'center' },


//     { label: 'Charging Power (kW)', id: 'power', align: "left", width: 180 },


//     // { label: 'Connector Id', id: 'connector_Id', align: "center" },


//     // {
//     //   label: 'Battery Capacity (kW)', id: 'battery_capacity', align: "center",
//     //   format: (props) => Math.round(props.battery_capacity),
//     // },
//     // { label: 'Start SoC (%)', id: 'start_soc', align: "center" },
//     // { title: 'End SoC (%)', field: 'end_soc' },
//     { label: 'Charging Duration', id: 'duration', align: "left", width: 160 },
//     { label: 'Pre-Conditioning', id: 'preConditioning', align: "left", width: 150 },
//     // { label: 'Charging Power (kW)', id: 'kW', align: "center" },
//     // { label: 'Consumption (kWh)', id: 'kWh', align: "center" },

//     { label: 'Status', id: 'status', align: "left", width: 120 },


//     {
//       label: 'Battery Info.', id: 'end_soc', width: 300,
//       format: (props) => renderProgress(props), align: "left"
//     },




//   ];



//   const handleChangePage = (event, newPage) => {
//     setPage(newPage);
//   };

//   const handleChangeRowsPerPage = (event) => {
//     setRowsPerPage(parseInt(event.target.value, 10));
//     setPage(0);
//   };

//   const handleRefreshSearch = async () => {

//     setFilteredData(data.filter(o => {
//       let res = true;
//       if (searchData.current.hasOwnProperty('charge_box_id') && o.hasOwnProperty('charge_box_id'))
//         res = res && o['charge_box_id'].toLocaleString().includes(searchData.current['charge_box_id']);
//       if (searchData.current.hasOwnProperty('end_soc') && o.hasOwnProperty('end_soc'))
//         res = res && o['end_soc'].toLocaleString().includes(searchData.current['end_soc']);
//       if (searchData.current.hasOwnProperty('vehicle_id') && o['vehicle_id'])
//         res = res && o['vehicle_id'].toLocaleString().includes(searchData.current['vehicle_id']);
//       if (searchData.current.hasOwnProperty('status'))
//         res = res && o['status'].toLocaleString().includes(searchData.current['status']);
//       return res;
//     })
//     )

//   }




//   const handleSearch = (e) => {
//     searchData.current = { ...searchData.current, [e.target.name]: e.target.value };
//     handleRefreshSearch();
//   }

//   useEffect(() => {
//     let isCanceled = false;

//     const fetchData = () => {
//       api
//         .getVehicleChargingStatus(null)
//         .then(({ data }) => {
//           if (!isCanceled) {
//             console.log("vehicle",data)
//             setData(data);
//             setFilteredData(data.filter(o => {
//               let res = true;
//               if (searchData.current.hasOwnProperty('charge_box_id') && o.hasOwnProperty('charge_box_id'))
//                 res = res && o['charge_box_id'].toLocaleString().includes(searchData.current['charge_box_id']);
//               if (searchData.current.hasOwnProperty('end_soc'))
//                 res = res && o['end_soc'].toLocaleString().includes(searchData.current['end_soc']);
//               if (searchData.current.hasOwnProperty('vehicle_id') && o['vehicle_id'])
//                 res = res && o['vehicle_id'].toLocaleString().includes(searchData.current['vehicle_id']);
//               if (searchData.current.hasOwnProperty('status'))
//                 res = res && o['status'].toLocaleString().includes(searchData.current['status']);
//               return res;
//             }));
//             setReady(true);
//           }
//         })
//         .catch((e) => {
//           setError(true);
//           console.log('Cannot load event data', e);
//         });
//     };

//     // Fetch data initially
//     fetchData();

//     // Set up interval for data refresh
//     const intervalId = setInterval(fetchData, 10000); // Refresh every 3 seconds

//     return () => {
//       isCanceled = true;
//       clearInterval(intervalId); // Clear interval on component unmount
//     };

//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [error]);


  // const dateTimeFormatter = (str) => {
  //   if (str === undefined)
  //     return " ";
  //   // const inputDateString = "2024-01-07T17:30:34.646+05:30";
  //   // console.log(str);
  //   const inputDate = new Date(str.toString());

  //   // Step 2: Extract components
  //   const dayOfWeek = new Intl.DateTimeFormat('en-IN', { weekday: 'long' })
  //     .format(inputDate).toString().substring(0, 3);

  //   const day = inputDate.getDate();
  //   const month = new Intl.DateTimeFormat('en-IN', { month: 'long' }).format(inputDate);
  //   const year = inputDate.getFullYear();

  //   // Step 3: Format time
  //   const hours = inputDate.getHours();
  //   const minutes = inputDate.getMinutes();
  //   const seconds = inputDate.getSeconds();

  //   const formattedTime = `Started At ${hours}:${minutes}`;

  //   // Final formatted date and time
  //   // const formattedDateTime = ` ${dayOfWeek}, ${day} ${month} ${year} | ${formattedTime} | `;

  //       const formattedDateTime = `| ${formattedTime} |`;


  //   // console.log(formattedDateTime);

  //   return formattedDateTime;
  // }



//   function renderProgress(params) {
//     return <LinearWithValueLabel value={params['end_soc']} />;
//   }


//   const statusColors = { Charging: '#008000', Available: '#4169e1', Preparing: '#FFAF38', Faulted: '#FF5733', Reserved: '#4BC0C0', Unavailable: '#878E98' }


//   return (
//     <div>
//       <TableContainer sx={{height: "60vh",overflow:"auto",backgroundColor: darkTheme ? '#333333' : '#fff'}} >
//       <StyledTable  stickyHeader aria-label="a dense table" >

//           <TableHead>
//             <TableRow style={{ zIndex: 999999999, backgroundColor: darkTheme ? '#333333' : '#eeeeee' }}>
//               {columns.map((column) => (
//                 <TableCell
//                   key={column.id}
//                   align={column.align}
//                   minWidth={column.minWidth}
//                   width={column.width}
//                   style={{
//                     background: darkTheme ? '#333333': '#eeeeee',
//                       color: darkTheme ? '#F2E34C' : 'black',
//                     minWidth: column.minWidth,
//                     width: column.width,
//                     padding: 10
//                   }}
//                 >
//                   <div style={{ height: "2rem", width: "100%" }}>{column.label}</div>


//                   {
//                     column.id === 'duration' | column.id === 'preConditioning'
//                       || column.id === 'status'
//                       ? (
//                         column.id === 'status' ? (
//                           <div key={column.id} style={{ width: "100%", padding: 0, marging: 0, height: "2rem", alignItems: "left" }}>
//                             <FormControl variant="standard" sx={{ width: "100%" }}>
//                               {/* <InputLabel id="select-label">All</InputLabel> */}
//                               <Select
//                                 labelId="demo-simple-select-standard-label"
//                                 id="demo-simple-select-standard"
//                                 name='status'
//                                 fullWidth
//                                 // value={selectedValue}
//                                 label="Select an Option"
//                                 onChange={handleSearch}
//                                 SelectDisplayProps={{
//                                   style: { color: darkTheme ? '#fff' : '#000' }, // Change the text color of the dropdown menu
//                                 }}
//                                 IconComponent={(props) => (
//                                   <ArrowDropDownIcon style={{ color: darkTheme ? '#fff' : '#000' }} /> // Change the color of the dropdown icon
//                                 )}
//                               >
//                                 <MenuItem value=""
//                                   style={{ display: 'block', padding: "10px", textAlign: "center", backgroundColor: darkTheme ? '#000' : '#fff', color: darkTheme ? '#fff' : '#000' }}>All</MenuItem>
//                                 {/* <MenuItem value="Available"
//                                   style={{ display: 'block', padding: "10px", textAlign: "center" }}>Available</MenuItem> */}
//                                 <MenuItem value="Charging"
//                                   style={{ display: 'block', padding: "10px", textAlign: "center", backgroundColor: darkTheme ? '#000' : '#fff', color: darkTheme ? '#fff' : '#000' }}>Charging</MenuItem>
//                                 {/* <MenuItem value="Reserved"
//                                   style={{ display: 'block', padding: "10px", textAlign: "center" }}>Reserved</MenuItem>
//                                 <MenuItem value="Fault"
//                                   style={{ display: 'block', padding: "10px", textAlign: "center" }}>Fault</MenuItem>
//                                 <MenuItem value="Unavailable"
//                                   style={{ display: 'block', padding: "10px", textAlign: "center" }}>Unavailable</MenuItem> */}
//                               </Select>
//                             </FormControl>
//                           </div>
//                         )
//                           : <div style={{ height: "2rem" }}></div>
//                       ) :
//                       (<div key={column.id} style={{ padding: 0, marging: 0, height: "2rem" }}>
//                         <TextField
//                           name={column.id}
//                           style={{ width: '100%' }}
//                           minWidth={column.minWidth}
//                           onChange={handleSearch}
//                           InputProps={{
//                             startAdornment: (
//                               <InputAdornment position="start">
//                                 <SearchIcon style={{ color: darkTheme ? '#fff' : '#000' }} />
//                               </InputAdornment>
//                             ),
//                             style: { color: darkTheme ? '#fff' : '#000' },
//                           }}
//                           variant="standard"
//                           fullWidth
//                         />
//                       </div>)
//                   }

//                 </TableCell>
//               ))}
//             </TableRow>

//           </TableHead>
//           <TableBody >
//             {filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row,index) => (
//               <TableRow hover role="checkbox" tabIndex={-1} key={row.code} style={{ margin: 1, padding: 0,backgroundColor: darkTheme ? `${index % 2 !== 0 ? ('#333333') : ('#454545')}` : `${index % 2 !== 0 ? ('#eeeeee') : ('#f9f9f9')}` }}>
//                 {columns.map((column) => {
//                   if (column.id === "status") {
//                     return <TableCell key={column.id} align={column.align}
//                       style={{
//                         // padding:0,
//                         paddingLeft: 10,
//                         color : darkTheme ? '#fff' : '#000'
//                         //  color: "white"

//                       }}>
//                       <div>
//                         {row[column.id]?row[column.id]:"-"}</div>

//                     </TableCell>
//                   }

//                   else if (column.id === "charge_box_id") {
//                     return <TableCell key={column.id} align={column.align} style={{ margin: 1, paddingLeft: 10, color : darkTheme ? '#fff' : '#000' }}>

//                       <div style={{ paddingLeft: "5px" }}>
//                         {row[column.id]?row[column.id]:"-"}
//                         {row[column.id] !== null && row[column.id] !== undefined ? " - " : ""}
//                         {row['connector_id']}
//                         {" "}
//                         {/* {row['power']} */}
//                         {/* {row['power']===undefined?"":"kW"} */}

//                       </div>



//                     </TableCell>
//                   }

//                   else {
//                     return <TableCell key={column.id} align={column.align} style={{ position: "relative", height: "3rem", padding: 0, paddingLeft: 10, color : darkTheme ? '#fff' : '#000' }}>
//                       {column.id === "battery_capacity"
//                         ? Math.round(row[column.id]) : (
//                           column.id === 'end_soc' ?
//                             row[column.id]?
//                             <div style={{ position: "relative", display: "flex", alignItems: "center", height: "3rem", marginLeft: "5px", padding: 0 }}>
//                               <div style={{
//                                 height: "100%", zIndex: "1", width: "100%", position: "absolute", top: 0, right: 0
//                                 , marginLeft: "2px", marginRight: "2px"
//                               }}>
//                                 <LinearWithValueLabel value={row[column.id]} />
//                               </div>
//                               <div style={{ color: "white", display: "flex", align: "center", padding: "0 3px 0 3px", zIndex: "1", position: "relative" }}>
//                                 {row[column.id]}/100% <a style={{ fontSize: "13px" }}>{dateTimeFormatter(row['start_time'])}</a>
//                                 &nbsp;{parseInt(row['energy'])} kWh
//                               </div>
//                             </div>:"-"
//                             : row[column.id] ? row[column.id] : "-"
//                         )
//                       }
//                     </TableCell>
//                   }


//                 })}
//               </TableRow>
//             ))}
//           </TableBody>
//         </StyledTable>
//       </TableContainer>
//       <Box display="flex" flexDirection="column">
//         <TablePagination
//           // SelectProps={{
//           //     MenuProps: { classes: { paper: classes.selectDropdown } }
//           // }}
//           // classes={{ menuItem: classes.menuItem }}
//           sx={{ px: 20 }}
//           component="div"
//           count={data.length}
//           page={page}
//           rowsPerPage={rowsPerPage}
//           onPageChange={handleChangePage}
//           onRowsPerPageChange={handleChangeRowsPerPage}
//           rowsPerPageOptions={[5, 10, 25]}
//           // nextIconButtonProps={{ 'aria-label': 'Next Page' }}
//           // backIconButtonProps={{ 'aria-label': 'Previous Page' }}
//           SelectProps={{
//             style: { color: darkTheme ? '#fff' : '#000' }, // Change the text color of the page size select
//             IconComponent: (props) => (
//               <ArrowDropDownIcon style={{ fill: darkTheme ? '#fff' : '#000' }} /> 
//             )
//           }}
//           labelRowsPerPage={<span style={{ color: darkTheme ? '#fff' : '#000' }}>Rows per page:</span>} // Change the text color of the pagination caption
//           rowsPerPageOptionsLabel={<span style={{ color: darkTheme ? '#fff' : '#000' }}>Rows per page</span>} 
//           labelDisplayedRows={({ from, to, count }) => (
//             <span style={{ color: darkTheme ? '#fff' : '#000' }}>{`${from}-${to} of ${count}`}</span> // Change the color of the text "1-10 of 10"
//           )}
//           nextIconButtonProps={{ 'aria-label': 'Next Page', style: { color: darkTheme ? '#fff' : '#000' } }}
//           backIconButtonProps={{ 'aria-label': 'Previous Page', style: { color: darkTheme ? '#fff' : '#000' } }}
//         />
//       </Box>
//       {/* </Paper> */}


//     </div >
//   );
// };

// export default VehicleStatusTable;