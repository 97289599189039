import OrgApi from './LoadMgmtApi';
import { Button, Grid, styled } from '@mui/material';
import { Span } from 'app/components/Typography';
import { useEffect, useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { ToastContainer, toast } from 'react-toastify';
import { makeStyles } from '@material-ui/core/styles';
import 'react-toastify/dist/ReactToastify.css';
import InputLabel from '@mui/material/InputLabel';
import NativeSelect from '@mui/material/NativeSelect';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import FormControl from '@mui/material/FormControl';
import moment from 'moment';
import { useThemeContext } from 'app/ThemeProvider';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const TextField = styled(TextValidator)(() => ({
  width: '100%',
  marginBottom: '16px',
}));

const style = makeStyles({
  titleItemRight: {
    float: 'right',
    position: 'relative',
    transform: 'translateY(-50%)',
  },
});

const SimpleForm = ({handleClose}) => {
  const [state, setState] = useState({ date: new Date() });
  const api = new OrgApi();
  const classes = style();
  const [selectedValidFrom, handleValidFrom] = useState(new Date());
  const [selectedValidTo, handleValidTo] = useState(new Date());
  const [selectedSchedule, handleSchedule] = useState(new Date());
  const { darkTheme } = useThemeContext();
  

  useEffect(() => {
    ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
      if (value !== state.password) return false;

      return true;
    });
    return () => ValidatorForm.removeValidationRule('isPasswordMatch');
  }, [state.password]);

  const refreshPage = (event) => {

    handleClose();

    // window.location.reload(false);
  };

  const handleSubmit = (event) => {
    state.date = null;
    event.preventDefault();
  
    state.validFromStr = moment(new Date(selectedValidFrom)).format('DD/MM/YYYY HH:mm:ss');
    state.validToStr = moment(new Date(selectedValidTo)).format('DD/MM/YYYY HH:mm:ss');
    state.startScheduleStr = moment(new Date(selectedSchedule)).format('DD/MM/YYYY HH:mm:ss');

    api
      .addChargeProfile(state)
      .then((res) => {
        toast.success('Successfully  added.');
        // window.reload()
        // window.location.href = '/cmsmgmt/chargeprofile';
      })
      .catch((e) => {
        return toast.error(e.message);
        // console.log('Cannot add  data', e);
      });
  };

  const handleChange = (event) => {
    event.persist();
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const handleSelectChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const handleSelectCountry = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const { description, minChargingRate, stackLevel, durationInSeconds, note } = state;

  return (
    <div>
      <ValidatorForm onSubmit={handleSubmit} onError={() => null}>
        <Grid container spacing={6}>
          <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
            <TextField
              type="text"
              name="description"
              id="standard-basic"
              value={description || ''}
              onChange={(event) => {
                const regex = /^[A-Za-z0-9 ]{0,30}$/; // Allows only letters, numbers, and spaces (max 30 chars)
                if (regex.test(event.target.value)) {
                    handleChange(event); // Update state only if input is valid
                }
              }}
              errorMessages={['this field is required']}
              label="Description"
              validators={['required']}
              variant="standard"
              style={{ borderColor : darkTheme ? '#fff' : '#000' }}
              inputProps={{
                maxLength: 30, // Limit the input length to 30 characters
              }}
              InputProps={{
                style: { color: darkTheme ? '#fff' : '#000' }, 
              }}
              InputLabelProps={{
                style: { color: darkTheme ? '#fff' : '#000' }, 
              }}
              sx={{
                "& .MuiInput-underline:before": {
                  borderBottomColor: darkTheme ? '#fff' : '#000',
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: darkTheme ? '#fff' : '#000',
                },
                "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                  borderBottomColor: darkTheme ? '#fff' : '#000',
                },
                "& .MuiInputBase-input": {
                  color: darkTheme ? '#fff' : '#000',
                },
              }}
            />

            <FormControl fullWidth>
              <InputLabel variant="standard" htmlFor="uncontrolled-native"  style={{ color: darkTheme ? '#fff' : '#000' }}>
                Charging Profile Purpose
              </InputLabel>
              <NativeSelect
                onChange={handleChange}
                inputProps={{
                  name: 'chargingProfilePurpose',
                  id: 'uncontrolled-native',
                }}
                style={{ color: darkTheme ? '#fff' : '#000' }}
                                    IconComponent={() => <ArrowDropDownIcon style={{ color: darkTheme ? '#fff' : '#000' }} />}
                                    sx={{
                                    "& .MuiSelect-icon": {
                                        color: darkTheme ? '#fff' : '#000',
                                    },
                                    "&:before, &:after": {
                                        borderBottom: '1px solid',
                                        borderColor: darkTheme ? '#fff' : '#000'
                                    },
                                    "& option": {
                                        backgroundColor: darkTheme ? '#000' : '#fff',
                                        color: darkTheme ? '#fff' : '#000',
                                    },
                                    }}
              >
               <option style={{ backgroundColor: darkTheme ? '#000' : '#fff', color: darkTheme ? '#fff' : '#000' }} value={0}></option>
                <option style={{ backgroundColor: darkTheme ? '#000' : '#fff', color: darkTheme ? '#fff' : '#000' }} value="ChargePointMaxProfile">CHARGE_POINT_MAX_PROFILE</option>
                <option style={{ backgroundColor: darkTheme ? '#000' : '#fff', color: darkTheme ? '#fff' : '#000' }} value="TxDefaultProfile">TX_DEFAULT_PROFILE</option>
                <option style={{ backgroundColor: darkTheme ? '#000' : '#fff', color: darkTheme ? '#fff' : '#000' }} value="TxProfile">TX_PROFILE</option>
              </NativeSelect>
            </FormControl>

            <FormControl fullWidth>
              <InputLabel variant="standard" htmlFor="uncontrolled-native" style={{ color: darkTheme ? '#fff' : '#000' }}>
                Recurrency Kind:
              </InputLabel>
              <NativeSelect
                onChange={handleChange}
                inputProps={{
                  name: 'recurrencyKind',
                  id: 'uncontrolled-native',
                }}
                style={{ color: darkTheme ? '#fff' : '#000' }}
                                    IconComponent={() => <ArrowDropDownIcon style={{ color: darkTheme ? '#fff' : '#000' }} />}
                                    sx={{
                                    "& .MuiSelect-icon": {
                                        color: darkTheme ? '#fff' : '#000',
                                    },
                                    "&:before, &:after": {
                                        borderBottom: '1px solid',
                                        borderColor: darkTheme ? '#fff' : '#000'
                                    },
                                    "& option": {
                                        backgroundColor: darkTheme ? '#000' : '#fff',
                                        color: darkTheme ? '#fff' : '#000',
                                    },
                                    }}
              >
                <option style={{ backgroundColor: darkTheme ? '#000' : '#fff', color: darkTheme ? '#fff' : '#000' }} value={0}></option>
                <option style={{ backgroundColor: darkTheme ? '#000' : '#fff', color: darkTheme ? '#fff' : '#000' }} value="Daily">DAILY</option>
                <option style={{ backgroundColor: darkTheme ? '#000' : '#fff', color: darkTheme ? '#fff' : '#000' }} value="Weekly">WEEKLY</option>
              </NativeSelect>
            </FormControl>

            <FormControl fullWidth style={{ marginTop: '23px' }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MobileDateTimePicker
                  label="Start Schedule"
               //   value={selectedSchedule}
               slotProps={{ textField: { variant:'standard',fullWidth: true, 
                                        InputLabelProps: {
                                            style: { color: darkTheme ? '#fff' : '#000' } 
                                        }
                                         } }}
                  onChange={(selectedSchedule) => {
                    handleSchedule(selectedSchedule);
                  }}
                  sx={{
                    "& .MuiInput-underline:before": {
                      borderBottomColor: darkTheme ? '#fff' : '#000',
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: darkTheme ? '#fff' : '#000',
                    },
                    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                      borderBottomColor: darkTheme ? '#fff' : '#000',
                    },
                    "& .MuiInputBase-input": {
                      color: darkTheme ? '#fff' : '#000',
                    },
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </FormControl>
            <TextField
              type="text"
              name="minChargingRate"
              id="standard-basic"
              value={minChargingRate || ''}
              onChange={handleChange}
              errorMessages={['this field is required']}
              label="Min Charging Rate (decimal, multiple of 0.1"
              validators={['required']}
              variant="standard"
              style={{ borderColor : darkTheme ? '#fff' : '#000' }}
              InputProps={{
                style: { color: darkTheme ? '#fff' : '#000' }, 
              }}
              InputLabelProps={{
                style: { color: darkTheme ? '#fff' : '#000' }, 
              }}
              sx={{
                "& .MuiInput-underline:before": {
                  borderBottomColor: darkTheme ? '#fff' : '#000',
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: darkTheme ? '#fff' : '#000',
                },
                "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                  borderBottomColor: darkTheme ? '#fff' : '#000',
                },
                "& .MuiInputBase-input": {
                  color: darkTheme ? '#fff' : '#000',
                },
              }}
            />
            <FormControl fullWidth style={{ marginTop: '13px' }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MobileDateTimePicker
                  label="Valid To:"
               //   value={selectedValidTo}
               slotProps={{ textField: { variant:'standard',fullWidth: true, 
                                        InputLabelProps: {
                                            style: { color: darkTheme ? '#fff' : '#000' } 
                                        }
                                         } }}
                  onChange={(selectedValidTo) => {
                    handleValidTo(selectedValidTo);
                  }}
                  sx={{
                    "& .MuiInput-underline:before": {
                      borderBottomColor: darkTheme ? '#fff' : '#000',
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: darkTheme ? '#fff' : '#000',
                    },
                    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                      borderBottomColor: darkTheme ? '#fff' : '#000',
                    },
                    "& .MuiInputBase-input": {
                      color: darkTheme ? '#fff' : '#000',
                    },
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>

          <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
            <TextField
              type="text"
              name="stackLevel"
              label="Stack Level(integer)"
              onChange={(event) => {
                const regex = /^[0-9]{0,3}$/; // Allows only numbers (0-9) and max 3 digits
                if (regex.test(event.target.value)) {
                    handleChange(event); // Update state only if input is valid
                }
              }}
              value={stackLevel || ''}
              variant="standard"
              errorMessages={['this field is required']}
              style={{ borderColor : darkTheme ? '#fff' : '#000' }}
              inputProps={{
                maxLength: 3, // Limit input length to 3 characters
              }}
              InputProps={{
                style: { color: darkTheme ? '#fff' : '#000' }, 
              }}
              InputLabelProps={{
                style: { color: darkTheme ? '#fff' : '#000' }, 
              }}
              sx={{
                "& .MuiInput-underline:before": {
                  borderBottomColor: darkTheme ? '#fff' : '#000',
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: darkTheme ? '#fff' : '#000',
                },
                "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                  borderBottomColor: darkTheme ? '#fff' : '#000',
                },
                "& .MuiInputBase-input": {
                  color: darkTheme ? '#fff' : '#000',
                },
              }}
            />
            <FormControl fullWidth>
              <InputLabel variant="standard" htmlFor="uncontrolled-native" style={{ color: darkTheme ? '#fff' : '#000' }}>
                Charging Profile Kind:
              </InputLabel>
              <NativeSelect
                onChange={handleChange}
                inputProps={{
                  name: 'chargingProfileKind',
                  id: 'uncontrolled-native',
                }}
                style={{ color: darkTheme ? '#fff' : '#000' }}
                IconComponent={() => <ArrowDropDownIcon style={{ color: darkTheme ? '#fff' : '#000' }} />}
                sx={{
                "& .MuiSelect-icon": {
                    color: darkTheme ? '#fff' : '#000',
                },
                "&:before, &:after": {
                    borderBottom: '1px solid',
                    borderColor: darkTheme ? '#fff' : '#000'
                },
                "& option": {
                    backgroundColor: darkTheme ? '#000' : '#fff',
                    color: darkTheme ? '#fff' : '#000',
                },
                }}
              >
                <option style={{ backgroundColor: darkTheme ? '#000' : '#fff', color: darkTheme ? '#fff' : '#000' }} value={0}></option>
                <option style={{ backgroundColor: darkTheme ? '#000' : '#fff', color: darkTheme ? '#fff' : '#000' }} value="Absolute">ABSOLUTE</option>
                <option style={{ backgroundColor: darkTheme ? '#000' : '#fff', color: darkTheme ? '#fff' : '#000' }} value="Recurring">RECURRING</option>
                <option style={{ backgroundColor: darkTheme ? '#000' : '#fff', color: darkTheme ? '#fff' : '#000' }} value="Relative">RELATIVE</option>
              </NativeSelect>
            </FormControl>

            <TextField
              type="number"
              name="durationInSeconds"
              id="standard-basic"
              value={durationInSeconds || ''}
              onChange={handleChange}
              errorMessages={['this field is required']}
              label="Duration (in seconds):"
              validators={['required']}
              variant="standard"
              style={{ borderColor : darkTheme ? '#fff' : '#000' }}
              InputProps={{
                style: { color: darkTheme ? '#fff' : '#000' }, 
              }}
              InputLabelProps={{
                style: { color: darkTheme ? '#fff' : '#000' }, 
              }}
              sx={{
                "& .MuiInput-underline:before": {
                  borderBottomColor: darkTheme ? '#fff' : '#000',
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: darkTheme ? '#fff' : '#000',
                },
                "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                  borderBottomColor: darkTheme ? '#fff' : '#000',
                },
                "& .MuiInputBase-input": {
                  color: darkTheme ? '#fff' : '#000',
                },
              }}
            />

            <FormControl fullWidth>
              <InputLabel variant="standard" htmlFor="uncontrolled-native" style={{ color: darkTheme ? '#fff' : '#000' }}>
                Charging Rate Unit:
              </InputLabel>
              <NativeSelect
                onChange={handleChange}
                inputProps={{
                  name: 'chargingRateUnit',
                  id: 'uncontrolled-native',
                }}
                style={{ color: darkTheme ? '#fff' : '#000' }}
                IconComponent={() => <ArrowDropDownIcon style={{ color: darkTheme ? '#fff' : '#000' }} />}
                sx={{
                "& .MuiSelect-icon": {
                    color: darkTheme ? '#fff' : '#000',
                },
                "&:before, &:after": {
                    borderBottom: '1px solid',
                    borderColor: darkTheme ? '#fff' : '#000'
                },
                "& option": {
                    backgroundColor: darkTheme ? '#000' : '#fff',
                    color: darkTheme ? '#fff' : '#000',
                },
                }}
              >
                <option style={{ backgroundColor: darkTheme ? '#000' : '#fff', color: darkTheme ? '#fff' : '#000' }} value={0}></option>
                <option style={{ backgroundColor: darkTheme ? '#000' : '#fff', color: darkTheme ? '#fff' : '#000' }} value="W">W</option>
                <option style={{ backgroundColor: darkTheme ? '#000' : '#fff', color: darkTheme ? '#fff' : '#000' }} value="A">A</option>
              </NativeSelect>
            </FormControl>
            <FormControl fullWidth style={{ marginTop: '23px' }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MobileDateTimePicker
                  label="Valid From"
               //   value={selectedValidFrom}
               slotProps={{ textField: { variant:'standard',fullWidth: true, 
                                        InputLabelProps: {
                                            style: { color: darkTheme ? '#fff' : '#000' } // Change the color to your desired color
                                        }
                                         } }}
                  onChange={(selectedValidFrom) => {
                    handleValidFrom(selectedValidFrom);
                  }}
                  sx={{
                    "& .MuiInput-underline:before": {
                      borderBottomColor: darkTheme ? '#fff' : '#000',
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: darkTheme ? '#fff' : '#000',
                    },
                    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                      borderBottomColor: darkTheme ? '#fff' : '#000',
                    },
                    "& .MuiInputBase-input": {
                      color: darkTheme ? '#fff' : '#000',
                    },
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </FormControl>
            <TextField
              type="text"
              name="note"
              id="standard-basic"
              value={note || ''}
              onChange={(event) => {
                const regex = /^[A-Za-z0-9&\- ]{0,70}$/; // Allows letters, numbers, '&', '-', and spaces (max 70 chars)
                if (regex.test(event.target.value)) {
                    handleChange(event); // Update state only if input is valid
                }
              }}
              label="Additional Note"
              variant="standard"
              style={{ borderColor : darkTheme ? '#fff' : '#000' }}
              inputProps={{
                maxLength: 70, // Limit input length to 70 characters
              }}
              InputProps={{
                style: { color: darkTheme ? '#fff' : '#000' }, 
              }}
              InputLabelProps={{
                style: { color: darkTheme ? '#fff' : '#000' }, 
              }}
              sx={{
                "& .MuiInput-underline:before": {
                  borderBottomColor: darkTheme ? '#fff' : '#000',
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: darkTheme ? '#fff' : '#000',
                },
                "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                  borderBottomColor: darkTheme ? '#fff' : '#000',
                },
                "& .MuiInputBase-input": {
                  color: darkTheme ? '#fff' : '#000',
                },
              }}
            />
          </Grid>
        </Grid>

        <Button
          color="info"
          alignItems="right"
          className={classes.titleItemRight}
          variant="contained"
          type="button"
          onClick={refreshPage}
          style={{
            background: '#e0e0e0',
            color: 'black',
            borderRadius: '3px',
            padding: '5px 7px',
            margin: '5px',
          }}
        >
          <Span sx={{ textTransform: 'capitalize' }}>Cancel</Span>
        </Button>
        <Button
          // color="success"
          style={{
            background: '#2e7d32',
            color: 'white',
            borderRadius: '3px',
            padding: '5px 7px',
            margin: '5px',
          }}
          alignItems="right"
          className={classes.titleItemRight}
          variant="contained"
          type="submit"
        >
          <Span sx={{ textTransform: 'capitalize' }}>Submit</Span>
        </Button>
      </ValidatorForm>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </div>
  );
};

export default SimpleForm;
