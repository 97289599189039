import React, { useEffect, useRef } from 'react';
import * as echarts from 'echarts';
import { chartOptions } from 'app/utils/utils';
// import { chartOptions } from 'app/utils/utils';

const VoltageChart = ({ data = [], darkTheme }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    const chartDom = chartRef.current;
    const myChart = echarts.init(chartDom);
    const textColor = darkTheme ? '#F2E34C' : '#000'; // Conditionally set text color
    const titleColor = darkTheme ? '#F2E34C' : '#000';
    const tooltipColor = darkTheme ? '#333333' : '#ffffff';
    const labelColor = darkTheme ? '#000' : '#6a7985';

    // console.log(data);

    const option = data ? {

      // title: {
      //   text: 'Load Profile',
      //   padding: [0,0, 0, 20],
      //   textStyle: {
      //     color: darkTheme ? '#F2E34C' : '#000' // Set text color based on theme
      // },
      // },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          label: {
            backgroundColor: labelColor,
            color: textColor
          }
        },
        backgroundColor: tooltipColor,
        textStyle: {
          color: darkTheme ? '#F2E34C' : '#000' // Set text color based on theme
        },
        formatter: function (params) {
          let tooltip = params[0].name + '<br/>';
          params.forEach(param => {
            let formattedValue = Number(param.value).toFixed(2).toLocaleString(); // Limit to 2 decimal places
            tooltip += param.marker + ' ' + param.seriesName + ' : ' + formattedValue + ' V<br/>';
          });
          return tooltip;
        }

      },
      legend: {
        data: ['V L1-L2', 'V L2-L3','V L3-L1'], // Set legend data
        textStyle: {
          color: darkTheme ? '#F2E34C' : '#000'
        }
      },
      toolbox: {
        show: true,
        top: '10px',
        right: 45,
        feature: {
          saveAsImage: {
            show: true,
            title: 'Download',
            name: 'Voltage',
            type: 'png'
          }
        },
        iconStyle: {
          borderColor: darkTheme ? '#F2E34C' : '#000'
        }
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '14%',
        containLabel: true
      },
      // dataZoom: [
      //     {
      //       type: 'inside'
      //     },
      //     {
      //       type: 'slider'
      //     }
      //   ],
      xAxis: {
        type: 'category',
        boundaryGap: false,
        // data: data.filter(obj => obj.location === "Outlet").map(obj => new Date(obj.value_timestamp)
        //   .toLocaleTimeString(undefined, { hour12: true, hour: '2-digit', minute: '2-digit' })),
        data:data.filter(obj=>obj.measurand==='v12_ry_voltage').map(obj=>obj.created_at)
        .map(obj => new Date(obj)
            .toLocaleTimeString(undefined, { hour12: true, hour: '2-digit', minute: '2-digit' }))
        ,
        name: 'Time',
        axisLabel: chartOptions().axisLabel,
        axisLabel: {
          color: textColor // Set text color based on theme
        },
        nameLocation: 'middle',
        nameGap: 35,
        nameTextStyle: chartOptions().nameTextStyle,
        nameTextStyle: {
          color: textColor // Set text color based on theme
        }
      },
      yAxis: {
        type: 'value',
        name: 'Voltage (V)',
        nameLocation: 'middle',
        nameGap: 40,
        nameTextStyle: chartOptions().nameTextStyle,
        nameTextStyle: {
          color: textColor // Set text color based on theme
        },
        axisLabel: chartOptions().axisLabel,
        axisLabel: {
          color: textColor // Set text color based on theme
        },
      },
      series: [
        {
          name: 'V L1-L2', // Set series name
          type: 'line',
          data: data.filter(obj=>obj.measurand==='v12_ry_voltage').map(obj => obj.register_value),
          smooth: true,
          color:'#a393eb',
          lineStyle: {
            width: 2, // Set line width if needed
            color: '#a393eb' // Set line color to red
          },
          showSymbol: false,
          // areaStyle: {
          //   opacity: 0.8,
          //   color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          //     {
          //       offset: 0,
          //       color: '#a393eb'
          //     },
          //     {
          //       offset: 1,
          //       color: 'rgb(1, 191, 236)'
          //     }
          //   ])
          // },
          emphasis: {
            focus: 'series'
          },

        },
        {
          name: 'V L2-L3', // Set series name
          type: 'line',
          color: '#430f58',
          data: data.filter(obj=>obj.measurand==='v23_yb_voltage').map(obj => obj.register_value),
          // areaStyle: { opacity: 0.5 }
          smooth: true,
          lineStyle: {
            width: 4, // Set line width if needed
            color: '#430f58' // Set line color to red
          },
          showSymbol: false,
          emphasis: {
            focus: 'series'
          },
      },
        {
            name: 'V L3-L1', // Set series name
            type: 'line',
            data: data.filter(obj=>obj.measurand==='v23_yb_voltage').map(obj => obj.register_value),
            // areaStyle: {
            //     opacity: 0.5,
            // },
            smooth: true,
            lineStyle: {
              width: 4, // Set line width if needed
              color: '#430f58' // Set line color to red
            },
            showSymbol: false,
            emphasis: {
              focus: 'series'
            },
        },
      ]
    } : {};

    option && myChart.setOption(option);

    return () => {
      myChart.dispose(); // Clean up when the component unmounts
    };
  }, [data.length]); // Empty dependency array to run this effect only once

  return <div ref={chartRef} style={{ width: '100%', height: '50vh', paddingBottom: "15px" }}></div>;
};

export default VoltageChart;