import React, { useEffect, useRef } from 'react';
import * as echarts from 'echarts';
import { chartOptions } from 'app/utils/utils';
import { DateTime } from 'luxon';

const EnergyChart = ( {data=[], darkTheme}) => {
  const chartRef = useRef(null);

  // console.log(data);

  useEffect(() => {
    const chartDom = chartRef.current;
    const myChart = echarts.init(chartDom);
    const startMeterValue=data.length?parseInt(data[0].value):0;
    const textColor = darkTheme ? '#F2E34C' : '#000'; // Conditionally set text color
    const titleColor = darkTheme ? '#F2E34C' : '#000';
    const tooltipColor = darkTheme ? '#333333' : '#ffffff';
    const labelColor = darkTheme ? '#000' : '#6a7985';

    const orgTimezone = localStorage.getItem("orgTimezone");

    const option = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          label: {
            backgroundColor: labelColor,
            color: textColor
        }
        },
        backgroundColor: tooltipColor,
        textStyle: {
            color: darkTheme ? '#F2E34C' : '#000' // Set text color based on theme
        },
        formatter: function (params) {
          let tooltip = params[0].name + '<br/>';
          params.forEach(param => {
              tooltip += param.marker + ' ' + param.seriesName + ' : ' + param.value.toFixed(2).toLocaleString() ;
          });
          return tooltip;
      }
      },
      legend: {
        data: [ 'Energy (kWh)'], // Set legend data
        textStyle: {
          color: darkTheme ? '#F2E34C' : '#000'
        }
    },
    toolbox: {
      show: true,
      top: '10px',
      right: 130,
      feature: {
        saveAsImage: {
          show: true,
          title: 'Download',
          name: 'Energy', 
          type: 'png' 
        }
      },
      iconStyle: {
          borderColor: darkTheme ? '#F2E34C' : '#000'
      }
    },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: data.map(obj => 
            // new Date(obj.value_timestamp)
            // .toLocaleTimeString(undefined, { hour12: true, hour: '2-digit', minute: '2-digit' })),
            DateTime.fromISO(obj.value_timestamp)  // Convert timestamp
              .setZone(orgTimezone)  // Convert to `orgTimezone` from state
              .toFormat("hh:mm a") // Format to HH:mm AM/PM
            ),
          name: 'Time',
          axisLabel: chartOptions().axisLabel,
          axisLabel: {
            color: textColor // Set text color based on theme
        },
          nameLocation: 'middle',
          nameGap: 35,
          nameTextStyle: chartOptions().nameTextStyle,
          nameTextStyle: {
            color: textColor // Set text color based on theme
        }
      },
      yAxis: {
        type: 'value',
        name: 'Energy (kWh)',
        nameLocation: 'middle',
        nameGap: 40,
              nameTextStyle: chartOptions().nameTextStyle,
              nameTextStyle: {
                color: textColor // Set text color based on theme
            },
        axisLabel: chartOptions().axisLabel,
        axisLabel: {
          color: textColor // Set text color based on theme
      }
      },
      series: [
        {
          name:'Energy (kWh)',
          type: 'line',
          color:'#34495e',
          data: data.map(obj=>(parseInt(obj.value)-startMeterValue)/1000),
          smooth: true,
            lineStyle: {
              width: 2, // Set line width if needed
              color: '#34495e' // Set line color to red
            },
            showSymbol: false,
            areaStyle: {
              opacity: 1,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: '#8dc6ff'
                },
                // {
                //   offset: 1,
                //   color: 'rgb(1, 191, 236)'
                // }
              ])
            },
            emphasis: {
              focus: 'series'
            },
        }
      ]
    };

    option && myChart.setOption(option);

    return () => {
      myChart.dispose(); // Clean up when the component unmounts
    };
  }, []); // Empty dependency array to run this effect only once

  return <div ref={chartRef} style={{ width: '100%', height: '25rem',paddingBottom:"15px" }}></div>;
};

export default EnergyChart;