import React, { useEffect, useRef } from 'react';
import * as echarts from 'echarts';
import { chartOptions } from 'app/utils/utils';
import { DateTime } from 'luxon';

const TempChart = ( {data=[], darkTheme}) => {

  const chartRef = useRef(null);

  // console.log(data);

    useEffect(() => {
        const chartDom = chartRef.current;
        const myChart = echarts.init(chartDom);

    const textColor = darkTheme ? '#F2E34C' : '#000'; // Conditionally set text color
    const titleColor = darkTheme ? '#F2E34C' : '#000';
    const tooltipColor = darkTheme ? '#333333' : '#ffffff';
    const labelColor = darkTheme ? '#000' : '#6a7985';

    const orgTimezone = localStorage.getItem("orgTimezone");

    const option = data ? {
      // title: {
      //   text: 'Load Profile',
      //   padding: [0,0, 0, 20]
      // },

      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          label: {
            backgroundColor: labelColor,
            color: textColor
        }
        },
        backgroundColor: tooltipColor,
        textStyle: {
            color: darkTheme ? '#F2E34C' : '#000' // Set text color based on theme
        },
        formatter: function (params) {
          let tooltip = params[0].name + '<br/>';
          params.forEach(param => {
            if (param.seriesName !== 'Inlet') { 
              tooltip += param.marker + ' ' + param.seriesName + ' : ' 
              + parseFloat(param.value).toFixed(2).toLocaleString() + ' °C<br/>';
            }
          });
          return tooltip;
      }
      },
      legend: {
        data: ['Outlet Temperature', 'Body Temperature','Inlet Temperature'], // Set legend data
        // color:['red','green'],
        textStyle: {
          color: darkTheme ? '#F2E34C' : '#000'
        }
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '14%',
      containLabel: true
      },
      // dataZoom: [
      //     {
      //         type: 'inside'
      //     },
      //     {
      //         type: 'slider'
      //     }
      // ],
      toolbox: {
        show: true,
        top: '10px',
        right: 45,
        feature: {
          saveAsImage: {
            show: true,
            title: 'Download',
            name: 'Temperature ', 
            type: 'png' 
          }
        },
        iconStyle: {
            borderColor: darkTheme ? '#F2E34C' : '#000'
        }
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: data.filter(obj => obj.location === "Outlet"
        &&obj.measurand==="Temperature"
        ).map(obj => 
            // new Date(obj.value_timestamp)
            // .toLocaleTimeString(undefined, { hour12: true, hour: '2-digit', minute: '2-digit' })),
            DateTime.fromISO(obj.value_timestamp)  // Convert timestamp
              .setZone(orgTimezone)  // Convert to `orgTimezone` from state
              .toFormat("hh:mm a") // Format to HH:mm AM/PM
            ),
          name: 'Time',
          axisLabel: chartOptions().axisLabel,
          nameLocation: 'middle',
          nameGap: 35,
          nameTextStyle: {
            ...chartOptions().nameTextStyle,
            color: textColor // Change text color based on dark theme
        },
        axisLabel: {
          color: textColor // Set text color based on theme
      }
      },
      yAxis: {
        type: 'value',
        name: 'Temperature (°C)',
        nameLocation: 'middle',
        nameGap: 40,
        nameTextStyle: {
          ...chartOptions().nameTextStyle,
          color: textColor // Change text color based on dark theme
      },
        axisLabel: chartOptions().axisLabel,
        axisLabel: {
          color: textColor // Set text color based on theme
      }
      },
      series: [
        {
            name: 'Outlet Temperature', // Set series name
            type: 'line',
            data: data.filter(obj => obj.location === "Outlet"
            &&obj.measurand==="Temperature"),
            // ).map(obj => obj.value/1000),
            // areaStyle: {
            //     opacity: 0.5
            // },


            smooth: true,
            color:'#ffad09',
            lineStyle: {
              width: 3, // Set line width if needed
              color: '#ffad09' // Set line color to red
            },
            showSymbol: false,
            // areaStyle: {
            //   opacity: 0.8,
            //   color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            //     {
            //       offset: 0,
            //       color: '#8cca6e'
            //     },
            //     // {
            //     //   offset: 1,
            //     //   color: 'rgb(1, 191, 236)'
            //     // }
            //   ])
            // },
            emphasis: {
              focus: 'series'
            },
        },
        {
          name: 'Body Temperature', // Set series name
          type: 'line',
          data: data.filter(obj => obj.location === "Body"
          &&obj.measurand==="Temperature"),
          // ).map(obj => obj.value/1000),
          areaStyle: {
              opacity: 0.5
          },


          smooth: true,
          color:'#ffe658',
          lineStyle: {
            width: 4, // Set line width if needed
            color: '#ffe658', // Set line color to red
           
          },
          showSymbol: false,
          // areaStyle: {
          //   opacity: 0.5,
          //   color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          //     {
          //       offset: 0,
          //       color: 'blue'
          //     },
          //     {
          //       offset: 1,
          //       color: 'rgb(1, 191, 236)'
          //     }
          //   ])
          // },
          emphasis: {
            focus: 'series'
          },
      },
        {
            name: 'Inlet Temperature', // Set series name
            color:'#ffad09',
            type: 'line',
            data: data.filter(obj => obj.location === "Inlet"),
            // &&obj.measurand==="Power.Offered").map(obj => obj.unit==='W'?obj.value/1000:obj.value),
            // areaStyle: {
            //     opacity: 0.5,
            // },

            smooth: true,
            lineStyle: {
              width: 6, // Set line width if needed
              color: '#ff9500', // Set line color to red
              opacity: 1,
            },
            showSymbol: false,
            emphasis: {
              focus: 'series'
            },
        },
        // {
        //     name: 'Inlet', // Set series name

        //     type: 'line',
        //     data: data.filter(obj => obj.location === "Inlet").map(obj => obj.value/1000),
        //     areaStyle: {
        //         opacity: 0.5,
        //     },
        // },
    ]
} : {};

option && myChart.setOption(option);

return () => {
    myChart.dispose(); // Clean up when the component unmounts
};
}, [data.length]); // Empty dependency array to run this effect only once

return <div ref={chartRef} style={{ width: '100%', height: '25rem',paddingBottom:"15px" }}></div>;
};

export default TempChart;
