import React, { useState, useEffect } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import TextField from '@mui/material/TextField';
import ChargerApi from '../../ChargerApi';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Checkbox from '@mui/material/Checkbox';
import { Button, Dialog, DialogActions, DialogContent, Select, MenuItem, DialogTitle, Grid, IconButton, Slide, Typography, ListItemIcon, ListItemText, CircularProgress } from '@mui/material';
import Loading from 'app/components/MatxLoading';



const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles({
    root: {
        padding: '0',
    },
    subtitleRight: {
        margin: '20px 0 10px 0',
        backgroundColor: 'rgb(7, 128, 235)',
        padding: '10px 10px 10px 15px',
        borderRadius: '0 30px 30px 0',
        color: 'white',
        position: 'relative',
        boxShadow:
            '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
        marginLeft: 'inherit',
        width: 'fit-content',
    },
    field: {
        // height: '50vh',
        width: 'fit-content',
        // borderRadius: ({isMobile}) => isMobile ? "inherit" : '20px',
        margin: '10px 20px',
        '& .MuiOutlinedInput-input': {
            padding: '18.5px 27px',
        },
    },
    fieldDate: {
        // height: '50vh',
        width: 'fit-content',
        // borderRadius: ({isMobile}) => isMobile ? "inherit" : '20px',
        margin: '10px 20px',
        '& .MuiOutlinedInput-input': {
            padding: '18.5px 5px',
        },
    },
});

function OccpSetLocalListViewDialog({
    open,
    handleOpenDialog,
    handleCloseDialog,
    row,
    occpCommnad,
    chargeBoxId,
}) {
    const classes = useStyles();
    const api = new ChargerApi();
    const [values, setValues] = useState([]);
    const orgId = localStorage.getItem("orgId");

    const [tags, setTags] = useState([]);
    const [state, setState] = useState({});
    const [disabled, setDisabled] = useState(false);
    const [selected, setSelected] = useState([]);
    const [selectedDel, setSelectedDel] = useState([]);
    const [isChecked, setIsChecked] = useState(false);

    const handleChangeCheckbox = (event) => {
        setIsChecked(event.target.checked);
    };


    const isAllSelected =
        tags.length > 0 && selected.length === tags.length;


    const isAllSelectedDel =
        tags.length > 0 && selectedDel.length === tags.length;

    const handleChangeSelectAdd = (event) => {
        const value = event.target.value;
        if (value[value.length - 1] === "all") {
            setSelected(selected.length === tags.length ? [] : tags);
            return;
        }
        setSelected(value);
    };


    const handleChangeSelectDelete = (event) => {
        const value = event.target.value;
        if (value[value.length - 1] === "all") {
            setSelectedDel(selectedDel.length === tags.length ? [] : tags);
            return;
        }
        setSelectedDel(value);
    };


    useEffect(() => {

        let isCanceled = false;
        // api
        //     .getChargerConnectorList(null, row)
        //     .then(({ data }) => {
        //         if (!isCanceled) {
        //             setValues(data);
        //         }
        //     })
        //     .catch((e) => {
        //         // console.log('Cannot load event data', e);
        //     });

        showVehicleTgs();

        return () => {
            isCanceled = true;
        };
    }, [row]);

    // const showVehicleTgs = () => {
    //     let isCanceled = false;
    //     debugger;
    //     api
    //         .getVehicleTags(null)
    //         .then(({ data }) => {
    //             if (!isCanceled) {
    //                 const idTags = data.map(entry => entry.idTag);
    //                 setTags(idTags);

    //             }
    //         })
    //         .catch((e) => {
    //             // console.log('Cannot load event data', e);
    //         });
    // };

    const showVehicleTgs = () => {

        api
            .getAllOcppTag({ orgId: orgId })
            .then((response) => {

                console.log(response.data)
                const idTags = response.data.map(entry => entry.idTag);
                setTags(idTags);

            })
            .catch((e) => {
                console.log('Cannot load event data', e);
            });
    };


    const handleClickOpen = () => {
        handleOpenDialog();
    };

    const handleClose = () => {
        setValues([]);
        handleCloseDialog();
    };

    const handleUpdateDetails = (name) => (event) => {
        // console.log(event.target.value);
        // console.log(event.target.name);
        setValues({ ...values, [name]: event.target.value });
    };

    const handleChanage = (event) => {
        setState({ ...state, [event.target.name]: event.target.value });
    };



    const saveUpdatedDetails = () => {
        debugger;

        state.chargeBoxId = Number(row);
        state.addList = selected.join(', ');
        state.deleteList = selectedDel.join(', ');
        state.isEmpty = isChecked
        console.log(state);


        setDisabled(true);
        if (occpCommnad === 16) {
            // console.log(state);
            api
                .sendLocalList(state)
                .then(({ data }) => {
                    handleClose()
                    return toast.success('Request ' + data);
                })
                .catch((e) => {

                    return toast.error('Request Failed');
                }).finally(() => setDisabled(false));
        }

    };

    const handleChange = (event) => {
        const {
            target: { value }
        } = event;
        setSelected(
            typeof value === "string" ? value.split(",") : value
        );
    };

    const { connectorId, availableType, hashstring, listVersion } = state;

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            // //keepMounted
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            fullWidth
        >
            <DialogTitle id="alert-dialog-slide-title" style={{ padding: '0' }}>


                <Grid container >
                    <Grid container className={classes.subtitleRight} item xs={11}>
                        <Grid item xs={6}>
                            <Typography variant={'h5'} >
                                Set Local List
                            </Typography>
                        </Grid>

                        <Grid item xs={6} style={{ fontSize: '16px', paddingTop: '3px' }} >

                            Charger ID:{chargeBoxId}

                        </Grid>

                    </Grid>

                    <Grid item container xs={0.5}>
                        <IconButton aria-label="close" color="inherit" onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>

            </DialogTitle>

            <DialogContent dividers>
                {/* maybe to do smart form that detect what he get from the props (column & row)  */}
                <form noValidate autoComplete="off">

                    <Grid container alignItems={'left'} direction={'column'}>
                        {/* <InputLabel variant="standard" htmlFor="uncontrolled-native">
              Hash (String)
              </InputLabel> */}
                        <FormControl fullWidth>
                            <TextField
                                type="text"
                                variant="standard"
                                name="hashstring"
                                label="Hash (String)"
                                onChange={handleChanage}
                                value={hashstring || ''}
                            />
                        </FormControl>
                    </Grid>
                    <Grid container alignItems={'left'} direction={'column'}>
                        <FormControl fullWidth>
                            {/* <InputLabel variant="standard" htmlFor="uncontrolled-native">
              List Version (integer):
              </InputLabel> */}
                            <TextField
                                type="text"
                                variant="standard"
                                label=" List Version (integer):"
                                name="listVersion"
                                // label="List Version (integer):"
                                onChange={handleChanage}
                                value={listVersion || ''}
                            />
                        </FormControl>
                    </Grid>


                    <Grid container alignItems={'left'} direction={'column'}>
                        <FormControl fullWidth>
                            <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                Update Type:
                            </InputLabel>
                            <NativeSelect
                                onChange={handleChanage}
                                variant="standard"
                                inputProps={{
                                    name: 'updateType',
                                    id: 'uncontrolled-native',
                                }}
                            >
                                <option value={0}></option>
                                <option value='DIFFERENTIAL'>DIFFERENTIAL</option>
                                <option value='FULL'>FULL</option>
                            </NativeSelect>
                        </FormControl>
                    </Grid>
                    <Grid container alignItems={'left'} direction={'column'}>
                        <FormControl fullWidth>
                            <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                Add/Update List:
                            </InputLabel>

                            <Select
                                labelId="mutiple-select-label"
                                multiple
                                fullWidth
                                variant="standard"
                                value={selected}
                                onChange={handleChangeSelectAdd}
                                renderValue={(selected) => selected.join(", ")}

                            >
                                <MenuItem
                                    value="all"
                                    classes={{
                                        root: isAllSelected ? classes.selectedAll : ""
                                    }}
                                >
                                    <ListItemIcon>
                                        <Checkbox
                                            classes={{ indeterminate: classes.indeterminateColor }}
                                            checked={isAllSelected}
                                            indeterminate={
                                                selected.length > 0 && selected.length < tags.length
                                            }
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        classes={{ primary: classes.selectAllText }}
                                        primary="Select All"
                                    />
                                </MenuItem>
                                {tags.map((s) => (
                                    <MenuItem key={s} value={s}>
                                        <ListItemIcon>
                                            <Checkbox checked={selected.indexOf(s) > -1} />
                                        </ListItemIcon>
                                        <ListItemText primary={s} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid container alignItems={'left'} direction={'column'}>
                        <FormControl fullWidth>
                            <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                Delete List:
                            </InputLabel>

                            <Select
                                labelId="mutiple-select-label"
                                multiple

                                fullWidth
                                variant="standard"
                                value={selectedDel}
                                onChange={handleChangeSelectDelete}
                                renderValue={(selectedDel) => selectedDel.join(", ")}

                            >
                                <MenuItem
                                    value="all"
                                    classes={{
                                        root: isAllSelectedDel ? classes.selectedAll : ""
                                    }}
                                >
                                    <ListItemIcon>
                                        <Checkbox
                                            classes={{ indeterminate: classes.indeterminateColor }}
                                            checked={isAllSelectedDel}
                                            indeterminate={
                                                selectedDel.length > 0 && selectedDel.length < tags.length
                                            }
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        classes={{ primary: classes.selectAllText }}
                                        primary="Select All"
                                    />
                                </MenuItem>
                                {tags.map((s) => (
                                    <MenuItem key={s} value={s}>
                                        <ListItemIcon>
                                            <Checkbox checked={selectedDel.indexOf(s) > -1} />
                                        </ListItemIcon>
                                        <ListItemText primary={s} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid container alignItems={'left'} direction={'column'}>
                        <FormControl fullWidth>
                            <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                Send empty list?:
                            </InputLabel>
                            <Checkbox
                                checked={isChecked}
                                onChange={handleChangeCheckbox}
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                        </FormControl>
                    </Grid>

                </form>
            </DialogContent>
            <DialogActions>
                <Button
                    type={'submit'}
                    size={'large'}
                    variant={'contained'}
                    style={{ backgroundColor: disabled ? 'white' : 'green', color: 'white' }}
                    onClick={saveUpdatedDetails}
                    disabled={disabled}
                >
                    {disabled ? <Loading /> : "Submit"}
                </Button>

                <Button
                    type={'submit'}
                    // size={'large'}
                    // variant={'contained'}
                    // color="secondry"
                    onClick={handleClose}
                >
                    Close
                </Button>
            </DialogActions>
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </Dialog>
        // </div>
    );
}

export default OccpSetLocalListViewDialog;
