import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import SimpleForm from './SimpleForm';
import Stack from '@mui/material/Stack';
import { useThemeContext } from 'app/ThemeProvider';

const AddUserForm = ({setRefresh}) => {
  const [open, setOpen] = React.useState(false);
  const { darkTheme } = useThemeContext();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Stack spacing={6} direction="row" justifyContent="right" color={'white'}>
      <Button
          onClick={handleClickOpen}
          variant="contained"
          style={{
            border: '2px solid',
            borderColor: darkTheme ? '#F2E34C' : '#000',
            backgroundColor: darkTheme ? '#F2E34C' : '#f9f9f9',color:'black',
            fontSize: '15px', fontWeight: 'bold', padding: 4, borderRadius: '25px',
            paddingLeft: '15px', paddingRight: '15px'
        }}
         
        >
          +1 NEW USER
        </Button>
      </Stack>
      <Dialog
        open={open}
        onClose={(_, reason) => {
          if (reason !== 'backdropClick') {
            handleClose();
          }
        }}
        disableEscapeKeyDown="true"
        fullWidth="false"
        maxWidth="sm"
        PaperProps={{
          style: {
            backgroundColor: darkTheme ? '#333333' : '#fff', 
          },
        }}
      >
        <DialogTitle style={{ color:darkTheme ? '#F2E34C' : 'black' }}> Add User</DialogTitle>
        <DialogContent>
          <SimpleForm setRefresh={setRefresh} setOpen={setOpen}/>
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default AddUserForm;
