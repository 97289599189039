import React, { useState, useEffect } from 'react';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Paper, Slide, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import ChargerApi from '../../ChargerApi';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TextField from '@mui/material/TextField';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { makeStyles } from '@material-ui/core';
import { useThemeContext } from 'app/ThemeProvider';
import Loading from 'app/components/MatxLoading';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles({
  root: {
    padding: '0',
  },
    subtitleRight: {
        margin: '20px 0 10px 0',
        backgroundColor: 'rgb(7, 128, 235)',
        padding: '10px 10px 10px 15px',
    borderRadius: '0 30px 30px 0',
    color: 'white',
    position: 'relative',
    boxShadow:
      '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
    marginLeft: 'inherit',
    width: 'fit-content',
  },
  field: {
    // height: '50vh',
    width: 'fit-content',
    // borderRadius: ({isMobile}) => isMobile ? "inherit" : '20px',
    margin: '10px 20px',
    '& .MuiOutlinedInput-input': {
      padding: '18.5px 27px',
    },
  },
  fieldDate: {
    // height: '50vh',
    width: 'fit-content',
    // borderRadius: ({isMobile}) => isMobile ? "inherit" : '20px',
    margin: '10px 20px',
    '& .MuiOutlinedInput-input': {
      padding: '18.5px 5px',
    },
  },
});

function OccpClearChargingProfileModal({
  open,
  handleOpenDialog,
  handleCloseDialog,
  row,
  occpCommnad,
  chargeBoxId,
}) {
  const classes = useStyles();
  const api = new ChargerApi();
  const [values, setValues] = useState([]);
  const [state, setState] = useState({});
  const [disabled, setDisabled] = useState(false);
  const [profileDisable, setProfileDisabled] = useState(true);
  const { darkTheme } = useThemeContext();

  useEffect(() => {
    let isCanceled = false;
    api
      .getChargergingProfile(null, row)
      .then(({ data }) => {
        if (!isCanceled) {
          setValues(data);
        }
      })
      .catch((e) => {
        // console.log('Cannot load event data', e);
      });
    return () => {
      isCanceled = true;
    };
  }, [row]);

  //   useEffect(() => {
  //     let isCanceled = false;
  //     api
  //       .getChargerConnectorList(null, row)
  //       .then(({ data }) => {
  //         if (!isCanceled) {
  //           setValues(data);
  //         }
  //       })
  //       .catch((e) => {
  //         // console.log('Cannot load event data', e);
  //       });
  //     return () => {
  //       isCanceled = true;
  //     };
  //   }, [row]);

  const handleClickOpen = () => {
    handleOpenDialog();
  };

  const handleClose = () => {
    setValues([]);
    handleCloseDialog();
  };

  const handleChanage = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const handleChanageKeyType = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
    if (event.target.value === 'OtherParameters') {
      // setDisabled(false);
      setProfileDisabled(true);
    }
    if (event.target.value === 'ChargingProfileId') {
      // setDisabled(true);
      setProfileDisabled(false);
    }
  };

  const saveUpdatedDetails = () => {
   
    setDisabled(true);
    state.chargeBoxId = Number(row);
    api
      .clearChargingProfile(state)
      .then(({ data }) => {
        return toast.success('Request ' + data);
      })
      .catch((e) => {
        return toast.error('Request failed');
        // console.log('Cannot load event data', e);
      }).finally(()=>setDisabled(false));
  };

  const { stackLevel, connectorId } = state;

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      //keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      fullWidth
    >
      <DialogTitle id="alert-dialog-slide-title" style={{ padding: '0', backgroundColor: darkTheme ? '#333333' : '#f9f9f9' }}>
        

        <Grid container >
          <Grid container className={classes.subtitleRight} item xs={11}>
            <Grid item xs={6}>
              <Typography variant={'h5'} >
              Clear Charging Profile
              </Typography>
            </Grid>

            <Grid item xs={6} style={{ fontSize: '16px', paddingTop: '3px' }} >

              Charger ID:{chargeBoxId}

            </Grid>

          </Grid>

          <Grid item container xs={0.5}>
            <IconButton aria-label="close" color="inherit" onClick={handleClose}>
              <CloseIcon style={{ color: darkTheme ? '#fff' : '#000' }} />
            </IconButton>
          </Grid>
        </Grid>


      </DialogTitle>

      <DialogContent dividers style={{ backgroundColor: darkTheme ? '#333333' : '#f9f9f9', color: darkTheme ? '#fff' : '#000' }}>
        <form noValidate autoComplete="off">
          {' '}
          <Grid container justify={'left'} alignItems={'left'} direction={'column'}>
            <FormControl fullWidth style={{ marginBottom: '20px' }}>
              <InputLabel variant="standard" htmlFor="uncontrolled-native" style={{ color: darkTheme ? '#fff' : '#000', fontSize: '18px' }}>
                Filter Type
              </InputLabel>
              <NativeSelect
                onChange={handleChanageKeyType}
                inputProps={{
                  name: 'filterType',
                  id: 'uncontrolled-native',
                }}
                style={{ color: darkTheme ? '#fff' : '#000' }}
                IconComponent={() => <ArrowDropDownIcon style={{ color: darkTheme ? '#fff' : '#000' }} />}
                sx={{
                  "& .MuiSelect-icon": {
                    color: darkTheme ? '#fff' : '#000',
                  },
                  "&:before, &:after": {
                    borderBottom: '1px solid',
                    borderColor : darkTheme ? '#fff' : '#000'
                  },
                  "& option": {
                    backgroundColor: darkTheme ? '#000' : '#fff',
                    color: darkTheme ? '#fff' : '#000',
                  },
                }}
              >
                <option style={{ backgroundColor: darkTheme ? '#000' : '#fff', color: darkTheme ? '#fff' : '#000' }} value={0}></option>
                <option style={{ backgroundColor: darkTheme ? '#000' : '#fff', color: darkTheme ? '#fff' : '#000' }} value="ChargingProfileId">ChargingProfileId</option>
                <option style={{ backgroundColor: darkTheme ? '#000' : '#fff', color: darkTheme ? '#fff' : '#000' }} value="OtherParameters">OtherParameters</option>
              </NativeSelect>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel variant="standard" htmlFor="uncontrolled-native" style={{ color: darkTheme ? '#fff' : '#000', fontSize: '18px' }}>
                Charging Profile ID:
              </InputLabel>
              <NativeSelect
                onChange={handleChanage}
                disabled={profileDisable}
                inputProps={{
                  name: 'profileId',
                  id: 'uncontrolled-native',
                }}
                style={{ color: darkTheme ? '#fff' : '#000' }}
                IconComponent={() => <ArrowDropDownIcon style={{ color: darkTheme ? '#fff' : '#000' }} />}
                sx={{
                  "& .MuiSelect-icon": {
                    color: darkTheme ? '#fff' : '#000',
                  },
                  "&:before, &:after": {
                    borderBottom: '1px solid',
                    borderColor : darkTheme ? '#fff' : '#000'
                  },
                  "& option": {
                    backgroundColor: darkTheme ? '#000' : '#fff',
                    color: darkTheme ? '#fff' : '#000',
                  },
                }}
              >
                <option style={{ backgroundColor: darkTheme ? '#000' : '#fff', color: darkTheme ? '#fff' : '#000' }} value={0}></option>
                {values &&
                  values.map((s, index) => (
                    <option style={{ backgroundColor: darkTheme ? '#000' : '#fff', color: darkTheme ? '#fff' : '#000' }} value={s.chargingProfilePk}>{s.description}</option>
                  ))}
              </NativeSelect>
            </FormControl>

            <FormControl fullWidth style={{ marginBottom: '20px' }}>
              <TextField
                type="text"
                disabled={disabled}
                variant="standard"
                name="connectorId"
                label="Connector ID (integer)"
                onChange={handleChanage}
                value={connectorId || ''}
                InputLabelProps={{
                  style: { color: darkTheme ? '#fff' : '#000' },
                }}
                sx={{
                  "& .MuiInput-underline:before": {
                    borderBottomColor: darkTheme ? '#fff' : '#000',
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: darkTheme ? '#fff' : '#000',
                  },
                  "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                    borderBottomColor: darkTheme ? '#fff' : '#000',
                  },
                  "& .MuiInputBase-input": {
                    color: darkTheme ? '#fff' : '#000',
                  },
                }}
              />
            </FormControl>
            <FormControl fullWidth>
              <InputLabel variant="standard" htmlFor="uncontrolled-native" style={{ color: darkTheme ? '#fff' : '#000' }}>
                Charging Profile Purpose
              </InputLabel>
              <NativeSelect
                onChange={handleChanageKeyType}
                disabled={disabled}
                inputProps={{
                  name: 'keyType',
                  id: 'uncontrolled-native',
                }}
                style={{ color: darkTheme ? '#fff' : '#000' }}
                IconComponent={() => <ArrowDropDownIcon style={{ color: darkTheme ? '#fff' : '#000' }} />}
                sx={{
                  "& .MuiSelect-icon": {
                    color: darkTheme ? '#fff' : '#000',
                  },
                  "&:before, &:after": {
                    borderBottom: '1px solid',
                    borderColor : darkTheme ? '#fff' : '#000'
                  },
                  "& option": {
                    backgroundColor: darkTheme ? '#000' : '#fff',
                    color: darkTheme ? '#fff' : '#000',
                  },
                }}
              >
                <option style={{ backgroundColor: darkTheme ? '#000' : '#fff', color: darkTheme ? '#fff' : '#000' }} value={0}></option>
                <option style={{ backgroundColor: darkTheme ? '#000' : '#fff', color: darkTheme ? '#fff' : '#000' }} value="ChargePointMaxProfile">CHARGE_POINT_MAX_PROFILE</option>
                <option style={{ backgroundColor: darkTheme ? '#000' : '#fff', color: darkTheme ? '#fff' : '#000' }} value="TxDefaultProfile">TX_DEFAULT_PROFILE</option>
                <option style={{ backgroundColor: darkTheme ? '#000' : '#fff', color: darkTheme ? '#fff' : '#000' }} value="TxProfile">TX_PROFILE</option>
              </NativeSelect>
            </FormControl>

            <FormControl fullWidth>
              <TextField
                type="text"
                disabled={disabled}
                variant="standard"
                name="stackLevel"
                label="Stack Level(integer)"
                onChange={handleChanage}
                value={stackLevel || ''}
                InputLabelProps={{
                  style: { color: darkTheme ? '#fff' : '#000' },
                }}
                sx={{
                  "& .MuiInput-underline:before": {
                    borderBottomColor: darkTheme ? '#fff' : '#000',
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: darkTheme ? '#fff' : '#000',
                  },
                  "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                    borderBottomColor: darkTheme ? '#fff' : '#000',
                  },
                  "& .MuiInputBase-input": {
                    color: darkTheme ? '#fff' : '#000',
                  },
                }}
              />
            </FormControl>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions style={{ backgroundColor: darkTheme ? '#333333' : '#f9f9f9'  }}>
        <Button
          type={'submit'}
          size={'large'}
          variant={'contained'}
          style={{ backgroundColor: disabled ? 'white' : 'green', color: 'white' }}
          onClick={saveUpdatedDetails}
          disabled={disabled}
        >
         {disabled ? <Loading /> : "Submit"}
        </Button>

        <Button
          type={'submit'}
          size={'large'}
          variant={'contained'}
          // color="secondry"
          onClick={handleClose}
          style={{ backgroundColor: '#334155', color: 'white' }}
        >
          Close
        </Button>
      </DialogActions>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </Dialog>
    // </div>
  );
}

export default OccpClearChargingProfileModal;
