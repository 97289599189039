import React, { useState, useEffect } from 'react';
import ReactEcharts from 'echarts-for-react';
import VehicleGraphApi from '../VehicleGraphApi';

const ChargerPortViewGraph = ({ height, color = [], chargerBoxId, connectorId, vehicleId }) => {
  const [chart, setData] = useState([]);
  const [dataHeader, setDataHeader] = useState([]);
  const [loaded, setReady] = useState(false); // flag for loading map
  const [error, setError] = useState(false);
  const api = new VehicleGraphApi();

  useEffect(() => {
    let isCanceled = false;
    api
      .getChargerPortView(null, 33, connectorId, vehicleId)
      .then(({ data }) => {
        if (!isCanceled) {
          setData(data.series);
          setDataHeader(data.header);
          setReady(true);
        }
      })
      .catch((e) => {
        setError(true);
        // console.log('Request history data', e);
      });
    return () => {};
  }, [error]);

  const getLoadingOption = () => {
    return { text: 'Data Loading ...' };
  };

  const onChartReady = (chart, loaded) => {
    if (loaded) {
      setTimeout(() => {
        chart.hideLoading();
      }, 1000);
    }
  };

  const getOption = () => {
    return {
      tooltip: {
        trigger: 'axis',
      },
      // legend: {
      //   data: ['Email', 'Union Ads', 'Video Ads', 'Direct', 'Search Engine'],
      // },
      grid: {
        left: '4%',
        right: '4%',
        bottom: '14%',
        containLabel: true,
      },
      toolbox: {
        feature: {
          //saveAsImage: {},
        },
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: dataHeader.map(function (str) {
          return str.replace(' ', '\n');
        }),
        nameLocation: 'middle',
        nameGap: 50,
        name: 'Date & Time(hours)',
      },
      yAxis: {
        type: 'value',
        name: 'Power (kW)',
        nameLocation: 'middle',
        nameGap: 50,
      },
      series: chart,
    };
  };

  // const option = {
  //   animationDuration: 5000,
  //   dataset: [
  //     {
  //       id: 'dataset_raw',
  //       source: data,
  //     },
  //     {
  //       id: 'dataset_since_1950_of_germany',
  //       fromDatasetId: 'dataset_raw',
  //       transform: {
  //         type: 'filter',
  //         config: {
  //           and: [{ dimension: 'ViewType', '=': 'charger' }],
  //         },
  //       },
  //     },
  //   ],
  //   title: {
  //     text: '',
  //   },
  //   tooltip: {
  //     trigger: 'axis',
  //   },
  //   xAxis: {
  //     type: 'category',
  //     nameLocation: 'middle',
  //   },
  //   yAxis: {
  //     name: 'kwH',
  //   },
  //   series: [
  //     {
  //       type: 'line',
  //       datasetId: 'dataset_since_1950_of_germany',
  //       showSymbol: false,
  //       encode: {
  //         x: 'Date',
  //         y: 'Power',
  //         itemName: 'Date',
  //         tooltip: ['Power'],
  //       },
  //     },
  //   ],
  // };

  return (
    <ReactEcharts
      style={{ height: height }}
      notMerge={true}
      option={getOption()}
      loadingOption={getLoadingOption()}
      onChartReady={onChartReady}
      lazyUpdate={true}
    />
  );
};
export default ChargerPortViewGraph;
