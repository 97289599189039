import React, { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import Autocomplete from '@mui/material/Autocomplete';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle'
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Switch from '@mui/material/Switch';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import UserApi from '../UserApi';
import DepoApi from '../../depomgmt/DepoApi';
import { ToastContainer, toast } from 'react-toastify';
import DeleteIcon from '@mui/icons-material/Delete';
import UpdateIcon from '@mui/icons-material/Update';
import { useThemeContext } from 'app/ThemeProvider';

import { FormControlLabel, FormGroup, Grid, TablePagination, Tooltip } from '@mui/material';
import UpdateUserForm from '../UpdateUserForm';
import { AlertUserDepot } from './AlertUserDepot';
import Loading from 'app/components/MatxLoading';
import NotInterestedIcon from '@mui/icons-material/NotInterested';


AlertRow.propTypes = {
    row: PropTypes.shape({
        calories: PropTypes.number.isRequired,
        carbs: PropTypes.number.isRequired,
        fat: PropTypes.number.isRequired,
        history: PropTypes.arrayOf(
            PropTypes.shape({
                amount: PropTypes.number.isRequired,
                customerId: PropTypes.string.isRequired,
                date: PropTypes.string.isRequired,
            }),
        ).isRequired,
        name: PropTypes.string.isRequired,
        price: PropTypes.number.isRequired,
        protein: PropTypes.number.isRequired,
    }).isRequired,
};

export function AlertRow(props, indexes) {




    const [Coount, setcoount] = useState(indexes);
    const { row, setRefresh } = props;
    const [open, setOpen] = React.useState(false);
    const [openo, setOpeno] = React.useState(false);
    const [allDepot, setAllDepot] = useState([]);
    const { darkTheme } = useThemeContext();
    const api = new UserApi();
    const depotApi = new DepoApi();


    useEffect(()=>{

        setOpen(false);

    },[props.page])

  

    const handleClickOpen = () => {
        setOpeno(true);
    };

    const handleClose = () => {
        setOpeno(false);
    };

    useEffect(() => {
        if (open)
            getUserDepot();
        else setAllDepot([])

    }, [open])

    const handleChecked = (e) => {
        setRefresh(true);
        e.preventDefault();
        let obj = { username: row.username };
        if (e.target.name === "status")
            obj.status = e.target.checked ? "enable" : "disable";

        if (e.target.name === "email")
            obj.isEmailEnabled = e.target.checked ? 1 : 0;

        if (e.target.name === "SMS")
            obj.isSmsEnabled = e.target.checked ? 1 : 0;

        // console.log(e.target.name);
        // console.log(obj);
        // let status = "disable";

        // if (e.target.checked)
        //     status = "enable";

        const orgId = localStorage.getItem('orgId');
        obj.orgId = orgId;
        
        api
            .updateUser(obj)
            .then((res) => {
                toast.success('Successfull');
                // setOpen(false)
                // setRefresh(false);
                setcoount(Coount + 1);
            })
            .catch((e) => {
                return toast.error(e.message);
                // console.log('Cannot add  data', e);
            })
            .finally(() => setRefresh(false));


    }
    const handleDelete = (e) => {

        if (window.confirm("Confirm Deletion")) {

            setRefresh(true);
            e.preventDefault();
            let status = "disable";
            if (e.target.checked)
                status = "enable";
            api
                .deleteUser({ username: row.username })
                .then((res) => {
                    toast.success(row.name + ' Successfully deleted');
                    // setOpen(false)
                    setRefresh(true);
                })
                .catch((e) => {
                    return toast.error(e.message);
                    // console.log('Cannot add  data', e);
                })
                .finally(() => setRefresh(false));;
        }

    }

    const getUserDepot = () => {
        api.getUserDepotByUsername({ username: row.username })
            .then((response) => {
                setAllDepot(response.data.body);
                setRefresh(true);
                console.log("alldepot", response.data.body);
            })
            .catch((e) => {
                // console.log('Cannot load event data', e);
            });
    }


    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' }, backgroundColor: darkTheme ? `${Coount % 2 !== 0 ? ('#333333') : ('#474747')}` : `${Coount % 2 !== 0 ? ('#e0e0e0') : ('#f9f9f9')}` }}>
                <TableCell width={200} align='center' >
                    
                    {row.role[0].toString().includes("SUPER") ? (
                        <NotInterestedIcon style={{ color: darkTheme ? '#bbb' : '#888' }} />
                    ) : (
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpen(!open)}
                        >
                            {open ? <KeyboardArrowUpIcon style={{ color: darkTheme ? '#fff' : '#000' }} /> : <KeyboardArrowDownIcon style={{ color: darkTheme ? '#fff' : '#000' }} />}

                        </IconButton>
                    )}

                </TableCell>

                <TableCell style={{ color: darkTheme ? '#fff' : '#000' }}>{row.name}</TableCell>
                <TableCell style={{ color: darkTheme ? '#fff' : '#000' }}>{row.email}</TableCell>
                <TableCell style={{ color: darkTheme ? '#fff' : '#000' }}>
                    {row.role[0].toString().includes("ROLE_ALERTUSER") ? "Alert User" : 
                    row.role[0].toString().includes("USER") ? "Depot User" : 
                    row.role[0].toString().includes("ZONAL") ? "Zonal Manager" : 
                    row.role[0].toString().includes("SUPER") ? "Super Admin" : 
                    "Depot Manager"}
                </TableCell>

                <TableCell>
                    <FormControlLabel
                        style={{ margin: 0, padding: 0 }}
                        checked={row.isEmailEnabled === 1}
                        name='email'
                        onChange={handleChecked}
                        control={<Switch />} />
                </TableCell>
                <TableCell>
                    <FormControlLabel
                        style={{ margin: 0, padding: 0 }}
                        checked={row.isSmsEnabled === 1}
                        name='SMS'
                        onChange={handleChecked}
                        control={<Switch />} />
                </TableCell>

            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0, color: darkTheme ? '#fff' : '#000' }} colSpan={9}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box style={{ backgroundColor: darkTheme ? '#333333' : '#f9f9f9' }}>
                            {/* <Grid padding={"20px"}>
                                <Typography variant="h6" gutterBottom component="div" style={{ color: darkTheme ? '#F2E34C' : '#000' }}>
                                    Depot Alert Allocation
                                </Typography>
                            </Grid> */}
                            <Table aria-label="purchases" >
                                <TableHead>
                                    <TableRow style={{ backgroundColor: '#333333' }}>
                                        <TableCell style={{ padding: "10px", backgroundColor: darkTheme ? '#333333' : '#f9f9f9', color: darkTheme ? '#F2E34C' : '#000', fontSize: "16px" }}>Selected</TableCell>
                                        <TableCell style={{ padding: "10px", backgroundColor: darkTheme ? '#333333' : '#f9f9f9', color: darkTheme ? '#F2E34C' : '#000', fontSize: "16px" }}>Depot ID</TableCell>
                                        <TableCell style={{ backgroundColor: darkTheme ? '#333333' : '#f9f9f9', color: darkTheme ? '#F2E34C' : '#000', fontSize: "16px" }}>Depot Name</TableCell>
                                        <TableCell style={{ backgroundColor: darkTheme ? '#333333' : '#f9f9f9', color: darkTheme ? '#F2E34C' : '#000', fontSize: "16px" }}>Depot Zone</TableCell>
                                        <TableCell style={{ backgroundColor: darkTheme ? '#333333' : '#f9f9f9', color: darkTheme ? '#F2E34C' : '#000', fontSize: "16px" }}>Email</TableCell>
                                        <TableCell style={{ backgroundColor: darkTheme ? '#333333' : '#f9f9f9', color: darkTheme ? '#F2E34C' : '#000', fontSize: "16px" }}>SMS</TableCell>

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {allDepot && allDepot.length > 0 ? 
                                        allDepot
                                        // .filter(depot => depot.username) // Filters out depots with null or empty username
                                        .map((depot, index) => (

                                            <AlertUserDepot depot={depot} row={row} getUserDepot={getUserDepot} allDepot={allDepot}

                                                style={{ backgroundColor: darkTheme ? '#333333' : '#f9f9f9', color: darkTheme ? '#F2E34C' : '#000', fontSize: "16px", padding: "10px" }}
                                            />
                                        )
                                    ): <TableCell colSpan={4}>
                                        <Loading />
                                        
                                        </TableCell>
                                    
                                    }


                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
            <Dialog
                open={openo}
                onClose={handleClose}
                fullWidth
                PaperProps={{
                    style: {
                        backgroundColor: darkTheme ? '#333333' : '#fff',
                    },
                }}
            >
                <DialogTitle style={{ color: darkTheme ? '#F2E34C' : '#000' }}>Updation</DialogTitle>
                <DialogContent>
                    <UpdateUserForm row={row} setRefresh={setRefresh} setOpeno={setOpeno} />
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
}