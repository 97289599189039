import {
  Box,
  Icon,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import { useState, useEffect } from 'react';
import UserApi from './UserApi';


const StyledTable = styled(Table)(() => ({
  whiteSpace: 'pre',
  fontSize:"20px",
  overflowX: 'auto',
  '& thead': {
    '& tr': { '& th': { paddingLeft: 0, paddingRight: 0 } },
  },
  '& tbody': {
    '& tr': { '& td': { paddingLeft: 0 } },
  },
}));

const UserListTable = ({refresh}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [APIData, setAPIData] = useState([]);
  const api = new UserApi();

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // useEffect(() => {
  //   const user=JSON.parse(localStorage.getItem('user'));
  //   api
  //     .getUserList(user.username)
  //     .then((response) => {
  //       setAPIData(response.data);
  //     })
  //     .catch((e) => {
  //       // console.log('Cannot load event data', e);
  //     });
  // }, [refresh]);

  return (
    <Box width="100%" overflow="auto" style={{fontSize:"30px"}}>
      <StyledTable >
        <TableHead >
          <TableRow  >
            <TableCell align="center" >
              Name
            </TableCell>
            <TableCell align="center" >
              Email
            </TableCell>
            <TableCell align="center">
              Role
            </TableCell>
            {/* <TableCell align="center">#</TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {APIData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(
            (subscriber, index) => (
              <TableRow key={index}>
                <TableCell align="center">{subscriber.name}</TableCell>
                <TableCell align="center">{subscriber.email}</TableCell>
                <TableCell align="center">{subscriber.role.join('\n')}</TableCell>

                {/* <TableCell align="center">
                  <IconButton>
                    <Icon color="info">edit</Icon>
                  </IconButton>
                </TableCell> */}
              </TableRow>
            )
          )}
        </TableBody>
      </StyledTable>

      <TablePagination
        // sx={{ px: 2 }}
        page={page}
        component="div"
        rowsPerPage={rowsPerPage}
        count={APIData.length}
        onPageChange={handleChangePage}
       rowsPerPageOptions={[5, 10, 25,50,75,100,150,200]}
        onRowsPerPageChange={handleChangeRowsPerPage}
        // nextIconButtonProps={{ 'aria-label': 'Next Page' }}
        // backIconButtonProps={{ 'aria-label': 'Previous Page' }}
      />
    </Box>
  );
};

export default UserListTable;
