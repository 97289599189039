import axios from 'axios.js';
export default class DelegationApi {
    constructor() {
        this.api_token = null;
        this.client = null;
        this.user = JSON.parse(localStorage.getItem('user'));
    }

    init = () => {
        this.client = axios.create({
            timeout: 120000,
        });

        return this.client;
    };

    addDelegation = (data) => {
        return this.init().post('/api/user-role-delegation/', data);
    };

    getAllByOrgId = (params) => {
        return this.init().get('/api/user-role-delegation/',{params:params});
    };

    update=(data)=>{
        return this.init().patch('/api/user-role-delegation/',data);
    }


}
