import { Autocomplete, Button, Grid, styled } from '@mui/material';
import { Span } from 'app/components/Typography';
import { useEffect, useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { ToastContainer, toast } from 'react-toastify';
import { makeStyles } from '@material-ui/core/styles';
import 'react-toastify/dist/ReactToastify.css';
import { useThemeContext } from 'app/ThemeProvider';

import Dialog from '@mui/material/Dialog';

import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import DelegationApi from './DelegationApi';
import UserApi from '../../usrmgmt/UserApi';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, MobileDateTimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';

const TextField = styled(TextValidator)(() => ({
    width: '100%',
    marginBottom: '16px',
}));
const style = makeStyles({
    titleItemRight: {
        float: 'right',
        position: 'relative',
        transform: 'translateY(-50%)',
        marginTop: 10,
    },
});

const AddDelegation = ({ setRefresh }) => {



    const [state, setState] = useState({});

    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    const api = new DelegationApi();
    const [userList, setUserList] = useState([]);
    const classes = style();
    const [roles, setRoles] = useState([
        { id: 5, name: "ROLE_SUPERADMIN" }
    ])
    const { darkTheme } = useThemeContext();

    const { days, reason } = state


    const inputStyles = {
        "& .MuiInput-underline:before": { borderBottomColor: darkTheme ? '#fff' : '#000' },
        "& .MuiInput-underline:after": { borderBottomColor: darkTheme ? '#fff' : '#000' },
        "& .MuiInput-underline:hover:not(.Mui-disabled):before": { borderBottomColor: darkTheme ? '#fff' : '#000' },
        "& .MuiInputBase-input": { color: darkTheme ? '#fff' : '#000' },
    };

    useEffect(() => {
        ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
            if (value !== state.password) return false;

            return true;
        });

        ValidatorForm.addValidationRule('isPasswordSixDigit', (value) => {
            if (value.toString().length < 6) return false;

            return true;
        });

        return () => ValidatorForm.removeValidationRule('isPasswordMatch');
    }, [state.password]);

    const handleSubmit = (event) => {
        setRefresh(true);
        const user = JSON.parse(localStorage.getItem("user"));
        state.delegatedBy = user.id;
        state.status = "active"
        // state.toDatetime = new Date().setDate(new Date(state.fromDatetime).getDate() + parseInt(state.days));
        state.roleId = roles[0].id;

        event.preventDefault();

        console.log(state);
        api
            .addDelegation(state)
            .then((res) => {
                toast.success('Successfully added.');
                setRefresh(false);
                setOpen(false)
            })
            .catch((e) => {
                console.log('Cannot add  data', e);
                return toast.error(e.response.data.message);
            })
        // .finally(() => setRefresh(false), setState({ ...state, name: "" }));

    };



    const refreshPage = (event) => {
        setOpen(false);
    };

    const handleChange = (event) => {
        event.persist();
        setState({ ...state, [event.target.name]: event.target.value });
    };

    const { name, orgId } = state;


    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    const userApi = new UserApi();

    useEffect(() => {

        const orgId=localStorage.getItem("orgId")

        userApi
            .getUserList(orgId)
            .then((response) => {
                setUserList(response.data);
                console.log(response.data);
            })
            .catch((e) => {
                // console.log('Cannot load event data', e);
            });
    }, []);

    return (
        <div>
            <Stack spacing={6} direction="row" justifyContent="right" color={'white'}>
                <Button
                    onClick={handleClickOpen}
                    variant="contained"
                    style={{
                        border: '2px solid', borderColor: darkTheme ? '#f2e34c' : '#000', color: '#000'
                        , backgroundColor: darkTheme ? '#f2e34c' : 'white', fontSize: '15px', fontWeight: 'bold'
                        , borderRadius: '25px', paddingLeft: '15px', paddingRight: '15px'
                    }}

                >
                    +1 NEW DELEGATION
                </Button>
            </Stack>
            <Dialog
                open={open}
                onClose={(_, reason) => {
                    if (reason !== 'backdropClick') {
                        handleClose();
                    }
                }}
                disableEscapeKeyDown="true"
                fullWidth="false"
                maxWidth="sm"
            >
                <DialogTitle sx={{ backgroundColor: darkTheme ? '#333333' : 'fff', color: darkTheme ? '#f2e34c' : 'black' }}>
                    Add New Delegation
                </DialogTitle>
                <DialogContent sx={{ backgroundColor: darkTheme ? '#333333' : 'fff' }}>
                    <ValidatorForm onSubmit={handleSubmit} onError={() => null}>
                        <Grid container spacing={1}>
                            {/* Left Column */}
                            <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
                                <Autocomplete
                                    // disablePortal
                                    options={userList}
                                    getOptionLabel={(option) => option.name}
                                    onChange={(event, value) => {
                                        setState({ ...state, delegatedTo: value.id })
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Users"
                                            variant="standard"
                                            sx={inputStyles}
                                        />
                                    )}
                                />

                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <MobileDateTimePicker
                                        label="Start Date & Time"
                                        slotProps={{ textField: { variant: 'standard', fullWidth: true } }}
                                        disablePast
                                        onChange={(newToDate) => {
                                            const date = new Date(newToDate);
                                            const utcMilliseconds = Date.UTC(
                                                date.getFullYear(),
                                                date.getMonth(),
                                                date.getDate(),
                                                date.getHours(),
                                                date.getMinutes(),
                                                date.getSeconds(),
                                                date.getMilliseconds()
                                            );
                                            setState({ ...state, fromDatetime: utcMilliseconds })

                                        }}

                                        renderInput={(params) => (
                                            <TextField {...params} sx={inputStyles} />
                                        )}
                                    />
                                </LocalizationProvider>
                            </Grid>

                            {/* Right Column */}
                            <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
                                <Autocomplete
                                    disablePortal
                                    options={roles}
                                    value={roles[0]}
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Roles"
                                            variant="standard"
                                            sx={inputStyles}
                                        />
                                    )}
                                />

                                {/* <TextField
                                    type="number"
                                    name="days"
                                    label="Days"
                                    value={days || ''}
                                    variant="standard"
                                    onChange={handleChange}
                                    // validators={['required', 'isPasswordSixDigit']}
                                    // errorMessages={['This field is required', 'Password must be at least 6 digits']}
                                    sx={inputStyles}
                                /> */}

                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <MobileDateTimePicker
                                        label="End Date & Time"
                                        slotProps={{ textField: { variant: 'standard', fullWidth: true } }}
                                        disablePast
                                        onChange={(newToDate) => {
                                            const date = new Date(newToDate);
                                            const utcMilliseconds = Date.UTC(
                                                date.getFullYear(),
                                                date.getMonth(),
                                                date.getDate(),
                                                date.getHours(),
                                                date.getMinutes(),
                                                date.getSeconds(),
                                                date.getMilliseconds()
                                            );
                                            setState({ ...state, toDatetime: utcMilliseconds })

                                        }}

                                        renderInput={(params) => (
                                            <TextField {...params} sx={inputStyles} />
                                        )}
                                    />
                                </LocalizationProvider>
                            </Grid>

                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <TextField
                                    label="Reason"
                                    name="reason"
                                    variant="standard"
                                    multiline
                                    value={reason || ""}
                                    onChange={(event) => {
                                        const regex = /^[A-Za-z0-9&()\- ]{0,100}$/; // Allows letters, numbers, &, (), -, and spaces (max 100 chars)
                                        if (regex.test(event.target.value)) {
                                            handleChange(event); // Update state only if input is valid
                                        }
                                    }}
                                    sx={inputStyles}
                                    fullWidth
                                    inputProps={{
                                        maxLength: 100, // Limit the input length to 70 characters
                                    }}
                                />
                            </Grid>
                        </Grid>


                        <Button
                            color="info"
                            alignItems="right"
                            className={classes.titleItemRight}
                            variant="contained"
                            type="button"
                            onClick={refreshPage}
                            style={{ margin: '5px', marginBottom: '-20px', background: '#e0e0e0', color: 'black' }}
                        >
                            <Span sx={{ textTransform: 'capitalize' }}>Cancel</Span>
                        </Button>

                        <Button
                            color="success"
                            alignItems="right"
                            className={classes.titleItemRight}
                            variant="contained"
                            type="submit"
                            style={{ margin: '5px', marginBottom: '-20px' }}
                        >
                            <Span sx={{ textTransform: 'capitalize' }}>Submit</Span>
                        </Button>

                    </ValidatorForm>
                    <ToastContainer
                        position="top-right"
                        autoClose={2000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="colored"
                    />
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default AddDelegation;