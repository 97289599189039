import { Button, Grid, styled } from '@mui/material';
import { Span } from 'app/components/Typography';
import { useEffect, useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { ToastContainer, toast } from 'react-toastify';
import { makeStyles } from '@material-ui/core/styles';
import 'react-toastify/dist/ReactToastify.css';
import { useThemeContext } from 'app/ThemeProvider';

import Dialog from '@mui/material/Dialog';

import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import DepartmentApi from './DepartmentApi';

const TextField = styled(TextValidator)(() => ({
    width: '100%',
    marginBottom: '16px',
}));
const style = makeStyles({
    titleItemRight: {
        float: 'right',
        position: 'relative',
        transform: 'translateY(-50%)',
        marginTop: 10,
    },
});

const AddDepartment = ({ setRefresh }) => {
    const [state, setState] = useState({ orgId: localStorage.getItem('orgId') });
    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    const api = new DepartmentApi();
    const classes = style();
    const [roles, setRoles] = useState([
        { id: 1, name: "ROLE_ADMIN" }, { id: 2, name: "ROLE_USER" }
    ])
    const { darkTheme } = useThemeContext();
    useEffect(() => {
        ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
            if (value !== state.password) return false;

            return true;
        });

        ValidatorForm.addValidationRule('isPasswordSixDigit', (value) => {
            if (value.toString().length < 6) return false;

            return true;
        });

        return () => ValidatorForm.removeValidationRule('isPasswordMatch');
    }, [state.password]);

    const handleSubmit = (event) => {
        setRefresh(true);
        debugger;

        event.preventDefault();
        api
            .add(state)
            .then((res) => {
                toast.success('Successfully added.');
                setRefresh(false);
                setOpen(false)
            })
            .catch((e) => {
                return toast.error(e.message);
                console.log('Cannot add  data', e);
            }).finally(() => setRefresh(false), setState({ ...state, name: "" }));

    };



    const refreshPage = (event) => {
        setOpen(false);
    };

    const handleChange = (event) => {
        event.persist();
        setState({ ...state, [event.target.name]: event.target.value });
    };

    const { name, orgId } = state;


    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Stack spacing={6} direction="row" justifyContent="right" color={'white'}>
                <Button
                    onClick={handleClickOpen}
                    variant="contained"
                    style={{ border: '2px solid', borderColor: darkTheme ? '#f2e34c' : '#000', color: '#000', backgroundColor: darkTheme ? '#f2e34c' : 'white', fontSize: '15px', fontWeight: 'bold', borderRadius: '25px', paddingLeft: '15px', paddingRight: '15px' }}

                >
                    +1 NEW DEPARTMENT
                </Button>
            </Stack>
            <Dialog
                open={open}
                onClose={(_, reason) => {
                    if (reason !== 'backdropClick') {
                        handleClose();
                    }
                }}
                disableEscapeKeyDown="true"
                fullWidth="false"
                maxWidth="sm"
            >
                <DialogTitle sx={{ backgroundColor: darkTheme ? '#333333' : 'fff', color: darkTheme ? '#f2e34c' : 'black' }}> Add New Department</DialogTitle>
                <DialogContent sx={{ backgroundColor: darkTheme ? '#333333' : 'fff' }}>
                    <ValidatorForm onSubmit={handleSubmit} onError={() => null}>
                        <Grid container spacing={6}>
                            <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
                                <TextField
                                    type="text"
                                    name="name"
                                    id="standard-basic"
                                    value={name || ''}
                                    onChange={(event) => {
                                        const regex = /^[A-Za-z0-9-& ]{0,70}$/; // Allow letters, numbers, "-", "&", and spaces (max 70 chars)
                                        if (regex.test(event.target.value)) {
                                            handleChange(event); // Only update state if input is valid
                                        }
                                    }}
                                    errorMessages={['This field is required']}
                                    label=" Department Name"
                                    validators={['required']}
                                    variant="standard"
                                    style={{ borderColor: darkTheme ? '#fff' : '#000' }}
                                    inputProps={{
                                        maxLength: 70, // Limits input length to 70 characters
                                    }}
                                    InputProps={{
                                        style: { color: darkTheme ? '#fff' : '#000' },
                                    }}
                                    InputLabelProps={{
                                        style: { color: darkTheme ? '#fff' : '#000' },
                                    }}
                                    sx={{
                                        "& .MuiInput-underline:before": {
                                            borderBottomColor: darkTheme ? '#fff' : '#000',
                                        },
                                        "& .MuiInput-underline:after": {
                                            borderBottomColor: darkTheme ? '#fff' : '#000',
                                        },
                                        "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                                            borderBottomColor: darkTheme ? '#fff' : '#000',
                                        },
                                        "& .MuiInputBase-input": {
                                            color: darkTheme ? '#fff' : '#000',
                                        },
                                    }}
                                />
                            </Grid>
                        </Grid>

                        <Button
                            color="info"
                            alignItems="right"
                            className={classes.titleItemRight}
                            variant="contained"
                            type="button"
                            onClick={refreshPage}
                            style={{ margin: '5px', marginBottom: '-20px', background: '#e0e0e0', color: 'black' }}
                        >
                            <Span sx={{ textTransform: 'capitalize' }}>Cancel</Span>
                        </Button>

                        <Button
                            color="success"
                            alignItems="right"
                            className={classes.titleItemRight}
                            variant="contained"
                            type="submit"
                            style={{ margin: '5px', marginBottom: '-20px' }}
                        >
                            <Span sx={{ textTransform: 'capitalize' }}>Submit</Span>
                        </Button>

                    </ValidatorForm>
                    <ToastContainer
                        position="top-right"
                        autoClose={2000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="colored"
                    />
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default AddDepartment;