import { Stack, Button, Icon, Paper, Grid, Typography, Tabs, Tab, Card } from '@mui/material';
import { Box, styled } from '@mui/system';
import { SimpleCard } from 'app/components';
import { useEffect, useState } from 'react';
import { componentStyle } from 'app/utils/utils';
import { useThemeContext } from 'app/ThemeProvider';
import { CustomTabPanel } from 'app/views/charger/ChargerAnalytics';
import UserForm from '../usrmgmt/UserForm';
import Analytics from '../department/DepartmentAnalytics';
import UserListTable2 from '../usrmgmt/UserListTable2';
import DepartmentTable from '../department/DepartmentTable';
import AddUserForm from '../usrmgmt/AddUserForm';
import AddAlertUserForm from '../alertmgmt/AddAlertUserForm';
import AddDepartment from '../department/AddDepartment';
import DelegationAnalytics from './delegationMgmt/DelegationAnalytics';
import AddDelegation from './delegationMgmt/AddDelegation';
import AlertUserListTable from '../alertmgmt/AlertUserListTable';
import { useNavigate } from 'react-router-dom';

const ManagementAnalytics = () => {

    const user = JSON.parse(localStorage.getItem("user"));


    const [userRefresh, setUserRefresh] = useState(false);
    const [departmentRefresh, setDepartmentRefresh] = useState(false);
    const [alertRefresh, setAlertRefresh] = useState(false);
    const [delegationRefresh, setDelegationRefresh] = useState(false);

    const { darkTheme } = useThemeContext();
    const [value, setValue] = useState(0);
    const navigate = useNavigate();


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

      useEffect(() => {
    
        const permissions = JSON.parse(localStorage.getItem("permission"))
          .filter(obj => obj.type === 'setting')
          .map(obj => obj.name) ?? [];
    
        if (!permissions.includes("User Administration"))
          navigate('/setdepot/default');
    
      }, []);




    return (
        <Grid
            margin={componentStyle.margin}
        >
            <Grid container style={{ padding: "10px", }}>

                <Grid item
                    lg={8} md={8} sm={8} xs={8}
                    sx={{
                        borderBottom: 1, borderColor: 'divider',
                        display: 'flex', justifyContent: 'left', alignItems: 'left'
                    }}>

                    <Tabs value={value}
                        onChange={handleChange}
                        textColor="black"
                        // backgroundColor="grey"
                        // style={{
                        //     margin: "20px 0 20px 0",

                        // }}
                        variant="scrollable" // Makes the tabs scrollable
                        scrollButtons="auto" // Show scroll buttons when necessary


                        indicatorColor="grey"
                        aria-label="secondary tabs example"
                    >
                        <Tab
                            style={{ backgroundColor: value === 0 ? 'black' : 'lightgrey', color: value === 0 ? 'white' : 'black', borderTopLeftRadius: 15, borderBottomLeftRadius: 15 }}
                            label="Users " />

                        {
                            user && Array.isArray(user.role) && user.role.length > 1 ?
                                "" :
                                <Tab
                                    style={{
                                        backgroundColor: value === 1 ? 'black' : 'lightgrey', color: value === 1 ? 'white' : 'black',
                                    }}
                                    label="Delegation" />
                        }
                        <Tab
                            style={{
                                backgroundColor: value === 2 ? 'black' : 'lightgrey', color: value === 2 ? 'white' : 'black',
                            }}
                            label="Departments" />

                        <Tab
                            style={{
                                backgroundColor: value === 3 ? 'black' : 'lightgrey', color: value === 3 ? 'white' : 'black',

                                borderTopRightRadius: 15, borderBottomRightRadius: 15,
                                
                            }}
                            label="Alert" />



                    </Tabs>
                </Grid>

                <Grid item
                    lg={4} md={4} sm={4} xs={4}
                    sx={{
                        borderBottom: 1, borderColor: 'divider',
                        padding: '10px',
                        display: 'flex', justifyContent: 'right', alignItems: 'right'
                    }}>

                    {(() => {

                        switch (value) {
                            case 0: return <AddUserForm setRefresh={setUserRefresh} />
                            case 2: return <AddDepartment setRefresh={setDepartmentRefresh} />
                            case 1: return <AddDelegation setRefresh={setDelegationRefresh} />
                            case 3: return <AddAlertUserForm setRefresh={setAlertRefresh} />
                        }
                    })()
                    }

                </Grid>


                <CustomTabPanel value={value} index={0}>

                    <Card style={{ width: "100%", backgroundColor: '#f9f9f9' }}>

                        <UserListTable2 refresh={userRefresh} setRefresh={setUserRefresh} />
                    </Card>

                </CustomTabPanel>
                <CustomTabPanel value={value} index={2} width={"100%"}>


                    <Card style={{ backgroundColor: '#f9f9f9' }}>

                        <DepartmentTable refresh={departmentRefresh} setRefresh={setDepartmentRefresh} />
                    </Card>



                </CustomTabPanel>
                <CustomTabPanel value={value} index={1} width={"100%"}>


                    <Card style={{ backgroundColor: '#f9f9f9' }}>

                        <DelegationAnalytics refresh={delegationRefresh} setRefresh={setDelegationRefresh} />
                    </Card>



                </CustomTabPanel>

                <CustomTabPanel value={value} index={3} width={"100%"}>


                    <Card style={{ backgroundColor: '#f9f9f9' }}>

                        <AlertUserListTable refresh={alertRefresh} setRefresh={setAlertRefresh} />
                    </Card>



                </CustomTabPanel>


            </Grid>

        </Grid>
    );
};

export default ManagementAnalytics;
// Registered Users